import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Avail } from 'src/app/common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-patient-model',
  templateUrl: './patient-model.component.html',
  styleUrls: ['./patient-model.component.css']
})
export class PatientModelComponent implements OnInit {

  haType: string = '';
  webURL: string;
  @Input() lstPH1: any;
  @Input() lstPH2: any;
  @Input() appUrl: string;
  @Input() isConsultationForDependent: boolean;
  @Input() toastr: any;
  @Output() onViewDoc = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void { 
    this.webURL = location.href.includes('localhost') ? environment.applicationURL : location.origin;
  }

  // onViewPH(pocId: any, files: any, type: string) {
  //   if (files.length > 0) {
  //     let filePrfx = '', filePath = '';

  //     if (type == 'h') {
  //       filePrfx = this.appUrl + Avail.fileUrl + Avail.VstFilesTxt + pocId + `/${Avail.VstFleFTId}/`;
  //       filePath = files.filter(f => f.file_type_id == Avail.VstFleFTId)[0]?.document_path;
  //     }
  //     else {
  //       let atchFile = files.filter(f => f.file_type_id != Avail.VstFleFTId && f.file_type_id != Avail.InvcFTId);
  //       filePath = atchFile.length > 0 ? atchFile[atchFile.length - 1].document_path : null;
  //       if (filePath != null)
  //         filePrfx = this.appUrl + Avail.fileUrl + Avail.VstFilesTxt + pocId + '/' + atchFile[0]?.file_type_id + '/'; //`/${atchFile[0]?.file_type_id}/`;
  //     }

  //     if (filePath != undefined)
  //       this.onViewDoc.emit(`${filePrfx}${filePath}`);
  //     else
  //       this.toastr.info('No file found');
  //   }
  //   else
  //     this.toastr.info('No file found');
  // }
  onViewPH(pocId: any, files: any, type: string) {
    if (files.length > 0) {
      let filePrfx = '', filePath = '';

      if (type == 'h') {
        // filePrfx = this.appUrl + Avail.fileUrl + Avail.VstFilesTxt + pocId + `/${Avail.VstFleFTId}/`; 
        filePrfx = this.webURL+'/'+ Avail.vmedFileUrlNew + Avail.VstFilesTxt + pocId + `/prescription/`; 
        filePath = files.filter(f => f.file_type_id == Avail.VstFleFTId)[0]?.document_path;
      }
      else {
        let atchFile = files.filter(f => f.file_type_id != Avail.VstFleFTId && f.file_type_id != Avail.InvcFTId);
        filePath = atchFile.length > 0 ? atchFile[atchFile.length - 1].document_path : null;
        if (filePath != null)
        filePrfx = this.appUrl + Avail.vmedFileUrlNew + Avail.VstFilesTxt + pocId + '/prescription/'  ; //`/${atchFile[0]?.file_type_id}/`;
          // filePrfx = this.appUrl + Avail.fileUrl + Avail.VstFilesTxt + pocId + '/' + atchFile[0]?.file_type_id + '/'; //`/${atchFile[0]?.file_type_id}/`;
    
        }

      if (filePath != undefined)
        this.onViewDoc.emit(`${filePrfx}${filePath}`);
      else
        this.toastr.info('No file found');
    }
    else
      this.toastr.info('No file found');
  }
  // onViewDocPH(item: any, type: string) {
  //   if (item.document_path != '' && item.document_path != null && item.document_path != undefined) {
  //     let filePath = this.appUrl + Avail.fileUrl + (this.isConsultationForDependent == true ? Avail.MemDocsTxt : Avail.BnfcryTxt);
  //     if (type == 'a')
  //       this.onViewDoc.emit(filePath + item.document_path.split('_')[0] + `/${item.file_type_id}/${item.document_path}`);
  //   }
  // }
  onViewDocPH(item: any, type: string) {
    if (item.document_path != '' && item.document_path != null && item.document_path != undefined) {
      // let filePath = this.appUrl + Avail.fileUrl + (this.isConsultationForDependent == true ? Avail.MemDocsTxt : Avail.BnfcryTxt);
      let filePath = this.appUrl + Avail.vmedFileUrlNew + (this.isConsultationForDependent == true ? Avail.MemDocsTxt : Avail.BnfcryTxt);
     
      if (type == 'a')
        this.onViewDoc.emit(filePath + item.document_path.split('_')[0]+`/BeneficiaryLabReport` + `/${item.document_path}`);
    }
  }

}