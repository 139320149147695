import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiService } from '../services/api.service';

@Injectable({
  providedIn: 'root'
})
export class LabandPharmacyService {

  constructor(private api: ApiService, private httpClient: HttpClient) { }
  
  get(queryString: string): Observable<any> { return this.api.get(`${environment.loginAPI}${environment.pharmacyAPI}${queryString}`); }
  getStoresName(queryString: string): Observable<any> { return this.api.get(`${environment.loginAPI}registration/jwt/pharmacy/${queryString}`); }
  getReports(queryString: string): Observable<any> { return this.api.get(`${environment.pharmacyAPI}${queryString}`); }
  
  saveReports(apiMethod: string, data: any): Observable<any> { 
    
    return this.api.post(`${environment.pharmacyAPI}${apiMethod}`, data) }

  

  save(apiMethod: string, data: any): Observable<any> { 
    return this.api.post(`${environment.loginAPI}${environment.pharmacyAPI}${apiMethod}`, data) }

  savePrescription(apiMethod: string, data: any): Observable<any> { return this.api.post(`${environment.loginAPI}${environment.labprescription}${apiMethod}`, data) }
  
  getImage(imageUrl: string): Observable<Blob> {
    return this.httpClient.get(`${environment.downloadPath}${imageUrl}`, { responseType: 'blob' });
  }

}
