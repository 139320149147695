import { FormArray, FormGroup } from "@angular/forms";

export class Ayush_POC {
    public static updateStage(pocAyuForm: FormGroup, covid19Obj): void {
        pocAyuForm.get('covid19.stage').valueChanges.subscribe(e => {
            covid19Obj.stageView = e;
        });
    }

    // public static getChiefComplaintList(pocAyuForm: FormGroup, chiefComplaintList: Array<any>, stageCounts: { MILD: number, MODERATE: number, SEVERE: number }) {
    //     pocAyuForm.get('covid19.presenting_complaints').valueChanges.subscribe(e => {
    //         const notes1FormControl = pocAyuForm.get('cc_brief_history.notes1');
    //         let symptoms: string = '';
    //         stageCounts = { MILD: 0, MODERATE: 0, SEVERE: 0 };

    //         e?.forEach((d: any) => {
    //             if (d.check_box) {
    //                 symptoms += symptoms.length == 0 ? d.symptoms : (',' + d.symptoms);
    //                 if (d.stage == 'MILD')
    //                     stageCounts.MILD++;
    //                 else if (d.stage == 'MODERATE')
    //                     stageCounts.MODERATE++;
    //                 else if (d.stage == 'SEVERE')
    //                     stageCounts.SEVERE++;
    //             }
    //         });
    //         notes1FormControl.setValue(chiefComplaintList.filter(e => symptoms.split(',').includes(e.chief_complaint)))

    //     });

    // }

    public static general_examination(pocAyuForm: FormGroup) {
        pocAyuForm.get('covid19.general_examination.TEMPERATURE').valueChanges.subscribe(e => {
            pocAyuForm.get('vital_history.temperature').setValue(e);
        });
        pocAyuForm.get('covid19.general_examination.PULSE').valueChanges.subscribe(e => {
            pocAyuForm.get('vital_history.pulse').setValue(e);
        });
        pocAyuForm.get('covid19.general_examination.Systolic').valueChanges.subscribe(e => {
            pocAyuForm.get('vital_history.systolic').setValue(e);
        });
        pocAyuForm.get('covid19.general_examination.Diastolic').valueChanges.subscribe(e => {
            pocAyuForm.get('vital_history.diastolic').setValue(e);
        });
    }

    public static enableOrDisable(pocAyuForm: FormGroup) {
        const presenting_complaints = pocAyuForm.get('covid19.presenting_complaints') as FormArray;
        presenting_complaints.controls.forEach((cont: FormGroup) => {
            const control = cont.controls;
            if (cont.value.check_box) {
                control.day_of_consultation.enable();
                control.remarks_if_any.enable();
                control.stage.enable();
            }
            else {
                control.day_of_consultation.disable();
                control.remarks_if_any.disable();
                control.stage.disable();
            }
        });
    }

    public static clearCovid19Form(pocAyuForm: FormGroup) {
        pocAyuForm.patchValue({
            covid19: {
                patient_identified: {
                    investigations: '',
                    investigations_date: '',
                    ct_value: '',
                    name: '',
                    reason_for_testing: '',
                    reason_date: '',
                    specify: '',
                },
                co_morbidities: {
                    DM: '',
                    hypertension: '',
                    COPD: '',
                },
                symptoms: false,
                presenting_complaints: [],
                present_illness: '',
                otherName: '',
                general_examination: {
                    SPO2: '',
                    PULSE: '',
                    TEMPERATURE: '',
                    RR: '',
                    WEIGHT: '',
                    Systolic: '',
                    Diastolic: ''
                },
                test: {
                    RT_PCR: '',
                    HRCT: '',
                    CRP: '',
                    IL6: '',
                    D_DIMER: '',
                    S_FERRITIN: '',
                    LDH: '',
                    ESRL: '',
                },
                clinical_evalution_of_the_patient: {
                    started_home_isolation: '',
                    medical_advice: false,
                    vaccination_status: '',
                    // status_Date: [''],
                    vaccine_name: '',
                    other_name: '',
                    vaccination_date: '',
                    symptom_status: '',
                    // asymptom_status: ['']
                },
                medicines_being_taken: {
                    Medicines: '',
                    other_Rx_for_COVID19: '',
                    any_other_medicines: '',
                },
                stage: '',
            }
        })
    }
}