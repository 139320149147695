
//sushma 10-08-2022 common validations
declare let $: any;

export class commonValidation {
  constructor() { }

  keyPressAlphabets(event) {
    var inp = String.fromCharCode(event.keyCode);

    if (/[a-zA-Z ]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  keyPressAlphaNumeric(event) {
    //  var inp = String.fromCharCode(event.keyCode);
    var inp = event.key;
    if (/[a-zA-Z0-9]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  address(event) {
    //  var inp = String.fromCharCode(event.keyCode);
    var inp = event.key;
    if (/^[#.0-9a-zA-Z\s,-]+$/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
  

  // Only Integer Numbers
  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;

    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  //Email Validation
  ValidateEmail(tagId: any) {
    var testEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var valueToTest = $("#" + tagId).val();
    if (!(testEmail.test(valueToTest))) {
      $('#' + tagId).focus();
      $('#' + tagId).val('');
      alert("Not a valid e-mail address");
      return false;
    }
    else {
      return true;
    }
  }
}
