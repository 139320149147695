<div class="modal-header">
    <h4 class="modal-title">Family Members List & Registration</h4>
    <button type="button" class="close" aria-label="Close" (click)="onClose()">
        <span aria-hidden="true">&times;</span>
    </button>
    <!-- <button type="button" class="close" aria-label="Close" (click)="onBack()">
        <span aria-hidden="true">&times;</span>
    </button> -->
</div>
<div class="modal-body">
    <div class="container-fluid">
        <form [formGroup]="familyForm" (ngSubmit)="onSubmit($event)" >
            <div class="row">
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">First Name <span class="text-danger">*</span> </label>
                        <input type="text" autocomplete="off" formControlName="first_name"
                            class="form-control form-control-sm text-uppercase"   onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                            (event.charCode >= 97 && event.charCode <= 122)|| event.charCode == 32)' [ngClass]="{ 'is-invalid': submitted && f.first_name.errors }"
                           >
                          
                       <div *ngIf="submitted && f.first_name.errors" class="invalid-feedback">
                            <div *ngIf="f.first_name.errors.required"> First Name is required</div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> Last Name </label>
                        <input type="text" autocomplete="off" formControlName="last_name"
                            class="form-control form-control-sm text-uppercase"   onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                            (event.charCode >= 97 && event.charCode <= 122)|| event.charCode == 32)' 
                           >
                          
                       <!-- <div *ngIf="submitted && f.last_name.errors" class="invalid-feedback">
                            <div *ngIf="f.last_name.errors.required"> Last Name is required</div>
                        </div> -->
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Email </label>
                        <div>
                            <input type="text" autocomplete="off" maxlength="60" class="form-control form-control-sm"
                                formControlName="email"   [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                               >
                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                <div *ngIf="f.email.errors.pattern">Enter Valid Email</div>
                            </div>
                        </div>
                    </div>
                 
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> Mobile Number </label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="mobile" id=" mobile"
                            maxlength="10"
                            onkeypress='return (event.charCode >= 48 && event.charCode <= 57)' [pattern]="mobPtrn"
                                class="form-control form-control-sm"   [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }" >
                                <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
                                    <div *ngIf="f.mobile.errors.pattern">Mobile Number {{mobErrMsg}}</div>
                                </div>
                        </div>
                    </div>
                  
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Age <span class="text-danger">*</span> </label>
                        <input type="text" autocomplete="off" formControlName="age"
                            onkeypress='return(event.charCode >= 48 && event.charCode <= 57)'
                            class="form-control form-control-sm text-uppercase" maxlength="3"  [ngClass]="{ 'is-invalid': submitted && f.age.errors }">
                            <div *ngIf="submitted && f.age.errors" class="invalid-feedback">
                                <div *ngIf="f.age.errors?.required">Age is required</div>
                            </div>
                    </div>
                </div>
               
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Gender <span class="text-danger">*</span> </label>
                        <div>
                            <select class="form-control form-control-sm" formControlName="gender_id"  [ngClass]="{ 'is-invalid': submitted && f.gender_id.errors }">
                                <option value="" selected>Select</option>
                                <option  *ngFor="let gen of genderList" [value]="gen.id" >{{gen.gender_name}}</option>          
                            </select>
                            <div *ngIf="submitted && f.gender_id.errors" class="invalid-feedback">
                                <div *ngIf="f.age.errors?.required">Gender is required</div>
                            </div>      
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3">
                        <div class="form-group">
                            <label class="control-label">Relation <span class="text-danger">*</span> </label>
                            <div>
                                <select class="form-control form-control-sm"  formControlName="relation_id" [ngClass]="{ 'is-invalid': submitted && f.relation_id.errors }" >
                                    <option value="" selected>Select</option> 
                                    <option  *ngFor="let rel of relationList" [value]="rel.id">{{rel.relation}}</option>                         
                                </select>
                                <div *ngIf="submitted && f.relation_id.errors" class="invalid-feedback">
                                    <div *ngIf="f.relation_id.errors?.required">Relation is required</div>
                                </div>  
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 mt-2">
                            <div class="form-group">
                                <label class="control-label"><span class="text-danger"></span> </label>
                                <div>
                                    <input type="submit" class="btn btn-sm btn-primary" value="Add Family Member" >&nbsp;&nbsp;
                                    </div>
                                </div>
                            </div>
                </div>
        </form>
    </div>
    <div class="table-responsive">
        <table datatable id="FamilyTable" class="table table-striped table-bordered table-sm">
            <thead>
                <tr>
                    <th>S No </th>
                    <th>Patient Name</th>
                    <th>Mobile</th>
                    <th>Email</th>
                    <th>Gender</th>
                    <th>Age</th>
                    <!-- <th *ngIf="showCard== true && menuItems.isVle ">Health Card</th> -->
                    <th *ngIf="+roleId==7">POC</th>
                    <th *ngIf="+roleId!=14">EHR</th>
                    <th *ngIf="+roleId==14" colspan="4">Action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of familyLists; let i=index">
                    <td style="width: 8%;" class="text-center">{{i+1}}</td>
                    <td>{{item.member_name | uppercase}}</td>
                    <td>{{item.mobile}}</td>
                    <td>{{item.email}}</td>
                    <td *ngIf="item.gender_id == 1">Male</td>
                    <td *ngIf="item.gender_id == 2">Female</td>
                    <td *ngIf="item.gender_id == 3">Transgender</td>
                    <td>{{item.age}}</td> 
                    <!-- <td *ngIf="showCard== true && menuItems.isVle "><button class="btn btn-success btn-sm ml-5"  (click)="memberHCDwnld(item)"><span><i class="fas fa-download"></i></span></button></td> -->
                    <td *ngIf="+roleId==7"><button class="btn btn-sm btn-success btn-responsive" (click)="OfflinePocSubmit(item)">POC</button>
                    </td>
                    <!-- New lines added for family member EHR 11-05-2022 #chandu" -->
                    <td *ngIf="+roleId!=14"><button class="btn btn-sm btn-success btn-responsive" (click)="getpatientid(item)">EHR</button>
                    </td>
                    <!-- End lines family member EHR -->
                    <td *ngIf="+roleId==14"> 
                        <button class="btn btn-sm btn-success ml-1" (click)="opendoclist(item)">Call</button>
                    </td>
                </tr>
            </tbody>
        </table> 
    </div>
</div>



