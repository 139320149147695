<!-- sushma 09-08-2022  tpa list-->

<div class="row">
    <div class="container-fluid pl-0 pr-0">
        <div class="row">
            <div class="col-xl-12">
                <div class="card card-header-actions mx-auto">
                    <!--  -->
                    <div class="card-header">
                        Screen Creation 
                        <button class="btn btn-blue btn-icon float-right" *ngIf="!showDetails"
                            (click)="openModal()"><i class="fa fa-plus"></i>
                        </button>
                        <a class="float-right" *ngIf="showDetails" (click)="onBack()">Back</a>
                   </div>
                    <div class="card-body" *ngIf="!showDetails">
                        <div id="accordion" class="accordion">
                            <div *ngFor="let item of updatedSPL; let i=index;">
                              <div class="card-header collapsed" data-toggle="collapse" data-parent="#accordion"
                                [attr.data-target]="'#collapse'+(i+1)" [id]="'heading'+(i+1)">
                                <a class="card-title font-weight-bold p-1">
                                  {{item?.name}}
                                </a>
                              </div>
                              <div [attr.aria-labelledby]="'heading'+(i+1)" [id]="'collapse'+(i+1)" class="card-body collapse"
                                data-parent="#accordion">
                                <div class="table-responsive">
                                  <table datatable id="{{'newpermissionTable'+(i+1)}}"
                                    class="table table-striped table-bordered table-sm" style="width:100%">
                                    <thead>
                                      <tr style="background-color: #5786e7; color: white;">
                                        <th>S. No..</th>
                                        <th>Screen Label</th>
                                        <th>Screen Code<br>
                                          (Screen Id)</th>
                                          <th>Status</th>
                                        <!-- <th>View/Edit</th> -->
                                        <th>Routing Path</th>
                                        <th>Screen Order</th>
                                        <!-- <th>Updated On</th> -->
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr *ngFor="let innerItem of item.lst;let j=index;"
                                        [ngStyle]="{'background-color':innerItem?.is_active == false ? 'rgb(241 198 177)' : ''}">
                                        <td>{{j+1}}</td>
                                        <td>{{innerItem.screen_label}}</td>
                                       <!-- <td>{{innerItem.screen_name}}<br>
                                          ({{innerItem.id}})</td>  -->
                                        <!-- <td>{{getSubmoduleLabel(innerItem.sub_module_id)}}</td> -->
                                        <td><span>
                                            <a class="text-success" title="Edit" data-toggle="modal" data-target="#myModal"
                                              (click)="Edit(innerItem)">{{innerItem.screen_name}}<br>
                                              ({{innerItem.id}})</a>
                                          </span>
                                        </td>
                                        <td>
                                          {{innerItem.is_active == true ? 'Active' : 'In Active'}}</td>
                                        <td>{{innerItem.routing}}</td>
                                        <td>{{innerItem.order_no}}</td>
                                        <!-- <td> {{innerItem.modified_on | date:'dd-MM-yyyy HH:mm' }}</td> -->
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="myModal" role="dialog">
  <div class="modal-dialog modal-lg">
      <div class="modal-content">
          <div class="modal-header pt-3 pl-3  pb-1">
              <h6 class="mb-0 font-weight-bold" style="color:#5786e7">Add Screen Creation</h6>
              <button type="button" class="close" (click)="onClose()">&times;</button>
          </div>
          <div class="modal-body small">
            <div class="row">
              <div class="col-xl-3">
                <label class="control-label">Module Name</label>
                 <div>
                 <select type="text"  class="form-control form-control-sm" [(ngModel)]="moduleName"
                 [ngClass]="{ 'is-invalid': submitted && moduleName==0}">
                    <option value=0>Select</option>
                    <option *ngFor="let item of moduleLst" [value]="item.id">{{item.module_name}}</option>
                </select>
                <div *ngIf="submitted && moduleName==0" class="invalid-feedback">Module Name is required</div>
              </div>
            </div>
            <div class="col-xl-3">
                <label class="control-label">Screen Label</label>
                <div>
                    <input type="text"  class="form-control form-control-sm" [(ngModel)]="screenLabel"
                    [ngClass]="{ 'is-invalid': submitted && screenLabel==''}">
                    <div *ngIf="submitted && screenLabel==''" class="invalid-feedback">Screen Label is required</div>
              </div>
                </div>
            <div class="col-xl-3">
                <label class="control-label">Screen Code</label>
                <div>
                    <input type="text"  class="form-control form-control-sm" [(ngModel)]="screenName" [ngClass]="{ 'is-invalid': submitted && screenName==''}">
                    <div *ngIf="submitted && screenName==''" class="invalid-feedback">Screen Code is required</div>
                </div>
           </div>
            <div class="col-xl-3">
                <label class="control-label">Order No</label>
                <div>
                    <input type="text"  class="form-control form-control-sm" [(ngModel)]="orderNumber"
                    [ngClass]="{ 'is-invalid': submitted && orderNumber==0}">
                    
                    <div *ngIf="submitted && orderNumber==0" class="invalid-feedback">Order No is required</div>
                </div>
           </div>
           <!-- <button type="button" class="btn btn-sm btn-danger float-right mt-4" (click)="onClose()">Close</button> -->
        <div class="col-xl-3 mt-4">
            <label class="control-label">fa fa icon</label>
            <div>
                <input type="text"  class="form-control form-control-sm" [(ngModel)]="fafaicon">
            </div>
       </div>
        <div class="col-xl-3 mt-4">
            <label class="control-label">Routing</label>
            <div>
                <input type="text"  class="form-control form-control-sm" [(ngModel)]="routing"
                [ngClass]="{ 'is-invalid': submitted && routing==''}">
                    
                <div *ngIf="submitted && routing==''" class="invalid-feedback">Routing is required</div>
            </div>
       </div>
       <div class="col-xl-5 mt-4">
        <label class="control-label">Status <span class="text-danger">*</span></label> <br />
        <div class="">
            <div class="custom-control-inline custom-control custom-radio">
                <input [(ngModel)]="is_active" [value]="true" class="custom-control-input" id="crp1"
                    type="radio" name="is_active">
                <label class="custom-control-label" for="crp1">Active </label>
            </div>
            <div class="custom-control-inline custom-control custom-radio">
                <input [(ngModel)]="is_active" [value]="false" class="custom-control-input " id="crp2"
                    type="radio" name="is_active">
                <label class="custom-control-label" for="crp2">In Active </label>
            </div>
        </div>
    </div>
    <div class="col-xl-3 mt-2">
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="checkbox" id="inlineCheckbox1"
            name="inlineCheckbox1" value="option1" [(ngModel)]="can_insert">
          &nbsp;&nbsp;<label class="mt-1">Add</label>&nbsp;&nbsp;
          <input class="form-check-input" type="checkbox" id="inlineCheckbox2"
            name="inlineCheckbox2" value="option2" [(ngModel)]="can_update">
          &nbsp;&nbsp;<label class="mt-1">Edit</label>&nbsp;&nbsp;
          <input class="form-check-input" type="checkbox" id="inlineCheckbox3"
            name="inlineCheckbox3" value="option3" [(ngModel)]="can_view">
          &nbsp;&nbsp;<label class="mt-1">View</label>&nbsp;&nbsp;
          <input class="form-check-input" type="checkbox" id="inlineCheckbox4"
            name="inlineCheckbox4" value="option4" [(ngModel)]="can_delete">
          &nbsp;&nbsp;<label>Delete</label>
        </div>
      </div>
      </div>
    </div>
    <div class="modal-footer">
       
      <button type="button" class="btn btn-success"
    (click)="onSave()">Save</button>
    </div> 
      </div>
      
  </div>
</div>

