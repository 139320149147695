import { Permissions } from 'src/app/shared/models/permissions.enum';
import { ScreenNames } from 'src/app/shared/models/screen-names.enum';
import { ModuleNames } from 'src/app/shared/models/module-names.enum';
import { Common } from 'src/app/common';

export class BaseComponent {
  Permission = Permissions;
  Screen_Name = ScreenNames;
  Module_Names = ModuleNames;

  mode = ''; entityName = ''; jwtTkn = '';
  entity: any; apiResult: any = {}; result: any = [];
  loading = false; frmData = new FormData();

  // constructor() { }

  setPrivilege(screen: ScreenNames, permission: Permissions) {
    return Common.screenPermission(screen, permission);
  }

  // ngOnInit() {
  // }

  // async RetrieveData(apiType: string, apiMethod: string, payLoad: any = {}) {
  //   if (apiType != '') {
  //     this.result = [];
  //     if (apiType == 'Get') {
  //       await this.apiServ.get(apiMethod).
  //         subscribe({
  //           next: x => { let res:any = x; this.result = res.data, console.log(res.data)},
  //           error: err => console.error('something wrong occurred: ', err),
  //           complete: () => console.log('done'),
  //         });
  //     }
  //     else {
  //       await this.apiServ.post(apiMethod, payLoad).
  //         subscribe({
  //           next: x => {let res:any = x; this.result = res.data },
  //           error: err => console.error('something wrong occurred: ', err),
  //           complete: () => console.log('done'),
  //         });
  //     }
  //   }
  // }

  sortList(list: any) {
    list.sort((a, b) => (a.name === b.name) ? 0 : (a.name > b.name) ? 1 : -1)
  }

  pick(obj: any, keys: any) {
    return keys.map(k => k in obj ? { [k]: obj[k] } : {}).reduce((res, o) => Object.assign(res, o), {});
  }

}