import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './authentication.service';
// const httpOptions = {
//     headers: new HttpHeaders({ 'Content-Type': 'application/json' })
// };
@Injectable({ providedIn: 'root' })

export class MasterService {
    // url: string = 'http://registration.dhanushinfotech.com/esubcenter_master/';
    mastersAPI: string = '';
    spinnerCount: number = 0;
    logTnntId: number = null;
    constructor(private api: ApiService,private authSvc: AuthenticationService,) {
        this.mastersAPI = environment.mastersAPI;
    }

    getLocations(queryString: string): Observable<any> { return this.api.get(`${this.mastersAPI}${queryString}`); }
    getAll(queryString: string): Observable<any> { return this.api.get(`${this.mastersAPI}${queryString}`); }
    getAPI(queryString: string): Observable<any> { return this.api.get(`${queryString}`); }
    // new method added for get status types 29-03-2023
    getStatusTypes(queryString: string): Observable<any> { return this.api.get(`${this.mastersAPI}${queryString}`); }
   
    //investigation
    saveInvestigation(data: any): Observable<any> { return this.api.post(`${this.mastersAPI}saveandupdateinvestigation`, data); }
    deleteByinvestId(id: number): Observable<any> { return this.api.get(`${this.mastersAPI}deleteinvestigation?investigationId=${id}`); }

    //drug
    saveDrug(data: any): Observable<any> { return this.api.post(`${this.mastersAPI}saveandupdatedrug`, data); }
    deleteBydrugId(id: number): Observable<any> { return this.api.get(`${this.mastersAPI}deletedrug?drugId=${id}`); }
    deleteByCoupon(id: number): Observable<any> { return this.api.get(`${this.mastersAPI}deletecoupon?couponId=${id}`); }
    deleteByCompany(id: number): Observable<any> { return this.api.get(`${this.mastersAPI}mastercompany/deletebyid?id=${id}`); }
    
    // save(methodName: string, data: any): Observable<any> { return this.api.post(`${this.mastersAPI}${methodName}`, data); }
    //company
    getAllCompanies(apiMethod: string): Observable<any> { return this.api.get(`${this.mastersAPI}${apiMethod}`); }

    save(methodName: string, data: any): Observable<any> { return this.api.post2(`${this.mastersAPI}${methodName}`, data); }
    searchbyIdMasters(queryString: string): Observable<any> { return this.api.get(`${this.mastersAPI}${queryString}`); }

    //deletbystate
    deleteById(queryString: string): Observable<any> { return this.api.get(`${this.mastersAPI}${queryString}`); }
    
    //getallpocoptions
    getAllPocOptions(): Observable<any> { return this.api.get(`${this.mastersAPI}getallmastermoduletype`); }
    getalldrugs(): Observable<any> { return this.api.get(`${this.mastersAPI}getalldrugtypes`); }
    getDrugDetails(id: Number): Observable<any> { return this.api.get(`${this.mastersAPI}drugtypemapping/getdrugbydrugtypeid?drugTypeId=${id}`); }
    getAllpaymenttypes(): Observable<any> { return this.api.get(`${this.mastersAPI}vle/getall`); }
    getAllDistrict(): Observable<any> { return this.api.get(`${this.mastersAPI}getalldistricts`); }
    activateSpinner() {
        // this.spinnerCount++;
        // if (this.spinnerCount == 1) {
        //   this.appComponent.activateSpinner()
    
        // }
      }
    
      deactivateSpinner() {
        // if (!this.spinnerCount) return
        // this.spinnerCount--
        // if (!this.spinnerCount) this.appComponent.deactivateSpinner();
        // if (this.spinnerCount < 0) {
        //   this.spinnerCount = 0;
        //   this.appComponent.deactivateSpinner()
        // }
    
      }
      getallhitypes():Observable<any>{
        // below line commented for new api added 04-07-2023
        // return this.api.get(`${this.mastersAPI}ndhm/hitype/getallhitypes`);
        return this.api.get(`${this.mastersAPI}master-approval-status/get-all-type-id/8`);
        // https://telemedicine-uk-training.vmedtm.com/masters/ndhm/hitype/getallhitypes
      }
      
      getallpurposeofuse():Observable<any>{
        // below line commented for new api call 04-07-2023
        // return this.api.get(`${this.mastersAPI}ndhm/purpose/getallpurposeofuse`);
        return this.api.get(`${this.mastersAPI}master-approval-status/get-all-type-id/7`);
        // https://telemedicine-uk-training.vmedtm.com/masters/ndhm/hitype/getallhitypes
      }
    //new apis added on 06-07-2023   #kranthi
       getDurationType(queryString: string): Observable<any> { 
        return this.api.get(`${this.mastersAPI}${queryString}`);
       } 
      /////////////////////////////////////spinner///////////////////////////////////////
      get(apiPoints): Observable<any> {
        const p= apiPoints.includes('?')?'&':'?';
        let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
        return this.api.get(`${apiPoints}${p}TENANT_ID=${tntId}`); }
        post(apiMethod: string, data: any): Observable<any> {
          let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
          if (tntId != null)
            return this.api.post(`${apiMethod}?TENANT_ID=${tntId}`, data);
        }
    
}