import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserIdleTimerService } from 'src/app/shared/services/user-idle-timer.service';
import { Avail } from 'src/app/common';

import { UserService } from 'src/app/shared/services/user.service';
import { BaseComponent } from 'src/app/shared/base.component';
import { environment } from 'src/environments/environment';
import { ApiService } from 'src/app/shared/services/api.service';
import { ChangePasswordComponent } from './../change-password/change-password.component';
import { MissedcallComponent } from '../modals/missedcall/missedcall.component';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { DatePipe } from '@angular/common';
import { shareReplay, takeUntil } from 'rxjs/operators';
import { IvideoComponent } from '../modals/ivideo/ivideo.component';
import { IvideosComponent } from '../modals/ivideos/ivideos.component';
import { ChatService } from 'src/app/shared/services/chat.service';
import { ChatMessageDto } from 'src/app/shared/models/user';
import { browserRefresh } from 'src/app/app.component';
import { BehaviorSubject, Subject, fromEvent } from 'rxjs';
import { DoctorRegistrationComponent } from '../pro/registrations/doctor-reg/doctor-registration/doctor-registration.component';
import { MenuService } from 'src/app/shared/axaservices/menu.service';
import { CommonDoctorRegistrationComponent } from '../pro/registrations/doctor-reg/common-doctor-registration/common-doctor-registration.component';
declare let $: any;
declare let closeAllSockets: any;
declare let register: any;
declare let statusRegister: any;
declare const updtLogoutSocket: any;  // stopVideo
declare let cancelCall:any;
declare let stopVideo: any;


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [DatePipe]
})

export class HomeComponent extends BaseComponent implements OnInit {
  current_year: number = (new Date()).getFullYear();
  isMenuOpen: boolean;
  userName: string = '';
  userId: any;
  id: number
  roleId: number = 0;
  role_name: string = '';
  logourl = '';
  orgfileDomainPath: string = environment.organizationFilePath;
  consultation: boolean = true;
  isNandGarh: boolean = false;
  registration: boolean = true;
  administration: boolean = true;
  master: boolean = true;
  admin: boolean = true;
  isDoctor = true;
  appUrl = '';
  moduleList: Array<{ id: number, module_name: string, canView: boolean }> = []; isGlblOrgShow = false;
  screenList: Array<any> = []; permissionList: Array<any> = []; version: string = '2.1'; isLcl = location.href.includes('localhost');
  msdCalVal = 0; umDocPath = ''; appSrce = ''; clientName = ''; logo = ''; logo1 = ''; tollFree = ''; lcnsPlanName = ''; isShowAPK = false;
  adminUMpath = environment.adminUM; docUMpath = environment.doctorUM; d1Vdo = environment.docHomeVdo; d2Vdo = environment.docConsVdo;
  drData: any; usrData: any; appleLink = environment.appleLink; andrdLink = environment.andrdLink; 
  //docPathUM = environment.docUM;
  isNIG = environment.isNIG; isKMP = environment.isKMP; isSMR = environment.isSMR; isEclnc = environment.isEclnc; orgList = [];
  menuItems = { isVle: false};
  tutorialUrl= environment.tutorialUrl;
  isLogOut: boolean = false;
  chatArray:any;
  nameArray:any;
  nameArray1:any;
  chatUser = '';
  messagechat = '';
  style1 = false;
  style2 = false;
  count:boolean;
  isDoc:boolean;
  isReferral=false;
  benificiary_id:any;isUkt = true; dataRefresher: any;
  msgDate:any = new Date();existingpname:any;patArray= [];orgName:any;patName =''
  property: string='register';
  center_type: any;
  hub_name: any;
  menudetails: any; menus: any;
  MId: string;
  moduleId: string;
  profile_pic: any;
  image_path: string;
  tenantData: any;
  webURL: any;
  isukhub: boolean = false;
  tenantChecking:any;
  subMenu: Array<any> = [];
  hwData: any;
  showError: boolean = false;
  private unsubscriber: Subject<void> = new Subject<void>();
  menusShow: boolean;
  constructor(private router: Router,
    config: NgbModalConfig
    , private modalService: NgbModal
    , private menuservice: MenuService
    , private userServices: UserService
    , private userIdleTimerService: UserIdleTimerService
    , private authSvc: AuthenticationService
    , private apiSvc: ApiService
    , private registrationService: RegistrationService
    , private toastr: ToastrService
    , private dtPipe: DatePipe
    , private chatService:ChatService) {
    super();
        // new line added for back button disabled 24-04-2023
        $('.modal-backdrop').remove();
        history.pushState(null, '');
    
        fromEvent(window, 'popstate')
          .pipe(takeUntil(this.unsubscriber))
          .subscribe((_) => {
            history.pushState(null, '');
            this.showError = true;
          });
          // end of lines back button disabled 24-04-2023
   this.appUrl = this.isLcl ? environment.applicationURL : location.origin, // this.orgfileDomainPath = environment.organizationFilePath;
   //this.appUrl = this.isLcl ? environment.applicationURL : location.origin 
  // this.appUrl = (location.href.includes('localhost') ? 'https://telemedicine-uk-qa.vmedtm.com' : location.origin) + '/';//QA Pointing
  //this.appUrl = (location.href.includes('localhost') ? 'https://telemedicine-uk-training.vmedtm.com' : location.origin) + '/';//Training Pointing
 // this.umDocPath = this.isLcl ? location.origin : this.appUrl + environment.docUM,
   this.OrgDetail();
    config.backdrop = 'static';
    config.keyboard = false;
    $(document).ready(function () {
      $("#sidebarToggle").click(function (e) {
        e.preventDefault();
        $("body").toggleClass("sidenav-toggled");
      });
    });
    $(() => {
      var IsOpen = false; this.isMenuOpen = false;
      $(".mobileclick").click(() => {
        IsOpen = true, this.isMenuOpen = true;
        //checkWidth();
        SideCollapse();
      })
      $("#sidebarCollapse").click(function (e) {
        SideCollapse();
      });
      // console.log(document.getElementsByClassName("nav-link")), console.log(document.getElementsByClassName("nav-link").length);
      // $("#btnMenuItem").click(function (e) { document.getElementById('sidebarToggle')?.click(); });
      function SideCollapse() {
        if (!IsOpen) {
          document.getElementById("sidebar").setAttribute("class", "sidebar active") //$("#sidebar").attr("class", "sidebar active")
          document.getElementById("content").setAttribute("class", "main-panel active")
          //$("#content").attr("class", "main-panel active")
          IsOpen = true; this.isMenuOpen = true;
        }
        else {
          document.getElementById("sidebar").setAttribute("class", "sidebar") //$("#sidebar").attr("class", "sidebar active")
          document.getElementById("content").setAttribute("class", "main-panel")
          // $("#sidebar").attr("class", "sidebar")
          // $("#content").attr("class", "main-panel")
          IsOpen = false; this.isMenuOpen = false;
        }
      }
    });
    // console.log(`browser type = "${window.orientation}"`)
    this.orgName = sessionStorage.getItem('orgName');
    // if (this.orgName == "Uttarakhand Telemedicine"){
    //   this.isUkt = true
    // } else{
    //   this.isUkt = false
    // }
    let isRefr = sessionStorage.getItem('showRefScreen')
    if(isRefr == 'true'){
    this.isReferral = true;
    }
    
  }

  shMenu() {
    if (window.orientation != undefined)
      document.getElementById('sidebarToggle')?.click();
  }
  nav(){
    this.router.navigateByUrl(`/home/doctorReg/doctorList`)
  }
  ngOnInit(): void {
  // alert('Home screen');
  this.webURL = location.href.includes('localhost') ? environment.applicationURL : location.origin;
  this.tenantChecking=JSON.parse(sessionStorage.getItem('currentUser'))?.tenantData?.tenantModuleList;

  this.tenantChecking.forEach(f=>{
    if(f.componentName == environment.tenantConfig.isUKT){
      this.isukhub = true;
    }
  }) 
   this.loadMenu()
    if(browserRefresh){
      this.authSvc.tenantId=sessionStorage.getItem('currentTenant');
      this.logOut(true);

    }
     if (this.isUkt){
     // this.chatService.openWebSocket();
      let data = JSON.parse(sessionStorage.getItem('DoctorDetails'));
      if(data && data!=null){
        this.chatService.connectSocket(sessionStorage.getItem('currentTenant'),data.id,JSON.parse(sessionStorage.getItem('currentUser')).user_role_mapping[0]?.user_type_id?.user_type);
     
      }
        // this.chatService.openWebSocket();
     
    }
    $(document).ready(function () {
      var link = document.querySelector("link[rel*='icon']");
      link.setAttribute("type", 'image/x-icon');
      link.setAttribute("rel", 'shortcut icon');
      // link.setAttribute("href", `${location.origin}/assets/vmLogos/${environment.webSrce}/appicon.ico`);
      // link.setAttribute("href", `${location.origin}/assets/vmLogos/${environment.webSrce}/appicon.ico`);
      link.setAttribute("href", `${localStorage.faviconUrl}`);
      link.setAttribute("href", `${localStorage.faviconUrl}`);
      document.getElementsByTagName('head')[0].appendChild(link);
      document.getElementById('appFavicon').setAttribute('href', `${localStorage.faviconUrl}`);
    });
    this.authSvc.crntOrgIDSubject.subscribe(org => this.getUserPermission(org)); 
    this.authSvc.appSrceSubject.subscribe(s => {
      var tenantDtls: any = JSON.parse(sessionStorage.getItem('tenantDtls')) 
      this.tollFree =tenantDtls?.data?.primary_contact_no
      this.appSrce = s;
      const loginImageOBJ = JSON.parse(sessionStorage.getItem('orgnization_images'))
      const orgLogo =loginImageOBJ?.filter(f=>f.image_type.id==1)[0];
      const pocHeaderLogo =loginImageOBJ?.filter(f=>f.image_type.id==5)[0];
      this.logo = orgLogo?`${this.webURL}/vidmed-orgfiles/ORGANIATION/${tenantDtls?.data?.id}/${orgLogo.image_type?.image_type}/${orgLogo.image_path}`:`${location.origin}${environment.assetsURL}${this.appSrce}${environment.logo}`;
      this.logo1 =pocHeaderLogo?`${this.webURL}/vidmed-orgfiles/ORGANIATION/${tenantDtls?.data?.id}/${pocHeaderLogo.image_type?.image_type}/${pocHeaderLogo.image_path}`:`${location.origin}${environment.assetsURL}/dhanush/${environment.logo1}`;
    });
      // this.authSvc.tollFreeSubject.subscribe(s => this.tollFree = s),

      this.authSvc.appClntSubject.subscribe(s => { 
        var tenantDtls: any = JSON.parse(sessionStorage.getItem('tenantDtls')) 
        var dclientName =tenantDtls?.data?.orgnization_name;
        this.clientName = dclientName ?dclientName:s
        this.isShowAPK = ((s == 'DHANUSH VIDMED') || this.isLcl); }),
      this.authSvc.isShowGlblOrg.subscribe(s => this.isGlblOrgShow = s);
     this.userId = sessionStorage.getItem('session_id'), 
     this.drData = JSON.parse(sessionStorage.getItem('DoctorDetails')),
   
      this.usrData = JSON.parse(sessionStorage.getItem('userData'));
     // console.log('User Data',this.usrData);
    let res = this.authSvc.currentUserValue;
    if(res && res!=null){

    } else{
      res= this.authSvc.currentUser;
    }
    // alert('@@@@@@');
    // console.log('##############Res#################',res);
    try {
      this.moduleList = JSON.parse(atob(sessionStorage.getItem('modules')));
      this.screenList = JSON.parse(atob(sessionStorage.getItem('screen')));
    } catch (error) {
      this.router.navigate(['']);
    }
    // this.authSvc.currentUser.subscribe(res => {
    // console.log(res);
    if (res) {
      // console.log('Response data',res);
      this.userName = (res?.first_name + ' ' + res?.last_name).toUpperCase();
      sessionStorage.setItem('userchatname',this.userName);
      let usrNme: string = 'Dr ' + this.userName, drTxt = usrNme.length > 25 ? usrNme.substring(0, 23) + '..' : usrNme;
      // let usrNme: string = this.authSvc.roleId == 14 ? 'Agt. ' : 'Dr. ' + this.userName,
      //   drTxt = usrNme.length > 25 ? usrNme.substring(0, 23) + '..' : usrNme;
      (<HTMLSpanElement>document.getElementById('doctorName')).innerText = this.userName;
      (<HTMLInputElement>document.getElementById('user')).value = drTxt;
   
      this.roleId = this.authSvc.roleId;
      // console.log("res",res);
      
      // alert('something fishified');
      // console.log("sessionStorage.getItem('currentUser')", sessionStorage.getItem('DoctorDetails'));
      let userData:any =JSON.parse(sessionStorage.getItem('DoctorDetails'));
      // console.log("userdata",userData);
        let tntId: any = this.tenantData?.id ?? null;
        const proImg="../../../assets/images/ph60.png"
        if(this.roleId==7){
          let profile_pic:any = userData.doctor_documents.filter(x => x.file_type_id == 5)[0];
          this.image_path=profile_pic?`${this.webURL}/${environment.viewFilePath}` + 'DOCTOR/' + userData.id + '/Photo/' + profile_pic.document_path :proImg;
    
        }
        else{
          this.image_path =proImg;
        }

     
    
      
      // 
      // console.log("666666666666",JSON.stringify(profile_pic),window.location.href);
      
      // console.log('Role ID',this.roleId);
      // alert('updtLogoutSocket'+this.authSvc.roleId);
      
      if(this.roleId==null){
        this.roleId = JSON.parse(sessionStorage.getItem('currentUser')).user_role_mapping[0]?.user_type_id?.id;
        this.authSvc.roleId= this.roleId;
        // console.log('$#$#$%^',this.authSvc.roleId);
      }
      
      
      this.role_name = res.user_role_mapping[0].user_type_id.user_type, this.lcnsPlanName = '';
      this.center_type =this.drData?.center_type;

      this.hub_name="";
      if(this.drData?.hub_name != null &&  this.drData?.hub_name!=""){
        this.hub_name="( "+this.drData?.hub_name+" )";
      }

      if (this.roleId == 1) // Super Admin
        [this.consultation, this.registration, this.administration, this.master, this.admin] = [true, true, true, true, true];
      
      else if (this.roleId == 2) {// Admin
        [this.consultation, this.registration, this.administration, this.master, this.admin] = [false, true, true, true, true];
        if (sessionStorage.getItem('planLcns') != null)
          this.lcnsPlanName = `, Plan: ${sessionStorage.getItem('planLcns')}`;
      }

      // this.roleId==7?JSON.parse(sessionStorage.getItem('DoctorDetails')):JSON.parse(sessionStorage.getItem('hwDetails'))
     
      else if (this.roleId == 7) { // Doctor
        // alert('Check1111111@@@@@@@@@@@@');
        [this.consultation, this.registration, this.administration, this.master, this.admin] = [true, false, false, false, false];
        let deDt = +this.dtPipe.transform(this.drData?.reg_expiry_date, "yyyyMMdd"), tdDt = +this.dtPipe.transform((new Date()), "yyyyMMdd");
        if (deDt != 0) {
          if (deDt < tdDt)
            document.getElementById('drRegExpiry')?.click();
          else {
            if (this.roleId == null) {
              this.roleId = JSON.parse(sessionStorage.getItem('currentUser')).user_role_mapping[0].user_type_id.id;
              if (this.roleId == 7) {
                let docData = JSON.parse(sessionStorage.getItem('DoctorDetails'));
                var healthWorkerId=docData?.doctor_health_worker[0]?.id;
                var healthWorkers=docData?.doctor_health_worker[0]?.first_name+ ' '+docData?.doctor_health_worker[0]?.last_name
                let ws = JSON.parse(sessionStorage.getItem('webSkt'));
                this.refreshData();
                register(ws.toId, ws.fromId, ws.name, ws.userType, ws.complaints, ws.qlfn, ws.expn);
                      
                statusRegister(ws.qlfn, ws.expn, ws.qlfnName, ws.grCode, ws.irCode, ws.erCode, docData.hub_id,docData.hub_name,docData.center_id,docData.center_type,docData.specialization_name,localStorage.getItem('gender'),docData.years_experience,localStorage.getItem('super_Qualification'), docData.department_name,localStorage.getItem('doctor_languages_name'),localStorage.getItem('document_path_new'),docData.doctor_hospitals[0].hospital_name,healthWorkerId,healthWorkers);
                //statusRegister(ws.qlfn, ws.expn, ws.qlfnName, ws.grCode, ws.irCode, ws.erCode);
              }
            }
          }
        }
      }

      else if (this.roleId == 9) { // HW
        // alert('Check@@@@@@@@@@@@');
      setTimeout(() => {
        this.hwData= JSON.parse(sessionStorage.getItem('dtlsDrHw'));; 
        
      }, 1500);

        [this.consultation, this.registration, this.administration, this.master, this.admin] = [true, false, false, false, false];
        let deDt = +this.dtPipe.transform(this.hwData?.registration_exp_date, "yyyyMMdd"),
        tdDt = +this.dtPipe.transform((new Date()), "yyyyMMdd")
        if (deDt != 0) {
          if (deDt < tdDt)
            document.getElementById('drRegExpiry')?.click();
          else {
            if (this.roleId == null) {
              this.roleId = JSON.parse(sessionStorage.getItem('currentUser')).user_role_mapping[0].user_type_id.id;
              if (this.roleId == 9) {
                let ws = JSON.parse(sessionStorage.getItem('webSkt'));
                this.refreshData();
                register(ws.toId, ws.fromId, ws.name, ws.userType, ws.complaints, ws.qlfn, ws.expn);
                let hwDetails =JSON.parse(sessionStorage.getItem('hwDetails'));
                // console.log('hwDetails',hwDetails);
                
                statusRegister(ws.qlfn, ws.expn, ws.qlfnName, ws.grCode, ws.irCode, ws.erCode, hwDetails?.doctor_health_worker[0]?.doctor_registration?.hub_id,hwDetails?.doctor_health_worker[0]?.doctor_registration?.hub_name,hwDetails?.doctor_health_worker[0]?.doctor_registration?.center_id,hwDetails?.doctor_health_worker[0]?.doctor_registration?.center_type,hwDetails?.doctor_health_worker[0]?.doctor_registration?.specialization_name,localStorage.getItem('gender'),hwDetails?.years_experience,localStorage.getItem('super_Qualification'), hwDetails?.doctor_health_worker[0].department_name,localStorage.getItem('doctor_languages_name'),localStorage.getItem('document_path_new'),hwDetails?.this.hospital_id,hwDetails.id,hwDetails.name,this.hwData?.healthWorkerId,this.hwData?.name);
                //statusRegister(ws.qlfn, ws.expn, ws.qlfnName, ws.grCode, ws.irCode, ws.erCode);
              }
            }
          }
        }
      }
     

      if(this.isUkt = true){
        this.chatService.count.subscribe( value => {
          this.count = value;
          if(this.count == true){
         this.style1 = true;
         this.style2 = false;
          } else{
            this.style2 = true;
            this.style1 = false;
          }
      });
      $(function () {
        $('[data-toggle="modal"]').tooltip()
      })
      }
    //  if(this.isUkt = true){
       this.nameArray = this.chatService.chatNames;
      this.chatArray=this.chatService.chatMessages;
  //     for (let i = 0; i < this.nameArray.length; i++){
  //       if (this.nameArray1.includes(this.nameArray[i].patient_name)){
  //        return;
  //       } else{
  //         this.nameArray1.push(this.nameArray[i].patient_name);
  //       }
  // }
      
    
    }
    // if (location.origin.includes('localhost') || location.origin.includes('genome') || location.origin.includes('incipe'))
    this.shMenu();

  

     
    let roleid= JSON.parse(sessionStorage.getItem('currentUser')).user_role_mapping[0]?.user_type_id?.id;
    if (roleid == 1){
     this.GetAllOrgs();
    }/// Super Admin
      
    else {
      if (location.origin.includes('localhost') || this.usrData.orgnization_registration.display_brand == true)
        this.LoadOrgDrugs();
    }
    if(this.roleId == 7){
      this.refreshChat();
      this.GetMisCalData();
    }
   
    // setTimeout(() => { this.GetMisCalData(); }, 1500);
    // if (location.origin.includes('localhost') || this.usrData.orgnization_registration.display_brand == true)
    //   this.LoadOrgDrugs();
  }
  async loadMenu() {
    this.MId = localStorage.getItem('mId');
    var menu = await this.menuservice.loadPermissions().subscribe(res => {
      this.menudetails = res; 
      let docDetails = JSON.parse(sessionStorage.getItem('DoctorDetails'));
       let doctor_module_id= this.menudetails.filter(f=>f.moduleId == 6);
       let doctor_screen_id =doctor_module_id[0]?.subMenu?.filter(f=>f.screen_id == 72 || f.screen_id == 63);
        let referral_doctor ={
         moduleCode: "Consultation",
         moduleDesc: "Consultation",
         moduleId: 6,
         subMenu : doctor_screen_id
        }
       if(docDetails?.center_type!=='Referral'){
        for(let i=0;i<this.menudetails.length;i++){
          for (let j = 0; j < this.menudetails[i].subMenu.length; j++) {
            const element = this.menudetails[i].subMenu[j];
             if(element.screen_label==="ReferralPatientList"){
              this.menudetails[i].subMenu.splice(j, 1);
             }
            
          }
        }
      }
       
       
          if(docDetails?.center_type === 'Referral'){
            let referral =[];
            referral.push(referral_doctor)
            this.menudetails =referral ;
          }
          this.subMenu=[]
          for(let k=0;k<this.menudetails.length;k++){
            for (let m = 0; m < this.menudetails[k].subMenu.length; m++) {
              const element = this.menudetails[k].subMenu[m];
               this.subMenu.push(element)
              
            }
          }
           localStorage.setItem('subMenuList',JSON.stringify(this.subMenu))
      });
        
    
   
      
    if (menu && this.MId) {

      setTimeout(res => {
        document.getElementById(`#m-${this.MId}`)?.click()
      }, 1500)

    }
  }


  sidemenuManual(value: any, type) {
    // if(type=='home'){
    //  localStorage.setItem('mId', value.module_id);
    // //  this.menus = value;
    // //  sessionStorage.setItem('menu', JSON.stringify(value));
    // }
    // if(type=='another'){
    //  setTimeout(res => {
    //    document.getElementById(`#m-${value.module_id}`)?.click()
    //  }, 1500)
    //  localStorage.setItem('mId', value.module_id);
    //  var module = this.menudetails.filter(f=>f.moduleId==value.module_id)[0].subMenu;
    //    var submenu = module.filter(f=>f.screen_id==value.screen_id)[0]
    //    sessionStorage.setItem('menu', JSON.stringify(submenu));
       
       
       
   // }

   //console.log(sessionStorage.setItem('menu', JSON.stringify(menu)));
 }

  sidemenu(value: any, type) {
    if(type=='home'){
     localStorage.setItem('mId', value.module_id);
     this.menus = value;
     sessionStorage.setItem('menu', JSON.stringify(value));
    }
    if(type=='another'){
     setTimeout(res => {
       document.getElementById(`#m-${value.module_id}`)?.click()
     }, 1500)
     localStorage.setItem('mId', value.module_id);
     var module = this.menudetails.filter(f=>f.moduleId==value.module_id)[0].subMenu;
       var submenu = module.filter(f=>f.screen_id==value.screen_id)[0]
       sessionStorage.setItem('menu', JSON.stringify(submenu));
       
       
       
    }

   //console.log(sessionStorage.setItem('menu', JSON.stringify(menu)));
 }
  GetMisCalData() {
    let dt2Day = this.dtPipe.transform((new Date()), 'yyyy-MM-dd');
    this.registrationService.getMisCals(this.drData?.id, dt2Day, dt2Day).subscribe(mcData => {
      if (mcData.status == 'OK')
        this.authSvc.msdCalSubject.next(mcData.data), this.msdCalVal = mcData.data.length;
      if (location.origin.includes('localhost') || this.usrData.orgnization_registration.display_brand == true)
        this.LoadOrgDrugs();
    });
  }
  // gethide(){
  //  if(window.location.origin.includes('ng.vidmed || ngqa.vidmed ')){
  //    this.isNandGarh = true;

  //  }
  // }

  GetAllOrgs() {
    this.userServices.getuser('organization/allorganizationrecords').subscribe(res => { // getorganizationrecords
      if (res) {
        this.orgList = res.data
        //res.data.filter(f => f.configered === true && f.id != -1 && f.id == 50);
        //this.orgList.filter(),
        // console.log('organization details...',this.orgList);
        if (this.orgList.length > 0)
            sessionStorage.setItem('currentTenant', this.orgList[0]?.id),
              //this.getOrgList(this.orgList[0]?.id)
            sessionStorage.setItem('defCrntTnnt', this.orgList[0]?.id),
            this.authSvc.tenantId = this.orgList[0]?.id, this.authSvc.cnfgrdOrgSubject.next(this.orgList), this.authSvc.crntOrgIDSubject.next(this.orgList[0]?.id);
      }
    });
  }

  onOrgChng(val: any) {
    sessionStorage.setItem('currentTenant', val), this.authSvc.tenantId = val, this.authSvc.crntOrgIDSubject.next(val);
    sessionStorage.setItem('TENANT_ID', val)
  }

  LoadOrgDrugs() {
    this.userServices.getOrgFTs('getallactiveorganizationbranddata').toPromise().then((od: any) => {
      if (od.status == 'OK' && od.message == 'success' && this.usrData != null && this.usrData != undefined) {
        let dt = od.data.filter(f => f.orgnization_id == this.usrData.orgnization_registration.id && f.is_active == true);
        if (dt.length > 0)
          this.authSvc.bannerSubject.next(dt[0].brand_name.split(','));
        // this.authSvc.drugsSubject.next(dt[0].brand_name);
      }
      // if (od.status == 'OK' && od.message == 'success' && od.data.filter(f => f.brand_type == 'drug').length > 0)
      //   this.authSvc.bannerSubject.next(od.data.filter(f => f.brand_type == 'drug'));
    });
  }

  OrgDetail() {
    let [response, imglogourl, imgwmurl, imgbannerurl] = [JSON.parse(sessionStorage.getItem('OrgDetails')), '', '', ''];
    if (response && this.authSvc.roleId != 1) {
      imglogourl = response.image_files.filter(f => f?.image_type?.id == 1)[0]?.image_path ?? '';
      imgwmurl = response.image_files.filter(f => f?.image_type?.id == 2)[0]?.image_path ?? '';
      imgbannerurl = response.image_files.filter(f => f?.image_type?.id == 3)[0]?.image_path ?? '';
    }
  }
  sessionManagement() {
    const is401Error = JSON.parse(sessionStorage.is401Error || 'false');
    if (this.userId && is401Error == false) {
      this.registrationService.session(this.userId).subscribe(res => {
        if (res.status === "success") { }
      });
    }
    sessionStorage.is401Error = false;
  }
  forceLogout(){
    this.isLogOut = true;
    (<HTMLInputElement>document.getElementById('fromId')).value = '';
    this.sessionManagement(), this.authSvc.logout(), this.modalService.dismissAll(), 
   // window.location.reload();
   // stopVideo();
    this.router.navigate(['']),
      this.authSvc.bannerSubject.next([]), this.authSvc.drugsSubject.next(''), closeAllSockets();
      if(this.isUkt = true){
        this.chatService.closeWebSocket();
      }
  }
  logOut(isSessionExpired: boolean = false) {
  // alert('after Browser refresh');
    var answer = window.confirm("Are you sure you want Logout?");
    sessionStorage.setItem('isCnfmLogOut', `${answer?'1':'0'}`);
    if (answer) {
      this.isLogOut = true;
      (<HTMLInputElement>document.getElementById('fromId')).value = '';
      this.sessionManagement(), this.authSvc.logout(), this.modalService.dismissAll(), 
     // window.location.reload();
     // stopVideo();
      this.router.navigate(['']),
        this.authSvc.bannerSubject.next([]), this.authSvc.drugsSubject.next(''), closeAllSockets();
        if(this.isUkt = true){
          this.chatService.closeWebSocket();
        }
    }
    else {
      this.roleId = JSON.parse(sessionStorage.getItem('currentUser')).user_role_mapping[0].user_type_id.id;
      if (this.roleId == 7){
        this.authSvc.updateSession(false);
      //###############################
      var ws = JSON.parse(sessionStorage.getItem('webSkt'));
    (<HTMLInputElement>document.getElementById('fromId')).value = ws.fromId;
    (<HTMLInputElement>document.getElementById('toId')).value = ws.toId;

    (<HTMLInputElement>document.getElementById('newUserId')).value = ws.tkn;
      //###############################
      this.authSvc.tenantId=sessionStorage.getItem('currentTenant');
      this.authSvc.currentUser=new BehaviorSubject<any>(JSON.parse(sessionStorage.getItem('currentUser')));
      let data = JSON.parse(sessionStorage.getItem('DoctorDetails'));
      if(data && data!=null){
        this.chatService.connectSocket(sessionStorage.getItem('currentTenant'),data.id,JSON.parse(sessionStorage.getItem('currentUser')).user_role_mapping[0]?.user_type_id?.user_type);
      }
      this.roleId = JSON.parse(sessionStorage.getItem('currentUser')).user_role_mapping[0].user_type_id.id;
      if (this.roleId == 7) {
        let ws = JSON.parse(sessionStorage.getItem('webSkt'));
        this.refreshData();
        register(ws.toId, ws.fromId, ws.name, ws.userType, ws.complaints, ws.qlfn, ws.expn);
        let docData = JSON.parse(sessionStorage.getItem('DoctorDetails')); 
        docData = JSON.parse(localStorage.getItem('DoctorDetails'));    
        // alert('Home Update Status'); 
        statusRegister(ws.qlfn, ws.expn, ws.qlfnName, ws.grCode, ws.irCode, ws.erCode, docData.hub_id, docData.hub_name,docData.center_id,docData.center_type,docData.specialization_name,localStorage.getItem('gender'),docData.years_experience,localStorage.getItem('super_Qualification'), docData.department_name,localStorage.getItem('doctor_languages_name'),localStorage.getItem('document_path_new'),docData.doctor_hospitals[0].hospital_name);
    
       // statusRegister(ws.qlfn, ws.expn, ws.qlfnName, ws.grCode, ws.irCode, ws.erCode);
      }
    
      }
        
    } 
    }

  logOut_new() {
    if (location.href.includes('poc'))
      updtLogoutSocket(); // stopVideo(false, false);
    if (sessionStorage.getItem('logUsrName') != null) {
      this.apiSvc.post(`${this.appUrl}${environment.loginAPI}user/logout`, { "login_name": sessionStorage.getItem('logUsrName') }).subscribe(
        res => {
          if (res) {
            (<HTMLInputElement>document.getElementById('fromId')).value = '';
            this.authSvc.logout(), closeAllSockets(), this.router.navigate(['']);
          }
        });
    }
    else
      this.router.navigate(['']);
  }

  openModal() {
    const modalRef = this.modalService.open(ChangePasswordComponent);
    modalRef.componentInstance.title = 'Change Password';
    // modalRef.componentInstance.pharmaData = pharma;
    modalRef.result.then(result => { }, (reason) => { });
  }

  onMsdCal() {
    const modalRef = this.modalService.open(MissedcallComponent, { scrollable: true, size: 'xl' });
    modalRef.result.then(result => { }, (reason) => { });
  }

  // onManual(type: string = '') {
  //   const modalRef = this.modalService.open(type == 'd1' ? IvideoComponent : IvideosComponent, { scrollable: true, size: 'xl' });
  //   modalRef.componentInstance.url = (type == 'd1' ? environment.docHomeVdo : type == 'd2' ? environment.docConsVdo : environment.vdoUM);
  //   modalRef.componentInstance.type = type;
  //   modalRef.result.then(result => { }, (reason) => { });
  // }

  @HostListener('window:click')
  @HostListener('window:keydown')
  @HostListener('window:mousemove')
  refreshUserState() {
    // console.log('HostListener')
    clearTimeout(this.userIdleTimerService.userActiveTimer);
    this.userIdleTimerService.timeoutUserActivity();
  }

 // @HostListener('window:beforeunload', ['$event'])
//   @HostListener('window:beforeunload')
//   onBeforeUnload() {
//     return false;
// }
  ngOnDestroy($event: any) {
    // if (this.isLogOut == false && location.pathname != '/') {
    //   this.sessionManagement();
    //   this.isLogOut = false;
    // }
    // if (performance.navigation.type == 1)
    //   this.logOut();
    // else
    //   this.logOut();
    if ($event)
      $event.returnValue = true;
    // else
    //   this.logOut();
    return false;
    if (this.isUkt = true){
      this.chatService.closeWebSocket();
    }
   
  }

  refreshData() {
    let openViduURL = sessionStorage.getItem('ovDtls');
    if (openViduURL) {
      const ovDtls = JSON.parse(openViduURL);
      (<any>window).OPENVIDU_SERVER_URL = ovDtls.openvidu_url;
      (<any>window).OPENVIDU_SERVER_SECRET = ovDtls.secret_key;
      (<any>window).vidmed_url = `${this.userServices.appUrl}telecon/`;
      (<HTMLInputElement>document.getElementById('apUrl')).value = this.userServices.appUrl;
    }
  }

  onAPK(type: string) {
    let selBox = document.createElement('textarea'); selBox.style.position = 'fixed', selBox.style.left = '0', selBox.style.top = '0',
      selBox.style.opacity = '0', selBox.value = type == 'a' ? this.appleLink : this.andrdLink, document.body.appendChild(selBox), selBox.focus(),
      selBox.select(), document.execCommand('copy'), document.body.removeChild(selBox), this.toastr.success('Copied to clipboard.!');
    /* let path = type == 'a' ? this.appleLink : this.andrdLink, link = document.createElement("a");
      link.setAttribute('href', path), link.setAttribute('visibility', 'hidden'), link.setAttribute('display', 'none'),
      link.setAttribute('target', '_blank'), document.body.appendChild(link), link.click(); */ // for redirecting links
  }

  onTollFree() {
    if (window.orientation == undefined) {
      let selBox = document.createElement('textarea'); selBox.style.position = 'fixed', selBox.style.left = '0', selBox.style.top = '0',
        selBox.style.opacity = '0', selBox.value = this.tollFree, selBox.focus(), document.body.appendChild(selBox), selBox.select(),
        document.execCommand('copy'), document.body.removeChild(selBox), this.toastr.success('Copied to clipboard.!');
    }
    else {
      let link = document.createElement('a');
      link.setAttribute('href', `tel:${this.tollFree}`), link.setAttribute('visibility', 'hidden'), link.setAttribute('display', 'none'),
        link.setAttribute('target', '_blank'), document.body.appendChild(link), link.click();
    }
  }

  umDocDwnld() {
    this.openFile(this.umDocPath);
  }

  openFile(filePath: string) {
    let link = document.createElement("a");
    link.setAttribute('href', filePath), link.setAttribute('visibility', 'hidden'), link.setAttribute('display', 'none'),
      link.setAttribute('target', '_blank'), document.body.appendChild(link), link.click();
  }
  fromPOCUserProfile(){
    var answer = window.confirm("Are you sure you want Leave ?");
    if (answer) {
       
      if (this.roleId == 7) {
        
         stopVideo()
         this.registrationService.deleteSessionbydocId(this.drData?.id).subscribe(res=>{

         });
        this.registrationService.getByDoctorId(this.drData?.id).subscribe(res => {
          const modalRef = this.modalService.open(DoctorRegistrationComponent, { scrollable: true, size: 'xl' });
          modalRef.componentInstance.title = 'User Profile';
          modalRef.componentInstance.doctor = res.data;
          modalRef.componentInstance.isEditable = true;
          modalRef.result.then(result => { }, (reason) => { });
        });
      }

      else{
        // document.getElementById('btnVideoCallModalClose').click();
        stopVideo();
        this.registrationService.deleteSessionbydocId(this.drData?.id).subscribe(res=>{

        });
        this.router.navigate(['/home/userprofile']);
      }
          //console.log('onUser Profile Check ESLE',);
          
        
    }
    
    else {
        
    }
   
  }
  onUsrPrfl() {
   // console.log('onUser Profile Check B4',);
    if (this.roleId == 7) {
      //console.log('onUser Profile Check IF',);
      this.registrationService.getByDoctorId(this.drData?.id).subscribe(res => {
        const modalRef =  this.isukhub  ?  this.modalService.open(DoctorRegistrationComponent, { scrollable: true, size: 'xl' }) : 
        this.modalService.open(CommonDoctorRegistrationComponent, { scrollable: true, size: 'xl' })
        modalRef.componentInstance.title = 'User Profile';
        modalRef.componentInstance.doctor = res.data;
        modalRef.componentInstance.isEditable = true;
        modalRef.result.then(result => { }, (reason) => { });
      });
    }
    else
        //console.log('onUser Profile Check ESLE',);
        
      this.router.navigate(['/home/admin/userprofile']);
  }

  onPS(type: string, inputElmnt: any = null) {
    if (type == 'g') {
      let path = `https://play.google.com/store/apps/details?id=in.ap.orgdhanush.vidmedin&hl=en`, link = document.createElement("a");
      link.setAttribute('href', path), link.setAttribute('visibility', 'hidden'), link.setAttribute('display', 'none'),
        link.setAttribute('target', '_blank'), document.body.appendChild(link), link.click();
    }
    else {
      /* inputElmnt.select();
      document.execCommand('copy');
      inputElmnt.setSelectionRange(0, 0); */
      let selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = `https://apps.apple.com/us/app/dhanush-vidmed/id1516571105`;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);
      this.toastr.success('Copied to clipboard.!')
    }
  }
  showOrHideMenuItems = async (arrPermision: any[], screenName: string) => {
    this.menuItems[screenName] = arrPermision.some(e => e.patient && e.feature_id.feature_name === screenName);
  }
  getUserPermission(orgId: number) {
    let orgIdValue=JSON.parse(sessionStorage.getItem('orgid'));
  this.userServices.getUserPermissionByOrgId(orgIdValue).pipe(shareReplay())
      .subscribe((res:any) => {
        if (res.status == 'OK') {
          this.showOrHideMenuItems(res.data, 'isVle');
         
        }
      }, error => { });
  }


  openECMnl(type: string) {
    this.openFile(environment.nigDocs[0][type]);
  }
  sendMessage(ev:any) {
    var str = this.messagechat
    this.messagechat = this.messagechat.replace('\n', '');
    let data = JSON.parse(sessionStorage.getItem('DoctorDetails'));
    let docData = {
      'doctor_id':data.id,
      'beneficiary_id':this.benificiary_id,
      'message':''+this.messagechat,
      'usertype':'Doctor'
    }
    if(str.trim() == '') {
   // alert("please enter text");
    return;
    }
     this.chatArray.push(docData);
     this.messagechat=this.userName+':'+''+this.messagechat;
     this.messagechat = this.messagechat.replace('"', '');
    // const chatMessageDto = new ChatMessageDto(sessionStorage.getItem('currentTenant'),data.id,this.benificiary_id, '',this.messagechat,this.existingpname);
    const chatMessageDto = new ChatMessageDto(sessionStorage.getItem('currentTenant'),data.id,this.benificiary_id, '',this.messagechat,this.existingpname,this.property);
    this.chatService.sendMessage(chatMessageDto);
    this.chatArray=this.chatService.chatMessages;
    if(this.chatArray.length > 0){
      this.style1 = true
     } else{
       this.style2 = true
       // this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
       //   this.router.navigate(['/home/poc']);
       // });
     }
    // alert(this.chatArray.length);
    // if(this.chatArray.message == 'Hi'){
    //   document.getElementById("chatalign").setAttribute("align", "");
    // }
    this.messagechat='';
  }
  refreshChat(){
    this.dataRefresher =
      setInterval(() => {
        if(this.chatService.webSocket?.readyState!=3){
        this.chatService.sendMessage2('ping');
        }
      }, 15000);  
  }
 
  close1(){
    $("#chatmodel").modal("hide");
    this.style1 = false
  
  }
  close(){
    $("#chatmodel1").modal("hide");
  }
  benId(e,pname:any){
this.benificiary_id = e
this.existingpname=pname;
this.patArray = [];
// 
for (let i = 0; i < this.chatArray.length; i++){
  // if(this.chatArray[i].beneficiary_id == this.benificiary_id && this.chatArray[i].usertype !='Doctor' ){
  //   document.getElementById("chatShow").setAttribute("display", "block")
  //   document.getElementById("chatShoww").setAttribute("display", "none")
  // } else{
  //   document.getElementById("chatShow").setAttribute("display", "none")
  // }
  // if(this.chatArray[i].beneficiary_id == this.benificiary_id && this.chatArray[i].usertype =='Doctor' ){
  //   document.getElementById("chatShoww").setAttribute("display", "block")
  // } else{
  //   document.getElementById("chatShoww").setAttribute("display", "none")
  // }
 
}
  }
  menuShow(){
    if(this.menusShow==true){
      this.menusShow=false;
    }else{
      this.menusShow=true;
    }
    
  }
}