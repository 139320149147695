import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  permissionList:Array<any>=[];
  tetantId: string;

  constructor(private userServices:UserService,
    private authenticationService:AuthenticationService
    ) { }

  loadPermissions() {
    let user = JSON.parse(sessionStorage.getItem('currentUser'));
    this.tetantId = sessionStorage.getItem('currentTenant');
    // return  this.userServices.getById(`userrolescreenpermission/get-by-roleid/${this.authenticationService.roleId}`).
    // return  this.userServices.getById(`userrolescreenpermission/get-by-roleid/${user?.user_role_mapping[0]?.user_type_id?.id}`).
    return  this.userServices.getById(`userrolescreenpermission/get-menus/${user?.user_role_mapping[0]?.user_type_id?.id}/${this.tetantId}`).
        pipe(
           map((data) => {
             return data.response;
           }), catchError( error => {
             return throwError( 'Something went wrong!' );
           })
        )
    }
}
