import { Directive, ElementRef, HostListener, OnChanges } from '@angular/core';

@Directive({
  selector: '[CustomDerDirective]'
})
export class CustomDerDirective {

  constructor(private el: ElementRef) { }
  // @HostListener('keydown', ['$event']) onKeyDown(e) {
  //   console.log(e['keyCode']);
  //   var value = e.target.value;
  //   if (value.length === 0 && e.which === 32) e.preventDefault();
  // }

  //    @HostListener("keydown", ["$event"])
  // onKeyDown(e: KeyboardEvent): boolean {
  //   console.log(this.el);
  //   return false
  // }
  @HostListener('keydown', ['$event'])
  onKeyDown(event) {
    var value = event.target.value;
    if (value.length === 0 && event.which === 32) event.preventDefault();
  }

  // @HostListener('keydown', ['$event']) onKeyDown(e) {
  //   console.log(e['keyCode']);
  //   var value = e.target.value;
  //   if (value.length === 0 && e.which === 32) e.preventDefault();
  // }
  // @HostListener('mouseenter') onMouseEnter() {
  //   this.highlight('yellow');
  // }

  // @HostListener('mouseleave') onMouseLeave() {
  //   this.highlight(null);
  // }

  // private highlight(color: string) {
  //   this.el.nativeElement.style.backgroundColor = color;
  // }
 
}

