
export class PrakrutiConsitution {
    age: IPCScores[];
    body_type: IPCScores[];
    wt_gain_ptrn: IPCScores[];
    skin_cmplxn: IPCScores[];
    skin_txtr: IPCScores[];
    hair_type: IPCScores[];
    sleep_ptrn: IPCScores[];
    appetite: IPCScores[];
    taste_pref: IPCScores[];
    phy_act: IPCScores[];
    bowel_mvmnt: IPCScores[];
    situation: IPCScores[];
    working_style: IPCScores[];
    memory: IPCScores[];
    fem_msntr: IPCScores[];
    grwth_berd: IPCScores[];
}

export interface IPCScores {
    id: number;
    score: number;
    attribute_name: string;
    is_active: boolean;
    score_type: {
        id: number;
        name: string;
        is_active: boolean;
    }
}

export class GeneralAssmnt {
    pulse_nadi: IGARP[];
    stool_mala: IGARP[];
    urination_mutra: IGARP[];
    tongue_jihva: IGARP[];
    appetite_agni: IGARP[];
}

export class RogaPariksha {
    prakruti: IGARP[];
    dosha: IGARP[];
    anubandha_dosha: IGARP[];
    dooshya: IGARP[];
    bala: IGARP[];
    desha: IGARP[];
    kala: IGARP[];
    analam: IGARP[];
    vaya: IGARP[];
    satwa: IGARP[];
    aahara: IGARP[];
    aahara_shakti: IGARP[];
    roga_dis_avastha: IGARP[];
}

export interface IGARP {
    name: string;
    id: number;
    is_active: boolean;
}

export class AyushPOC {
    id: number = 0;
    is_epaper: boolean = false;
    consultation_fee_required: boolean = false;
    medicine_billable: boolean = false;
    dependent_id: number = 0;
    guardian_id: number = 0;
    beneficiary_id: string = '';
    doctor_id: string = '';
    visit_date: string = '';
    visit_start_time: string = '';
    visit_end_time: string = '';
    order_no: string = '';
    patient_interaction_id: number = 0;
    cc_brief_history: {
        chiefComplaints: IChiefComplaints[],
        otherComplaints: string;
        presentIllness: string;
        doctorComments: string;
        notes1: string;
        notes2: string;
        ccRmrks: ICcRemarks[];
    }
    vital_history: {
        temperature: string;
        pulse: string;
        systolic: string;
        diastolic: string;
    }
    other_history: {
        history: string;
        past_history: string;
        consultationType: string;
    }
    allergy: {
        allergies: string;
    }
    diagnosis: {
        provisionalDiagnosis: IProvisionalDiagnosis[];
    }
    investigation: {
        master: IInvMstr[];
        otherInvestigation: string;
    }
    // prescribed_medications: {
    //     drugType: string;
    //     drugName: string;
    //     otherDrug: string;
    //     instruction: string;
    // }
    prescribed_medications: {
        drugs: IDrugs[];
        instruction: string;
    }
    advice: {
        restExercise: string;
        diet: string;
        followAdvice: string;
        referral: string;
        doctorComment: string;
        followupDate: any;
        is_self:any
    }
    health_worker_id: number = 0;
    health_worker_observation: string = '';
    medical_history: IMedHstry[];
    remarks: {

    }
    covid19: {

    }
    prakruti_constitution: IPrkrtConstNew[];
    general_assessment: IGenAsmnt[];
    roga_pariksha: IRgaPrksh[];
    vata_score: number = 0;
    pita_score: number = 0;
    kafa_score: number = 0;
    visit_no: number = null;
    call_type_id: string = '';
    referred_visit_id: string = '';
    beneficiary_visit_logs: IBenVstLogs[];
    user_type_id: number = 0;
    follow_up_date: string = '';
    physical_consultation_required: boolean = false;
    type_of_consultation: any;

    constructor() {
        this.cc_brief_history = {
            chiefComplaints: [{
                id: 0,
                chief_complaint: '',
            }],
            otherComplaints: '',
            presentIllness: '',
            doctorComments: '',
            notes1: '',
            notes2: '',
            ccRmrks: [
                {
                    index: 0,
                    value: '',
                    dsbld: true,
                }
                /* {
                    index: 3,
                    value: 'ghi',
                },{
                    index: 1,
                    value: 'abc',
                },{
                    index: 5,
                    value: 'mno',
                },{
                    index: 2,
                    value: 'def',
                },{
                    index: 4,
                    value: 'jkl',
                } */
            ],
        },
            this.vital_history = {
                temperature: '',
                pulse: '',
                systolic: '',
                diastolic: '',
            },
            this.other_history = {
                history: '',
                past_history: '',
                consultationType: '',
            },
            this.allergy = {
                allergies: '',
            },
            this.advice = {
                restExercise: '',
                diet: '',
                followAdvice: '',
                referral: '',
                followupDate: '',
                doctorComment: '',
                is_self:''
            },
            // this.prescribed_medications = {
            //     drugName: '',
            //     drugType: '',
            //     otherDrug: '',
            //     instruction: '',
            // },
            this.prescribed_medications = {
                drugs: [{
                    id: 0,
                    drugType: '',
                    drugName: {
                        id: 0,
                        name: '',
                    },
                    frequency: [{
                        id: 0,
                        name: '',
                    }],
                    dosage: {
                        id: 0,
                        name: '',
                    },
                    direction: {
                        id: 0,
                        name: '',
                    },
                    anupaan: {
                        id: 0,
                        name: '',
                    },
                    days: '',
                    remarks: '',
                }],
                instruction: '',
            },
            this.diagnosis = {
                provisionalDiagnosis: []
            },
            this.investigation = {
                master: [{
                    id: 0,
                    servicegroup: '',
                    servicesubgroup: {
                        id: '',
                        name: '',
                        services: {
                            id: '',
                            name: '',
                        },
                        remarks: '',
                    }
                }],
                otherInvestigation: '',
            }
    }
}

export interface IChiefComplaints {
    id: number;
    chief_complaint: string;
}

export interface IInvMstr {
    id: number;
    servicegroup: string;
    servicesubgroup: {
        id: string;
        name: string;
        services: {
            id: string;
            name: string;
        },
        remarks: string;
    }
}

export interface IMedHstry {
    type: string;
    name: string;
    mrng: boolean;
    aftr: boolean;
    evng: boolean;
    dosage: string;
    relfood: string;
    period: string;
    count: string;
}

export interface IPrkrtConstNew {
    id: number;
    name: string;
    prakruti_lookup_values: IPrakLookUpsNew[];
}

export interface IPrakLookUpsNew {
    score: number;
    attribute_name: string;
    score_type: string;
}

export interface IPrkrtConst {
    id: number;
    name: string;
    is_active: boolean;
    prakruti_lookup_values: IPrakLookUps[];
}

export interface IPrakLookUps {
    id: number;
    score: number;
    attribute_name: string;
    is_active: boolean;
    score_type: {
        id: number;
        name: string;
        is_active: boolean;
    }
}

export interface IGenAsmnt {
    id: number;
    name: string;
    is_active: boolean;
    assesment_lookup_values: ILookUps[];
}

export interface IRgaPrksh {
    id: number;
    name: string;
    is_active: boolean;
    roga_pariksha_lookup_values: ILookUps[];
}

export interface ILookUps {
    id: number;
    name: string;
    is_active: boolean;
}

export interface IBenVstLogs {
    visit_status_id: number;
    visit_end_time: string;
}

export interface IDrugs {
    id: number;
    drugType: string;
    drugName: {
        id: number;
        name: string;
    },
    frequency: IIdNames[];
    dosage: {
        id: number;
        name: string;
    },
    direction: {
        id: number;
        name: string;
    },
    anupaan: {
        id: number;
        name: string;
    },
    days: string;
    remarks: string;
}

export interface IIdNames {
    id: number;
    name: string;
}

export interface ICcRemarks {
    index: number;
    value: string;
    dsbld: boolean;
}
export interface IProvisionalDiagnosis {
    
    index: number;
    value: string;
    dsbld: boolean;
    name :string;
}

export class benDepAtchmnts {
    data: IBDAtchmnt[];
    constructor() {
        this.data = [{
            beneficiary_document_id: 0,
            doctor_id: 0,
        }]
    }
}

export interface IBDAtchmnt {
    beneficiary_document_id: number;
    doctor_id: number;
}