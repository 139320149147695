import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MasterService } from 'src/app/shared/services/master.service';
import{Common} from 'src/app/common'
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/shared/services/user.service';
import * as XLSX from 'xlsx';
declare let $: any;

@Component({
  selector: 'app-tenant-features',
  templateUrl: './tenant-features.component.html',
  styleUrls: ['./tenant-features.component.css']
})
export class TenantFeaturesComponent implements OnInit {
  Featurelist:Array<any> = [];
  title='Master Feature List'
  fileName = 'tenant list.xlsx';

  constructor(public activeModal: NgbActiveModal,public userService:UserService,private authSvc:AuthenticationService,
    private toastr:ToastrService) { }

  ngOnInit(): void {
    this.getAllFeatures();
  }
getAllFeatures(){
this.userService.getAllfeatures().subscribe((res:any) => {
  if (res.status == 'OK') {
    this.Featurelist =res.data;
    Common.loadDataTable(this.Featurelist,'#FeatureTable');
    }
})
}
submitFeature(){
  let orgIdValue=JSON.parse(sessionStorage.getItem('currentTenant'));
this.userService.submitFeaturesByOrgId(orgIdValue).subscribe((res:any) => {
  if (res.status == 'OK') {
   this.toastr.success(res.message);
    }
})
}

exportexcel(): void {
  var table = $('#FeatureTable').DataTable();
  if (!table.data().count()) {
    this.toastr.error('No data available in table! Please select and try again');
  }
  else {	
    / table id is passed over here /
    let element = document.getElementById('FeatureTable');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    / generate workbook and add the worksheet /
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    ws['!cols'] = [];
    ws['!cols'][10] = { hidden: true };

    / save to file /
    XLSX.writeFile(wb, this.fileName);
  }
}

}
