import { Injectable, HostListener } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UserIdleTimerService {
    userActiveTimer;
    public isUserInactive: Subject<any> = new Subject();
    constructor() {
        this.timeoutUserActivity();
        this.isUserInactive.subscribe(() => {
            const logOut = document.getElementById('logOut');
            // this.timeoutUserActivity();
            if (logOut) {
                logOut.click();
                console.log('user has been inactive for 3hrs');
            }
        });
    }
    ngOnDestroy(): void {
        this.isUserInactive.unsubscribe();
    }

    timeoutUserActivity() {
        this.userActiveTimer = setTimeout(() => this.isUserInactive.next(undefined), 180 * 60 * 1000);
    }
}