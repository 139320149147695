<div class="modal-header">
    <h4 class="modal-title">Doctors List</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
      <div class="container-fluid pl-0 pr-0">
        <div class="row">
          <div class="col-xl-12">
            <div class="card card-header-actions mx-auto">
              <!-- <div class="row"> -->
              <div class="card-header">
                Active Doctors List
              </div>
              <!-- <div class="card-header">
                <button type="submit"  class="btn btn-sm btn-primary" value="Submit"
                (click)="alldocclick()" style="margin-left: 810px;" *ngIf="alldocdivbtn">All Doctors</button>
              </div> -->
            <!-- </div> -->
              <div class="card-body">
                <div class="table-responsive">
                  <table datatable id="tablel" class="table table-striped table-bordered table-sm" style="width:100%">
                    <thead>
                      <tr>
                        <th class="singleLine">S No </th>
                        <th class="singleLine">Doctor Name </th>
                        <th class="singleLine">Qualification</th>
                        <th class="singleLine">Specialization</th>
                        <th class="singleLine">Experience</th>
                        <th class="singleLine">Status</th>
                        <th class="singleLine">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of activelistofdoctors;let ind=index">
                        <td class="text-center">{{(ind+1)}}</td>
                        <td class="singleLine">{{item.name}}</td>
                        <td class="singleLine">
                          {{item.qualification}}
                        </td>
                        <td class="singleLine">
                          {{item.specialization}}
                        </td>
                        <td class="singleLine">
                          {{item.numberOfYrsExperience}}
                        </td>
                        <td class="singleLine">
                          {{item.status}}</td>
                       <td  *ngIf="roleId==7">
                        <!-- <button type="submit"  class="btn btn-sm btn-primary" value="Submit"
                        (click)="consultDoctorjoin(item)" [disabled]="userName ==item.name">Join</button> -->
                              <button type="submit"  class="btn btn-sm btn-primary" value="Submit"
                                (click)="consultDoctor(item)" [disabled]="userName ==item.name && item.status!=='AVAILABLE'" *ngIf="pagetitle!='familymemberscreen'">Consult Now</button>&nbsp;&nbsp;
                                <button type="submit"  class="btn btn-sm btn-primary" value="Submit"
                                (click)="consultDoctorfm(item)" *ngIf="pagetitle=='familymemberscreen'" [disabled]="userName ==item.name && item.status!=='AVAILABLE'">Consult Now</button>&nbsp;&nbsp;
                          
                       </td>
                       <td *ngIf="roleId==14">
                        <!-- <button type="submit"  class="btn btn-sm btn-primary" value="Submit"
                        (click)="consultDoctorjoin(item)" [disabled]="userName ==item.name">Join</button> -->
                              <button type="submit"  class="btn btn-sm btn-primary" value="Submit"
                                (click)="consultDoctoragent(item)" *ngIf="pagetitle!='familymemberscreen'">Consult Now</button>&nbsp;&nbsp;
                                <button type="submit"  class="btn btn-sm btn-primary" value="Submit"
                                (click)="consultDoctoragentfm(item)" *ngIf="pagetitle=='familymemberscreen'">Consult Now</button>&nbsp;&nbsp;
                            
                          
                       </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="table-responsive" style="display: none;">
                  <table datatable id="tablel" class="table table-striped table-bordered table-sm" style="width:100%">
                    <thead>
                      <tr>
                        <th class="singleLine">S No </th>
                        <th class="singleLine">Doctor Name </th>
                        <th class="singleLine">Qualification</th>
                        <th class="singleLine">Specialization</th>
                        <th class="singleLine">Experience</th>
                        <th class="singleLine">Status</th>
                        <th class="singleLine">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of activelistofdoctors;let ind=index">
                        <td class="text-center" *ngIf="item.doctorId==recentdoctorid">{{(ind+1)}}</td>
                        <td class="singleLine" *ngIf="item.doctorId==recentdoctorid">{{item.name}}</td>
                        <td class="singleLine" *ngIf="item.doctorId==recentdoctorid">
                          {{item.qualification}}
                        </td>
                        <td class="singleLine" *ngIf="item.doctorId==recentdoctorid">
                          {{item.specialization}}
                        </td>
                        <td class="singleLine" *ngIf="item.doctorId==recentdoctorid">
                          {{item.numberOfYrsExperience}}
                        </td>
                        <td class="singleLine" *ngIf="item.doctorId==recentdoctorid">
                          {{item.status}}</td>
                       <td *ngIf="item.doctorId==recentdoctorid">
                        <!-- <button type="submit"  class="btn btn-sm btn-primary" value="Submit"
                        (click)="consultDoctorjoin(item)" [disabled]="userName ==item.name">Join</button> -->
                              <button type="submit"  class="btn btn-sm btn-primary" value="Submit"
                                (click)="consultDoctor(item)" [disabled]="userName ==item.name">Consult Now</button>&nbsp;&nbsp;
                            
                          
                       </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
   