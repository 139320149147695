import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Common } from 'src/app/common';
import { UserService } from 'src/app/shared/services/user.service';
declare let $: any;

@Component({
  selector: 'app-tenantwise-screens',
  templateUrl: './tenantwise-screens.component.html',
  styleUrls: ['./tenantwise-screens.component.css']
})


export class TenantwiseScreensComponent implements OnInit {

  showDetails:boolean = false;
  
  moduleName:number =0;  orderNumber:number =0; screen_id:number =0;

  screenName:string = ''; screenLabel:string = ''; routing:string = '';
  fafaicon:string ='';

  moduleLst:Array<any>=[]; modulescreenList:Array<any>=[]; lstGrid:Array<any>=[];
  updatedSPL:Array<any>=[];

  is_active:boolean = true; can_insert:boolean = false; can_update:boolean = false;
  can_view:boolean = false; can_delete:boolean = false;
  tenantScreens:any; submitted:boolean = false;
  constructor(private toastr:ToastrService
    ,private user:UserService
    ) { }

  ngOnInit(): void {
    this.getModules();
    this.LoadData();
  }

  onSubmit(){

  }

  onBack(){
    this.showDetails = false;
  }

  openModal(){
    $('#myModal').modal('show');
  }

  onClose(){
    $('#myModal').modal('hide');
  }

  getModules(){
    this.user.getAll(`module/getall`).subscribe(res => {
      if (res?.status === "OK") {
        if (res?.data == null || res?.data.length == 0)
          this.toastr.info('No records available');
        else
          this.moduleLst = res.data;
      }
      },
        error => { this.toastr.error(error.message); }
       )
  }

  onSave(){
    if(this.moduleName == 0 || this.orderNumber == 0 || this.routing == '' || this.screenLabel == '' || this.screenName ==''){
      this.toastr.info('Please Enter All Mandatory fields');
      this.submitted = true;
      return;
    }
    let payload = {
      "can_delete": this.can_delete,
      "can_insert": this.can_insert,
      "can_update": this.can_update,
      "can_view": this.can_view,
      "fafa_icon": this.fafaicon,
      "id": this.screen_id,
      "is_active": this.is_active,
      "module": {
        id: +this.moduleName,
      },
      "order_no": this.orderNumber,
      "routing": this.routing,
      "screen_label": this.screenLabel,
      "screen_name": this.screenName
    }
    this.user.save(`screen/save`,payload).subscribe(res => {
      if (res?.status === "OK") {
          this.tenantScreens = res.data;
          this.toastr.success(res.message);
          this.onClear();
          this.LoadData();
          this.onClose();
          this.submitted = false;
      }
      },
        error => { this.toastr.error(error.message); }
       )
  }

  onClear(){
   this.can_insert = this.can_update = this.can_delete = this.can_view = false;
   this.moduleName =this.orderNumber=0;
   this.screenName = this.screenLabel = this.routing = this.fafaicon ='';
  }

  
  LoadData() {
    this.user.getAll(`screen/getall`).subscribe(res => {
      if (res.status === "OK") {
        if (res.data == null || res.data.length == 0)
          this.toastr.info('No records available');
        this.lstGrid = res.data;
        this.updateNewsPermission();
        this.updatedSPL.forEach((x,i)=>{
          $('.dataTable').DataTable().destroy();
          $('.dataTable').DataTable({ destroy: true, searching: false });
          setTimeout(() => {
            $('#newpermissionTable'+(i+1)).DataTable();
          }, 2000);
        });
        
      }
    },
      err => { this.toastr.error(err.error.message == '' ? 'Something went wrong' : err.error.message);  },
      () => { })
  }

  updateNewsPermission(){
    this.updatedSPL = [];
    for(const[key,value] of Object.entries(this.lstGrid)){
      let item = {name: key , lst:value};
      item.lst.sort((a,b) => (a.screen_label < b.screen_label ? -1 : 1));
      this.updatedSPL.push(item);
    }
    this.updatedSPL.sort((a,b) => (a.name < b.name ? -1 : 1));
  }

  Edit(item){
     $('#myModal').modal('show');
     this.moduleName = item.module?.id,
     this.screenLabel = item.screen_label;
     this.screenName = item.screen_name;
     this.orderNumber = item.order_no;
     this.fafaicon = item.fafa_icon;
     this.routing = item.routing;
     this.is_active = item.is_active;
     this.can_insert = item.can_insert;
     this.can_update = item.can_update;
     this.can_view  = item.can_view;
     this.can_delete = item.can_delete;
     this.screen_id = item.id;
  }
}
