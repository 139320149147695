<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Close click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="table-responsive" *ngIf="!insuranceFilesShow">
        <table class="table table-bordered table-sm table-striped small">
            <thead>
                <tr class="sticky-top heading-bg">
                    <!-- <th class="text-center" style="width:6%;">S No</th> -->
                    <!-- <th class="text-center" scope="col" style="width: 25%;">File Name</th> -->
                    <th>File Name</th>
                    <th>Date</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <!-- <tr *ngFor="let item of previewFileList;let i=index">
                    <td style="text-align: center;">{{(i+1)}}</td>                   
                    <td>{{item.file_name}}</td>
                    <td>{{item.date | date: "dd-MM-yyyy HH:mm:ss"}}</td>
                    <td><a title="View" (click)="showImageFile(item.document_path)"><i class="text-primary fa fa-eye"></i></a></td>
                </tr> -->
                <!-- old view files -->
                <!-- <ng-container *ngFor="let itemn of previewFileList;let i=index">
                    <tr *ngFor="let item of itemn.response;let i=index">
                         <!-- <td style="text-align: center;">{{(i+1)}}</td>                    -->
                         <!-- <td>{{item.file_name}}</td>
                         <!-- <td>{{item?.date | date: "dd-MM-yyyy HH:mm:ss"}}</td> -->
                         <!-- <td>{{item?.visit_date | date: "dd-MM-yyyy HH:mm:ss"}}</td>
                         <td class="text-center" ><a title="View"  (click)="showImageFile(item,item.document_path)"><i class="text-primary fa fa-eye"></i></a></td>
                    </tr>
                 </ng-container>  -->
                 <!-- // sushma view file 03-08-2022 -->
                    <tr *ngFor="let item of previewFileList;let i=index">
                         <!-- <td style="text-align: center;">{{(i+1)}}</td>                    -->
                         <td>{{item.file_name}}</td>
                         <!-- <td>{{item?.date | date: "dd-MM-yyyy HH:mm:ss"}}</td> -->
                         <td>{{item?.visit_date | date: 'dd-MM-yyyy'}}</td>
                         <td class="text-center" ><a title="View"  (click)="showImageFile(item,item.document_path)"><i class="text-primary fa fa-eye"></i></a></td>
                    </tr>
            </tbody>
        </table>
    </div>
    <div class="table-responsive" *ngIf="insuranceFilesShow">
        <table class="table table-bordered table-sm table-striped small">
            <thead>
                <tr class="sticky-top heading-bg">
                    <!-- <th class="text-center" style="width:6%;">S No</th> -->
                    <!-- <th class="text-center" scope="col" style="width: 25%;">File Name</th> -->
                    <th>File Name</th>
                    <th>Date</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <!-- <tr *ngFor="let item of previewFileList;let i=index">
                    <td style="text-align: center;">{{(i+1)}}</td>                   
                    <td>{{item.file_name}}</td>
                    <td>{{item.date | date: "dd-MM-yyyy HH:mm:ss"}}</td>
                    <td><a title="View" (click)="showImageFile(item.document_path)"><i class="text-primary fa fa-eye"></i></a></td>
                </tr> -->
                <!-- old view files -->
                <!-- <ng-container *ngFor="let itemn of previewFileList;let i=index">
                    <tr *ngFor="let item of itemn.response;let i=index">
                         <!-- <td style="text-align: center;">{{(i+1)}}</td>                    -->
                         <!-- <td>{{item.file_name}}</td>
                         <!-- <td>{{item?.date | date: "dd-MM-yyyy HH:mm:ss"}}</td> -->
                         <!-- <td>{{item?.visit_date | date: "dd-MM-yyyy HH:mm:ss"}}</td>
                         <td class="text-center" ><a title="View"  (click)="showImageFile(item,item.document_path)"><i class="text-primary fa fa-eye"></i></a></td>
                    </tr>
                 </ng-container>  -->
                 <!-- // sushma view file 03-08-2022 -->
                    <tr *ngFor="let item of insuranceFilesListNew;let i=index">
                         <!-- <td style="text-align: center;">{{(i+1)}}</td>                    -->
                         <td>{{item.fileName}}</td>
                         <!-- <td>{{item?.date | date: "dd-MM-yyyy HH:mm:ss"}}</td> -->
                         <td>{{item?.date | date: 'dd-MM-yyyy'}}</td>
                         <td class="text-center" *ngIf="item.fileName!=='AadharCard'"><a title="View"  (click)="showImageInsuranceFile(item.fullObj,item.file)"><i class="text-primary fa fa-eye"></i></a></td>
                         <td class="text-center" *ngIf="item.fileName==='AadharCard'"><span *ngFor="let path of item.file">
                            <a title="View"  (click)="showImageInsuranceFile(item.fullObj,path)"><i class="text-primary fa fa-eye"></i>&nbsp; &nbsp;</a></span></td>
                        </tr>
            </tbody>
        </table>
    </div>
</div>
<div class="modal-footer">
    <!-- <input type="submit" class="btn btn-sm btn-primary" value="Submit">&nbsp;&nbsp; -->
    <button type="button" id="viewFileButton" class="btn btn-sm btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
</div>