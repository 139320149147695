import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-ivideo',
  templateUrl: './ivideo.component.html',
  styleUrls: ['./ivideo.component.css'],
})

export class IvideoComponent implements OnInit {
  
  @Input() url: string = ''; @Input() type: string = '';
  vdoPath: SafeResourceUrl; docPath: SafeResourceUrl;

  constructor(public sntzr: DomSanitizer, public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.vdoPath = this.sntzr.bypassSecurityTrustResourceUrl(this.url);  // location.origin + environment.vdoUM
    // this.docPath = this.sntzr.bypassSecurityTrustResourceUrl(`https://drive.google.com/file/d/15om8RXb8o4xNI3Ge6LTTKwL4tu45YFxV/view?usp=sharing&embedded=true`);
  }

}