<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="container-fluid">
        <form [formGroup]="companyForm" [hidden]="!isCorp" (ngSubmit)="onSubmit($event)">
            <div class="row">
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">{{isECLINIC ? 'States' : 'State'}} <span
                                class="text-danger">*</span> </label>
                        <div>
                            <select formControlName="state_id" id="state_id" #State
                                (change)="locationChanged(State.value, 'district')"
                                [ngClass]="{ 'is-invalid': submitted && f.state_id.errors }"
                                class="form-control form-control-sm">
                                <option value="">Select</option>
                                <option *ngFor="let state of stateList" [value]="state.id">{{state.state_name}}</option>
                            </select>
                            <div *ngIf="submitted && f.state_id.errors" class="invalid-feedback">
                                <div *ngIf="f.state_id.errors.required">{{isECLINIC ? 'States' : 'State'}} is required
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">{{isECLINIC ? 'State Capitals' : 'District'}}
                            <span class="text-danger">*</span> </label>
                        <div>
                            <!-- (change)="locationChanged(District.value,'')" -->
                            <select formControlName="district_id" id="district_id" #District
                                [ngClass]="{ 'is-invalid': submitted && f.district_id.errors }"
                                class="form-control form-control-sm">
                                <option value="">Select</option>
                                <option *ngFor="let district of districtList" [value]="district.id">
                                    {{district.district_name}}</option>
                            </select>
                            <div *ngIf="submitted && f.district_id.errors" class="invalid-feedback">
                                <div *ngIf="f.district_id.errors.required">
                                    {{isECLINIC ? 'State Capitals' : 'District'}} is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Registration Number <span class="text-danger">*</span>
                        </label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="registration_no" id="registration_no"
                                onkeypress='return event.charCode >= 48 && event.charCode <= 57' maxlength="20"
                                [ngClass]="{ 'is-invalid': submitted && f.registration_no.errors }"
                                placeholder="Registration Number" class="form-control form-control-sm">
                            <div *ngIf="submitted && f.registration_no.errors" class="invalid-feedback">
                                <div *ngIf="f.registration_no.errors.required">Registration Number is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Company Name <span class="text-danger">*</span> </label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="company_name" id="company_name"
                                [ngClass]="{ 'is-invalid': submitted && f.company_name.errors }" maxlength="50"
                                class="form-control form-control-sm text-uppercase" placeholder="Company Name"
                                onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122)|| event.charCode == 32)'>
                            <div *ngIf="submitted && f.company_name.errors" ng-pattern="/^[.]*$/"
                                class="invalid-feedback">
                                <div *ngIf="f.company_name.errors.required">
                                    Company Name is required</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Company Address <span class="text-danger">*</span></label>
                        <div>
                            <textarea type="text" autocomplete="off" formControlName="address" id="address"
                                maxlength="100" placeholder="Company Address"
                                onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                            (event.charCode >= 97 && event.charCode <= 122) || (event.charCode == 32) || (event.charCode == 44)|| 
                            (event.charCode == 45)|| (event.charCode == 47)) ||  (event.charCode >= 48 && event.charCode <= 57)' maxlength="100"
                                [ngClass]="{ 'is-invalid': submitted && f.address.errors }"
                                class="form-control form-control-sm"></textarea>
                            <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                                <div *ngIf="f.address.errors.required">Address is required
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Company Pin Code <span class="text-danger">*</span></label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="pin_code" id="pin_code"
                                [ngClass]="{ 'is-invalid': submitted && f.pin_code.errors }"
                                class="form-control form-control-sm"
                                onkeypress='return event.charCode >= 48 && event.charCode <= 57' maxlength="6"
                                placeholder="Company Pin Code">
                            <div *ngIf="submitted && f.pin_code.errors" class="invalid-feedback">
                                <div *ngIf="f.pin_code.errors.required">Pin Code is required</div>
                                <div *ngIf="f.pin_code.errors.minlength">Pincode should have 6 digits</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Company Contact Number <span class="text-danger">*</span></label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="contact_number"
                                id="contact_person_last_name" [pattern]="mobPtrn"
                                onkeypress='return event.charCode >= 48 && event.charCode <= 57' [maxlength]="maxLngth"
                                placeholder="Company Contact Number" class="form-control form-control-sm"
                                [ngClass]="{ 'is-invalid': submitted && f.contact_number.errors }">
                            <div *ngIf="submitted && f.contact_number.errors" class="invalid-feedback">
                                <div *ngIf="f.contact_number.errors.required">Company Contact Number is required</div>
                                <div *ngIf="f.contact_number.errors.pattern">Company Contact Number {{mobErrMsg}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Company Email <span class="text-danger">*</span></label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="company_email" id="company_email"
                                maxlength="50" placeholder="Company Email" maxlength="60"
                                [ngClass]="{ 'is-invalid': submitted && f.company_email.errors }" maxlength="50"
                                class="form-control form-control-sm" required>
                            <div *ngIf="submitted && f.company_email.errors" class="invalid-feedback">
                                <div *ngIf="f.company_email.errors.pattern">
                                    Sample email address is x@yahoo.com
                                </div>
                                <div *ngIf="f.company_email.errors.required">
                                    Company Email is required
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Company Whatsapp Number <span class="text-danger">*</span></label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="company_whatsapp_number"
                                id="company_whatsapp_number" placeholder="Company Whatsapp Number"
                                onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                                [ngClass]="{ 'is-invalid': submitted && f.company_whatsapp_number.errors }"
                                class="form-control form-control-sm" [maxlength]="maxLngth" [pattern]="mobPtrn">
                            <div *ngIf="submitted && f.company_whatsapp_number.errors" class="invalid-feedback">
                                <div *ngIf="f.company_whatsapp_number.errors.required">Company Whatsapp Number is
                                    required</div>
                                <div *ngIf="f.company_whatsapp_number.errors.pattern">Company Whatsapp Number
                                    {{mobErrMsg}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Person First Name <span class="text-danger">*</span>
                        </label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="contact_person_first_name"
                                id="contact_person_first_name" class="form-control form-control-sm text-uppercase"
                                onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                    (event.charCode >= 97 && event.charCode <= 122)|| event.charCode == 32)'
                                maxlength="50" placeholder="Person First Name"
                                [ngClass]="{ 'is-invalid': submitted && f.contact_person_first_name.errors }">
                            <div *ngIf="submitted && f.contact_person_first_name.errors" class="invalid-feedback">
                                <div *ngIf="f.contact_person_first_name.errors.required">Person First Name is required
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Person Last Name <span class="text-danger">*</span></label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="contact_person_last_name"
                                id="contact_person_last_name"
                                onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122)|| event.charCode == 32)'
                                maxlength="50" placeholder="Person Last Name"
                                [ngClass]="{ 'is-invalid': submitted && f.contact_person_last_name.errors }"
                                class="form-control form-control-sm text-uppercase">
                            <div *ngIf="submitted && f.contact_person_last_name.errors" class="invalid-feedback">
                                <div *ngIf="f.contact_person_last_name.errors">
                                    Person last Name is required
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Person Email <span class="text-danger">*</span></label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="contact_person_company_email"
                                id="contact_person_company_email" maxlength="60"
                                [ngClass]="{ 'is-invalid': submitted && f.contact_person_company_email.errors }"
                                maxlength="50" placeholder="Person Email" class="form-control form-control-sm" required>
                            <div *ngIf="submitted && f.contact_person_company_email.errors" class="invalid-feedback">
                                <div *ngIf="f.contact_person_company_email.errors.pattern">
                                    sample email address is x@yahoo.com
                                </div>
                                <div *ngIf="f.contact_person_company_email.errors.required">
                                    Person Email is required
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Person Number <span class="text-danger">*</span></label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="contact_person_phone_number"
                                [ngClass]="{ 'is-invalid': submitted && f.contact_person_phone_number.errors }"
                                [maxlength]="maxLngth" [pattern]="mobPtrn" placeholder="Person Number"
                                onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                                id="contact_person_phone_number" class="form-control form-control-sm">
                            <div *ngIf="submitted && f.contact_person_phone_number.errors" class="invalid-feedback">
                                <div *ngIf="f.contact_person_phone_number.errors.required">Person Number is required
                                </div>
                                <div *ngIf="f.contact_person_phone_number.errors.pattern">Person Number {{mobErrMsg}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <label class="control-label">Status <span class="text-danger">*</span></label> <br />
                    <div class="">
                        <div class="custom-control-inline custom-control custom-radio">
                            <input formControlName="is_active" [value]="false" class="custom-control-input"
                                id="customRadio111" type="radio" name="is_active">
                            <label class="custom-control-label" for="customRadio111">Active </label>
                        </div>
                        <div class="custom-control-inline custom-control custom-radio">
                            <input formControlName="is_active" [value]="true" class="custom-control-input "
                                id="customRadio222" type="radio" name="is_active">
                            <label class="custom-control-label" for="customRadio222">In Active </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-3" *ngIf="!isCorp">
                    <div class="form-group">
                        <label class="control-label"><b>Corporate ID</b></label>
                        <input type="text" disabled class="form-control form-control-sm">
                    </div>
                </div>
            </div>
            <input type="submit" id="btnsubmit" style="display: none;">
        </form>
        <form [formGroup]="corporateForm" [hidden]="isCorp" (ngSubmit)="onSubmit($event)">
            <div class="row">
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Corporate Name <span class="text-danger">*</span> </label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="corporate_name" id="corporate_name"
                                [ngClass]="{ 'is-invalid': submitted && c.corporate_name.errors }"
                                class="form-control form-control-sm text-uppercase" placeholder="Corporate Name"
                                onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97
                                    && event.charCode <= 122) || (event.charCode >= 48 && event.charCode <= 57))'>
                            <div *ngIf="submitted && c.corporate_name.errors" class="invalid-feedback">
                                <div *ngIf="c.corporate_name.errors.required">Corporate Name is required</div>
                                <div *ngIf="c.corporate_name.errors.minlength">Minimum Characters is 6</div>
                                <div *ngIf="c.corporate_name.errors.maxlength">Maximum Characters is 10</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" *ngIf="+corporateForm.get('id').value!=0">
                    <div class="form-group">
                        <label class="control-label">Corporate ID <span class="text-danger">*</span></label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="corporate_code" id="corporate_code"
                                [ngClass]="{ 'is-invalid': submitted && c.corporate_code.errors }" readonly
                                class="form-control form-control-sm text-uppercase" placeholder="Corporate Code"
                                onkeypress='return event.charCode >= 48 && event.charCode <= 57' maxlength="6">
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Address <span class="text-danger">*</span></label>
                        <div>
                            <textarea type="text" autocomplete="off" formControlName="main_address" id="main_address"
                                placeholder="Company Address" maxlength="500" minlength="1"
                                [ngClass]="{ 'is-invalid': submitted && c.main_address.errors }"
                                class="form-control form-control-sm"></textarea>
                            <div *ngIf="submitted && c.main_address.errors" class="invalid-feedback">
                                <div *ngIf="c.main_address.errors.required">Address is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Contact Person Name <span class="text-danger">*</span> </label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="primary_contact_name"
                                id="primary_contact_name"
                                [ngClass]="{ 'is-invalid': submitted && c.primary_contact_name.errors }"
                                class="form-control form-control-sm text-uppercase" placeholder="Contact Person Name"
                                onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                    (event.charCode >= 97 && event.charCode <= 122) || event.charCode == 32)'>
                            <div *ngIf="submitted && c.primary_contact_name.errors" class="invalid-feedback">
                                <div *ngIf="c.primary_contact_name.errors.required">Contact Person Name is required
                                </div>
                                <div *ngIf="c.primary_contact_name.errors.minlength">Minimum Characters is 4</div>
                                <div *ngIf="c.primary_contact_name.errors.maxlength">Maximum Characters is 20</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Contact Person Number <span class="text-danger">*</span></label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="primary_contact_no"
                                id="primary_contact_no" [pattern]="mobPtrn" [maxlength]="maxLngth"
                                onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                                placeholder="Contact Person Number" class="form-control form-control-sm"
                                [ngClass]="{ 'is-invalid': submitted && c.primary_contact_no.errors }">
                            <div *ngIf="submitted && c.primary_contact_no.errors" class="invalid-feedback">
                                <div *ngIf="c.primary_contact_no.errors.required">Contact Person Number is required
                                </div>
                                <div *ngIf="c.primary_contact_no.errors.pattern">Contact Person Number {{mobErrMsg}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Corporate Alternate Number </label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="alternate_contact_no"
                                id="alternate_contact_no" [pattern]="mobPtrn" [maxlength]="maxLngth"
                                onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                                placeholder="Corporate Alternate Number" class="form-control form-control-sm"
                                [ngClass]="{ 'is-invalid': submitted && c.alternate_contact_no.errors }">
                            <div *ngIf="submitted && c.alternate_contact_no.errors" class="invalid-feedback">
                                <div *ngIf="c.alternate_contact_no.errors.required">Corporate Alternate Number is
                                    required</div>
                                <div *ngIf="c.alternate_contact_no.errors.pattern">Corporate Alternate Number
                                    {{mobErrMsg}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Corporate Email <span class="text-danger">*</span></label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="email" id="email" maxlength="60"
                                placeholder="Corporate Email" [ngClass]="{ 'is-invalid': submitted && c.email.errors }"
                                class="form-control form-control-sm">
                            <div *ngIf="submitted && c.email.errors" class="invalid-feedback">
                                <div *ngIf="c.email.errors.pattern">Sample email address is x@yahoo.com</div>
                                <div *ngIf="c.email.errors.required">Corporate Email is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Fax </label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="fax"
                                onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                                [ngClass]="{ 'is-invalid': submitted && c.fax.errors }" id="fax"
                                class="form-control form-control-sm" placeholder="Fax">
                            <div *ngIf="submitted && c.fax.errors" class="invalid-feedback">
                                <div *ngIf="c.fax.errors.minlength">Minimum Characters is 5</div>
                                <div *ngIf="c.fax.errors.maxlength">Maximum Characters is 12</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <label class="control-label">Status <span class="text-danger">*</span></label> <br />
                    <div class="">
                        <div class="custom-control-inline custom-control custom-radio">
                            <input formControlName="is_active" [value]="true" class="custom-control-input" id="crp1"
                                type="radio" name="is_active">
                            <label class="custom-control-label" for="crp1">Active </label>
                        </div>
                        <div class="custom-control-inline custom-control custom-radio">
                            <input formControlName="is_active" [value]="false" class="custom-control-input " id="crp2"
                                type="radio" name="is_active">
                            <label class="custom-control-label" for="crp2">In Active </label>
                        </div>
                    </div>
                </div>
            </div>
            <input type="submit" id="btnsubmit" style="display: none;">
        </form>
        <br />
        <div *ngIf="!isCorp">
            <div class="row">
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Corporate Levels</label>
                        <div>
                            <div tabindex="0" class="">
                                <ng-multiselect-dropdown [settings]="corpDDStngs" (onSelect)="onMultiSelect($event)"
                                    (onDeSelect)="onMultiSelect($event)"
                                    [ngClass]="{ 'is-invalid': submitted && isPlnError }" [(ngModel)]="lstClr"
                                    [data]="empLvlLst" [placeholder]="'Select'">
                                </ng-multiselect-dropdown>
                                <div *ngIf="submitted && isPlnError" class="invalid-feedback">
                                    Corporate Levels is required
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Corporate Plans</label>
                        <div>
                            <select id="plan_id" class="form-control form-control-sm" [(ngModel)]="slctPlan"
                                [ngClass]="{ 'is-invalid': submitted && slctPlan == '' && lstCorps.length == 0 }">
                                <option value="">Select</option>
                                <option *ngFor="let plann of plansList" value="{{plann.plan}}">{{plann.plan}}</option>
                            </select>
                            <div *ngIf="submitted && slctPlan == '' && lstCorps.length == 0" class="invalid-feedback">
                                Corporate Plans is required
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-2">
                    <div class="form-group">
                        <label class="control-label">Plan Start Date <span class="text-danger">*</span></label>
                        <div>
                            <input type="text" autocomplete="off" (focus)="dp.show()" [(ngModel)]="sd"
                                placeholder="Plan Start Date" bsDatepicker #dp="bsDatepicker" id="sd"
                                (focus)="dp1.hide()" [ngClass]="{ 'is-invalid': submitted && (sd == '' || sd == null) }"
                                (bsValueChange)="onFromDateChange($event)" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false,
                                    adaptivePosition: true,isAnimated: true }" [minDate]="minDate"
                                class="form-control form-control-sm" />
                            <div *ngIf="submitted && (sd == '' || sd == null)" class="invalid-feedback">
                                Plan Start Date is required
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-2">
                    <div class="form-group">
                        <label class="control-label">Plan End Date <span class="text-danger">*</span></label>
                        <div>
                            <input type="text" autocomplete="off" class="form-control form-control-sm" [(ngModel)]="ed"
                                id="ed" placeholder="Plan End Date" bsDatepicker [minDate]="minToDate"
                                (focus)="dp.hide()" #dp1="bsDatepicker" (focus)="dp1.show()"
                                [ngClass]="{ 'is-invalid': submitted && (ed == '' || ed == null) }" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false,
                                    adaptivePosition: true,isAnimated: true }">
                            <div *ngIf="submitted && (ed == '' || ed == null)" class="invalid-feedback">
                                Plan End Date is required
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-1">
                    <div class="form-group">
                        <label class="control-label"></label><br />
                        <button class="btn btn-blue btn-icon float-right" (click)="onAddECL()" type="button"
                            [disabled]="lstSlctdECL.length == 0 || slctPlan == '' || sd == '' ||ed == '' "><i
                                class="fa fa-plus"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="lstCorps.length != 0">
                <table style="width:60%">
                    <thead>
                        <tr>
                            <th class="brdr thBg">S No</th>
                            <th class="brdr thBg">Corporate Levels</th>
                            <th class="brdr thBg">Corporate Plan</th>
                            <th class="brdr thBg">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of lstCorps; let i=index">
                            <td class="brdr">{{i+1}}</td>
                            <td class="brdr">{{item.empLvl}}</td>
                            <td class="brdr">{{item.pln}}</td>
                            <td class="brdr">
                                <button class="btn btn-sm btn-danger btn-icon float-right" (click)="onDelCP(i)"
                                    type="button" title="Delete" style="margin-right: 34%"><i class="fa fa-trash"></i>
                                </button>&nbsp;&nbsp;&nbsp;&nbsp;
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <input type="submit" class="btn btn-sm btn-primary" [disabled]="isSubmit_Disabled?true:null" (click)="submitForm()"
        value="Submit">&nbsp;&nbsp;
    <button type="button" class="btn btn-sm btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
</div>