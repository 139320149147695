<div class="modal fade" data-backdrop="static" data-keyboard="false" tabindex="-1" style="z-index: 99999 !important;"
    id="emergencyModal" role="dialog" aria-labelledby="edit" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Do you want to Exit the Plan of Care ?</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-group ">
                    <div class="col-md-12">
                        <label>Comments</label>
                        <textarea type="text" rows="5" [(ngModel)]="emergencyComment"  class="form-control form-control-sm"> </textarea>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-sm  btn-primary" data-dismiss="modal" (click)="emergency_Exit()"
                    data-dismiss="modal">Yes</button>
                <button type="button" class="btn btn-sm btn-danger" data-dismiss="modal">No</button>
            </div>
        </div>
    </div>
</div>