import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { environment } from 'src/environments/environment';
import { Common, Avail } from 'src/app/common';
import { ToastrService } from 'ngx-toastr';

declare let $: any;

@Component({
  selector: 'app-vitals',
  templateUrl: './vitals.component.html',
  styleUrls: ['./vitals.component.css']
})
export class VitalsComponent implements OnInit {

  vitalsList: any = [];
  dtRender:boolean;
  orgn = window.location.href.includes('localhost') ? environment.applicationURL : window.origin;
  @Input() bdId: any;
  @Input() is_self: any;
  @Input() fmbdId :any

  constructor(public activeModal: NgbActiveModal, private regService: RegistrationService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.getAll()
  }
  
  getAll() {
    this.dtRender=false
    this.regService.getByAllVitals(this.bdId, this.is_self).subscribe(res => {
      if (res?.status === "OK") {
        if (res?.data == null || res?.data.length == 0) {
          this.toastr.info('No records available');
          this.dtRender=true
          this.vitalsList = [];
        }
        else
          this.vitalsList = res.data;
          this.dtRender=true
        // if (this.is_self)
        // {
         
        //   this.vitalsList = result.filter(f => f.beneficiary_member_id == null); 
        //   // if(this.vitalsList.length==0){
        //     this.dtRender=true;
        //   // } 
        // }
         
        // else{

        //   this.vitalsList = result.filter(f => f.beneficiary_member_id == this.bdId);
        //   // if(this.vitalsList.length==0){
        //     this.dtRender=true;
        //   // } 
        // }

        
        // setTimeout(() => $(`#vitalTable`).DataTable({ destroy: true }));
      }
      console.log("vitallist",this.vitalsList);
      
    });
  }

  onViewDoc(fileTypeId: number, res: any) {
    let link = document.createElement("a"), filePath = '', docPath = '', docLen = 0;
    if (res.beneficiary_member_id == null) {
      docLen = res.beneficiary_vital_documents.filter(f => f.file_type_id == fileTypeId).length;
      if (docLen > 0)
        docPath = res.beneficiary_vital_documents.filter(f => f.file_type_id == fileTypeId)[docLen-1]?.document_path ?? '';
    }
    else {
      docLen = res.beneficiary_member_vital_documents.filter(f => f.fileTypeId == fileTypeId).length;
      if (docLen > 0)
        docPath = res.beneficiary_member_vital_documents.filter(f => f.fileTypeId == fileTypeId)[docLen-1]?.documentPath ?? '';
    }
    if (docPath != '') {
      filePath = `${this.orgn}/${environment.viewFilePath}${res.beneficiary_member_id == null?Avail.BnfcryTxt:Avail.MemDocsTxt}${docPath.split('_')[0]}/${fileTypeId==17?environment.ecgText:environment.audioText}${docPath}`;
      link.setAttribute('href', filePath) ,link.setAttribute('visibility', 'hidden'), link.setAttribute('display', 'none'), link.setAttribute('target', '_blank'), document.body.appendChild(link), link.click();
    }
    else
      this.toastr.info('No Files found');
  }

}