
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { ToastrService } from 'ngx-toastr';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { MasterService } from 'src/app/shared/services/master.service';
import { BaseComponent } from 'src/app/shared/base.component';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import * as XLSX from 'xlsx';
import { ExcelDownloadService } from 'src/app/shared/services/excel-download.service';
import { DoctorRegistrationComponent } from '../doctor-reg/doctor-registration/doctor-registration.component';
import { LaboratoryRegistrationComponent } from '../lab-phar-reg/laboratory-registration/laboratory-registration.component';
import { PharmacyRegistrationComponent } from '../lab-phar-reg/pharmacy-registration/pharmacy-registration.component';
import { HospitalDetailComponent } from '../hospital-reg/hospital-detail/hospital-detail.component';
import { CompanyComponent } from 'src/app/modules/masters/master/company/company.component';
import { CommonDoctorRegistrationComponent } from '../doctor-reg/common-doctor-registration/common-doctor-registration.component';
import { threadId } from 'worker_threads';
import { CommonLaboratoryRegistrationComponent } from '../lab-phar-reg/common-laboratory-registration/common-laboratory-registration.component';
import { CommonPharmacyRegistrationComponent } from '../lab-phar-reg/common-pharmacy-registration/common-pharmacy-registration.component';
import { CommonHospitalRegComponent } from '../hospital-reg/common-hospital-reg/common-hospital-reg.component';
import { ItemsList } from '@ng-select/ng-select/ng-select/items-list';
import { Common } from 'src/app/common';
import { Subject } from 'rxjs';
declare let $: any;


@Component({
  selector: 'app-in-active-list',
  templateUrl: './in-active-list.component.html',
  styleUrls: ['./in-active-list.component.css']
})

export class InActiveListComponent extends BaseComponent implements OnInit {

  modalTitle: string = '';
  headerList: any = [];
  dataList: Array<any> = [];
  selectedTest: string = 'doctor';
  checkedList: Array<{ id: number, account_activated: boolean,is_active: boolean, email_id: string, mobile_no: string, name: string }> = [];
  chkdLst: Array<{ id: number, account_activated: boolean, is_active: boolean, user_type: string }> = [];
  isSAGE = environment.isSAGE;idval1:any;idval2:any;
  fileName = '';
  excelData: any =[];
  excelType: string; screennames:any;
  tenantChecking: any;
  isukhub: boolean=false;
  dtRender:boolean;
  dtRender2:boolean;
  dtRender3:boolean;
  dtRender4:boolean;
  dtRender5:boolean;
  dtOptions: any = {};
  dtOptions2:any ={};
  dtOptions3:any ={};
  dtOptions4:any ={};
  dtOptions5:any ={};
  dtTrigger1: Subject<any> = new Subject();
  constructor(config: NgbModalConfig,
    
public changeDetection: ChangeDetectorRef
    , private modalService: NgbModal
    , private toastr: ToastrService
    , private notificationService: NotificationService
    , private regService: RegistrationService
    , private authSvc: AuthenticationService,
    private excelDownloadService:ExcelDownloadService
    , private masterService: MasterService, ) {
    super();
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 15,
      dom: 'Bfrtip',
      processing: true,
        buttons: [
          
            {
              extend: 'excelHtml5',
              title: 'Doctor_list',
              exportOptions: { columns: ':visible:not(:first-child)' }
          },
          
          // {
          //     // extend: 'pdfHtml5',
          //     title: 'Data export'
          // }
      ]

    };
    this.dtOptions2 = {
      pagingType: 'full_numbers',
      pageLength: 15,
      dom: 'Bfrtip',
      processing: true,
        buttons: [
          
            {
              extend: 'excelHtml5',
              title: 'pharmacy_list',
              exportOptions: { columns: ':visible:not(:first-child)' }
          },
          
          // {
          //     // extend: 'pdfHtml5',
          //     title: 'Data export'
          // }
      ]

    };
    this.dtOptions3 = {
      pagingType: 'full_numbers',
      pageLength: 15,
      dom: 'Bfrtip',
      processing: true,
        buttons: [
          
            {
              extend: 'excelHtml5',
              title: 'lab_list',
              exportOptions: { columns: ':visible:not(:first-child)' }
          },
          
          // {
          //     // extend: 'pdfHtml5',
          //     title: 'Data export'
          // }
      ]

    };
    this.dtOptions4 = {
      pagingType: 'full_numbers',
      pageLength: 15,
      dom: 'Bfrtip',
      processing: true,
        buttons: [
          
            {
              extend: 'excelHtml5',
              title: 'hospital_list',
              exportOptions: { columns: ':visible:not(:first-child)' }
          },
          
          // {
          //     // extend: 'pdfHtml5',
          //     title: 'Data export'
          // }
      ]

    };
    // this.dtOptions5 = {
    //   pagingType: 'full_numbers',
    //   pageLength: 15,
    //   dom: 'Bfrtip',
    //   processing: true,
    //     buttons: [
          
    //         {
    //           extend: 'excelHtml5',
    //           title: 'company_list',
    //           exportOptions: { columns: ':visible:not(:first-child)' }
    //       },
          
    //       // {
    //       //     // extend: 'pdfHtml5',
    //       //     title: 'Data export'
    //       // }
    //   ]

    // };
    this.sideMenupermission();
    this.tenantChecking=JSON.parse(sessionStorage.getItem('currentUser'))?.tenantData?.tenantModuleList;
    this.tenantChecking?.forEach(f=>{
      if(f.componentName == environment.tenantConfig.isUKT){
        this.isukhub = true;
      }
    }) 
    this.authSvc.isShowGlblOrg.next(false), this.authSvc.tenantId = sessionStorage.getItem('defCrntTnnt')
    if (this.authSvc.roleId == 1) {
      sessionStorage.setItem('currentTenant', sessionStorage.getItem('defCrntTnnt'));
    }
       
      this.getHeadings('doctor');
  }

  sideMenupermission() {
    this.screennames = JSON.parse(sessionStorage.getItem('menu'));
  }

  getHeadings(type: string) {
    this.excelType = type;
this.idval1='#'+type;
this.idval2=type;
 var heading;
 if(this.isukhub==true){
   heading = {
    doctor: ['District','Hub/Centre','Doctor Name', `${this.isSAGE ? 'KMPDU Number' : 'MCI Number'}`, 'Email', 'Actions', 'View'], // Status
    pharmacy: ['District','Block','Centre','Pharmacy Name', 'Registration Number', 'Status', 'View'],
    lab: ['District','Block','Centre','Lab Name', 'Registration Number', 'NABL Certificate', 'Status', 'View'],
    company: ['Company Name', 'Registration Number', 'Status', 'View'],
    hospital: ['District','Block','Centre','Hospital Name', 'Registration Number', 'Status', 'View'],
  }
 }
else{
  heading = {
    doctor: ['Doctor Name', `${this.isSAGE ? 'KMPDU Number' : 'MCI Number'}`, 'Email', 'Actions', 'View'], // Status
    pharmacy: ['Pharmacy Name', 'Registration Number', 'Status', 'View'],
    lab: ['Lab Name', 'Registration Number', 'NABL Certificate', 'Status', 'View'],
    company: ['Company Name', 'Registration Number', 'Status', 'View'],
    hospital: ['Hospital Name', 'Registration Number', 'Status', 'View'],
  }
 
}

    if (this.headerList.length > 0) {
      // $('.dataTable').DataTable().destroy();;
      // $('.dataTable').DataTable({ destroy: true, searching: false });
    }
    this.selectedTest = type, 
    console.log('selectedType...',this.selectedTest)
    this.headerList = heading[type], 
    this.loadTable(type), this.checkedList = [];
    // console.log('#########',this.headerList,type);
    // alert('STEP');
  }

  destroyExistingTables(tableId: string) {
    let [tablesName, destroyTables] = [['doctor', 'pharmacy', 'lab', 'company', 'hospital'], ''];
    tablesName.forEach(item => { if (tableId !== item) destroyTables += `#${item},#${item}_wrapper,`; });
    destroyTables = destroyTables.substring(0, destroyTables.length - 1);
     setTimeout(() => {
       $(destroyTables).attr("hidden", "hidden");
       $(`#${tableId},#${tableId}_wrapper`).removeAttr("hidden");
    //   $(`#${tableId}`).DataTable({ destroy: true });
    }, 1000);
  }

  tableDataFromAPI(subURL: string,type:string) {
    this.dataList = [];
 
    this.regService.getAll(subURL).subscribe({
      next: res => {
        if (res.status === 'OK') {
          if (res.data == null || res.data.length == 0)
            this.toastr.info('No records available', 'In Active List');
          else
          if(type=='pharmacy'){
            this.dtRender2=false
            this.changeDetection.detectChanges()
            this.dataList = res.data;
            this.dtRender2=true
            this.changeDetection.detectChanges()
          }
          else
          if(type=='lab'){
            this.dtRender3=false;
            this.changeDetection.detectChanges()
            this.dataList = res.data;
            this.dtRender3=true
            this.changeDetection.detectChanges()
          }
          else
          if(type=='hospital'){
            this.dtRender4=false;
            this.changeDetection.detectChanges()
            this.dataList = res.data;
            this.dtRender4=true
            this.changeDetection.detectChanges()
          }
           
        }
      },
      error: err => { this.toastr.error(err) },
      complete: () => { this.destroyExistingTables(type); }
    });
  }

  tableDataFromAPI2(subURL: string) {
    this.dataList = [], this.masterService.getAll(subURL).subscribe(res => {
      if (res.status === 'OK') {
        if (res.data == null || res.data.length == 0)
          this.toastr.info('No records available', 'In Active List');
        else
        // this.dtRender5=false;
        // this.changeDetection.detectChanges()
        this.dataList = res.data;
        // this.dtRender5=true
        // this.changeDetection.detectChanges()
          
      }
    });
  }

  tableDataFromAPI3() {
    this.dataList=[]
    this.regService.getByAllDoctorsNew().subscribe({
      next: res => {
        if (res.message === 'All doctor records') {
          if (res.data == null || res.data.length == 0)
            this.toastr.info('No records available', 'In Active List');
          else{
            this.dtRender=false;
            this.changeDetection.detectChanges()
            this.dataList = res.data;
            this.dtRender=true
            this.changeDetection.detectChanges()
             // .filter(f => f.is_active == true);
          }
           
        }
      },
      error: err => { this.toastr.error(err) },
      complete: () => { this.destroyExistingTables('doctor'); }
    });
  }

  loadTable(type: string) {
    //this.dtRender =false;
    let data = {
      'doctor': () => { this.tableDataFromAPI3() },  // 'doctor': () => { this.tableDataFromAPI('doctor/getdoctorrecords',type) }
      'pharmacy': () => { this.tableDataFromAPI('pharmacy/allpharmacyrecords', type) }, // getallrecords
      'lab': () => { this.tableDataFromAPI('lab/alllabregistrations', type)},   // getalllabregistrations
      'company': () => { this.tableDataFromAPI2('mastercompany/getallcompanies'); 
      this.destroyExistingTables(type); }, 
      'hospital': () => { this.tableDataFromAPI('hospital/gethospitalrecords',type)},  // getallhospitalrecords
    }
    if (type == '')
      this.dataList = [], 
      this.destroyExistingTables(type);
    else{
      
      //this.changeDetection.detectChanges()
      data[type]();
      //this.dtRender=true
      //this.changeDetection.detectChanges()
    }
     
      
  }

  statusChanged(id: number, status: boolean, email_id: string, mobile_no: string, first_name: string, last_name: string) {
    const newItem = this.checkedList.find(item => item.id == id);
    if (newItem)
    {
      newItem.account_activated = status;
      newItem.is_active = status;
    }
      
    else
      this.checkedList.push({ id: id, account_activated: status,is_active: status, email_id: email_id, mobile_no: mobile_no, name: `${first_name} ${last_name}` });
  }

  onChkBoxChng(id: number, isChkd: boolean, usrType: string, type: string ) {
    const chkItem = this.chkdLst.find(item => item.id == id), rowItem = this.dataList.find(item => item.id == id);
    if (chkItem) {
      if (type == 'a')
        chkItem.account_activated = isChkd;
      else
        chkItem.is_active = isChkd;
    }
    else
      this.chkdLst.push({ id: id, account_activated: type == 'a' ? isChkd : rowItem.account_activated,
        is_active: type == 'd' ? isChkd : rowItem.is_active, user_type: usrType });
    this.chkdLst.forEach(e => {
      if (e.id == rowItem.id) {
        if (e.account_activated == rowItem.account_activated && e.is_active == rowItem.is_active)
          this.chkdLst = this.chkdLst.filter(f => f.id != rowItem.id);
      }
    });
    // if (type == 'd')
    //   rowItem.is_active = isChkd;
  }

  submit() { 
    if (this.selectedTest == 'doctor')
      this.onSubmitNew();
    else {
      if (this.checkedList.length > 0) {
        let [emailList, url] = [[], ''];
        let usersListType = [
          { id: 4, name: 'pharmacy' },
          { id: 5, name: 'lab User' },
          { id: 7, name: 'doctor' },
          { id: 0, name: 'hospital' },
        ];
        let saveTypes = {
          'doctor': () => url = 'doctor/updatedoctorstatus',
          'pharmacy': () => url = 'pharmacy/updatepharmacystatus',
          'lab': () => url = 'lab/updatelabstatus',
          'company': () => url = 'mastercompany/updatecompanystatus',
          'hospital': () => url = 'hospital/updatehospitalstatus',
        }
        saveTypes[this.selectedTest]();

        let user = usersListType.find(item => item.name == this.selectedTest);
        if (url) {
          // this.toastr.success(`${this.selectedTest.toUpperCase()} Records Submitted Successfully`);
          let arrList = [];
          this.checkedList.forEach(e => {
            arrList.push({ id: e.id, account_activated: e.account_activated ,is_active:e.is_active});
            if (e.account_activated && user)
              emailList.push({ email: e.email_id, mobile_no: e.mobile_no, name: e.name, user_id: user.id, user_type: user.name });
          })
          if ('company' == this.selectedTest)
            this.masterService.save(url, arrList).subscribe(res => {
              if (res.status == 'OK') // console.log(res)
              {
                this.toastr.success(`${this.selectedTest.toUpperCase()} records Submitted Successfully`);
               
              }
              
              else
                this.toastr.warning(res.message)
            });
          else
            this.regService.save(url, arrList).subscribe(res => {
              if (res.status == 'OK') // console.log(res)
              {
                this.toastr.success(`${this.selectedTest.toUpperCase()} records Submitted Successfully`);
                this.checkedList=[];
                setTimeout(() => {
                  this.getHeadings(this.selectedTest);
                  
                }, 2000)
              }
              
              else
                this.toastr.warning(res.message)
            });
          //const notificationData = { email: sendData.email, mobile_no: +sendData.mobile, name: `${sendData.first_name} ${sendData.last_name}` };
          emailList.forEach(e => this.send_Notification(e));
          
        }
      }
      else
        this.toastr.error('Please select atleast on record');
    }
    
  }

  onSubmitNew() {
    if (this.chkdLst.length > 0) {
      this.regService.postAPI2(`updatedelteuserstatus`, this.chkdLst).subscribe(res => {
        if (res) {
          if (res.status == 'OK') {
            this.toastr.success(res.message), this.chkdLst.forEach(e => {
              if (e.account_activated) {
                let rowItem = this.dataList.find(item => item.id == e.id), payload: any;
                if (rowItem) {
                  payload = { email: rowItem.email, mobile_no: +rowItem.mobile, name: `${rowItem.first_name} ${rowItem.last_name}`,
                    user_id: +rowItem.id, user_type: this.selectedTest };
                  this.send_Notification(payload);
                }
              }
            });
          }
          else
            this.toastr.warning(res.message);
        }
      })
    }
    else
      this.toastr.info('Please change at least one record..!');
  }

  send_Notification(payload: { email: string, mobile_no: number, name: string, user_id: number, user_type: string }) {
    const postData = {
      "email": payload.email,
      "mobile_no": payload.mobile_no,
      "user_id": payload.user_id,
      "user_type": payload.user_type,
      "name": payload.name.toUpperCase()
    }
    this.notificationService.sendNotification(postData).subscribe();
  }

  openModal(item: any = null, componentType: string = 'doctor', title: string) {
    let comp: any;
    let list = {
      'doctor': () => comp = this.isukhub ? DoctorRegistrationComponent : CommonDoctorRegistrationComponent,
      'lab': () => comp = this.isukhub ? LaboratoryRegistrationComponent :CommonLaboratoryRegistrationComponent,
      'pharmacy': () => comp = this.isukhub ?PharmacyRegistrationComponent :CommonPharmacyRegistrationComponent,
      'company': () => comp = CompanyComponent,
      'hospital': () => comp = this.isukhub ? HospitalDetailComponent :CommonHospitalRegComponent,
    }
   
    list[componentType]();
    const modalRef = this.modalService.open(comp, { scrollable: true, size: 'xl' });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.isSubmit_Disabled = true;
    if (componentType == 'lab')
      modalRef.componentInstance.labData = item;
    else if (componentType == 'doctor')
      modalRef.componentInstance.doctor = item;
    else if (componentType == 'pharmacy')
      modalRef.componentInstance.pharmaData = item;
    else if (componentType == 'hospital')
      modalRef.componentInstance.hospitalData = item;
    else if (componentType == 'company')
      modalRef.componentInstance.DataEdit = item;
    modalRef.result.then(result => {
    }, (reason) => { });
  }

  ViewModal(id: any, type: string = 'doctor', title: string = 'Doctor Registration Details') {
    let comp: any, list = {
      'doctor': () => comp = this.isukhub ? DoctorRegistrationComponent : CommonDoctorRegistrationComponent,
      'lab': () => comp = this.isukhub ?LaboratoryRegistrationComponent : CommonLaboratoryRegistrationComponent,
      'pharmacy': () => comp = this.isukhub ? PharmacyRegistrationComponent : CommonPharmacyRegistrationComponent,
      'company': () => comp = CompanyComponent,
      'hospital': () => comp =this.isukhub ? HospitalDetailComponent : CommonHospitalRegComponent,
    }
    
    
    list[type]();

    if (id != null) {
      if (type == 'doctor')
        this.regService.getByDoctorId(id).subscribe(res => {
          const modalRef = this.modalService.open(comp, { scrollable: true, size: 'xl' });
          modalRef.componentInstance.title = title;
          modalRef.componentInstance.doctor = res.data;
          modalRef.componentInstance.isSubmit_Disabled = false;
          modalRef.result.then(result => { }, (reason) => { });
    });
    }
  }

  downLoadExcel(){
    this.excelData = this.dataList;
    const fileName = 'Active_Inactive_List'
      var excelData = this.excelData;
      if(excelData.length>0){
        if(this.excelType==='doctor'){
          const fileName = 'Doctor_Active_Inactive_List'
          excelData.map(res =>{
            res.fullName = (res.first_name +' '+ res.last_name)
          })
          const excelHeaders = this.headerList;
          const internalHeaders = ['district_name','center_type','fullName', 'mci_number', 'email'];
          this.toastr.success('Please wait excel is downloading')
          this.excelDownloadService.downloadExcel(excelHeaders,internalHeaders,fileName,excelData)
        }
          
        if(this.excelType==='pharmacy'){
          const fileName = 'Pharmacy_Active_Inactive_List'
          excelData.map(res =>{
            res.fullName = (res.pharmacy_name)
          })
          const excelHeaders = this.headerList;
          const internalHeaders = ['district_name','mandal_name','center_name','fullName', 'registration_no'];
          this.toastr.success('Please wait excel is downloading')
          this.excelDownloadService.downloadExcel(excelHeaders,internalHeaders,fileName,excelData)
        }
        if(this.excelType==='lab'){
          const fileName = 'Lab_Active_Inactive_List'
          excelData.map(res =>{
            res.fullName = (res.lab_name)
          })
          const excelHeaders = this.headerList;
          const internalHeaders = ['district_name','mandal_name','center_name','fullName', 'registration_no'];
          this.toastr.success('Please wait excel is downloading')
          this.excelDownloadService.downloadExcel(excelHeaders,internalHeaders,fileName,excelData)
        }
        if(this.excelType==='hospital'){
          const fileName = 'Hospital_Inactive_List'
          excelData.map(res =>{
            res.fullName = (res.hospital_name)
          })
          const excelHeaders = this.headerList;
          const internalHeaders = ['district_name','mandal_name','center_name','fullName', 'registration_no'];
          this.toastr.success('Please wait excel is downloading')
          this.excelDownloadService.downloadExcel(excelHeaders,internalHeaders,fileName,excelData)
        }
        if(this.excelType==='company'){
          const fileName = 'Company_Inactive_List'
          excelData.map(res =>{
            res.fullName = (res.company_name)
          })
          const excelHeaders = this.headerList;
          const internalHeaders = ['fullName', 'registration_no'];
          this.toastr.success('Please wait excel is downloading')
          this.excelDownloadService.downloadExcel(excelHeaders,internalHeaders,fileName,excelData)
        }
        }
      
      else{
        this.toastr.error('No data to download')
      }
    
  }


}