<div class="row">
    <div class="container-fluid pl-0 pr-0">
        <div class="row">
            <div class="col-xl-12">
                <div class="card card-header-actions mx-auto">
                    <div class="card-header">
                        Form Templates
                    </div>
                    <form [formGroup]="templateForm" (ngSubmit)="onSubmit($event)">

                        <div class="card-body">

                            <div class="row ">
                                <div class="col-xl-6">

                                    <div class="form-group">
                                        <label for="inputPassword" class="col-form-label">Organization Name
                                        </label>
                                        <div class="">
                                            <select class="form-control form-control-sm"
                                                formControlName="orgnization_id" id="orgnization_id" #Organization
                                                (change)="organizationChanged(Organization.value)"
                                                [ngClass]="{ 'is-invalid': submitted && f.orgnization_id.errors }">
                                                <option value="">Select</option>
                                                <option *ngFor="let item of organizationList" [value]="item.id">
                                                    {{item.orgnization_name}}</option>
                                            </select>
                                            <div *ngIf="submitted && f.orgnization_id.errors" class="invalid-feedback">
                                                <div *ngIf="f.orgnization_id.errors.required">Organization Name is
                                                    required</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-6">

                                    <div class="form-group">
                                        <label for="inputPassword" class=" col-form-label">Form Type </label>
                                        <div class="">
                                            <select class="form-control form-control-sm" formControlName="form_type_id"
                                                id="form_type_id"
                                                [ngClass]="{ 'is-invalid': submitted && f.form_type_id.errors }">
                                                <option value="">Select</option>
                                                <option *ngFor="let item of formtypeList" [value]="item.id">
                                                    {{item.form_type}}</option>
                                            </select>
                                            <div *ngIf="submitted && f.form_type_id.errors" class="invalid-feedback">
                                                <div *ngIf="f.form_type_id.errors.required">Form Type is
                                                    required</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-6">
                                    <div class="form-group ">
                                        <label for="inputPassword" class=" col-form-label">Title</label>
                                        <div class="">
                                            <input type="text" autocomplete="off" class="form-control form-control-sm"
                                                placeholder="Title" formControlName="title" id="title"
                                                [ngClass]="{ 'is-invalid': submitted && f.title.errors }">

                                            <div *ngIf="submitted && f.title.errors" class="invalid-feedback">
                                                <div *ngIf="f.title.errors.required">Title is
                                                    required</div>
                                            </div>
                                        </div>

                                    </div>
                                  
                                </div>

                                <div class="col-xl-6">

                                    <div class="form-group">
                                        <label class="control-label">Status <span class="text-danger">*</span></label>
                                        <div class="">
                                            <div class="custom-control custom-radio custom-control-inline">
                                                <input class="custom-control-input" formControlName="is_active"
                                                    [value]="true" id="customRadio1" type="radio">
                                                <label class="custom-control-label" for="customRadio1">Active</label>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline">
                                                <input class="custom-control-input" formControlName="is_active"
                                                    [value]="false" id="customRadio2" type="radio">
                                                <label class="custom-control-label" for="customRadio2">Inactive</label>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div class="col-xl-12">
                                    <tinymce formControlName="body" [config]="config"></tinymce>
                                </div>
                            </div>
                           
                            <div class="row mt-2">

                                <div class="col-xl-12 text-center">

                                    <!-- <a class="btn btn-sm btn-success text-white pl-4 pr-4">Save</a> -->
                                    <input type="submit" class="btn btn-sm btn-primary" value="Submit" (click)="onSubmit" >

                                </div>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
        </div>

    </div>
</div>

<!-- <br>
<br>
{{html}} -->