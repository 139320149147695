<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <!-- <form [formGroup]="changepasswordForm"> -->
    <div class="container-fluid">
        <div class="form-group">
            <label for="staticEmail" class="col-form-label">Old Password</label>
            <div class="">
                <input type="password" autocomplete='off' id="oldPassword" [(ngModel)]="oldPassword"
                     class="form-control form-control-sm"
                    placeholder="Old Password">
            </div>
        </div>
        <div class="form-group">
            <label for="staticEmail" class=" col-form-label">New Password</label>
            <div class="">
                <input type="password" autocomplete="off" [(ngModel)]="newPassword"
                     class="form-control form-control-sm" id="newPassword" (keyup)="patternCheck($event.target.value)"
                    placeholder="New Password">
            </div>
            <div
            class="alert alert-danger"
            *ngIf="!patternResult">
            Provide Strong password (ex:Asdf_12345)
          </div>
        </div>
        <div class="form-group">
            <label for="staticEmail" class="col-form-label">Confirm Password</label>
            <div class="">
                <input type="password" autocomplete="off" [(ngModel)]="confirmed_password"
                     class="form-control form-control-sm" id="confirmed_password"
                    placeholder="Confirm Password">
            </div>
        </div>
        <!-- <app-passoword-strength-bar [passwordToCheck]="newPassword" >  </app-passoword-strength-bar>  -->
    </div>
   
    <!-- </form> -->
</div>
<div class="modal-footer">
    <input type="submit" class="btn btn-sm btn-primary" value="Submit" (click)="submit()">&nbsp;&nbsp;
    <button type="button" class="btn btn-sm btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
</div>