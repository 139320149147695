import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ValidationErrors } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { MasterService } from 'src/app/shared/services/master.service';
import { FileService } from 'src/app/shared/services/file.service';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { Avail, Common, toFormData } from 'src/app/common';
import { send } from 'process';
declare const updateStatus: any;
@Component({
  selector: 'app-ayush-doctor-registration',
  templateUrl: './ayush-doctor-registration.component.html',
  styleUrls: ['./ayush-doctor-registration.component.css'],
  providers: [DatePipe]
})
export class AyushDoctorRegistrationComponent implements OnInit {

  @Input() title: string;
  @Input() doctor: any;
  @Input() disableActive: boolean = false;
  @Input() isSubmit_Disabled: boolean = false;

  isMeridian: boolean = false;
  doctorForm: FormGroup;
  submitted = false;

  ayush_Reg: boolean = false;
  locationTypeList: Array<any> = [];
  stateList: Array<any> = [];
  districtList: Array<any> = [];
  mandalList: Array<any> = [];
  cityList: Array<any> = [];
  villageList: Array<any> = [];

  weekDaysList: Array<any> = [];
  dropdownSettings_weekDays = {};
  selectedItems = [];

  languagesList: Array<any> = [];
  dropdownSettings_language = {};
  selectedItems_language = [];

  super_SpecialisationList = [];
  is_AssistantDetails: boolean = false;

  uploadFileList: Array<{ id: number, reg_id: number, name: string, File: any, extensions: string, file_name: string, fileType: string }> = []
  labList: Array<any> = [];
  hwList: Array<any> = [];
  hospitalList: Array<any> = [];
  AyushhospitalList: Array<any> = [];
  pharmacyList: Array<any> = [];
  genderList: Array<any> = [];
  maritalList: Array<any> = [];
  titleList: any;
  visitStatusList: any;
  addressTypesList: any;
  qualificationsList: any;
  allSpecializationList: any;
  specializationList: any;
  superSpecialityDegreeList: any;
  bankNamesList: any;
  isPhrmcy = false;
  minDate: Date;
  isShowExlsvtyCtrls = false;
  showCharge = false;
  appUrl = '';
  lstDrDocs = { dgtlSign: '', highQlfcn: '', bioData: '', photo: '', videoRcrd: '', aadhaar: '', panCard: '' };
  RoleID: any;
  // defaultdoctor_ayush_mapping: [
  // 	{
  // 		"id": 0,
  // 		"is_active": true,
  // 		"doctor_id": 0,
  // 		"ayush_id": "3"
  // 	}
  // ];
  constructor(public activeModal: NgbActiveModal
    , private toastr: ToastrService
    , private datePipe: DatePipe
    , private formBuilder: FormBuilder
    , private fileService: FileService
    , private registrationService: RegistrationService
    , private authServ: AuthenticationService
    , private notificationService: NotificationService
    , private masterService: MasterService) {
    this.minDate = new Date();
  }

  ngOnInit(): void {

    // const hlthWrkrs = this.registrationService.getUnasgndHlthWrkrs();
    // console.log('healthWRKS...',hlthWrkrs);
    this.RoleID = sessionStorage.getItem('logUsrRoleId');
    if (this.RoleID == 1) {
      this.showCharge = true;
    }
    this.defaultLocations(), this.initFormControls(), this.setLocationTypeValidators(),
      // this.setAssistantDetailsValidators(), 
      this.locationChanged('', 'state'),
      Common.setFocus('doctor_type_id'), this.getAllLabPharmacyHospital(), this.appUrl = location.href.includes('localhost') ? Avail.lclUrl : location.origin + '/';
  }

  activeSpinner() {
    this.masterService.activateSpinner();
  }

  deactivateSpinner() {
    this.masterService.deactivateSpinner()
  }

  defaultLocations() {
    ['digitalCertificate', 'mciCertificate', 'bioData', 'photo', 'audioRecord', 'aAdhar', 'PanCard'].forEach((element, ind) =>
      this.uploadFileList.push({ id: 0, reg_id: ind + 1, name: element, File: null, extensions: '', file_name: '', fileType: '' }));
    ['png', 'jpg,jpeg,png,pdf', 'dot,dotx,doc,docx,pdf', 'jpg,jpeg,bmp', 'mp4', 'jpg,jpeg,png,pdf', 'jpg,jpeg,png,pdf'].forEach((item, i) =>
      this.uploadFileList[i].extensions = item);

    this.dropdownSettings_language = {
      singleSelection: false,
      idField: 'id',
      textField: 'language_name',
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.dropdownSettings_weekDays = {
      singleSelection: false,
      idField: 'id',
      textField: 'weekday',
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

  initFormControls() {
    this.doctorForm = this.formBuilder.group({
      id: ['0'],
      user_id: [''],
      // reg_no: [''],
      group_reference_code: [''],
      individual_reference_code: [''],
      exclusive_reference_code: [false],
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      birth_date: ['', Validators.required],
      department_id: ['',],
      gender_id: ['', Validators.required],
      mobile: ['', [Validators.required, Validators.minLength(10)]],
      email: ['', [Validators.required, Validators.email]],
      mci_number: ['',],

      mci_number_state_id: ['',],
      marital_status_id: ['',],

      qualification_id: ['', Validators.required],
      specialization_id: ['',],
      years_experience: ['', Validators.required],
      from_time: [new Date()],
      to_time: [new Date()],
      //doctor_available_slots: this.formBuilder.group({}),
      doctor_languages: [''],
      doctor_address: [''],
      doctor_hospitals: [''],
      address: [''],
      age: [''],
      exclusive_patients: [true],

      pin_code: ['', [Validators.required, Validators.pattern('^([1-9]){1}([0-9]){5}?$')]],
      weekday_id: [],
      doctor_type_id: ['4'],
      audio_consultation_price: ['0', Validators.required],
      video_consultation_price: ['0', Validators.required],
      hospital_name: [''],
      ayush_id: ['', Validators.required],
      state_id: ['', Validators.required],
      location_type_id: [''],
      district_id: ['', Validators.required],
      mandal_id: [''],
      village_id: [''],
      city_id: [''],
      home_address: [''],
      clinic_address: [''],
      hospital_address: [''],
      hospital_working_in_presently: [''],
      other_hospital: [''],
      super_qualification_id: [''],
      account_activated: [true],
      super_specialization_id: [''],
      max_consultation_time: ['15'],
      is_active: [{ value: false, disabled: this.disableActive }],
      send_notification_to_mobile: [false],
      bank_account_number: ['12345678910'],
      branch_name: [1],
      branch_location: ['cba'],
      ifsc_code: ['SBIN0001050', Validators.pattern('[A-Z|a-z]{4}[0][a-zA-Z0-9]{6}$')],
      pharmacy_id: [''],
      lab_id: [''],
      other_qualification: ['',],
      isHW: [false],
      hlthWorker: [''],

      assistant_detail: [false],
      assistant_first_name: [''],
      assistant_contact_number: ['',],
      assistant_email: [''],
      notification_to_assistant: [false],
      aadhar_number: ['',],
      prescription_address: [''],
      reg_expiry_date: [''],
      login_name: [''],
      password: [''],
      "longitude": "56.362",
      "latitude": "56.365",
      "assistant_last_name": "",
      employee_id: [''],
      digitalFile: ['', Validators.required],
      qualificationFile: ['', Validators.required],
      // photo: [''],
      aadharFile: ['',],
      panFile: ['',],
      // doctor_modules: [[]],
      // docTmplt: [''],
      // start_hours: [''],
      // start_minutes: [''],
      // end_hours: [''],
      // end_minutes: [''],
      // center_type: [''],
      // hospital_type_id: [null],
      // referral_hospital: [null]
    });
  }

  get f() { return this.doctorForm.controls; }

  ayushRegHide = (id: number) => {
    // this.ayush_Reg = ((this.AyushhospitalList.find(h => h.id == +id)?.hospital_name || '') == 'Ayurveda');
    //this.doctorForm.get('reg_no').setValue('');
  };

  onSubmit(event: any) {
    let lgnName: string = this.doctorForm.get('id').value == '0' ? this.doctorForm.value.email : this.doctor?.user_registration?.login_name ?? '';
    let isEdit: boolean = false;
    event.preventDefault();
    this.submitted = true;
    Common.getFormValidationErrors(this.doctorForm);
    if (this.doctorForm.invalid) {
      this.toastr.info('Please Enter All Mandatory Fields');
      return;
    }
    else if (this.doctorForm.value.home_address == '' && this.doctorForm.value.clinic_address == '' && this.doctorForm.value.hospital_address == '') {
      this.toastr.warning(' Pls provide one of the address');
      return;
    }
    else if (this.doctorForm.value.isHW == true && this.doctorForm.value.hlthWorker == '') {
      this.toastr.warning('Please select Health Worker');
      return;
    }
    else if (this.doctorForm.value.doctor_languages?.length == 0) {
      this.toastr.warning('Please select at least one language');
      return;
    }
    else {
      this.activeSpinner();
      let user = this.authServ.currentUserValue,
        crntTentId = +sessionStorage.getItem('currentTenant'),
        roleID = +sessionStorage.getItem('logUsrRoleId')
        this.doctorForm.patchValue({
          login_name: lgnName == '' ? this.doctorForm.get('email').value : lgnName,
          password: +this.doctorForm.value.id == 0 ? '123456' : this.doctor ? this.doctor.user_registration?.password ?? '123456' : ''
        });
      // this.doctorForm.patchValue({ login_name: this.doctorForm.value.email, password: +this.doctorForm.value.id == 0 ? '123456' : this.doctor ? this.doctor?.user_registration?.password : '' });
      const sendData = JSON.parse(JSON.stringify(this.doctorForm.getRawValue()));

      Object.assign(sendData, {
        // "alternate_mobile_no": this.disableActive ? null : user?.alternate_mobile_no,
        // "registration_source_id": this.disableActive ? 1 : user?.master_registration_source?.id, "user_types": [{ user_type_id: "7" }],
        // "branch_id": this.disableActive ? 1 : user?.orgnization_branch.id, "organization_id": this.disableActive ? 1 : user?.orgnization_registration.id

        "alternate_mobile_no": user.alternate_mobile_no, "user_types": [{ user_type_id: "7" }],
        "registration_source_id": user?.master_registration_source?.id, "branch_id": user.orgnization_branch?.id ?? 0,
        "organization_id": roleID == 1 ? crntTentId : user.orgnization_registration.id
      });

      const [arr, arr1, adr, hspadr] = [[], [], [], []];
      // const [arr, arr1, adr, dam] = [[], [], [], []];
      const [start_Time, end_Time] = [this.datePipe.transform(sendData.from_time, 'yyyy-MM-dd HH:mm:ss'), this.datePipe.transform(sendData.to_time, 'yyyy-MM-dd HH:mm:ss')];
      [sendData.birth_date, sendData.reg_expiry_date] = [this.datePipe.transform(sendData.birth_date, 'yyyy-MM-dd HH:mm:ss'), this.datePipe.transform(sendData.reg_expiry_date, 'yyyy-MM-dd HH:mm:ss')];
      // sendData.charge_amt = this.doctorForm.value.charge_amt == true ? 1 : 0;

      if (+sendData.id == 0)
        delete sendData.id, delete sendData.user_id;
      else
        isEdit = true;
      if (isEdit)
        this.updateDoctor(sendData, start_Time, end_Time);
      else {
        if (sendData.weekday_id?.length > 0) {
          // if (sendData.weekday_id.length > 0) {
          sendData.weekday_id.forEach(item => arr.push({ id: 0, doctor_id: 0, is_active: true, weekday_id: item.id, from_time: start_Time, to_time: end_Time }))
          // sendData.doctor_available_slots = arr;
          sendData.doctor_available_slots = [];
          // }
        }

        if (sendData.doctor_languages?.length > 0) {
          // if (sendData.doctor_languages.length > 0) {
          sendData.doctor_languages.forEach(item => arr1.push({ id: 0, doctor_id: 0, is_active: true, language_id: item.id }));
          sendData.doctor_languages = arr1;
          // }
        }

        // if (+this.doctorForm.get('doctor_type_id').value == 1)
        //   adr.push({ address_type_id: this.doctorForm.get('prescription_address').value, address: this.doctorForm.get('hospital_address').value, display_on_prescription: true });
        // else if (+this.doctorForm.get('doctor_type_id').value == 3)
        //   adr.push({ address_type_id: this.doctorForm.get('prescription_address').value, address: this.doctorForm.get('clinic_address').value, display_on_prescription: true });
        // else {
        //   ["home_address", "clinic_address", "hospital_address"].forEach((e, i) => {
        //     adr.push({ address_type_id: `${i + 1}`, address: this.doctorForm.get(e).value, display_on_prescription: this.doctorForm.get('prescription_address').value == i + 1 ? true : false });
        //   });
        // }
        ["home_address", "clinic_address", "hospital_address"].forEach((e, i) => {
          const add = this.doctorForm.get(e).value;
          if (add)
            adr.push({ id: 0, doctor_id: 0, is_active: true, address_type_id: `${i + 1}`, address: this.doctorForm.get(e).value, display_on_prescription: this.doctorForm.get('prescription_address').value == i + 1 ? true : false });
        });
        adr.forEach(f => {
          Object.assign(f, {
            pin_code: this.doctorForm.get('pin_code').value, state_id: this.doctorForm.get('state_id').value, district_id: this.doctorForm.get('district_id').value,
            city_id: this.doctorForm.get('city_id').value, mandal_id: this.doctorForm.get('mandal_id').value, village_id: this.doctorForm.get('village_id').value,
            location_type_id: this.doctorForm.get('location_type_id').value
          })
        });
        sendData.doctor_address = adr;

        hspadr.push({
          id: 0, is_active: true, doctor_registration: 0,
          hospital_name: this.doctorForm.get('hospital_name').value,
          working_in_presently: true,
          other_hospital: this.doctorForm.get('other_hospital').value
        });

        sendData.doctor_hospitals = hspadr;
      }
      sendData.age = (new Date()).getFullYear() - (new Date(sendData.birth_date)).getFullYear();
      sendData.address = sendData.home_address || sendData.clinic_address || sendData.hospital_address;
      var Ddoctor_ayush_mapping = [
        {
          id: 0,
          is_active: true,
          doctor_id: 0,
          ayush_id: this.doctorForm.get('ayush_id').value,
        }
      ];
      sendData.doctor_ayush_mapping = Ddoctor_ayush_mapping;
      this.removedAPIFields(sendData);
      // sendData.aadhar_number = '1234456';
      // console.log('send_data..',JSON.stringify(sendData));

      delete sendData.reg_no;
      this.registrationService.saveDoctor(sendData).subscribe(res => {
        this.deactivateSpinner();
        let apiResponse = res;
        if (apiResponse.status == "OK") {
          this.saveFile(apiResponse.data.id);
          // this.send_Notification(notificationData);
          // // // let responseData: any = apiResponse.response;
          // this.activeModal.close('changes saved');
          // this.initFormControls();
          this.toastr.success(`Record ${(+sendData.id > 0) ? 'Updated' : 'Saved'} Successfully`, 'Doctor Registration');
          if (sessionStorage.getItem('logUsrRoleId') != null) {
            if (+sessionStorage.getItem('logUsrRoleId') == 7) {
              sessionStorage.setItem('DoctorDetails', JSON.stringify(apiResponse.data));
              if (sessionStorage.getItem('userData') != null) {
                let frmId = (<HTMLInputElement>document.getElementById('fromId')).value,
                  payLoad = `doctor/getdoctororhealthworkerrecordbyuserid?roleId=${+sessionStorage.getItem('logUsrRoleId')}&userId=${JSON.parse(sessionStorage.getItem('userData')).id}`;
                this.registrationService.getDHDetails(payLoad).subscribe(res => { sessionStorage.setItem('dtlsDrHw', JSON.stringify(res.data)), updateStatus(frmId, "AVAILABLE", "register"); });
              }
            }
          }
          if (this.doctorForm.value.isHW) {
            let mapData = [];
            mapData.push({
              doctor_id: apiResponse.data.id, health_worker_id: this.doctorForm.value.hlthWorker,
              id: this.doctor?.doctor_health_worker[0]?.id ?? 0
            });
            this.registrationService.mapDoctorHW(mapData).subscribe(() => {
              // this.initFormControls();
            });
          }
          else {
            if (this.doctor?.doctor_health_worker[0]?.id)
              this.registrationService.unMapDoctorHW(this.doctor?.doctor_health_worker[0]?.id).subscribe(() => { });
          }

          if (sendData.id == 0) {
            const notificationData = { email: sendData.email, mobile_no: +sendData.mobile, name: `${sendData.first_name} ${sendData.last_name}` };
            this.send_Notification(notificationData);
          }
          this.activeModal.close('changes saved');
        }
      }, error => { this.deactivateSpinner(); this.toastr.error(error.message) });
    }
  }

  removedAPIFields(data: any) {
    delete data.pin_code;
    delete data.weekday_id;
    delete data.hospital_name;
    delete data.state_id;
    delete data.location_type_id;
    delete data.district_id;
    delete data.mandal_id;
    delete data.village_id;
    delete data.city_id;
    delete data.home_address;
    delete data.clinic_address;
    delete data.hospital_address;
    delete data.from_time;
    delete data.to_time;
    delete data.address;
    delete data.prescription_address;
    delete data.hospital_working_in_presently;
    delete data.other_hospital;
    // delete data.status;
    delete data.digitalFile;
    delete data.qualificationFile;
    delete data.aadharFile;
    delete data.panFile;

    delete data.isHW;
    delete data.hlthWorker;
    delete data.group_reference_code;
    delete data.individual_reference_code;
  }

  onDoctorChange(doctorTypeId: string) {
    this.doctorForm.get('prescription_address').setValue((+doctorTypeId > 0) ? (+doctorTypeId == 1) ? '3' : (+doctorTypeId == 3) ? '2' : '' : '');
    this.doctorForm.patchValue({ hospital_name: '', hospital_working_in_presently: '', hospital_address: '', clinic_address: '', home_address: '' });
    if (+doctorTypeId == 2) {
      // this.doctorForm.get('bank_account_number').setValidators([Validators.required]);
      // this.doctorForm.get('branch_name').setValidators([Validators.required]);
      // this.doctorForm.get('branch_location').setValidators([Validators.required]);
      // this.doctorForm.get('ifsc_code').setValidators([Validators.required, Validators.pattern('[A-Z|a-z]{4}[0][a-zA-Z0-9]{6}$')]);
      this.doctorForm.get('hospital_working_in_presently').setValidators([Validators.required]);
      this.doctorForm.get('hospital_name').setValidators(null);
    }
    else if (+doctorTypeId == 1) {
      this.doctorForm.get('hospital_name').setValidators([Validators.required]);
      this.doctorForm.get('hospital_working_in_presently').clearValidators();
    }
    else {
      if (+doctorTypeId == 3)
        this.doctorForm.patchValue({ clinic_address: '1-8-448, Lakshmi Building, 4th, 5th, 6th Floors, Begumpet, Hyderabad, Telangana 500003' })

      this.doctorForm.get('hospital_working_in_presently').setValidators([Validators.required]);
      // this.doctorForm.get('bank_account_number').clearValidators();
      // this.doctorForm.get('branch_name').clearValidators();
      // this.doctorForm.get('branch_location').clearValidators();
      // this.doctorForm.get('ifsc_code').clearValidators();
      this.doctorForm.get('hospital_name').clearValidators();
      this.doctorForm.get('hospital_name').setValidators(null);
    }

    this.doctorForm.get('hospital_working_in_presently').updateValueAndValidity();
    // this.doctorForm.get('bank_account_number').updateValueAndValidity();
    // this.doctorForm.get('branch_name').updateValueAndValidity();
    // this.doctorForm.get('branch_location').updateValueAndValidity();
    // this.doctorForm.get('ifsc_code').updateValueAndValidity();
    this.doctorForm.get('hospital_name').updateValueAndValidity();
  }

  onHospitalName(val: any) {
    // if (val != '')
    //   this.doctorForm.patchValue({ hospital_address: this.hospitalList.filter(f => f.id == val)[0].address });
    // else
    //   this.doctorForm.patchValue({ hospital_address: '' });

    if (val != '' && val != undefined) {
      this.activeSpinner()
      let item = this.hospitalList.find(f => f.id == val);
      Promise.all([this.locationChanged(item.state_id, 'district'),
      item.locationtype_id == 1 ? (this.locationChanged(item.district_id, 'mandal'),
        this.locationChanged(item.mandal_id, 'village')) : this.locationChanged(item.district_id, 'city')])
        .then(() => {
          this.deactivateSpinner()
          this.doctorForm.patchValue({
            hospital_address: this.hospitalList.filter(f => f.id == val)[0].address, state_id: item.state_id,
            location_type_id: item.locationtype_id, district_id: item.district_id, mandal_id: item.mandal_id, village_id: item.village_id,
            city: item.city_id
          });
        }).catch(e => { this.deactivateSpinner() });
    }
    else {
      this.doctorForm.patchValue({
        state_id: '', location_type_id: '', district_id: '', mandal_id: '', village_id: '', city: '', hospital_address: ''
      });
      this.districtList = this.mandalList = this.villageList = this.cityList = [];
    }
  }

  onHospitalWorking(type: string, val: any) {
    if (val != '' && this.doctorForm.value.doctor_type_id == '3')
      this.doctorForm.patchValue({ clinic_address: '#1-8-448, Lakshmi Building, 4th, 5th & 6th Floors, Begumpet, Hyderabad, Telangana 500003' });
    else
      this.doctorForm.patchValue({ clinic_address: '' });

    if (type == 'other') {
      if (val != '')
        this.doctorForm.get('hospital_working_in_presently').clearValidators();
      else
        this.doctorForm.get('hospital_working_in_presently').setValidators(Validators.required);

      this.doctorForm.get('hospital_working_in_presently').updateValueAndValidity();

    }
  }

  // setAssistantDetailsValidators() {
  //   const [nameControl, contact_noControl, emailControl] = [this.doctorForm.get('assistant_first_name'), this.doctorForm.get('assistant_contact_number'), this.doctorForm.get('assistant_email')];
  //   this.doctorForm.get('assistant_detail').valueChanges
  //     .subscribe(isAssistant => {
  //       if (isAssistant) {
  //         nameControl.setValidators([Validators.required]);
  //         contact_noControl.setValidators([Validators.required]);
  //         emailControl.setValidators([Validators.required]);
  //       }
  //       else {
  //         nameControl.setValidators(null);
  //         contact_noControl.setValidators(null);
  //         emailControl.setValidators(null);

  //         nameControl.setValue('');
  //         contact_noControl.setValue('');
  //         emailControl.setValue('');
  //       }

  //       nameControl.updateValueAndValidity();
  //       contact_noControl.updateValueAndValidity();
  //       emailControl.updateValueAndValidity();
  //     });
  // }

  setLocationTypeValidators() {
    const [city_idControl, mandal_idControl, village_idControl] = [this.doctorForm.get('city_id'), this.doctorForm.get('mandal_id'), this.doctorForm.get('village_id')];
    this.doctorForm.get('location_type_id').valueChanges
      .subscribe(locationType => {
        if (+locationType == 2) {
          city_idControl.setValidators(null);
          mandal_idControl.setValue('');
          village_idControl.setValue('');
          mandal_idControl.setValidators(null);
          village_idControl.setValidators(null);
        }
        else if (locationType == 1) {
          city_idControl.setValidators(null);
          city_idControl.setValue('');
          mandal_idControl.setValidators([Validators.required]);
          village_idControl.setValidators([Validators.required]);
        }
        else {
          mandal_idControl.setValue('');
          village_idControl.setValue('');
          city_idControl.setValue('');
          city_idControl.setValidators(null);
          mandal_idControl.setValidators(null);
          village_idControl.setValidators(null);
        }

        city_idControl.updateValueAndValidity();
        mandal_idControl.updateValueAndValidity();
        village_idControl.updateValueAndValidity();
      });
  }

  locationChanged(id: string, locationType: string) {
    let query: string = '';
    let locations = {
      'location': () => {
        // this.districtList = [];
        this.mandalList = [];
        this.villageList = [];
        this.cityList = [];

        // this.labForm.get('state_id').setValue('');
        this.doctorForm.get('district_id').setValue('');
      },
      'state': () => {
        this.districtList = [];
        this.mandalList = [];
        this.villageList = [];
        this.cityList = [];
        // if (+id > 0)
        query = `getOrgStates/${sessionStorage.getItem('currentTenant')}`;
      },
      'district': () => {
        this.districtList = [];
        this.mandalList = [];
        this.villageList = [];
        this.cityList = [];
        if (+id > 0)
          query = `getstatebyid?stateId=${id}`;
      },
      'mandal': () => {
        this.mandalList = [];
        this.villageList = [];
        this.cityList = [];
        if (+id > 0)
          query = `getdistrictbyid?districtId=${id}`;
      },
      'village': () => {
        this.villageList = [];
        if (+id > 0)
          query = `getmandalbyid?mandalId=${id}`;
      },
      'city': () => {
        this.cityList = [];
        if (+id > 0)
          query = `getdistrictbyid?districtId=${id}`;
      }
    };

    locations[locationType]();
    if (query.length > 0)
      this.getMasters(query, locationType);
  }

  getMasters(queryString: string, type: string) {
    this.masterService.getLocations(queryString).subscribe(data => {
      let result = data;
      if (result.status == "OK") {
        let arr: any = result.data;
        let assign_locations = {
          'state': () => this.stateList = arr,//.map(row => this.pick(row, ['id', 'name', 'scheduleCasteType', 'castettype'])),
          'district': () => this.districtList = arr.districts,
          'mandal': () => this.mandalList = arr.mandals,
          'village': () => this.villageList = arr.villages,
          'city': () => this.cityList = arr.cities
        };
        assign_locations[type]();
      }
    }, error => this.toastr.error(error));
  }

  keyUp(elementId: string) {
    let el: any = (<HTMLTextAreaElement>(document.getElementById(elementId)));
    el.value = el.value.replace(/^(0*)/, "");
  }

  changeProperty(hashmap: any, existProp: string) {
    Object.keys(hashmap).forEach((key) => {
      hashmap['name'] = hashmap[key];
      delete hashmap[key];
    });
  }

  submitForm() {
    document.getElementById('btnsubmit').click();
  }

  superSecialityDegree(_id: number) {
    this.super_SpecialisationList = (+_id == 0) ? [] : this.superSpecialityDegreeList.find(({ id }) => +id == _id).specializations;
  }

  singleFileUpload(event: any, fileNumber: number, fileType: string) {
    fileNumber = fileNumber - 1;
    let fileExt: string = this.uploadFileList[fileNumber].extensions;
    // Check if any file is selected.
    if (event.target.files.length > 0) {
      const fileList = event.target, fsize = fileList.files.item(0).size, currentfile = Math.round((fsize / (1024 * 5)));
      // Checking type of file
      if (fileList.files[0]) {
        const fileExtension: string = fileList.files[0].name.split('.').pop().toLowerCase();
        // fileExt = fileExtension;
        if (!this.uploadFileList[fileNumber].extensions.split(',').includes(fileExtension)) {
          this.toastr.warning(`Please Select ${fileExt} files only`);
          return false;
        }
      }
      // The size of the file.
      if (currentfile >= (1024 * 5)) {
        this.toastr.error(fileList.files[0].name + ' too Big, please select a file less than 5MB', 'Doctor Registration');
        return;
      }
      else {
        this.uploadFileList[fileNumber].File = fileList.files.item(0);
        this.uploadFileList[fileNumber].file_name = fileList.files[0].name;
        this.uploadFileList[fileNumber].fileType = fileType;
        if (this.doctor) {
          let f = this.doctor?.doctor_documents.filter(f => f.file_type_id == +this.uploadFileList[fileNumber].fileType);
          this.uploadFileList[fileNumber].id = f.length > 0 ? f[0].id : 0;
        }
      }
    }
  }

  saveFile(id: string) {
    let sendAPIfiles = [];
    this.uploadFileList.forEach(item => {
      if (item.File)
        sendAPIfiles.push(this.fileService.uploadFile(Common.form_Data(item.id.toString(), item.File, id, 'DOCTOR', item.fileType)));
    });

    forkJoin([...sendAPIfiles]).subscribe(res => console.log, err => console.log);
  }

  getAllLabPharmacyHospital() {
    this.activeSpinner();
    const hospital = this.registrationService.getAll('hospital/getallhospitalrecords');
    //const hospital = this.registrationService.getAll('ayush/getall');
    const gender = this.masterService.getAll('getallgenders');
    const languages = this.masterService.getAll('getalllanguages');
    const qualifications = this.masterService.getAll('ayushqualification/getall');
    const hlthWrkrs = this.registrationService.getUnasgndHlthWrkrs();
    //const lab = this.registrationService.getAll('lab/getalllabregistrations');

    // const pharmacy = this.registrationService.getAll('pharmacy/getallrecords');
    //
    // const weekDays = this.masterService.getAll('getallweekdays');

    // const locationType = this.masterService.getAll('getalllocationtypes');
    // const marital = this.masterService.getAll('getallmaritalstatus');
    // const title = this.masterService.getAll('getalltitle');
    // const visitStatus = this.masterService.getAll('getallvisitstatus');
    // const addressTypes = this.masterService.getAll('getallAddresstypes');
    // const qualifications = this.masterService.getAll('ayushqualification/getall'); // getallqualifications
    // const specialization = this.masterService.getAll('ayushspecialization/getall'); // getallspecialization
    // const superSpecialityDegree = this.masterService.getAll('getallsuperspecialityqualifications');
    // const bankName = this.masterService.getAll('getallmasterbankdetails');
    //

    forkJoin([hospital, gender, languages, qualifications, hlthWrkrs
      // pharmacy, languages, weekDays, gender, locationType, marital, title, visitStatus, addressTypes, qualifications,
      // specialization, superSpecialityDegree, bankName, hlthWrkrs


    ]).subscribe(
      {
        next: res => {
          res.forEach((list, ind) => {
            if (list.status === 'OK') {
              if ((list.data == null || list.data.length == 0) && this.isPhrmcy == false && this.disableActive == false)
                this.isPhrmcy = true; // this.toastr.warning('No records available', 'Pharmacy List'), 
              else {
                const assignList = {
                  // '0': () => this.labList = list.data,
                  // '0': () => {
                  //   this.hospitalList = list.data;
                  //   let id: string = this.hospitalList.find(e => e.hospital_name == 'Ayushkaameeya')?.id || '';
                  //   (+id > 0) && this.doctorForm.get('hospital_name').setValue(id), this.ayush_Reg = +id > 0;

                  // },
                  '0': () => {
                    this.AyushhospitalList = list.data;
                    //  let id: string = this.hospitalList.find(e => e.hospital_name == 'Ayushkaameeya')?.id || '';
                    //  (+id > 0) && this.doctorForm.get('hospital_name').setValue(id), this.ayush_Reg = +id > 0;

                  },
                  '1': () => this.genderList = list.data,
                  '2': () => this.languagesList = list.data,
                  '3': () => this.qualificationsList = list.data,
                  '4': () => this.hwList = list.data,
                  // '2': () => this.pharmacyList = list.data,
                  // 
                  // '2': () => this.weekDaysList = list.data,

                  // '': () => this.locationTypeList = list.data,
                  // '7': () => this.maritalList = list.data,
                  // '8': () => this.titleList = list.data,
                  // '4': () => this.visitStatusList = list.data,
                  // '5': () => this.addressTypesList = list.data,
                  //
                  // '7': () => this.allSpecializationList = list.data,
                  // '8': () => this.superSpecialityDegreeList = list.data,
                  // '9': () => this.bankNamesList = list.data,
                  // 
                }
                assignList[ind]();
              }
            }
          });
        },
        error: err => { this.deactivateSpinner(); console.error('something wrong occurred: ', err) },
        complete: () => {
          this.deactivateSpinner()
          this.editDoctor();
        }
      });

  }

  // get health Worker 10-05-2023
  getAllHW(){
    this.registrationService.getAllHlthWrkrs().subscribe(res => {
      if (res.message === 'Success') {
        if (res.data == null || res.data.length == 0)
          this.toastr.error('No records available', 'Health Worker List');
        else {
          let data = res.data;
          data.forEach(e => {
            e.qualificationName = this.qualificationsList.filter(f => f.id == e.qualification_id)[0]?.health_worker_qualification;
          });
          this.hwList = data;
          
        }
      }
    });
  }
  editDoctor() {
    if (this.doctor) {
      this.fileValidations();
      this.doctorForm.get('is_active').disable();
      const editDoctor = Object.assign({}, this.doctor);
      var val = (this.doctor?.doctor_ayush_mapping[0].ayush_id);
      //  alert(typeof(val))
      this.doctorForm.controls['ayush_id'].setValue(val);

      // alert('va;'+val);
      // let doctor_ayush_mapping = ( (editDoctor.doctor_ayush_mapping.length > 0) ? editDoctor.doctor_ayush_mapping : )
      let address = (editDoctor.doctor_address.length > 0) ? editDoctor.doctor_address : null;
      let [state_id, location_type_id, district_id, mandal_id] = (address) ? [address[0].state_id, address[0].location_type_id,
      address[0].district_id, address[0].mandal_id] : [0, 0, 0, 0];
      //this.ayushRegHide(editDoctor.doctor_ayush_mapping[0]?.ayush_id || 0);

      if (this.doctor?.doctor_health_worker?.length > 0) {
        if (this.doctor?.doctor_health_worker[0].is_active)
          this.hwList.push(this.doctor?.doctor_health_worker[0].health_worker_registration);
      }
      // this.doctorForm.patchValue({
      //   ayush_id:this.doctor?.doctor_ayush_mapping[0].ayush_id
      // });
      Promise.all([this.locationChanged(state_id, 'district'),
      location_type_id == 1 ? (this.locationChanged(district_id, 'mandal'),
        this.locationChanged(mandal_id, 'village')) : this.locationChanged(district_id, 'city')])
        .then(() => {
          let [loc, hosp, ayush] = [editDoctor.doctor_address, editDoctor.doctor_hospitals, editDoctor.doctor_ayush_mapping];
          //console.log('ayush....',ayush);
          this.doctorForm.patchValue({
            // ayush_id: ayush[0]?.ayush_id,
            group_reference_code: this.doctor?.group_reference_code,
            individual_reference_code: this.doctor?.individual_reference_code,
            send_notification_to_mobile: this.doctor?.send_notification_to_mobile == null ? false : this.doctor?.send_notification_to_mobile,

            state_id: loc[0]?.state_id,
            district_id: loc[0]?.district_id,
            mandal_id: loc[0]?.mandal_id,
            village_id: loc[0]?.village_id,
            city_id: loc[0]?.city_id,
            pin_code: loc[0]?.pin_code,
            location_type_id: loc[0]?.location_type_id,

            home_address: loc.filter(f => f.address_type_id == 1)[0]?.address,
            // clinic_address: loc.filter(f => f.address_type_id == 2)[0]?.address,
            // hospital_address: loc.filter(f => f.address_type_id == 3)[0]?.address,
            prescription_address: loc.filter(f => f.display_on_prescription == true)[0]?.address_type_id,

            isHW: this.doctor?.doctor_health_worker?.length > 0 ? this.doctor?.doctor_health_worker[0].is_active : false,
            hlthWorker: this.doctor?.doctor_health_worker?.length > 0 ? (this.doctor?.doctor_health_worker[0].is_active == true ? this.doctor?.doctor_health_worker[0]?.health_worker_registration.id : '') : '',
          }, { emitEvent: true, onlySelf: false });

          this.doctorForm.patchValue(editDoctor);
          this.doctorForm.patchValue({
            // hospital_name: (hosp.length > 0) ? hosp[0].hospital_name : '',
            ayush_id: (ayush.length > 0) ? ayush[0].ayush_id : '',
            other_hospital: (hosp.length > 0) ? hosp[0].other_hospital : '',
          }, { emitEvent: true, onlySelf: false });

          this.setWeekDays(editDoctor.doctor_available_slots);
          this.setLanguages(editDoctor.doctor_languages);
          this.superSecialityDegree(editDoctor.super_qualification_id);
          this.doctorForm.patchValue({
            from_time: this.gettimeFormat(editDoctor.doctor_available_slots[0]?.from_time),
            to_time: this.gettimeFormat(editDoctor.doctor_available_slots[0]?.to_time),
            weekday_id: this.selectedItems,
            doctor_languages: this.selectedItems_language,
            birth_date: new Date(editDoctor.birth_date),
            reg_expiry_date: new Date(editDoctor.reg_expiry_date),
          });
          this.QualificationChanged(editDoctor.qualification_id);

          this.doctorForm.patchValue({
            lab_id: editDoctor.preferred_lab_id?.id ?? '',
            pharmacy_id: editDoctor.preferred_pharmacy_id?.id ?? ''
          });
          // console.log(this.doctor);
        });

      let [docUrl, dtDgtlSgn, highQlfcn, bioData, photo, videoRcrd, aadhaar, panCard] = [
        this.appUrl + Avail.vmedFileUrl + Avail.DrTxt + this.doctor?.id, this.doctor?.doctor_documents.filter(f => f.file_type_id == Avail.DrFTId),
        this.doctor?.doctor_documents.filter(f => f.file_type_id == Avail.HiQlfcnId), this.doctor?.doctor_documents.filter(f => f.file_type_id == Avail.BioDataId),
        this.doctor?.doctor_documents.filter(f => f.file_type_id == Avail.DrImgFTId), this.doctor?.doctor_documents.filter(f => f.file_type_id == Avail.VidRcrdId),
        this.doctor?.doctor_documents.filter(f => f.file_type_id == Avail.BnfcryFTId), this.doctor?.doctor_documents.filter(f => f.file_type_id == Avail.PanCrdId)];

      if (dtDgtlSgn.length > 0)
      //  this.lstDrDocs.dgtlSign = docUrl + `/${Avail.DrFTId}/` + dtDgtlSgn[dtDgtlSgn.length - 1].document_path;
      this.lstDrDocs.dgtlSign = docUrl + `/DigitalSignature/` + dtDgtlSgn[dtDgtlSgn.length - 1].document_path;
      if (highQlfcn.length > 0)
       // this.lstDrDocs.highQlfcn = docUrl + `/${Avail.HiQlfcnId}/` + highQlfcn[highQlfcn.length - 1].document_path;
       this.lstDrDocs.highQlfcn = docUrl + `/HighestQualifiedCertificate/` + highQlfcn[highQlfcn.length - 1].document_path;
      if (bioData.length > 0)
        // this.lstDrDocs.bioData = docUrl + `/${Avail.BioDataId}/` + bioData[bioData.length - 1].document_path;
        this.lstDrDocs.bioData = docUrl + `/BioData/` + bioData[bioData.length - 1].document_path;
      if (photo.length > 0)
        //this.lstDrDocs.photo = docUrl + `/${Avail.DrImgFTId}/` + photo[photo.length - 1].document_path;
        this.lstDrDocs.photo = docUrl + `/Photo/` + photo[photo.length - 1].document_path;
      if (videoRcrd.length > 0)
        //this.lstDrDocs.videoRcrd = docUrl + `/${Avail.VidRcrdId}/` + videoRcrd[videoRcrd.length - 1].document_path;
        this.lstDrDocs.videoRcrd = docUrl + `/VideoRecord/` + videoRcrd[videoRcrd.length - 1].document_path;
      if (aadhaar.length > 0)
      //this.lstDrDocs.aadhaar = docUrl + `/${Avail.BnfcryFTId}/` + aadhaar[aadhaar.length - 1].document_path;
        this.lstDrDocs.aadhaar = docUrl + `/AadharCard/` + aadhaar[aadhaar.length - 1].document_path;
      if (panCard.length > 0)
       //this.lstDrDocs.panCard = docUrl + `/${Avail.PanCrdId}/` + panCard[panCard.length - 1].document_path; 
        this.lstDrDocs.panCard = docUrl + `/PANCard/` + panCard[panCard.length - 1].document_path;

      this.isShowExlsvtyCtrls = true;
    }
    else
      this.isShowExlsvtyCtrls = false;
  }

  gettimeFormat(time: string) {
    const date_time = `${this.datePipe.transform(new Date(), 'yyyy-MM-dd')} ${time}`;
    return new Date(date_time);
  }

  setWeekDays(weeks: any[]) {
    weeks.forEach(day => {
      const dayname = this.weekDaysList.find(item => item.id == day.weekday_id && day.is_active);
      if (dayname) {
        const item = { id: dayname.id, weekday: dayname.weekday };
        this.selectedItems.push(item);
      }
    });
  }

  setLanguages(languages: any[]) {
    languages.forEach(day => {
      const dayname = this.languagesList.find(item => item.id == day.language_id && day.is_active);
      if (dayname) {
        const item = { id: dayname.id, language_name: dayname.language_name };
        this.selectedItems_language.push(item);
      }
    });
  }

  QualificationChanged(id: string) {
    this.specializationList = [];
    if (+id > 0) {
      this.masterService.getAll(`ayushqualification/getbyid?id=${id}`).subscribe({
        next: res => {
          this.specializationList = res.data.ayush_specializations;
          // if (+id == 1)
          //   this.doctorForm.get('specialization_id').clearValidators();
          // else
          //   this.doctorForm.get('specialization_id').setValidators([Validators.required]);
          // this.doctorForm.get('specialization_id').updateValueAndValidity();
        }
      });
    }
  }

  updateDoctor(sendData: any, start_Time: string, end_Time: string) {
    const old_record = JSON.parse(JSON.stringify(this.doctor));
    let doctor_id = sendData.id;
    let [doctor_available_slots, doctor_languages, doctor_address, doctor_hospitals] = [[], [], [], []];

    this.weekDaysList.forEach(w => {
      let old = (old_record.doctor_available_slots.length > 0) ? old_record.doctor_available_slots.find(e => e.weekday_id == w.id) : null;
      let new1 = sendData.weekday_id != null ? ((sendData.weekday_id.length > 0) ? sendData.weekday_id.find(e => e.id == w.id) : null) : null;

      if (old && new1)
        doctor_available_slots.push({ id: old.id, doctor_id: doctor_id, is_active: true, weekday_id: w.id, from_time: start_Time, to_time: end_Time })
      else if (old)
        doctor_available_slots.push({ id: old.id, doctor_id: doctor_id, is_active: false, weekday_id: w.id, from_time: start_Time, to_time: end_Time })
      else if (new1)
        doctor_available_slots.push({ id: 0, doctor_id: doctor_id, is_active: true, weekday_id: w.id, from_time: start_Time, to_time: end_Time })
    })
    sendData.doctor_available_slots = doctor_available_slots;

    this.languagesList.forEach(l => {
      let old = (old_record.doctor_languages.length > 0) ? old_record.doctor_languages.find(e => e.language_id == l.id) : null;
      let new1 = (sendData.doctor_languages.length > 0) ? sendData.doctor_languages.find(e => e.id == l.id) : null;

      if (old && new1)
        doctor_languages.push({ id: old.id, doctor_id: doctor_id, is_active: true, language_id: l.id });
      else if (old)
        doctor_languages.push({ id: old.id, doctor_id: doctor_id, is_active: false, language_id: l.id });
      else if (new1)
        doctor_languages.push({ id: 0, doctor_id: doctor_id, is_active: true, language_id: l.id });
    });
    sendData.doctor_languages = doctor_languages;

    ["home_address", "clinic_address", "hospital_address"].forEach((e, i) => {
      const add = this.doctorForm.get(e).value, ind = (i + 1);
      let old = old_record.doctor_address.find(e => e.address_type_id == ind);
      // const prescription_address = this.doctorForm.get('prescription_address').value == ind ? true : false;
      const prescription_address = true;

      if (old && add)
        doctor_address.push({ id: old.id, doctor_id: doctor_id, is_active: true, address_type_id: ind, address: add, display_on_prescription: prescription_address });
      else if (old)
        doctor_address.push({ id: old.id, doctor_id: doctor_id, is_active: false, address_type_id: ind, address: add, display_on_prescription: prescription_address });
      else if (add)
        doctor_address.push({ id: 0, doctor_id: doctor_id, is_active: true, address_type_id: ind, address: add, display_on_prescription: prescription_address });
    });

    doctor_address.forEach(f => {
      Object.assign(f, {
        pin_code: this.doctorForm.get('pin_code').value, state_id: this.doctorForm.get('state_id').value, district_id: this.doctorForm.get('district_id').value,
        city_id: this.doctorForm.get('city_id').value, mandal_id: this.doctorForm.get('mandal_id').value, village_id: this.doctorForm.get('village_id').value,
        location_type_id: this.doctorForm.get('location_type_id').value
      })
    });
    sendData.doctor_address = doctor_address;

    doctor_hospitals.push({
      id: (old_record.doctor_hospitals.length > 0) ? old_record.doctor_hospitals[0].id : 0,
      is_active: true, doctor_registration: doctor_id,
      hospital_name: this.doctorForm.get('hospital_name').value, working_in_presently: true,
      other_hospital: this.doctorForm.get('other_hospital').value
    });
    sendData.doctor_hospitals = doctor_hospitals;

  }

  fileValidations() {
    this.doctorForm.get('digitalFile').clearValidators();
    this.doctorForm.get('qualificationFile').clearValidators();
    // this.doctorForm.get('aadharFile').clearValidators();
    // this.doctorForm.get('panFile').clearValidators();

    this.doctorForm.get('digitalFile').updateValueAndValidity();
    this.doctorForm.get('qualificationFile').updateValueAndValidity();
    // this.doctorForm.get('aadharFile').updateValueAndValidity();
    // this.doctorForm.get('panFile').updateValueAndValidity();
  }

  send_Notification(payload: { email: string, mobile_no: number, name: string }) {
    const postData = {
      "email": payload.email,
      "mobile_no": payload.mobile_no,
      "user_id": 7,
      "user_type": "Doctor",
      "name": payload.name
    }
    this.notificationService.sendNotification(postData).subscribe();
  }


}
