import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

import { Common } from './../../common';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-verify-otp',
  templateUrl: './verify-otp.component.html',
  styleUrls: ['./verify-otp.component.css']
})

export class VerifyOTPComponent implements OnInit {
  
  @Input() title: string; @Input() email_id: string; OTP: string = '';
  tenantData: any;

  constructor(public activeModal: NgbActiveModal
    , private toastr: ToastrService
    , private router: Router
    , private notificationService: NotificationService) { }

  ngOnInit(): void {
    Common.setFocus('OTP');
    this.tenantData = JSON.parse(sessionStorage.getItem('tenantDtls'));
  }

  verifyOTP() {
    let postData = `{"${this.email_id}":"${this.OTP}"}`;
    var  tnt = this.tenantData ? `?TENANT_ID=${this.tenantData?.data?.id}` : '';
    this.notificationService.validateOTP(postData, tnt).subscribe({
      next: res => {
        if (res.Status === 'Success') {
          this.toastr.success(res.response, this.title);
          this.activeModal.close('changes saved');
        }
        else if (res.Status === 'failed')
          this.toastr.error(res.response, this.title);
      },
      error: err => this.toastr.error('something wrong occurred: ', err),
      complete: () => { }
    });
  }

  resendOTP() {
    let tenantData = JSON.parse(sessionStorage.getItem('tenantDtls')), tnt = tenantData ? `&TENANT_ID=${tenantData?.data?.id}` : '';
    this.notificationService.send_OTP_Notification(`email=${this.email_id}${tnt}`).subscribe();
  }

}