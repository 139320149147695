<div *ngIf="loginPage">
<div id="sessionExpired" [ngClass]="{'d-block':showSessionExpired,'d-none':!showSessionExpired}"
    class="alert alert-danger" role="alert" >
    <strong> Your session has expired. Please login again.</strong>
</div>
<div class="limiter">
    <div class="bg_img" [ngStyle]="{ 'background': 'url(' + lgnUrl + ')', 'background-repeat': 'no-repeat' }"
        class="container-login100">
        <!-- <div class="container-login100" style="background-image: url('../../../assets/login_bg_no_logo.jpg'); background-repeat: no-repeat;
            background-size: cover;"> -->
        <div style="position: absolute; top:0px; margin: 0px auto;" class="d-none d-md-none d-lg-block" >
            <!-- <img *ngIf="clientName != 'UTTARAKHAND TELEMEDICINE'" [src]="lgnLogo1" class="img-fluid" /> -->
            <!-- <img src="../../../assets/login_logo1.png" class="img-fluid"/> -->
        </div>
        <div class="wrapper">
            <div class="text-center" style="top:0px;  position:absolute;">
            </div>
            <div class="login-page">
                <div class="form m-auto pt-3 pr-5 pl-5">
                    <div class="m-auto pl-5 pr-5 border  border-info shadow-sm rounded-pill loginTitle">
                        <h5 style="font-weight: bold" class="text-info pl-3 pr-3 pt-2 pb-2">
                            User Login
                        </h5>
                    </div>
                    <div class="d-block d-md-block d-lg-none">
                        <div>
                            <img [src]="lgnLogo2" class="img-fluid" />
                            <!-- <img src="../../../assets/login_logo2.png" class="img-fluid"/> -->
                        </div>
                        <h5 style="font-weight: bold" class="text-info pl-3 pr-3 pt-2 pb-2">
                            {{lgnUsrBoxTxt}}
                            <!-- Telehealth Application -->
                        </h5>
                    </div>
                    <!-- <div class="m-auto pl-5 pr-5 border  border-info shadow-sm rounded-pill"
                        style="top: -31px;position: relative;  background: white;">
                        <h5 style="font-weight: bold" class="text-info pl-3 pr-3 pt-2 pb-2">
                            User Login
                        </h5>
                    </div> -->
                    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="login-form mt-2">
                        <div class="col-12 padingleft input-effect">
                            <input class="effect-16" autocomplete="off" formControlName="username" id="username"
                                maxlength="50" name="username" type="text" (change)="onChngUN()" />
                            <label class="fontsize col-12 padingleft text-left"><i class="fas fa-user"
                                    style="color:#007c78;"></i> Username</label>
                            <span class="focus-border"></span>
                        </div>
                        <div class="col-12 padingleft input-effect mt-4">
                            <input class="effect-16"  formControlName="password" id="password"
                                maxlength="15" name="password"  [type]="showPassword ? 'text' : 'password'" />
                                <div  style="position: absolute ;
                                right: 0px;
                                top: 0;">
                                    <i alt="show" class="far fa-eye eye-show"  style="color: #00cfd5 !important;"
                                    (click)="showPassword = !showPassword"
                                    [class.hide]="showPassword"></i>
                                    <i alt="hide" class="far fa-eye-slash eye-hide"  style="color: #00cfd5 !important;"
                                    (click)="showPassword = !showPassword"
                                    [class.hide]="!showPassword"></i>
                                  </div>
                                  <!-- <div 
                                  (mousedown)="showPasswordOnPress = !showPasswordOnPress" 
                                  (mouseup)="showPasswordOnPress = !showPasswordOnPress">
                                    <i alt="show" class="fas fa-eye eye-show" 
                                    
                                    [class.hide]="showPasswordOnPress"></i>
                                    <i alt="hide" class="fas fa-eye-slash eye-hide" 
                                    
                                    [class.hide]="!showPasswordOnPress"></i>
                                  </div> -->
                            <label class="fontsize col-12 padingleft text-left"><i class="fas fa-key"
                                    style="color:#007c78;"></i> Password</label>
                            <span class="focus-border"></span>
                        </div>
                        <div class="col-12 padingleft input-effect mt-4">
                            <div class="card">
                                <img [src]="captChaImg" class="card-img-top" alt="Fissure in Sandstone"/><span (click)="callCaptchaAPI()" onmouseover=""  style="color:#007c78;cursor: pointer;">Refresh</span>
                              </div>
                       </div>
                       <br>
                        <div class="col-12 padingleft input-effect">
                            <input class="effect-16" autocomplete="off" formControlName="captchaText" id="captchaText"
                               name="captchaText" type="text" (change)="onChngCapthaImg()" />
                            <label class="fontsize col-12 padingleft text-left">Captcha Image Text</label>
                            <span class="focus-border"></span>
                        </div>
                        <div class="row mt-4 mb-2">
                            <div class="col-12 col-lg-12 col-12">
                                <button type="submit" class="btn btn-lg btn-primary active  p-1 pt-2"
                                    style="background-color:#a0530b;"> <span
                                        style="text-transform: capitalize; font-size: 18px">Login</span></button>
                            </div>
                            <!-- <div class="col-12 col-lg-8 col-8 mt-3"><a class="float-left forgo" > Forgot Password? </a></div> -->
                        </div>
                    </form>
                    <div class="d-block d-md-block d-lg-none">
                        <h6 class="text-dark">For Support, Please contact {{clientName}}</h6>
                        <div class="text-center text-dark"><a (click)="onTollFree()">
                                <i class="fa fa-phone text-dark" aria-hidden="true"></i>&nbsp;&nbsp;{{tollFree}}</a>
                        </div>
                    </div>
                    <!-- <div class="row">
                        <div class="col-6 col-lg-6 small mb-1 text-left">
                            <a class="text-info pointer" style="font-size: 12px;"
                                (click)="forgotpaasword()"><i class="fa fa-key"></i> <strong> Forgot Password</strong></a>
                        </div>
                        <div class="col-6 col-lg-6 small mb-1">
                            <div class="form-check-inline">
                                <label class="form-check-label pointer">
                                    <input type="checkbox" class="form-check-input chk" [checked]="isRmbrChk"
                                        (change)="isRmbrChk=!isRmbrChk"><strong>Remember me</strong>
                                </label>
                            </div>
                        </div>
                    </div> -->
                    <div class="row">
                        <div class="col-12 col-lg-7 small mb-1 text-xl-left pl-0 pr-0">
                            <a class="text-info pointer" style="font-size: 12px;" (click)="forgotpaasword()"><i
                                    class="fa fa-key"></i> <strong> Forgot Password</strong></a>
                        </div>
                        <div class="col-12 col-lg-5 small text-xl-right pl-0 pr-0">
                            <div>
                                <label class="pointer chk2"><input type="checkbox" style="width: auto !important;"
                                        [checked]="isRmbrChk" (change)="isRmbrChk=!isRmbrChk" /> <strong> Remember
                                        me</strong>
                                </label>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="row">
                        <div class="col-12 col-lg-7 small mb-1 text-xl-left pl-0 pr-0"></div>
                            <div class="col-12 col-lg-5 small mb-1 text-xl-left pl-0 pr-0">
                            <a class="text-info pointer" style="font-size: 12px;" routerLink="/home/rakthadhan/rakthadhanReg"><strong>Register Here!</strong></a>
                        </div>
                    </div> -->
                    <div class="d-none d-md-none d-lg-block">
                        <p class="text-center text-white small" style="position: relative; top: 45px;">Powered by
                            {{pwrdBy}}<br>
                           <span *ngIf="isNIG">&copy;All rights reserved to Dhanush Health</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="d-none d-md-none d-lg-block">
    <div class="support ">
        <h6>For Support, Please contact {{clientName}}</h6>
        <div class="text-right"><a class="btn btn-info" (click)="onTollFree()">
                <i class="fa fa-phone" aria-hidden="true"></i>&nbsp;&nbsp;{{tollFree}}</a>
        </div>
    </div>
</div>

<!-- Confirm Message for Same user login -->
<button type="button" class="d-none" data-toggle="modal" data-target="#logInConfirmMessage"
    id="btnLogInConfirmMessage"></button>
<div class="modal fade" data-backdrop="static" data-keyboard="false" id="logInConfirmMessage" tabindex="-1"
    role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Login Confirmation</h5>
                <button type="button" id="btnLogInConfirmMessageClose" class="close" data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body alert alert-danger" role="alert">
                <!-- <div class="alert alert-danger" role="alert"> -->
                    Another user with the same username <strong>({{current_userName}})</strong> is already logged in to
                    application.<strong>Do you want to continue?</strong>
                <!-- </div> -->
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary btn-sm" data-dismiss="modal"
                    (click)="checkYes()" id="bypassLgn">Yes</button>
                <button type="button" class="btn btn-danger btn-sm" data-dismiss="modal">No</button>
            </div>
        </div>
    </div>
</div>

<!-- <div class="col-md-6 offset-md-3 mt-5">
    <div class="card">
        <h4 class="card-header">
            Angular 8 JWT Login Example<br />
            apiCalled: {{apiCalled}} <br />
            submit clicked: {{loginCalled}}
        </h4>
        <div class="card-body">
            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                <div class="form-group">
                    <label for="username">Username</label>
                    <input type="text" formControlName="username" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.username.errors }" autocomplete="off" />
                    <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                        <div *ngIf="f.username.errors.required">Username is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="password">Password</label>
                    <input type="password" formControlName="password" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                        <div *ngIf="f.password.errors.required">Password is required</div>
                    </div>
                </div>
                <button [disabled]="loading" class="btn btn-primary">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    Login
                </button>
                <button class="btn btn-primary" #loginButton>Login</button>
                <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
            </form>
        </div>
    </div>
</div> -->

</div>