import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Common } from 'src/app/common';
import { forkJoin } from 'rxjs';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { RegistrationService } from 'src/app/shared/services/registration.service'
import { environment } from 'src/environments/environment';
import { MasterService } from 'src/app/shared/services/master.service';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { FileService } from 'src/app/shared/services/file.service';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { toBase64String } from '@angular/compiler/src/output/source_map';
import { ImagePreviewComponent } from 'src/app/modules/modals/image-preview/image-preview.component';
import { SafePipe } from 'src/app/shared/pipes/safe.pipe';
declare let $: any;
declare const fnEncryption: any;
@Component({
  selector: 'app-patient-registration',
  templateUrl: './patient-registration.component.html',
  styleUrls: ['./patient-registration.component.css'],
  providers: [SafePipe]
})

export class PatientRegistrationComponent implements OnInit {
  @Input() isEditable: boolean = false;
  patientForm: FormGroup; ndhmForm: FormGroup; @Input() title: string = ''; submitted = false; sbmtDsbld: boolean = false;
  stateList: Array<any> = [];
  districtList: Array<any> = [];
  mandalList: Array<any> = [];
  cityList: Array<any> = [];
  villageList: Array<any> = [];
  genderList: Array<any> = []; dwnldCard: any
  locationTypeList: Array<any> = []; tentId = sessionStorage.getItem('currentTenant'); url: any; body: any; encRes: any
  stateid: any; isShwPic = false; isEdit = false; mobPtrn = ''; mobErrMsg = ''; maxLngth = ''; logUsr: any;
  uploadFileList: Array<{
    id: number, reg_id: number, name: string, File: any, extensions: string, file_name: string, fileType: string,
    document_path: string
  }> = []; patient: any; isNIG = environment.isNIG; isSAGE = environment.isSAGE; otpRes: any; encryptRes: any; verifyRes: any; verifyMobRes: any
  genOTPRes: any; isShowModal: boolean = false;
  cnfrmAadhar: any; showFields = false; enableBtn = true; enableAdBtn = false; enabletBtn2 = false; ndhmRes: any; ispwd = false
  @Input() benDetails: any; isHidChecked = false; searchRes: any; authRes: any; enableOtp = false; cnfrmAadharRes: any; cnfrmMobRes: any; enablepwd = false; istermsChecked = false;
  verifyMethod = [{ "id": 2, "method": "AADHAAR_OTP" },
  { "id": 4, "method": "MOBILE_OTP" },
    // { "id": 5, "method": "DEMOGRAPHICS" }
  ];
  verifyMethodnew = [{ "id": 2, "method": "AADHAAR_OTP" },{ "id": 4, "method": "MOBILE_OTP" },
   { "id": 5, "method": "DEMOGRAPHICS" }];
  entrOTP = true; genOTP = true; verOTP = true; authBtn = true; existRes: any; profileRes: any; forgotHid = false; verifyMethods: any; myBase64: SafeResourceUrl; dwnldBtn = true; dwnldUrl: any; rfrshToken: any
  baseimg: any; showHcBtn = false; authBtn1 = false; forgotAadharRes: any; verifyMthd = 'Aadhar Number'; showfrgtBtn = false; isDemoGraphic = false; demoGraphRes: any; isDemoGraphicM: any; isDgAadhr: any; isABHAChecked = false
  isForgotMob = false; showmore = false; baseimgnew: any; fileNumbernew = 0; fileTypenew = "26"; frmCntrlnew = "proofFile"; @Input() screentitle: string = '';
  patternResult: boolean;
  isNDHMEnable: boolean = false;
  isEnableEHR: boolean = false;
  isResendOtp: boolean;
  finalName: string;
  display: any;
  resend_otp: boolean;
  displayTimer: any;
  resendMobileOtp: boolean;
  getOtpBtn: boolean;
  mobileLinked: boolean=true;
  frgtMobile: any;
  ForAdhrdisplay: any;
  linkProfileDetail: any;
  suggestionData: any=[];
  abhaAddress: boolean;
  submitbtn: boolean;
  resend_otps: boolean;
  checkBox1: boolean=false;
  termsAndConditions1: boolean=false;
  checkBox2: boolean=false;
  checkBox3: boolean=false;
  checkBox4: boolean=false;
  checkBox5: boolean=false;
  checkBox6: boolean=false;
  checkBox7: boolean=false;
  termsAndConditions2: boolean=false;
  termsAndConditions3: boolean=false;
  showPassword: boolean;
  userName: any;
  beneficiaryFullName: any;
  docdetails: any;
  facilityId: any;
  facilityName: any;


  constructor(public activeModal: NgbActiveModal
    , private modalService: NgbModal
    , private toastr: ToastrService
    , private masterService: MasterService
    , private authServ: AuthenticationService
    , private registrationService: RegistrationService
    , private formBuilder: FormBuilder
    , private fileService: FileService
    , private router: Router
    , private http: HttpClient
    , private sanitizer: DomSanitizer) {
    this.tentId = sessionStorage.getItem('currentTenant');
    if(sessionStorage.DoctorDetails){
      this.docdetails=JSON.parse(sessionStorage.getItem('DoctorDetails'))
      this.facilityId=this.docdetails?.facility_id,
      this.facilityName=this.docdetails?.facility_name

    }else if(sessionStorage.labDetails){
      let LabDetails:any=JSON.parse(sessionStorage.getItem('labDetails'))
      this.facilityId=LabDetails[0]?.facility_id,
      this.facilityName=LabDetails[0]?.facility_name
    }else if(sessionStorage.agentDetails){
      let agentDetails:any=JSON.parse(sessionStorage.getItem('agentDetails'))
      this.facilityId=agentDetails?.facility_id,
      this.facilityName=agentDetails?.facility_name
    }else{
      this.facilityId="",
      this.facilityName=""
    }

  }

  ngOnInit(): void {

    ['NDHMHealthCard'].forEach((e, i) => this.uploadFileList.push({
      id: 0, reg_id: i + 1, name: e, File: null, extensions: '', file_name: '',
      fileType: ['26'][i], document_path: ''
    })), ['jpg,jpeg,pdf'].forEach((e, i) => this.uploadFileList[i].extensions = e);
    this.logUsr = this.authServ.currentUserValue
    this.authServ.mobPtrnSubject.subscribe(s => this.mobPtrn = s), this.authServ.mobErrMsgSubject.subscribe(s => this.mobErrMsg = s),
      this.authServ.maxLngthSubject.subscribe(s => this.maxLngth = s);
    this.getAllMasters();
   
    this.locationChanged('', 'state');
    // this.locationChanged('', 'district')//Training
    this.initFormControls(), this.initFormControls2();
    this.setLocationTypeValidators();
    this.getFeaturePermission();
    // this.setAssistantDetailsValidators();


    // if (this.isNIG)
    // this.patientForm.get('proofFile').setValidators(null), this.patientForm.get('proofFile').updateValueAndValidity();
    // document.getElementById('Btn2').setAttribute("disabled", 'true');
    // document.getElementById('Btn3').setAttribute("disabled", 'true');
    // document.getElementById('Btn4').setAttribute("disabled", 'true');
    // document.getElementById('applist').setAttribute("disabled", 'true');
    // this.locationChanged('', 'state');
    if (this.benDetails === undefined || this.benDetails === null || this.benDetails === '') {
      this.locationChanged('', 'district');
      this.onChngDD('', '')
    }
    if (this.benDetails) {
      this.locationChanged(this.benDetails?.state_id, 'district');
      this.patientForm.get('password').clearValidators();
      this.ispwd = true
      setTimeout(() => {
        this.patientForm.patchValue({
          first_name: this.benDetails?.first_name,
          middle_name: this.benDetails?.middle_name,
          last_name: this.benDetails?.last_name,
          age: this.benDetails?.age,
          mobile: this.benDetails?.mobile,
          gender_id: this.benDetails?.gender_id,
          state_id: this.benDetails?.state_id,
          district_id: this.benDetails?.district_id,
          mandal_id: this.benDetails?.mandal_id,
          city_id: this.benDetails?.city_id,
          village_id: this.benDetails?.village_id,
          address: this.benDetails?.address,
          id: this.benDetails?.id
        })
      }, 2000);
    }
    this.locationChanged('', 'state');
    this.locationChanged('', 'district')
  }

  getFeaturePermission() {
    var featureList: any = JSON.parse(localStorage.getItem('featuresList'));
    for (let i = 0; i < featureList.length; i++) {
      if (featureList[i].feature_id.feature_name == "isNDHMEnable") {
        this.isNDHMEnable = featureList[i].patient;
      }

      if (featureList[i].feature_id.feature_name == "isEnableEHR") {
        this.isEnableEHR = featureList[i].patient;

      }
      //isEnableEHR

    }

  }
  setLocationTypeValidators() {
    {
      const [city_idControl, mandal_idControl, village_idControl] = [this.patientForm.get('city_id'), this.patientForm.get('mandal_id'), this.patientForm.get('village_id')];
      this.patientForm.get('location_type_id').valueChanges
        .subscribe(locationType => {
          if (this.isNIG)
            village_idControl.setValue(''), village_idControl.setValidators(null);
          if (+locationType == 2) {
            city_idControl.setValidators(null);
            mandal_idControl.setValue('');
            village_idControl.setValue('');
            mandal_idControl.setValidators(null);
            village_idControl.setValidators(null);
          }
          else if (locationType == 1) {
            city_idControl.setValidators(null);
            city_idControl.setValue('');
            mandal_idControl.setValidators([Validators.required]);
            if (!this.isNIG)
              village_idControl.setValidators([Validators.required]);
          }
          else {
            mandal_idControl.setValue('');
            village_idControl.setValue('');
            city_idControl.setValue('');
            city_idControl.setValidators(null);
            mandal_idControl.setValidators(null);
            village_idControl.setValidators(null);
          }

          city_idControl.updateValueAndValidity();
          mandal_idControl.updateValueAndValidity();
          village_idControl.updateValueAndValidity();
        });
    }
  }
  setAssistantDetailsValidators() {
    throw new Error('Method not implemented.');
  }
  initFormControls() {
    this.patientForm = this.formBuilder.group({
      first_name: ['', Validators.required],
      middle_name: [''],
      last_name: ['', Validators.required,],
      age: ['', Validators.required],
      mobile: ['', [Validators.required, Validators.pattern(this.mobPtrn)]],
      // mobile: ['', [Validators.required]],
      password: [null, Validators.required],
      email: ['', [Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      gender_id: ['', Validators.required,],
      // state_id: ['', Validators.required],
      // state_id: [36, Validators.required], //QA pointing
      // state_id: ['', Validators.required],//Training
      state_id: ['', Validators.required],//Training
      location_type_id: ['', Validators.required],
      district_id: ['', Validators.required],
      mandal_id: [''],
      village_id: [''],
      city_id: [''],
      address: [null],
      aadhar: [''],
      otp: [''],
      adhrNum: [''],
      verotp: [''],
      firstName: [null],
      lastName: [null],
      healthId: [null],
      middleName: [null],
      password1: ['', [Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/)]],
      email1: [null],
      account_activated: [true],
      branch_id: [null],
      // coporateId: [null],
      // corporate_id: null,
      employee_id: [null],
      employee_level: [null],
      employee_level_id: [null],
      login_name: [null],
      national_identifier_no: [null],
      national_identifier_type_id: [null],
      organization_id: [+sessionStorage.getItem('logUsrRoleId') == 1 ? +sessionStorage.getItem('currentTenant') : (this.logUsr?.orgnization_registration?.id ?? 1)],
      referrel_code: [null],
      registration_source_id: [null],
      source_detail: [null],
      source_type_id: [null],
      agent_id: [sessionStorage.agentId],
      id: [0],
      health_id_number: [null],
      health_id: [null],
      ndhm_response: [null],
      health_Id: [null],
      verify_with: ['AADHAAR_OTP'],
      otp_exist: [''],
      pwd: [''],
      name: [''],
      gender: [''],
      mob: [''],
      yob: [''],
      createdBy: [],
      db: [''],
      mb: [''],
      f_name: [''],
      m_name: [''],
      l_name: [''],
    facility_id:[''],
    facility_name:['']


    });
  }
  initFormControls2() {
    this.ndhmForm = this.formBuilder.group({
      aadhar: [''],
      otp: [''],
      adhrNum: [''],
      verotp: [''],
      firstName: [null],
      lastName: [null],
      healthId: [null],
      middleName: [null],
      password1: [null],
      email1: [null, [Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
    });
  }
  get f() { return this.patientForm.controls; }
  onFileUpload(event: any, fileNumber: number, fileType: string, frmCntrl: string = '') {
    fileNumber = fileNumber - 1;
    let fileExt: string = this.uploadFileList[fileNumber].extensions;
    if (event.target.files.length > 0) {
      const fileList = event.target, fsize = fileList.files.item(0).size, currentfile = Math.round((fsize / (1024 * 5)));
      if (fileList.files[0]) {
        const fileExtension: string = fileList.files[0].name.split('.').pop().toLowerCase();
        if (!this.uploadFileList[fileNumber].extensions.split(',').includes(fileExtension)) {
          this.toastr.warning(`Please Select ${fileExt} files only`);
          if (frmCntrl != '') {
            this.patientForm.get(frmCntrl).clearValidators(), this.patientForm.get(frmCntrl).setValue(''), // this.cancelFile(fileNumber+1),
              this.patientForm.get(frmCntrl).setValidators([Validators.required]), this.patientForm.get(frmCntrl).updateValueAndValidity();
          }
          return false;
        }
      }
      if (currentfile >= (1024 * 5)) {
        this.toastr.error(fileList.files[0].name + ' too Big, please select a file less than 5MB', 'Doctor Registration');
        return;
      }
      else {
        this.uploadFileList[fileNumber].File = fileList.files.item(0), this.uploadFileList[fileNumber].file_name = fileList.files[0].name,
          this.uploadFileList[fileNumber].fileType = fileType;
        if (this.patient) {
          let f = this.patient.doctor_documents?.filter(f => f.file_type_id == +this.uploadFileList[fileNumber].fileType) ?? '';
          this.uploadFileList[fileNumber].id = f.length > 0 ? f[0].id : 0;
        }
      }
    }
  }
  cancelFile(ind: number, frmCntrl: string = '', fileTypeId: number = 0) {
    let view_File = this.uploadFileList[ind - 1];
    view_File.File = null, view_File.file_name = '';
    this.patientForm.controls['proofFile'].patchValue('');
    if (this.patient) {
      if (fileTypeId > 0) {
        this.patient.doctor_documents.forEach((e, i) => {
          if (e) {
            if (e.file_type_id == fileTypeId)
              this.patient.doctor_documents[i] = null;
          }
        });
        this.uploadFileList.forEach(e => {
          if (e) {
            if (+e.fileType == fileTypeId)
              e.document_path = '', e.file_name = '', e.id = 0;
          }
        });
        this.isShwPic = false;
      }
    }
    if (frmCntrl != '') {
      this.patientForm.get(frmCntrl).clearValidators(), this.patientForm.get(frmCntrl).setValue(''),
        this.patientForm.get(frmCntrl).setValidators([Validators.required]), this.patientForm.get(frmCntrl).updateValueAndValidity();
    }
  }
  patternCheck(e) {
    //  var pattern = new RegExp('/^[ A-Za-z0-9_@./#&+-]*$/');
    var passw = /^[A-Za-z0-9_@./]\w{8,14}$/;
    if (e.match(passw)) {
      //alert('Correct, try another...')
      this.patternResult = true;
    }
    else {
      //alert('Wrong...!')
      this.patternResult = false;
    }
    // this.patternResult=pattern.test(this.newPassword);
  }

  viewFile(ind: number) {
    let view_File = this.uploadFileList[ind - 1], imagePath = '';
    const patient_id = +this.patientForm.get('id').value, fileExtension: string = view_File.file_name.split('.').pop().toLowerCase();
    if (fileExtension == 'pdf')
      (view_File.File) ? Common.showPDF_File(URL.createObjectURL(view_File.File)) :
        Common.showPDF_File(`${this.registrationService.viewFilePath}BENEFICIARY/${patient_id}/${view_File.name}/${view_File.document_path}`);
    else if (['jpg', 'jpeg', 'pdf'].includes(fileExtension)) {
      const modalRef = this.modalService.open(ImagePreviewComponent, { size: 'lg', scrollable: true });
      if (view_File.File) {
        const reader = new FileReader();
        reader.readAsDataURL(view_File.File);
        reader.onload = (event1: any) => {
          imagePath = event1.target.result;
          modalRef.componentInstance.image = imagePath;
        };
      }
      else {
        imagePath = `${this.registrationService.viewFilePath}BENEFICIARY/${patient_id}/${view_File.name}/${view_File.document_path}`;
        modalRef.componentInstance.image = imagePath;
      }
    }
  }
  showMessage(id: number = 0) {
    this.activeModal.close('changes saved'), this.toastr.success(`Record ${(id > 0) ? 'Updated' : 'Saved'} Successfully`, 'Patient Registration');
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/home/patientList']);
    });
  }
  saveFile(id: string, record_id: number) {
    let sendAPIfiles = [];
    this.uploadFileList.forEach(item => {
      if (item.File)
        sendAPIfiles.push(this.fileService.uploadFile(Common.form_Data(item.id.toString(), item.File, id, 'BENEFICIARY', '26')));
    });

    forkJoin([...sendAPIfiles]).subscribe(res => console.log, err => console.log, () => { this.showMessage(record_id); });
  }

  onSubmit(event: any) {

    this.submitted = true, Common.getFormValidationErrors(this.patientForm);
    if (this.villageList.length == 0) {
      // alert('village info 0');
      //this.patientForm.get('village_id').clearValidators();
      this.patientForm.controls['village_id'].clearValidators()
    }
    if (this.patientForm.invalid) {
      this.toastr.info('Please Enter All Mandatory Fields');
      return;
    }
    else {
      let payLoad;
      if (this.benDetails) {

          payLoad = this.benDetails;
          payLoad.first_name = this.patientForm.value.first_name,
          payLoad.middle_name = this.patientForm.value.middle_name,
          payLoad.last_name = this.patientForm.value.last_name,
          payLoad.age = this.patientForm.value.age,
          payLoad.mobile = this.patientForm.value.mobile,
          payLoad.gender_id = this.patientForm.value.gender_id,
          payLoad.state_id = this.patientForm.value.state_id,
          payLoad.district_id = this.patientForm.value.district_id,
          payLoad.address = this.patientForm.value.address,
          payLoad.id = this.benDetails?.id,
         


        // payLoad.createdBy = this.patientForm.value.created_on
        payLoad.createdBy = sessionStorage.getItem('loginId')

        if (this.profileRes) {
          payLoad.ndhm_response = this.profileRes;
          payLoad.health_id_number = this.profileRes.healthIdNumber;
          payLoad.health_id = this.profileRes.healthId
        }
        if (this.profileRes) {
          let firstName = this.profileRes.firstName.toLowerCase().toString();
          let fistCheck = this.patientForm.value.first_name;
          if (!firstName.includes(fistCheck.toLowerCase())) {
            //this.toastr.error('Your Entered First Name is not matched with the NDHM Data', 'Please Enter Valid First Name')
            this.toastr.error('ABHA details are not matched with patient details,please verify ABHA Health Card')
            return;
          }
          if (this.profileRes.mobile != this.patientForm.value.mobile) {
            //this.toastr.error('Your Entered Mobile Number is not matched with the NDHM Data', 'Please Enter Valid Mobile Number')
            this.toastr.error('ABHA details are not matched with patient details,please verify')
            return;
          }

        }
        if (this.profileRes) {
          // payLoad.ndhm_response = this.ndhmRes;
          payLoad.health_id_number = this.profileRes.healthIdNumber;
          payLoad.health_id = this.profileRes.healthId
        }
        //  if(payLoad.email == ''){
        //  payLoad.email = null
        //  }
        if (payLoad.address == '') {
          payLoad.address = null
        }
        if (this.benDetails) {
          payLoad['user_id'] = this.benDetails?.user_id
        }
        // let docData = JSON.parse(sessionStorage.getItem('DoctorDetails'));
        // if(sessionStorage.getItem('linkWithAbha')!==1+""){
        //   payLoad['center_id']= docData.center_id;
        // } else {
        //   payLoad['center_id']= this.benDetails.center_id;
        // }
        let docData = JSON.parse(sessionStorage.getItem('DoctorDetails'));
        let userData = JSON.parse(sessionStorage.getItem('userData'));
        if (docData && docData !== null && docData !== undefined) {
          if (sessionStorage.getItem('linkWithAbha') !== 1 + "") {
            payLoad['center_id'] = docData.center_id;
          }
          else {
            payLoad['center_id'] = this.benDetails?.center_id;
          }

        } else if (userData && userData !== null && userData !== undefined) {
          payLoad['center_id'] = null;
        }

      } else {
        payLoad = this.patientForm.getRawValue();
        // let encryptPswd= fnEncryption(this.patientForm.value.password, environment.securityKey);
        // payLoad.password=encryptPswd;
        if (+this.patientForm.get('id').value == 0)
          delete payLoad.id;
        delete payLoad.aadhar;
        delete payLoad.otp;
        delete payLoad.adhrNum;
        delete payLoad.verotp;
        delete payLoad.firstName;
        delete payLoad.lastName;
        delete payLoad.healthId;
        delete payLoad.middleName;
        delete payLoad.password1;
        delete payLoad.email1;
        delete payLoad.user_id;
        delete payLoad.db;
        delete payLoad.mb;
        delete payLoad.yob;
        delete payLoad.f_name;
        delete payLoad.m_name;
        delete payLoad.l_name;
        // if(this.verifyRes.healthIdNumber){
        //   payLoad.ndhm_response = this.verifyRes;
        //   payLoad.health_id_number = this.verifyRes.healthIdNumber;
        //   payLoad.health_id = this.verifyRes.healthId
        //  }
        // below line commented for registartion with abha 14-06-2023
        // if (this.ndhmRes) {
        //   payLoad.ndhm_response = this.profileRes;
        //   payLoad.health_id_number = this.cnfrmAadhar.healthIdNumber;
        //   payLoad.health_id = this.ndhmRes.healthId
        // }
        // new line added 14-06-2023
        if (this.profileRes) {
          payLoad.ndhm_response = this.profileRes;
          payLoad.health_id_number = this.profileRes.healthIdNumber;
          payLoad.health_id = this.profileRes.healthId
        }
        if (this.profileRes) {
          let firstName = this.profileRes.firstName.toLowerCase().toString();
          let fistCheck = this.patientForm.value.first_name;
          if (this.profileRes.firstName != this.patientForm.value.first_name) {
            if (!firstName.includes(fistCheck.toLowerCase())) {
              //this.toastr.error('Your Entered First Name is not matched with the NDHM Data', 'Please Enter Valid First Name')
              this.toastr.error('ABHA details are not matched with patient details,please verify ABHA Health Card')
              return;
            }
          }
          if (this.profileRes.mobile != payLoad.mobile) {
            //this.toastr.error('Your Entered Mobile Number is not matched with the NDHM Data', 'Please Enter Valid Mobile Number')
            this.toastr.error('ABHA details are not matched with patient details,please verify ABHA Health Card')
            return;
          }
        }
        if (this.profileRes) {
          // payLoad.ndhm_response = this.ndhmRes;
          payLoad.health_id_number = this.profileRes.healthIdNumber;
          payLoad.health_id = this.profileRes.healthId
        }
        //  if(payLoad.email == ''){
        //  payLoad.email = null
        //  }
        if (payLoad.address == '') {
          payLoad.address = null
        }
        if (this.benDetails) {
          payLoad['user_id'] = this.benDetails?.user_id
        }
        let docData = JSON.parse(sessionStorage.getItem('DoctorDetails'));
        let userData = JSON.parse(sessionStorage.getItem('userData'));
        if (docData && docData !== null && docData !== undefined) {
          if (sessionStorage.getItem('linkWithAbha') !== 1 + "") {
            payLoad['center_id'] = docData.center_id;
          }
          else {
            payLoad['center_id'] = this.benDetails?.center_id;
          }
        } else if (userData && userData !== null && userData !== undefined) {
          payLoad['center_id'] = null;
        }
        payLoad['createdBy'] = sessionStorage.getItem('loginId');

      }
      
      payLoad.in_house_abha_created= this.patientForm.value.healthId?true:false
      payLoad.facility_id=this.facilityId,
      payLoad.facility_name=this.facilityName
      this.registrationService.patientRegistration(payLoad).subscribe(res => {
        if (res.status == "OK" && res.data != null) {
          var id: number = +(payLoad.id ?? 0);
          sessionStorage.setItem('linkWithAbha', 0 + "");
          if (this.uploadFileList.some(f => f.File != null))
            this.saveFile(res.data.id, id);
          // else
          this.activeModal.close('changes saved')
          this.toastr.success(res.message)
          this.activeModal.close('Close click');

          // this.toastr.success(res.message), this.activeModal.close('changes saved');
          // this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          //   // this.router.navigate(['/home/consultation/patientlist']);
          // });
        }
        if (res.data == null) {
          this.toastr.error(res.message);
        }
      }, (err: HttpErrorResponse) => {
        this.toastr.error(err.message)
      });

    }

  }
  getAllMasters() {
    const locationType = this.masterService.getAll('getalllocationtypes');
    const gender = this.masterService.getAll('getallgenders');
    forkJoin([locationType, gender]).subscribe({
      next: res => {
        res.forEach((list, ind) => {
          if (list.status === 'OK') {
            if (list.data == null || list.data.length == 0)
              console.log('No records available');
            else {
              const assignList = {
                '0': () => this.locationTypeList = list.data,
                '1': () => this.genderList = list.data,

              }
              assignList[ind]();
            }
          }
        });
      },
      error: err => console.error('something wrong occurred: ', err),
      complete: () => { this.isShwPic = false }
    });
  }
  locationChanged(id: string, locationType: string) {
    let query: string = '';
    let locations = {
      'location': () => {
        // this.districtList = [];
        this.mandalList = [];
        this.villageList = [];
        this.cityList = [];

        // this.labForm.get('state_id').setValue('');
        this.patientForm.get('district_id').setValue('');
      },
      'state': () => {
        this.districtList = [];
        this.mandalList = [];
        this.villageList = [];
        this.cityList = [];
        // if (+id > 0)
        query = `getOrgStates/${sessionStorage.getItem('currentTenant')}`;
      },
      'district': () => {
        this.districtList = [];
        this.mandalList = [];
        this.villageList = [];
        this.cityList = [];
        if (+id > 0)
          //  query = `getstatebyid?stateId=36`;//QA Pointing
          query = `getstatebyid?stateId=${id}`;//Training Pointing
        // query = `getOrgStates/${sessionStorage.getItem('currentTenant')}`;
      }
      ,
      'mandal': () => {
        this.mandalList = [];
        this.villageList = [];
        this.cityList = [];
        if (+id > 0)
          query = `getdistrictbyid?districtId=${id}`;
      },
      'village': () => {
        this.villageList = [];
        if (+id > 0)
          query = `getmandalbyid?mandalId=${id}`;
      },
      'city': () => {
        this.cityList = [];
        if (+id > 0)
          query = `getdistrictbyid?districtId=${id}`;
      }
    };
    locations[locationType]();
    if (query.length > 0)
      this.getLocations(query, locationType);
  }

  onChngDD(ctrl: string, val: any) {
    if (val == 'null')
      this.patientForm.get(ctrl).setValue(null);
  }

  getLocations(queryString: string, type: string) {
    this.masterService.getLocations(queryString).subscribe(data => {
      // let result = data[0]['setItem'];
      if (data.status == "OK") {
        let arr: any = data.data;
        let assign_locations = {
          'state': () => this.stateList = arr,//.map(row => this.pick(row, ['id', 'name', 'scheduleCasteType', 'castettype'])),
          'district': () => this.districtList = arr.districts,
          'mandal': () => this.mandalList = arr.mandals,
          'village': () => this.villageList = arr.villages,
          'city': () => this.cityList = arr.cities
        };
        if (this.villageList.length == 0) {
          this.patientForm.controls['village_id'].clearValidators();
        }
        // console.log('Drop downs list',this.stateList,this.districtList,this.mandalList,this.villageList,this.cityList);
        assign_locations[type]();
      }
      this.districtList.sort((a, b) => 0 - (a.district_name > b.district_name ? -1 : 1));
    }, error => this.toastr.error(error));
  }

  submitForm() {
    $('#btnsubmit').click();
  }
  close() {
    $("#appModal").modal("hide");
  }
  closeterms() {
    $("#termsModal").modal("hide");
  }
  // GetOtp(){
  //   // this.http.get('https://healthidsbx.abdm.gov.in/api/v1/auth/cert').subscribe(res => {
  //   //   this.publickKey = res
  //   // })
  //   return new Promise((resolve, reject) => {
  //     $.ajax({
  //         type: 'GET',
  //         url:'https://healthidsbx.abdm.gov.in/api/v1/auth/cert',
  //         success: function (response){
  //         //  var publicKey  = response.replace('-----BEGIN PUBLIC KEY-----','');
  //         //  publicKey = publicKey.replace('-----END PUBLIC KEY-----','');
  //         //  publicKey = publicKey.replace(/^\s+|\s+$/gm,'');
  //         var publicKey = response.substring(26,775);
  //          $(".encVal").val(publicKey)
  //           document.getElementById('encAdhar').click();
  //         },
  //         error: (error) => reject(error),
  //     });
  // });
  // }

  //   getEncryptAadhar() {
  //   //   return new Promise((resolve, reject) => {
  //   //   $.ajax({
  //   //     type: 'POST',
  //   //     url: 'https://www.devglan.com/online-tools/rsa-encrypt',
  //   //    body:({
  //   //    "textToEncrypt": "877140665828",
  //   //    "publicKey":(<HTMLInputElement>document.getElementById('enc')).value,
  //   //    "privateKey":(<HTMLInputElement>document.getElementById('enc')).value,
  //   //    "keyType": "publicKeyForEncryption",
  //   //    "cipherType": "RSA/ECB/PKCS1Padding"
  //   //   }),
  //   //   headers: {
  //   //     'Access-Control-Allow-Origin-Type': '*',
  //   //     'Content-Type': 'application/json',
  //   //   },
  //   //   success: function (response){
  //   //     this.encryptKey = response
  //   //   },
  //   //   error: (error) => reject(error),
  //   //   });
  //   // });
  //  // }

  //  // this.url='https://www.devglan.com/online-tools/rsa-encrypt';
  //   // "text":{
  //   //   "mimeType": "application/json",
  //   //   "textToEncrypt": "877140665828",
  //   //   "publicKey": (<HTMLInputElement>document.getElementById('enc')).value,
  //   //   "privateKey": (<HTMLInputElement>document.getElementById('enc')).value,
  //   //   "keyType": "publicKeyForEncryption",
  //   //   "cipherType": "RSA/ECB/PKCS1Padding"
  //   // }
  //   // this.body ={
  //   //   // "mimeType":"application/json",
  //   //    "textToEncrypt":"877140665828",
  //   //    "publicKey":(<HTMLInputElement>document.getElementById('enc')).value,
  //   //    "privateKey":(<HTMLInputElement>document.getElementById('enc')).value,
  //   //   // "publicKey":"MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAstWB95C5pHLXiYW59qyO4Xb+59KYVm9Hywbo77qETZVAyc6VIsxU+UWhd/k/YtjZibCznB+HaXWX9TVTFs9Nwgv7LRGq5uLczpZQDrU7dnGkl/urRA8p0Jv/f8T0MZdFWQgks91uFffeBmJOb58u68ZRxSYGMPe4hb9XXKDVsgoSJaRNYviH7RgAI2QhTCwLEiMqIaUX3p1SAc178ZlN8qHXSSGXvhDR1GKM+y2DIyJqlzfik7lD14mDY/I4lcbftib8cv7llkybtjX1AayfZp4XpmIXKWv8nRM488/jOAF81Bi13paKgpjQUUuwq9tb5Qd/DChytYgBTBTJFe7irDFCmTIcqPr8+IMB7tXA3YXPp3z605Z6cGoYxezUm2Nz2o6oUmarDUntDhq/PnkNergmSeSvS8gD9DHBuJkJWZweG3xOPXiKQAUBr92mdFhJGm6fitO5jsBxgpmulxpG0oKDy9lAOLWSqK92JMcbMNHn4wRikdI9HSiXrrI7fLhJYTbyU3I4v5ESdEsayHXuiwO/1C8y56egzKSw44GAtEpbAkTNEEfK5H5R0QnVBIXOvfeF4tzGvmkfOO6nNXU3o/WAdOyV3xSQ9dqLY5MEL4sJCGY1iJBIAQ452s8v0ynJG5Yq+8hNhsCVnklCzAlsIzQpnSVDUVEzv17grVAw078CAwEAAQ==",
  //   //   // "privateKey":"MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAstWB95C5pHLXiYW59qyO4Xb+59KYVm9Hywbo77qETZVAyc6VIsxU+UWhd/k/YtjZibCznB+HaXWX9TVTFs9Nwgv7LRGq5uLczpZQDrU7dnGkl/urRA8p0Jv/f8T0MZdFWQgks91uFffeBmJOb58u68ZRxSYGMPe4hb9XXKDVsgoSJaRNYviH7RgAI2QhTCwLEiMqIaUX3p1SAc178ZlN8qHXSSGXvhDR1GKM+y2DIyJqlzfik7lD14mDY/I4lcbftib8cv7llkybtjX1AayfZp4XpmIXKWv8nRM488/jOAF81Bi13paKgpjQUUuwq9tb5Qd/DChytYgBTBTJFe7irDFCmTIcqPr8+IMB7tXA3YXPp3z605Z6cGoYxezUm2Nz2o6oUmarDUntDhq/PnkNergmSeSvS8gD9DHBuJkJWZweG3xOPXiKQAUBr92mdFhJGm6fitO5jsBxgpmulxpG0oKDy9lAOLWSqK92JMcbMNHn4wRikdI9HSiXrrI7fLhJYTbyU3I4v5ESdEsayHXuiwO/1C8y56egzKSw44GAtEpbAkTNEEfK5H5R0QnVBIXOvfeF4tzGvmkfOO6nNXU3o/WAdOyV3xSQ9dqLY5MEL4sJCGY1iJBIAQ452s8v0ynJG5Yq+8hNhsCVnklCzAlsIzQpnSVDUVEzv17grVAw078CAwEAAQ==",
  //   //    "keyType":"publicKeyForEncryption",
  //   //    "cipherType":"RSA/ECB/PKCS1Padding"
  //   // }
  //   // this.http.post<any>(this.url,this.body).subscribe( res => {
  //   // this.encRes = res
  //   //     }) ;
  //   this.url = 'https://dev.abdm.gov.in/gateway/v0.5/sessions';
  //   this.body={
  //     "clientId": "SBX_000541",
  //     "clientSecret": "807a3bf7-78a1-47b7-9b4e-afd97c9b0244"
  //   }
  //   this.http.post<any>(this.url,this.body).subscribe( res => {
  //     this.encRes = res
  //         }) ;
  // }
  GetOtp() {
    if (this.istermsChecked == false) {
      this.toastr.error('Please accept the terms & conditions');
      return;
    }
    if(this.istermsChecked==true){
      if(this.termsAndConditions1==true){
      }else{
        this.toastr.error('Please check All mandatory checkboxes');
        this.istermsChecked=false
        return
      }
    }
    if (this.patientForm.value.aadhar.length == 12) {
      var myaadharbase64v = btoa(this.patientForm.value.aadhar)
      this.registrationService.getOTP(myaadharbase64v).subscribe(res => {
        if (res.status = "OK") {
          this.otpRes = res.data;
          this.enableBtn = false;
          this.toastr.info(res.message);
          this.entrOTP = false;
          setTimeout(() => {
            this.resend_otp = true
          }, 30000);
          this.aadhaartimer()
          // document.getElementById('Btn2').removeAttribute("disabled");
        } else {
          this.toastr.error(res.message)
        }
      }, error => this.commonerrormethod(error.message))
    } else {
      this.toastr.error('Please Enter Valid Aadhar Number')
    }

  }

  resendOtp() {
    if (this.istermsChecked == false) {
      this.toastr.error('Please accept the terms & conditions');
      return;
    }
    let data: any = { 'txnId': this.otpRes?.txnId }
    let type: any = this.patientForm.value.verify_with == 'AADHAAR_OTP' ? 'aadhaar' : 'mobile';
    this.registrationService.resendOtps('registration/jwt/apis/aadhaar/resend-aadhaar-otp',data).subscribe(res => {
      if (res.status = "OK") {
        this.otpRes = res.data;
        this.enableBtn = false;
        this.toastr.info(res.message);
        this.entrOTP = false;
        setTimeout(() => {
          this.resend_otp = true
        }, 30000);

        this.aadhaartimer()

      } else {
        this.toastr.error(res.message)
      }
    })
  }
  aadhaartimer() {
    // let minute = 1;
    let minute: any
    let seconds: number = 90;
    let textSec: any = '0';
    let statSec: number = 90;

    const prefix = minute < 10 ? '0' : '';

    const timer = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = '0' + statSec;
      } else textSec = statSec;

      this.display = `${textSec}`;

      if (seconds == 0) {
        this.enableBtn = true;
        this.resend_otp = true
        console.log('finished');
        clearInterval(timer);
      }
    }, 1000);
  }


  // resend OTP new API integration 24-08-2023
  // resendOtp() {
  //   let data: any = { 'txnId': '' }
  //   let type: any = this.patientForm.value.verify_with == 'AADHAAR_OTP' ? 'aadhaar' : 'mobile';
  //   this.registrationService.resendOtp(data, type).subscribe(res => {

  //   }, (err: HttpErrorResponse) => {
  //     this.toastr.error(err.message)
  //   });
  // }
  verifyOTP(type: string) {
    let payLoad = {
      "otp": this.patientForm.value.otp,
      "txnId": this.otpRes.txnId
    }

    this.registrationService.verifyOTP(payLoad).subscribe(res => {
      if (res.status != 'OK') {
        this.toastr.error(res.message);
      } else {
        this.verifyRes = res.data;
        this.rfrshToken = res.data.refreshToken;
        var dobd = this.verifyRes.birthdate;
        let cyearofd = dobd.slice(6);
        let getcyear = new Date().getFullYear();
        let agedata = getcyear - cyearofd;
        this.patientForm.patchValue({
          firstName: this.verifyRes.firstName,
          first_name: this.verifyRes.firstName,
          lastName: this.verifyRes.lastName,
          last_name: this.verifyRes.lastName,
          middleName: this.verifyRes.middleName,
          middle_name: this.verifyRes.middleName,
          mobile: this.verifyRes.mobile,
          age: agedata
        })
        if (this.verifyRes.gender == 'M') {
          this.patientForm.patchValue({
            gender_id: 1
          })
        }
        if (this.verifyRes.gender == 'F') {
          this.patientForm.patchValue({
            gender_id: 2
          })
        }
        if (this.verifyRes.healthIdNumber && this.verifyRes.jwtResponse) {
          this.rfrshToken = this.verifyRes.jwtResponse.refreshToken
          this.getProfile();
          return;
        }
        this.genOTP = false;

        this.toastr.info('Please Enter The Aadhar Linked Mobile Number');
      }

    }, (err: HttpErrorResponse) => {
      this.toastr.error(err.message)
    });
  }


  linkProfile(){
    let payLoad = {
      "authToken":  this.ndhmRes?.token
    }
    this.registrationService.resendOtps('registration/jwt/apis/link/profile-details',payLoad).subscribe(res => {
      if (res.status == 'OK') {
      this.linkProfileDetail = res.data;
      this.suggestionApiCalling();
      this.toastr.success(res.message);
      }
      this.toastr.error(res.message)
    });
  }



  suggestionApiCalling(){
    let payLoad = {
      "txnId":  this.linkProfileDetail?.transactionId
    }
    this.registrationService.resendOtps('registration/jwt/apis/abha-address/suggestions',payLoad).subscribe(res => {
      if (res.status == 'OK') {
      // this.suggestionData =JSON.parse(res.data) ;
      this.suggestionData =res.data ;
      this.toastr.success(res.message);
      }
      this.toastr.error(res.message)
    });
  }

  addSuggestion(d:any) {
    this.patientForm.controls['healthId'].setValue(d);
    // this.abhaAddress=true

  }
  createPhrAdd(){
    if(this.patientForm.value.healthId==''|| this.patientForm.value.healthId==null){
    this.toastr.warning('Please Provide Abha Address')
    return;
    }
    // this.checkexhealthid();
    let payLoad = {
      "phrAddress":this.patientForm.value.healthId,
      "transactionId": this.linkProfileDetail?.transactionId
    }
    this.registrationService.resendOtps('registration/jwt/apis/create/phr/address',payLoad).subscribe(res => {
      if (res.status == 'OK') {
      this.toastr.success(res.message);
      }
      this.toastr.error(res.message)
    });
  }


  downloadHcNew() {
    let payLoad = { 'token': this.rfrshToken };
    this.baseimg = sessionStorage.getItem('ndhmbase')
    this.myBase64 = this.sanitizer.bypassSecurityTrustResourceUrl(
      `data:image/png;base64, ${this.baseimg}`
    );
  }

  onHidCheck(event) {
    this.isHidChecked = event.target.checked;
    //this.initFormControls();
    this.showHcBtn = false;
    this.forgotHid=false;
    this.enableOtp = false
  }
  onABHACheck(event) {
    this.isABHAChecked = event.target.checked;
  }
  generateOTP() {
    if (this.patientForm.value.adhrNum && this.patientForm.value.adhrNum != '') {
      let payLoad = {
        "mobile": this.patientForm.value.adhrNum,
        "txnId": this.otpRes.txnId
      }
      this.registrationService.generateOTP(payLoad).subscribe(res => {
        this.genOTPRes = res.data;
        this.genOTP = true
        this.mobileLinked = res.data?.mobileLinked
        if (res.data?.mobileLinked == true) {
          this.showFields = true
          this.toastr.info("Entered Mobile Number is same as Aadhaar Linked Mobile Number")
        }else{

          this.toastr.info("Entered Mobile Number is not same as Aadhaar Linked Mobile Number. Please Enter OTP received on Mobile No")
          this.toastr.info('Please submit the Otp Received On Your Aadhar Linked Number');
        }
        this.verOTP = false;
        this.mobileTimer();
        // this.enabletBtn2 = true;
       
        // document.getElementById('Btn4').removeAttribute("disabled");
      }, (err: HttpErrorResponse) => {
        this.toastr.error(err.message)
      });
    } else {
      this.toastr.warning('Please Enter Valid Mobile Number');
    }

  }

  reGenerateOtp() {
    if (this.patientForm.value.adhrNum && this.patientForm.value.adhrNum != '') {
      let data: any = { 'txnId': this.genOTPRes?.txnId }
      let mobile= 'mobile'
      // let type: any = this.patientForm.value.verify_with == 'AADHAAR_OTP' ? 'aadhaar' : 'mobile';

      this.registrationService.resendOtp(data,mobile).subscribe({
        next: res => {
          if (res.status == 'OK') {
            this.genOTPRes = res;
        this.genOTP = true
        this.getOtpBtn = true
        this.mobileLinked = res.data?.mobileLinked
        if (res.data?.mobileLinked == true) {
          this.showFields = true
          this.toastr.info("Entered Mobile Number is same as Aadhaar Linked Mobile Number")
        }else{
          this.toastr.info("Entered Mobile Number is not same as Aadhaar Linked Mobile Number. Please Enter OTP received on Mobile No")
          this.toastr.info('Please submit the Otp Received On Your Aadhar Linked Number');
        }
        this.verOTP = false;
        this.mobileTimer();
         
  
          } else {
            let errMsg: string = res.message;
            this.toastr.error(errMsg);
          }
        },
        error: (err: HttpErrorResponse) => {
          this.toastr.error(err.error.message);
  
        },
        complete: () => {
          // Any additional logic after patching values
        },
      });

      // this.registrationService.resendOtp('registration/jwt/apis/mobile/resend-mobile-otp',data).subscribe(res => {
      //   this.genOTPRes = res;
      //   this.genOTP = true
      //   this.getOtpBtn = true
      //   this.mobileLinked = res.data?.mobileLinked

      //   if (res.data?.mobileLinked == true) {
      //     this.showFields = true
      //   }
      //   this.verOTP = false;
      //   this.mobileTimer();
      //   this.toastr.info('Please submit the Otp Received On Your Aadhar Linked Number');
      // }, (err: HttpErrorResponse) => {
      //   this.toastr.error(err.message)
      // });
    // } else {
    //   this.toastr.warning('Please Enter Valid Mobile Number');
     }
  }

  mobileTimer() {
    // let minute = 1;
    let minute: any
    let seconds: number = 90;
    let textSec: any = '0';
    let statSec: number = 90;

    const prefix = minute < 10 ? '0' : '';

    const timer = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = '0' + statSec;
      } else textSec = statSec;

      this.displayTimer = `${textSec}`;

      if (seconds == 0) {
        this.genOTP = false
        this.resendMobileOtp = true
        this.getOtpBtn = false
        console.log('finished');
        clearInterval(timer);
      }
    }, 1000);
  }
  verifyMobOTP() {
    let payLoad = {
      "otp": this.patientForm.value.verotp,
      "txnId": this.otpRes.txnId
    }
    this.registrationService.verifyMobOtp(payLoad).subscribe(res => {
      if (res.data) {
        this.verifyMobRes = res.data;
        this.showFields = true;
        // document.getElementById('applist').removeAttribute("disabled");
        this.toastr.info('Please enter below details for ABHA Generation')
      } else {
        this.toastr.error('Something Went Wrong..try again')
      }

    }, (err: HttpErrorResponse) => {
      this.toastr.error(err.message)
    });
  }
  createHealthId() {
    // let encryptPswd = fnEncryption(this.patientForm.value.password1,environment.securityKey);
    let payLoad = {
      "photo": this.verifyRes.photo,
      "firstName": this.patientForm.value.firstName,
      "healthId": this.patientForm.value.healthId,
      "lastName": this.patientForm.value.lastName,
      "middleName": this.patientForm.value.middleName,
      // "password": encryptPswd,
      "password": this.patientForm.value.password1,
      "email": this.patientForm.value.email1,
      "txnId": this.otpRes.txnId
    }
    // this.registrationService.confirmAadhar(payLoad).subscribe(res => {
    this.registrationService.createHealthID(payLoad).subscribe(res => {
      this.ndhmRes = res.data;
      this.submitbtn=true
      this.cnfrmAadhar = res.data;
      this.linkProfile();
      sessionStorage.setItem('refToken', res.data.refreshToken)
      this.toastr.success(res.message);
      this.toastr.info('Your ABHA Number is ' + this.cnfrmAadhar.healthIdNumber);

      this.showHcBtn = true
      this.getAcntProfile('create');
      if (this.profileRes) {
        let data = { 'token': res.data.refreshToken }
        setTimeout(() => {
          this.downloadHealthCard(data);
        }, 400);
      }

      this.dwnldBtn = false
    }, (err: HttpErrorResponse) => {
      this.toastr.error(err.message)
    });
  }
  downloadHealthCard2() {
    // console.log('profile resp', this.profileRes, this.ndhmRes);
    let PayLoad;
    if (this.cnfrmAadhar) {
      PayLoad = {
        "token": this.cnfrmAadhar.refreshToken
      }
    } else if (this.ndhmRes) {
      PayLoad = {
        "token": this.ndhmRes.refreshToken
      }
    } else if (this.verifyRes) {
      PayLoad = {
        "token": this.verifyRes.jwtResponse.token
      }
    } else if (this.cnfrmAadharRes) {
      PayLoad = {
        "token": this.cnfrmAadharRes
      }
    }

    this.registrationService.downloadHealthCardNew(PayLoad, this.profileRes.healthIdNumber).subscribe(res => {
      this.dwnldCard = res.data.pngBytes;
      if (this.dwnldCard) {
        this.myBase64 = this.sanitizer.bypassSecurityTrustResourceUrl(
          `data:image/png;base64, ${this.dwnldCard}`
        );
        sessionStorage.setItem('ndhmbase', this.dwnldCard);
        $("#appModal").modal("show");
        this.isShowModal = true;
        // this.domSanitize();
        // window.open(this.dwnldCard, '_blank', 'left=300,top=50,width=700,height=600,toolbar=1,resizable=0');

      }
      // this.dwnldUrl =  this.sanitizer.bypassSecurityTrustResourceUrl(
      //  this.dwnldCard
      // );
    }, (err: HttpErrorResponse) => {
      this.toastr.error(err.message)
    });
  }
  downloadHealthCard(payload: any) {
    // let PayLoad = {
    //   "token": this.rfrshToken
    // }
    // console.log('profileRes',JSON.stringify(this.profileRes));
    // console.log('payload',JSON.stringify(payload));
    // let referenceToken = sessionStorage.getItem('refToken')
    // let currentUser = this.authServ.currentUserValue;
    // let urlpath = sessionStorage.getItem('tenantUrl') + `/registration/jwt/apis/getPngCard?healthIdNumber=${this.profileRes.healthIdNumber}&TENANT_ID=` + sessionStorage.getItem('currentTenant')
    this.registrationService.downloadHealthCardNew(payload, this.profileRes.healthIdNumber).subscribe(res => {
      this.dwnldCard = res.data.pngBytes;
      if (this.dwnldCard) {
        this.myBase64 = this.sanitizer.bypassSecurityTrustResourceUrl(
          `data:image/png;base64, ${this.dwnldCard}`
        );
        sessionStorage.setItem('ndhmbase', this.dwnldCard);
        $("#appModal").modal("show");
        this.isShowModal = true;
        // this.domSanitize();
        // window.open(this.dwnldCard, '_blank', 'left=300,top=50,width=700,height=600,toolbar=1,resizable=0');

      }

      // this.dwnldUrl =  this.sanitizer.bypassSecurityTrustResourceUrl(
      //  this.dwnldCard
      // );
    }, (err: HttpErrorResponse) => {
      this.toastr.error(err.message)
    });
    // return new Promise((resolve, reject) => {
    //   $.ajax({
    //     // type: 'POST',
    //     //url: 'https://www.devglan.com/online-tools/rsa-encrypt',
    //     url: urlpath,
    //     method: 'POST',
    //     data: JSON.stringify({
    //       "token": referenceToken
    //     }),
    //     headers: {
    //       'Content-Type': 'application/json',
    //       'Access-Control-Allow-Origin-Type': '*',
    //       'Authorization': currentUser.jwt
    //     },
    //     success: function (response: any) {
    //       console.log('png card',response);
    //       alert('PNG card check');
    //       var dwnldCards = response;
    //       sessionStorage.setItem('ndhmbase', dwnldCards);
    //       setTimeout(() => {
    //         document.getElementById('autodownload').click();
    //       }, 1000);
    //       //  this.myBase64 = this.sanitizer.bypassSecurityTrustResourceUrl(
    //       //   `data:image/png;base64, ${dwnldCards}`
    //       // );
    //       // $("#appModal").modal("show");
    //       // this.isShowModal=true;
    //       // this.domSanitize();
    //       // console.log(dwnldCards);
    //     },
    //     error: (error: any) => this.commonerrormethod(error.responseText),
    //   });
    // });

  }

  domSanitize() {
    let base64 = sessionStorage.getItem('ndhmbase')
    this.myBase64 = this.sanitizer.bypassSecurityTrustResourceUrl(
      `data:image/png;base64, ${base64}`
    );
    $("#appModal").modal("show");
    // this.isShowModal=true;
    // $("#appModal").modal("show");
    // console.log(dwnldCards);
  }
  downloadHc() {
    this.baseimg = sessionStorage.getItem('ndhmbase')
    this.myBase64 = this.sanitizer.bypassSecurityTrustResourceUrl(
      `data:image/png;base64, ${this.baseimg}`
    );
    // this.downloadHealthCard2();
    // this.myBase64 = this.sanitizer.bypassSecurityTrustResourceUrl(
    //   `data:image/png;base64, ${base64}`
    // );
  }
  existhealthID() {
    if (this.istermsChecked == false) {
      this.toastr.error('Please accept the terms & conditions');
      return;
    }
    if (this.patientForm.value.health_Id == null) {
      this.toastr.warning('Please enter ABHA / ABHA Address');
      return;
    }
    this.serachHealthID();
    // this.registrationService.existHID(this.patientForm.value.health_Id).subscribe(res => {
    //   this.existRes = res.ndhmResponse;
    //   if (this.existRes.status == true) {
    //     this.serachHealthID();
    //   } else {
    //       this.toastr.info('ABHA Does not exist');
    //     }
    // })
  }
  serachHealthID() {
    // if(this.patientForm.value.health_Id.length < 12){
    //   this.toastr.error('please enter valid health Id');
    //   return;
    // }
    this.registrationService.searchWithHID(this.patientForm.value.health_Id).subscribe(res => {
      if (res.data?.length == 0 || res.data == '' || res.data == null) {
        if (res.ndhmResponse != '' || res.ndhmResponse != null) {
          const elementsToRemove = ['AADHAAR_BIO', 'DEMOGRAPHICS'];
          this.verifyMethods = res.ndhmResponse.authMethods.filter(item => !elementsToRemove.includes(item));
          this.searchRes = res.ndhmResponse;
          this.authBtn = false;
          this.toastr.info('Valid Id,Patient Eligible for Registration/Update');
          return;
        }

      } if (res.data?.length != 0 && res.data != '' && res.data != null) {
        this.toastr.info('This Id already registered, Please try with another');
        return;
      }
      // this.searchRes = res.data;
      // // this.verifyMethods = res.data.authMethods
      // this.verifyMethods=res.ndhmResponse.authMethods;
      // this.authBtn = false;
      // // this.cnfrmAadhar =res.data.healthIdNumber;
      // this.toastr.info(res.message+' '+'and please verify');
    }, (err: HttpErrorResponse) => {
      this.toastr.error(err.message)
    });
  }
  onMethodChange(e: any) {
    if (e == 'AADHAAR_OTP') {
      this.verifyMthd = 'Aadhar Number'
      this.isDemoGraphic = false;
      this.isDemoGraphicM = false;
      this.showfrgtBtn = false
      this.isForgotMob = false
    }
    if (e == 'MOBILE_OTP') {
      this.verifyMthd = 'Mobile Number'
      this.isDemoGraphic = false
      this.isDemoGraphicM = false
      this.showfrgtBtn = false
      this.isForgotMob = true
    }
    if (e == 'DEMOGRAPHICS') {
      this.isDemoGraphic = true
      this.showfrgtBtn = false
      this.isForgotMob = false
      // this.isDemoGraphicM = true
    }
    this.patientForm.patchValue({ aadhar: '', otp: '', name: '', gender: '', mob: '', yob: '' })
  }
  authenticateIt() {
    this.registrationService.authenticate(this.patientForm.value.verify_with, this.searchRes.healthId).subscribe(res => {
      if (res.data) {
        if (this.patientForm.value.verify_with == 'AADHAAR_OTP' || this.patientForm.value.verify_with == 'MOBILE_OTP') {
          this.enableOtp = true
        }
        if (this.patientForm.value.verify_with == 'PASSWORD') {
          this.enablepwd = true
        }
      }
      this.authRes = res.data
      // this.cnfrmAadhar =res.data.healthIdNumber;
      if (this.authRes.mobileNumber) {
        this.authBtn1 = false;
        this.toastr.info('OTP Sent Succesfully To Your Number' + this.authRes.mobileNumber);
      } else {
        this.toastr.info('OTP Sent Succesfully To Your Number');
        this.authBtn1 = false;
      }

    }, (err: HttpErrorResponse) => {
      this.toastr.error(err.message)
    });
  }


  confirmOTP() {
    if (this.patientForm.value.otp_exist == '' && this.patientForm.value.pwd == '') {
      this.toastr.info('Please Enter OTP');
      return;
    }
    if (this.patientForm.value.verify_with == 'AADHAAR_OTP') {
      let payLoad = {
        "otp": this.patientForm.value.otp_exist,
        "txnId": this.authRes.txnId

      }
      this.registrationService.cnfrmAadharOtp(payLoad).subscribe(res => {
        this.cnfrmAadharRes = res.data.refreshToken;
        this.getAcntProfile('ver');
        // this.cnfrmAadhar =res.data.healthIdNumber;
        // this.toastr.info(res.message);
      }, (err: HttpErrorResponse) => {
        this.toastr.error(err.message)
      });
    }
    if (this.patientForm.value.verify_with == 'MOBILE_OTP') {
      let payLoad = {
        "otp": this.patientForm.value.otp_exist,
        "txnId": this.authRes.txnId

      }
      this.registrationService.cnfrmMobOtp(payLoad).subscribe(res => {
        this.cnfrmAadharRes = res.data.token;
        this.getAcntProfile('ver');
        // this.cnfrmAadhar =res.data.healthIdNumber;
        // this.toastr.info(res.message);
      }, (err: HttpErrorResponse) => {
        this.toastr.error(err.message)
      });
    }
    if (this.patientForm.value.verify_with == 'PASSWORD') {
      let payLoad = {
        "healthId": this.patientForm.value.health_Id,
        "password": this.patientForm.value.pwd
      }
      this.registrationService.password(payLoad).subscribe(res => {
        this.cnfrmAadharRes = res.data.refreshToken;
        this.getAcntProfile('ver');
        // this.cnfrmAadhar =res.data.healthIdNumber;
        // this.toastr.info(res.message);
      }, (err: HttpErrorResponse) => {
        this.toastr.error(err.message)
      });
    }
  }



  getAcntProfile(type: string) {
    let payLoad: any;
    if (type == 'ver') {
      payLoad = {
        "token": this.cnfrmAadharRes
      }
      sessionStorage.setItem('refToken', this.cnfrmAadharRes)
    }
    else if (type == 'forgot') {
      if (this.verifyRes.jwtResponse.token) {
        payLoad = {
          "token": this.verifyRes.jwtResponse.token
        }
        sessionStorage.setItem('refToken', this.verifyRes)
      } else {
        payLoad = {
          "token": this.verifyRes
        }
        sessionStorage.setItem('refToken', this.verifyRes)
      }


    }
    else if (type == 'create') {
      payLoad = {
        "token": this.ndhmRes.refreshToken
      }

    }
    this.registrationService.getAcntProfile(payLoad).subscribe(res => {
      this.profileRes = res.data;
      // console.log('#########',this.profileRes);
      var currentyear = new Date().getFullYear();
      var birthyear = this.profileRes.yearOfBirth;
      var calage = currentyear - birthyear;
      if (this.screentitle != 'Edit') {
        this.patientForm.patchValue({
          firstName: this.profileRes.firstName,
          first_name: this.profileRes.firstName,
          middleName: this.profileRes.middleName,
          middle_name: this.profileRes.middleName,
          lastName: this.profileRes.lastName,
          last_name: this.profileRes.lastName,
          mobile: this.profileRes.mobile,
          age: calage,


        })
        if (this.profileRes.gender == 'M') {
          this.patientForm.patchValue({
            gender_id: 1
          })
        }
        if (this.profileRes.gender == 'F') {
          this.patientForm.patchValue({
            gender_id: 2
          })
        }
      }
      this.toastr.info('Your ABHA Number is' + this.profileRes.healthIdNumber);
      this.clearfields();
      this.downloadHealthCard(payLoad);
      this.authBtn1 = true
      this.showfrgtBtn = true

    }, (err: HttpErrorResponse) => {
      this.toastr.error(err.message)
    });
  }
  getProfile() {
    let payLoad = {
      "token": this.rfrshToken
    }
    this.registrationService.getAcntProfile(payLoad).subscribe(res => {
      this.profileRes = res.data;
      // console.log('@@@@@@@@@@@@@',this.profileRes);
      this.toastr.info('Your ABHA Number is ' + this.profileRes.healthIdNumber);
      sessionStorage.setItem('refToken', this.rfrshToken)
      this.downloadHealthCard(payLoad);
      this.authBtn1 = true
      this.showfrgtBtn = true
      this.showHcBtn = true
    }, (err: HttpErrorResponse) => {
      this.toastr.error(err.message)
    });
  }

  forgotHealthID() {
    // if(this.patientForm.value.health_Id && this.patientForm.value.health_Id!=''){
    this.isHidChecked = false;
    this.forgotHid = true;
    this.showHcBtn = false;
    //this.initFormControls();
    this.enableOtp = false
    // }
    // else{
    //   this.toastr.warning('Please enter ABHA Number/ ABHA Address')
    // }

  }
  verifyForgotOTP(type: string) {
    if (this.patientForm.value.verify_with == 'AADHAAR_OTP') {
      let payLoad = {
        "otp": this.patientForm.value.otp,
        "txnId": this.forgotAadharRes.txnId
      }
      this.registrationService.frgtvrfyotp(payLoad).subscribe(res => {
        this.verifyRes = res.data;
        this.getAcntProfile(type);
        // this.genOTP = false;
        // if(this.verifyRes.healthIdNumber){
        //   this.toastr.info('Your HealthID Number is' + this.verifyRes.healthIdNumber);
        //   return;
        // }
        // this.toastr.info('Please Enter The Aadhar Linked Mobile Number');
        // document.getElementById('Btn3').removeAttribute("disabled");
      }, (err: HttpErrorResponse) => {
        this.toastr.error(err.message)
      });
    }
    if (this.patientForm.value.verify_with == 'MOBILE_OTP') {
      if (this.patientForm.value.gender == 1) {
        this.patientForm.value.gender = 'M'
      }
      if (this.patientForm.value.gender == 2) {
        this.patientForm.value.gender = 'F'
      }
      if (this.patientForm.value.gender == 3) {
        this.patientForm.value.gender = 'O'
      }
      if (this.patientForm.value.m_name !== '') {
        this.finalName = this.patientForm.value.f_name + ' ' + this.patientForm.value.m_name + ' ' + this.patientForm.value.l_name
      } else {
        this.finalName = this.patientForm.value.f_name + ' ' + this.patientForm.value.l_name
      }
      let payLoad = {
        "dayOfBirth": this.patientForm.value.db,
        "firstName": this.patientForm.value.f_name,
        "gender": this.patientForm.value.gender,
        "lastName": this.patientForm.value.l_name,
        "middleName": this.patientForm.value.m_name,
        "monthOfBirth": this.patientForm.value.mb,
        "name": this.finalName,
        // "name": this.patientForm.value.name,
        "otp": this.patientForm.value.otp,
        "status": "Active",
        "txnId": this.forgotAadharRes.txnId,
        "yearOfBirth": this.patientForm.value.yob
      }
      this.registrationService.frgtvrfyotpmob(payLoad).subscribe(res => {
        this.verifyRes = res.data;
        this.getAcntProfile(type);
        this.genOTP = false;
        if (this.verifyRes.healthIdNumber) {
          this.toastr.info('Your HealthID Number is' + this.verifyRes.healthIdNumber);
          return;
        }
        // this.toastr.info('Please Enter The Aadhar Linked Mobile Number');
        // document.getElementById('Btn3').removeAttribute("disabled");
      }, (err: HttpErrorResponse) => {
        this.toastr.error(err.message)
      });
    }

  }
  forgotAuth() {
    if (this.istermsChecked == false) {
      this.toastr.error('Please accept the terms & conditions');
      return;
    }
    if(this.istermsChecked==true){
      if(this.termsAndConditions1==true){
      }else{
        this.toastr.error('Please check All mandatory checkboxes');
        this.istermsChecked=false
        return
      }
    }
    if (this.patientForm.value.verify_with == 'AADHAAR_OTP') {
      var myaadharbase64 = btoa(this.patientForm.value.aadhar)
      this.registrationService.forgotHidAadhr(myaadharbase64).subscribe(res => {
        this.forgotAadharRes = res.data;
        this.enableBtn = false;
        this.entrOTP = false;
        this.forgetAadhaarTimer();
        if (this.forgotAadharRes.mobileNumber) {
          this.toastr.info('please enter the OTP Received on your number' + this.forgotAadharRes.mobileNumber)
        } else {
          this.toastr.info('please enter the OTP Received on your number')
        }

        // this.getAcntProfile('ver');
        // this.cnfrmAadhar =res.data.healthIdNumber;
        // this.toastr.info(res.message);
      }, (err: HttpErrorResponse) => {
        this.toastr.error(err.message)
      });
    }
    if (this.patientForm.value.verify_with == 'MOBILE_OTP') {
      this.registrationService.forgotHidmob(this.patientForm.value.aadhar).subscribe(res => {
        this.forgotAadharRes = res.data;
        this.genOTP = false;
        this.entrOTP = false;
        this.forgetMobileNumberTimer();
        if (this.forgotAadharRes.mobileNumber) {
          this.toastr.info('please enter the OTP Received on your number' + this.forgotAadharRes.mobileNumber)
        } else {
          this.toastr.info('please enter the OTP Received on your number')
        }
        // this.getAcntProfile('ver');
        // this.cnfrmAadhar =res.data.healthIdNumber;
        // this.toastr.info(res.message);
      }, (err: HttpErrorResponse) => {
        this.toastr.error(err.message)
      });
    }
  }
  resendForgotAuth(){
    if (this.istermsChecked == false) {
      this.toastr.error('Please accept the terms & conditions');
      return;
    }
    if (this.patientForm.value.verify_with == 'AADHAAR_OTP') {
      let data: any = { 'txnId': this.forgotAadharRes?.txnId }
      let myaadharbase64 = btoa(this.patientForm.value.aadhar)
      this.registrationService.resendOtps('registration/jwt/apis/aadhaar/resend-aadhaar-otp',data).subscribe(res => {
        // this.registrationService.forgotHidAadhr(myaadharbase64).subscribe((res: { data: any; }) => {
        this.forgotAadharRes = res.data;
        this.enableBtn = false;
        this.entrOTP = false;
        this.forgetAadhaarTimer();
        if (this.forgotAadharRes.mobileNumber) {
          this.toastr.info('please enter the OTP Received on your number' + this.forgotAadharRes.mobileNumber)
        } else {
          this.toastr.info('please enter the OTP Received on your number')
        }


      }, (err: HttpErrorResponse) => {
        this.toastr.error(err.message)
      });
    }
    if (this.patientForm.value.verify_with == 'MOBILE_OTP') {
      let data: any = { 'txnId': this.forgotAadharRes?.txnId }
      this.registrationService.resendOtps('registration/jwt/apis/mobile/resend-mobile-otp',data).subscribe(res => {
        // this.registrationService.forgotHidmob(this.patientForm.value.aadhar).subscribe((res: { data: any; }) => {
        this.forgotAadharRes = res.data;
        this.genOTP = true
        this.entrOTP = false;
        this.forgetMobileNumberTimer();
        if (this.forgotAadharRes.mobileNumber) {
          this.toastr.info('please enter the OTP Received on your number' + this.forgotAadharRes.mobileNumber)
        } else {
          this.toastr.info('please enter the OTP Received on your number')
        }

      }, (err: HttpErrorResponse) => {
        this.toastr.error(err.message)
      });
    }
  }

  forgetAadhaarTimer() {
    // let minute = 1;
    let minute: any
    let seconds: number = 90;
    let textSec: any = '0';
    let statSec: number = 90;

    const prefix = minute < 10 ? '0' : '';

    const timer = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = '0' + statSec;
      } else textSec = statSec;

      this.ForAdhrdisplay = `${textSec}`;

      if (seconds == 0) {
        this.enableBtn = true;
        this.resend_otps = true
        console.log('finished');
        clearInterval(timer);
      }
    }, 1000);
  }

  forgetMobileNumberTimer() {
     // let minute = 1;
     let minute: any
     let seconds: number = 90;
     let textSec: any = '0';
     let statSec: number = 90;

     const prefix = minute < 10 ? '0' : '';

     const timer = setInterval(() => {
       seconds--;
       if (statSec != 0) statSec--;
       else statSec = 59;

       if (statSec < 10) {
         textSec = '0' + statSec;
       } else textSec = statSec;

       this.frgtMobile = `${textSec}`;

       if (seconds == 0) {
         this.genOTP = false
         this.resendMobileOtp = true
         this.getOtpBtn = false
         console.log('finished');
         clearInterval(timer);
       }
     }, 1000);
  }

  verifyDemographics() {
    if (this.patientForm.value.mob == '' || this.patientForm.value.yob == '' || this.patientForm.value.gender == '' || this.patientForm.value.name == '') {
      this.toastr.error('please enter all mandatory fields');
      return
    }
    if (this.patientForm.value.gender == 1) {
      this.patientForm.value.gender = 'M'
    }
    if (this.patientForm.value.gender == 2) {
      this.patientForm.value.gender = 'F'
    }
    if (this.patientForm.value.gender == 3) {
      this.patientForm.value.gender = 'O'
    }
    let payLoad = {
      "gender": this.patientForm.value.gender,
      "mobile": this.patientForm.value.mob,
      "name": this.patientForm.value.name,
      "yearOfBirth": this.patientForm.value.yob
    }
    this.registrationService.verifyDemographics(payLoad).subscribe(res => {
      if (res.data.healthIdNumber == null) {
        this.toastr.info('Record not found with given details');
        return;
      } if (res.data.healthIdNumber != '') {
        this.patientForm.patchValue({
          health_Id: res.data.healthIdNumber
        })
        //this.patientForm.value.health_Id=res.data.healthIdNumber;
        this.isHidChecked = true;
        this.serachHealthID();
      }
      this.demoGraphRes = res.data.authMethods;
      this.toastr.info('please select the authentication method')
    }, (err: HttpErrorResponse) => {
      this.toastr.error(err.message)
    });
  }
  onAuthMethod(e: any) {
    if (e == 'AADHAAR_OTP') {
      this.verifyMthd = 'Aadhar Number'
      this.isDgAadhr = true
      this.showfrgtBtn = false
    }
    if (e == 'MOBILE_OTP') {
      this.verifyMthd = 'Mobile Number'
      this.isDgAadhr = true
      this.showfrgtBtn = false
    }
    this.patientForm.patchValue({ aadhar: '' })
  }
  checkPattern(e: any) {
    var value = e;
    var pattern = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/);
    var result = pattern.test(value);
    if (result == false) {
      this.toastr.info('Must contain an uppercase, a lowercase, a number, a special character and at least 8 characters. It should not contain any sequences (like 123)')
    }
  }

  // confirmAadhar(){
  //   let payLoad={
  //     "txnId":this.genOTPRes.txnId
  //   }
  //   this.registrationService.confirmAadhar(payLoad).subscribe(res => {
  // this.cnfrmAadhar =res;
  //   })
  // }
  commonerrormethod(err) {
    try {


      //var newval=err.replace(/\d+/g, '');
      //newval=newval.slice(2)
      //var obj=newval;
      this.toastr.error(err);
      return;
      var jsonObj = JSON.parse(err);
      if (jsonObj['details'] == null) {
        if (jsonObj['message'].includes('<title>503')) {
          this.toastr.error('Service down please try again');
        } else {
          this.toastr.error(jsonObj['message']);
        }
      } else {
        this.toastr.error(jsonObj['details'][0]['message']);
      }
      // for(var i = 0; i < jsonObj.length; i++)
      // {
      //     this.toastr.error(jsonObj[i]['message']);
      // }
    } catch (exp) {
      this.toastr.error('Service down please try again');
    }
  }
  clearfields() {
    this.patientForm.patchValue({
      health_Id: '',
      verify_with: '',
      otp_exist: ''
    })
  }
  checkexhealthid() {
    if(this.patientForm.value.healthId.endsWith('@sbx')) {
      let hlthId = this.patientForm.value.healthId.slice(0, -4); // Removes the last 4 characters
      this.patientForm.get('healthId').setValue(hlthId)
    }
    this.registrationService.existHID(this.patientForm.value.healthId).subscribe(res => {
      this.existRes = res.data;
      if (this.existRes.status == true) {
        this.toastr.error('Health Id Already Exist');
      } else {
       this.createPhrAdd();
      }
    }, (err: HttpErrorResponse) => {
      this.toastr.error(err.message)
    });
  }
  termscheck(event) {
    this.istermsChecked = event.target.checked;
  }
  showmoreclick() {
    this.showmore = !this.showmore;
  }
  downloadHc2() {
    this.baseimg = sessionStorage.getItem('ndhmbase')
    this.myBase64 = this.sanitizer.bypassSecurityTrustResourceUrl(
      `data:image/png;base64, ${this.baseimg}`
    );
    // setTimeout(() => {
    //   document.getElementById('autodownloadnew').click();
    // }, 1000);
    // this.myBase64 = this.sanitizer.bypassSecurityTrustResourceUrl(
    //   `data:image/png;base64, ${base64}`
    // );
  }
  downloadHc3(event) {
    // event.target.download;
    // event.target.value;
    // console.log(event,'test');
    // this.onFileUploadnew(event,0,'26','proofFile');
  }
  onFileUploadnew(event: any, fileNumbernew: number, fileTypenew, frmCntrlnew) {
    fileNumbernew = fileNumbernew - 1;
    // let fileExt: string = this.uploadFileList[fileNumbernew].extensions;
    let fileExt: string = "jpg,jpeg,pdf";
    //if (event.target.files.length > 0) {
    const fileList = event.target, fsize = fileList.files.item(0).size, currentfile = Math.round((fsize / (1024 * 5)));
    // if (fileList.files[0]) {
    const fileExtension: string = 'jpg';
    // if (!this.uploadFileList[fileNumbernew].extensions.split(',').includes(fileExtension)) {
    //   this.toastr.warning(`Please Select ${fileExt} files only`);
    //   if (frmCntrlnew != '') {
    //     this.patientForm.get(frmCntrlnew).clearValidators(), this.patientForm.get(frmCntrlnew).setValue(''), // this.cancelFile(fileNumber+1),
    //       this.patientForm.get(frmCntrlnew).setValidators([Validators.required]), this.patientForm.get(frmCntrlnew).updateValueAndValidity();
    //   }
    //   return false;
    // }
    // }
    if (currentfile >= (1024 * 5)) {
      this.toastr.error(fileList.files[0].name + ' too Big, please select a file less than 5MB', 'Doctor Registration');
      return;
    }
    else {
      this.uploadFileList[fileNumbernew].File = fileList.files.item(0), this.uploadFileList[fileNumbernew].file_name = fileList.files[0].name,
        this.uploadFileList[fileNumbernew].fileType = fileTypenew;
      if (this.patient) {
        let f = this.patient.doctor_documents?.filter(f => f.file_type_id == +this.uploadFileList[fileNumbernew].fileType) ?? '';
        this.uploadFileList[fileNumbernew].id = f.length > 0 ? f[0].id : 0;
      }
    }
    // }
  }
  closemodal(){
    // if(this.checkBox1==true && this.checkBox6==true && this.checkBox7==true){
    //   this.termsAndConditions1=true;
    //   this.closeterms();
    //   //  document.getElementById('closemodal')?.click()
    //  this.istermsChecked=true;
    // }else{
      this.checkBox1=false
      this.checkBox2=false
      this.checkBox3=false
      this.checkBox4=false
      this.checkBox5=false
      this.checkBox6=false
      this.checkBox7=false
   this.istermsChecked=false;
   this.closeterms();
    // }
  }
  
  onOkay(){
  
  if(this.checkBox1==true && this.checkBox6==true && this.checkBox7==true){
    this.termsAndConditions1=true; 
    this.closeterms();
  //  document.getElementById('closemodal')?.click()
  
   this.istermsChecked=true;
  }else{
    this.toastr.info("Please check All mandatory checkboxs")
    return
  }
  } 
}