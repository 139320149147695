<div class="modal-header">
    <h4 class="modal-title">Corporate Plans</h4>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close"
        (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="container-fluid">
        <form [formGroup]="corporateform" (ngSubmit)="onSubmit($event)">
            <div class="row">
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Name of Corporate Plan <span class="text-danger">*</span></label>
                        <div>
                            <input type="text" class="form-control form-control-sm" formControlName="plan"
                                [ngClass]="{ 'is-invalid': submitted && f.plan.errors }" maxlength="30" onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97
                                    && event.charCode <= 122) || (event.charCode >= 48 && event.charCode <= 57))'
                                placeholder="Name of Corporate Plan" minlength="5">
                            <div *ngIf="submitted && f.plan.errors" class="invalid-feedback">
                                <div *ngIf="f.plan.errors.required">Name of Corporate Plan is required</div>
                                <div *ngIf="f.plan.errors.minlength">Name of Corporate Plan should be Minimum of 5
                                    characters</div>
                                <div *ngIf="f.plan.errors.maxlength">Name of Corporate Plan should be Maximum of 30
                                    characters</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Apply To <span class="text-danger">*</span> </label>
                        <div>
                            <select formControlName="plan_for" id="plan_for" disabled
                                [ngClass]="{ 'is-invalid': submitted && f.plan_for.errors }"
                                class="form-control form-control-sm">
                                <option value="">Select</option>
                                <option value="Consultation">Consultation</option>
                                <option value="Lab">Lab</option>
                                <option value="Pharmacy">Pharmacy</option>
                            </select>
                            <div *ngIf="submitted && f.plan_for.errors" class="invalid-feedback">
                                <div *ngIf="f.plan_for.errors.required">Apply To is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"><b>Plan Type</b>&nbsp;<span class="text-danger">*</span></label><br />
                        <!-- <span style="border: 1px solid red;"> -->
                        <label [ngClass]="{ 'is-invalid': submitted && corporateform.get('plan_type').value == '' }"
                            class="control-label">Subscription&nbsp;<input type="radio" formControlName="plan_type"
                                (click)="showsubscription()" value="Subscription"></label>&nbsp;
                        <!-- [ngClass]="{ 'is-invalid': submitted && corporateform.get('plan_type').value == '' }" -->
                        <label [ngClass]="{ 'is-invalid': submitted && corporateform.get('plan_type').value == '' }"
                            class="control-label">Discount&nbsp;<input type="radio" formControlName="plan_type"
                                (click)="showdiscount();corporateform.get('discount_type').setValue('')"
                                value="Discount"></label>
                        <!-- [ngClass]="{ 'is-invalid': submitted && corporateform.get('plan_type').value == '' }" -->
                        <!-- </span> -->
                        <span *ngIf="submitted && corporateform.get('plan_type').value == ''" class="invalid-feedback">
                            <div>Plan Type is required</div>
                        </span>
                    </div>
                </div>
                <div class="col-xl-3" *ngIf="false">
                    <div class="form-group">
                        <label class="control-label"><b>Subscription Type</b><span
                                class="text-danger">*</span></label><br />
                        <div class="row ml-2">
                            <label>Monthly&nbsp;<input type="radio" name="subscription_type" id="subscription_type"
                                    formControlName="subscription_type" value="Monthly"></label>&nbsp;
                            <label>Yearly&nbsp;<input type="radio" name="subscription_type" id="subscription_type"
                                    formControlName="subscription_type" value="Yearly"></label>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="showw">
                <div class="row mt-1">
                    <div class="col-xl-3">
                        <label class="ml-5 control-label"><b>No. of calls</b>&nbsp;<span class="text-danger"
                            *ngIf="corporateform.get('plan_type').value=='Subscription'">*</span>
                        </label><br />
                        <div class="row ml-1">
                            <label [ngClass]="{ 'is-invalid': submitted && corporateform.get('isLmtd').value == null }">Limited
                                &nbsp;<input type="radio" name="isLmtd" id="isLmtd" formControlName="isLmtd"
                                    [value]="true" #LmtdCall (click)="checkedlimt()"></label>&nbsp;
                            <label>Unlimited&nbsp;<input type="radio" name="isLmtd" id="isLmtd" (click)="checkedlimt()"
                                    formControlName="isLmtd" [value]="false" #LmtdCall></label>
                            <div *ngIf="submitted && corporateform.get('isLmtd').value == null"
                                class="invalid-feedback">
                                <div>No. of calls is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3">
                        <div class="form-group">
                            <label class="control-label">No. of Calls Per Employee
                                <span class="text-danger" *ngIf="LmtdCall.checked">*</span>
                            </label>
                            <div>
                                <input type="text" class="form-control  form-control-sm"
                                    formControlName="max_calls_per_employee" placeholder="No. of Calls Per Employee"
                                    [ngClass]="{ 'is-invalid': submitted && f.max_calls_per_employee.errors }"
                                    [attr.disabled]="LmtdCall.checked ? null : true" minlength="1" maxlength="4"
                                    onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'>
                                <div *ngIf="submitted && f.max_calls_per_employee.errors" class="invalid-feedback">
                                    <div *ngIf="f.max_calls_per_employee.errors.required">No. of Calls Per Employee is
                                        required</div>
                                    <div *ngIf="f.max_calls_per_employee.errors.min">Minimum No. of Calls Per Employee
                                        is 1</div>
                                    <div *ngIf="f.max_calls_per_employee.errors.max">Maximum No. of Calls Per Employee
                                        is 1000</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3" hidden>
                        <div class="form-group">
                            <label class="control-label">Max. Cap of users </label>
                            <div>
                                <input type="text" class="form-control  form-control-sm" minlength="1" maxlength="5"
                                    onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'
                                    [ngClass]="{ 'is-invalid': submitted && f.max_employee_count.errors }"
                                    formControlName="max_employee_count" placeholder="Max. Cap of users">
                                <div *ngIf="submitted && f.max_employee_count.errors" class="invalid-feedback">
                                    <div *ngIf="f.max_employee_count.errors.min">Minimum Max. Cap of users is 1</div>
                                    <div *ngIf="f.max_employee_count.errors.max">Maximum Max. Cap of users is 10000
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3" *ngIf="false">
                        <div class="form-group">
                            <label class="control-label">Recurring</label><br />
                            <input type="checkbox" formControlName="is_recurring" [value]="false" name="is_recurring">
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="showDDiscount">
                <div class="row mt-1">
                    <div class="col-xl-3">
                        <div class="form-group">
                            <label>Discount Type <span class="text-danger">*</span></label>
                            <select class="form-control form-control-sm" formControlName="discount_type"
                                [ngClass]="{ 'is-invalid': submitted && f.discount_type.errors }" (change)="onChngDT()"
                                #DiscountType>
                                <option value="">Select</option>
                                <option value="Flat">Flat</option>
                                <option value="Percentage">Percentage</option>
                            </select>
                            <div *ngIf="submitted && f.discount_type.errors" class="invalid-feedback">
                                <div *ngIf="f.discount_type.errors.required">Discount Type is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3">
                        <div class="form-group">
                            <label>Discount Rate<span class="text-danger"
                                    *ngIf="DiscountType.value=='Flat'">*</span></label>
                            <div>
                                <input type="text" class="form-control form-control-sm"
                                    formControlName="max_discount_amount" autocomplete="off" maxlength="4"
                                    [attr.disabled]="DiscountType.value=='Flat'?null:true" minlength="1"
                                    [ngClass]="{ 'is-invalid': submitted && f.max_discount_amount.errors && DiscountType.value=='Flat' }"
                                    onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'
                                    placeholder="Discount Rate">
                                <div *ngIf="submitted && f.max_discount_amount.errors" class="invalid-feedback">
                                    <div *ngIf="f.max_discount_amount.errors.required && DiscountType.value=='Flat'">
                                        Discount Rate is required</div>
                                    <div *ngIf="f.max_discount_amount.errors.min">Minimum Discount Rate is
                                        1</div>
                                    <div *ngIf="f.max_discount_amount.errors.max">Maximum Discount Rate is
                                        1000</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3">
                        <div class="form-group">
                            <label class="ml-5"><b>No. of Calls</b>&nbsp;<span class="text-danger"
                                    *ngIf="corporateform.get('plan_type').value=='Discount'">*</span></label><br />
                            <div class="row ml-1">
                                <label
                                    [ngClass]="{ 'is-invalid': submitted && corporateform.get('isLmtd').value == null }">
                                    Limited&nbsp;<input type="radio" name="isLmtd" id="isLmtd" formControlName="isLmtd"
                                        [value]="true" #LmtdCall
                                        (click)="checkedlimt();corporateform.get('isLmtd').setValue(true)"></label>&nbsp;
                                <label>Unlimited&nbsp;<input type="radio" name="isLmtd" id="isLmtd"
                                        (click)="checkedlimt();corporateform.get('isLmtd').setValue(false)"
                                        formControlName="isLmtd" [value]="false" #LmtdCall></label>
                                <div *ngIf="submitted && corporateform.get('isLmtd').value == null"
                                    class="invalid-feedback">
                                    <div>No. of Calls is required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3">
                        <div class="form-group">
                            <label class="control-label">No. of Calls Per Employee
                                <span class="text-danger" *ngIf="LmtdCall.checked">*</span>
                            </label>
                            <div>
                                <input type="text" class="form-control  form-control-sm"
                                    formControlName="max_calls_per_employee" placeholder="No. of Calls Per Employee"
                                    [ngClass]="{ 'is-invalid': submitted && f.max_calls_per_employee.errors }"
                                    [attr.disabled]="LmtdCall.checked ? null : true" minlength="1" maxlength="4"
                                    onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'>
                                <div *ngIf="submitted && f.max_calls_per_employee.errors" class="invalid-feedback">
                                    <div *ngIf="f.max_calls_per_employee.errors.required">No. of Calls Per Employee is
                                        required</div>
                                    <div *ngIf="f.max_calls_per_employee.errors.min">Minimum No. of Calls Per Employee
                                        is 1</div>
                                    <div *ngIf="f.max_calls_per_employee.errors.max">Maximum No. of Calls Per Employee
                                        is 1000</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-1">
                    <div class="col-xl-3">
                        <div class="form-group">
                            <label>Discount Percentage<span class="text-danger"
                                    *ngIf="DiscountType.value=='Percentage'">*</span></label>
                            <div>
                                <input type="text" autocomplete="off" class="form-control form-control-sm"
                                    id="discount_amt_percentage" maxlength="3" minlength="1"
                                    [attr.disabled]="DiscountType.value=='Percentage'?null:true"
                                    formControlName="discount_amt_percentage" placeholder="Discount Percentage"
                                    [ngClass]="{ 'is-invalid': submitted && f.discount_amt_percentage.errors }"
                                    onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'>
                                <div *ngIf="submitted && f.discount_amt_percentage.errors" class="invalid-feedback">
                                    <div *ngIf="f.discount_amt_percentage.errors.required">Discount
                                        Percentage is required</div>
                                    <div *ngIf="f.discount_amt_percentage.errors.min">Min. Discount
                                        Percentage is 1</div>
                                    <div *ngIf="f.discount_amt_percentage.errors.max">Max. Discount
                                        Percentage is 100</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3">
                        <div class="form-group">
                            <label>Max. Discount Amount<span class="text-danger"
                                    *ngIf="DiscountType.value=='Percentage'">*</span></label>
                            <div> <input type="text" autocomplete="off" class="form-control form-control-sm"
                                    formControlName="max_discount_amount_perc"
                                    [attr.disabled]="DiscountType.value=='Percentage'?null:true"
                                    [ngClass]="{ 'is-invalid': submitted && f.max_discount_amount_perc.errors }"
                                    placeholder="Max. Discount Amount" maxlength="4" minlength="1"
                                    onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'>
                                <div *ngIf="submitted && f.max_discount_amount_perc.errors" class="invalid-feedback">
                                    <div *ngIf="f.max_discount_amount_perc.errors.required">Maximum Discount
                                        Amount is required</div>
                                    <div *ngIf="f.max_discount_amount_perc.errors.min">Minimum of Max. Discount
                                        Percentage is 1</div>
                                    <div *ngIf="f.max_discount_amount_perc.errors.max">Maximum of Max. Discount
                                        Percentage is 1000</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3" hidden>
                        <div class="form-group">
                            <label>Max. Cap of users</label>
                            <div>
                                <input type="text" class="form-control  form-control-sm" minlength="1" maxlength="5"
                                    onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'
                                    [ngClass]="{ 'is-invalid': submitted && f.max_employee_count.errors }"
                                    formControlName="max_employee_count" placeholder="Max. Cap of users">
                                <div *ngIf="submitted && f.max_employee_count.errors" class="invalid-feedback">
                                    <div *ngIf="f.max_employee_count.errors.min">Minimum Max. Cap of users is 1</div>
                                    <div *ngIf="f.max_employee_count.errors.max">Maximum Max. Cap of users is 10000
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <input type="submit" id="btnsubmit" style="display: none;">
        </form>
    </div>
</div>
<div class="modal-footer">
    <input type="submit" class="btn btn-sm btn-primary" (click)="submitForm()" value="Submit">
    <button type="button" class="btn btn-sm btn-outline-dark" data-dismiss="modal"
        (click)="activeModal.close('Close click')">Close</button>
</div>