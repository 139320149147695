<div class="row">
  <div class="container-fluid pl-0 pr-0">
    <div class="row">
      <div class="col-xl-12">
        <div class="card card-header-actions mx-auto">
          <div class="card-header d-block  h-auto">
            <div class="row">
                <div class="col-xl-4 col-lg-4">  <span class="">Active/Inactive List</span></div>
                <div class="col-xl-8 col-lg-8 float-right">
                  <span class="d-block float-lg-right">     
                    <span class="">
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" checked name="inlineRadioOptions"
                          (change)="getHeadings('doctor')" id="inlineRadio1" value="Doctor">
                        <label class="form-check-label" for="inlineRadio1"><strong>Doctor</strong></label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="inlineRadioOptions"
                          (change)="getHeadings('pharmacy')" id="inlineRadio2" value="Pharmacy">
                        <label class="form-check-label" for="inlineRadio2"><strong>Pharmacy</strong></label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="inlineRadioOptions" 
                        (change)="getHeadings('lab')" id="inlineRadio3" value="Lab">
                        <label class="form-check-label" for="inlineRadio2"><strong>Lab</strong></label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="inlineRadioOptions"
                          (change)="getHeadings('hospital')" id="inlineRadio5" value="Hospital">
                        <label class="form-check-label" for="inlineRadio2"><strong>Hospital</strong></label>
                      </div>
                      <!-- <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="inlineRadioOptions" 
                        (change)="getHeadings('company')" id="inlineRadio4" value="Company">
                        <label class="form-check-label" for="inlineRadio3"><strong>Company</strong></label>
                      </div> -->
                    </span>
                  </span>
                </div>
            </div>
            <div class=""></div>
          </div>
          <div class="card-body">
            <!-- <div class="col-md-9 mb-3" style="margin-top: 2rem;" >
              <button type="button" class="btn  btn-sm btn-secondary float-right"   (click)="downLoadExcel()"> <i class="fa fa-file-excel-o"></i>&nbsp;Export</button> &nbsp; &nbsp;
          </div> -->
            <div class="table-responsive" >
              <table datatable  id="doctor" [dtOptions]="dtOptions" *ngIf="dtRender"  hidden class="table table-striped table-bordered table-sm">
                <thead>
                  <tr>
                    <th>S No</th>
                    <th class="text-center" *ngFor="let head of headerList">{{head}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of dataList;let ind=index">
                    <td style="width: 7%;" class="singleLine text-center">{{(ind+1)}}</td>
                    <td class="singleLine" style="width: 17%;" *ngIf="isukhub">{{item?.district_name}}</td>
                    <td class="singleLine" style="width: 17%;" *ngIf="isukhub">{{item?.center_type}}</td>
                    <td class="singleLine text-uppercase">{{item?.first_name+' '+item?.last_name}}</td>
                    <td class="singleLine" style="width: 17%;">{{item?.mci_number}}</td>
                    <td class="singleLine" style="width: 17%;">{{item?.email}}</td>
                    <td class="singleLine">
                      <!-- <input type="radio"
                        (change)="statusChanged(item?.id,true,item?.email,item?.mobile,item?.first_name,item?.last_name)"
                        [name]="'doctor'+ind" [checked]="item?.account_activated" [value]="true">Active
                      <input type="radio"
                        (change)="statusChanged(item?.id,false,item?.email,item?.mobile,item?.first_name,item?.last_name)"
                        [name]="'doctor'+ind" [checked]="!item?.account_activated" [value]="false">In Active -->
                      <div class="custom-control-inline custom-control custom-checkbox">
                        <input class="custom-control-input" type="checkbox" [checked]="item?.account_activated"
                          (change)="onChkBoxChng(item?.id, $event.target.checked, 'DOCTOR', 'a')" [id]="'cbAct'+(ind+1)">
                          <!-- [disabled]="!item?.is_active" -->
                        <label class="custom-control-label" [for]="'cbAct'+(ind+1)"><strong>Active</strong></label>
                      </div>
                      <div class="custom-control-inline custom-control custom-checkbox">
                        <input class="custom-control-input" [id]="'cbDel'+(ind+1)" [checked]="!item?.is_active"
                          (change)="onChkBoxChng(item?.id, !$event.target.checked, 'DOCTOR', 'd')" type="checkbox">
                        <label class="custom-control-label" [for]="'cbDel'+(ind+1)"><strong>Deleted</strong></label>
                      </div>
                    </td>
                    <td class="singleLine">
                      <button type="button" (click)="ViewModal(item?.id)" class="btn btn-primary btn-sm"
                      *ngIf="screennames?.screen_name == 'inactivelist' ? screennames.can_update : ''">
                        <i class="fa fa-eye" aria-hidden="true"></i>
                      </button> <!-- (click)="openModal(item,'doctor','Doctor Registration Details')" -->
                    </td>
                  </tr>
                </tbody>
              </table>

              <table datatable [dtOptions]="dtOptions2" *ngIf="dtRender2" id="pharmacy" hidden class="table table-striped table-bordered table-sm">
                <thead>
                  <tr>
                    <th>S No</th>
                    <th *ngFor="let head of headerList;">{{head}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of dataList;let ind=index">
                    <td style="width: 7%;" class="text-center">{{(ind+1)}}</td>
                    <td class="text-uppercase" *ngIf="isukhub">{{item?.district_name}}</td>
                    <td class="text-uppercase"  *ngIf="isukhub">{{item?.mandal_name}}</td>
                    <td class="text-uppercase"  *ngIf="isukhub">{{item?.center_name}}</td>
                    <td class="text-uppercase">{{item?.pharmacy_name}}</td>
                    <td>{{item?.registration_no}}</td>
                    <td>
                      <input type="radio" [name]="'pharmacy'+ind" id="'pharmacy'+ind" [checked]="item?.account_activated" [value]="true"
                        (change)="statusChanged(item?.id,true,item?.email,item?.contact_person_mobile,item?.pharmacy_name,'')">Active
                      <input type="radio" [name]="'pharmacy'+ind" id="'pharmacy'+ind" [checked]="!item?.account_activated" [value]="false"
                        (change)="statusChanged(item?.id,false,item?.email,item?.contact_person_mobile,item?.pharmacy_name,'')">In Active
                    </td>
                    <!-- <td>
                      <button  class="btn btn-primary btn-sm"
                        (click)="openModal(item,'pharmacy','Pharmacy Registration Details')"
                        *ngIf="screennames?.screen_name == 'inactivelist' ? screennames.can_update : ''">
                        <i class="fa fa-eye" aria-hidden="true"></i>
                      </button>
                    </td> -->
                    <td>
                      <button  class="btn btn-primary btn-sm"
                        (click)="openModal(item,'pharmacy','Pharmacy Registration Details')"
                        >
                        <i class="fa fa-eye"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>

              
              <table datatable  [dtOptions]="dtOptions3" *ngIf="dtRender3" id="lab" hidden class="table table-striped table-bordered table-sm">
                <thead>
                  <tr>
                    <th>S No</th>
                    <th *ngFor="let head of headerList;">{{head}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of dataList;let ind=index">
                    <td style="width: 7%;" class="text-center">{{(ind+1)}}</td>
                    <td class="text-uppercase"  *ngIf="isukhub">{{item?.district_name}}</td>
                    <td class="text-uppercase"  *ngIf="isukhub">{{item?.mandal_name}}</td>
                    <td class="text-uppercase"  *ngIf="isukhub">{{item?.center_name}}</td>
                    <td class="text-uppercase">{{item?.lab_name}}</td>
                    <td>{{item?.registration_no}}</td>
                    <td>
                      <input type="checkbox" [checked]="item?.registration_exp_date!=null" disabled>
                    </td>
                    <td>
                      <input type="radio" [name]="'lab'+ind" [checked]="item?.account_activated" [value]="true"
                        (change)="statusChanged(item?.id,true,item?.email,item?.contact_person_mobile,item?.lab_name,'')">Active
                      <input type="radio" [name]="'lab'+ind" [checked]="!item?.account_activated" [value]="false"
                        (change)="statusChanged(item?.id,false,item?.email,item?.contact_person_mobile,item?.lab_name,'')">In Active
                    </td>                  
                    <td>
                      <button type="button" class="btn btn-primary btn-sm"
                        (click)="openModal(item,'lab','Lab Registration Details')"
                        *ngIf="screennames?.screen_name == 'inactivelist' ? screennames.can_update : ''">
                        <i class="fa fa-eye" aria-hidden="true"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table datatable  [dtOptions]="dtOptions5" *ngIf="dtRender5" id="company" hidden class="table table-striped table-bordered table-sm" style="width:100%">
                <thead>
                  <tr>
                    <th>S No</th>
                    <th *ngFor="let head of headerList;">{{head}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of dataList;let ind=index">
                    <td style="width: 7%;" class="text-center">{{(ind+1)}}</td>
                    <td class="text-uppercase">{{item?.company_name}}</td>
                    <td>{{item?.registration_no}}</td>
                    <td>
                      <input type="radio" [name]="'company'+ind" [checked]="item?.is_active" [value]="true"
                        (change)="statusChanged(item?.id,true,'','','','')">Active
                      <input type="radio" [name]="'company'+ind" [checked]="!item?.is_active" [value]="false"
                        (change)="statusChanged(item?.id,false,'','','','')">In Active
                    </td>
                    <td>
                      <button type="button" class="btn btn-primary btn-sm"
                        (click)="openModal(item,'company','Company Registration Details')"
                        *ngIf="screennames?.screen_name == 'inactivelist' ? screennames.can_update : ''">
                        <i class="fa fa-eye" aria-hidden="true"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table datatable  [dtOptions]="dtOptions4" *ngIf="dtRender4" id="hospital" hidden class="table table-striped table-bordered table-sm" style="width:100%">
                <thead>
                  <tr>
                    <th>S No</th>
                    <th *ngFor="let head of headerList;">{{head}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of dataList;let ind=index">
                    <td style="width: 7%;" class="singleLine text-center">{{(ind+1)}}</td>
                    <td class="text-uppercase"  *ngIf="isukhub">{{item?.district_name}}</td>
                    <td class="text-uppercase"  *ngIf="isukhub">{{item?.mandal_name}}</td>
                    <td class="text-uppercase"  *ngIf="isukhub">{{item?.center_name}}</td>
                    <td class="singleLine uppercase">{{item?.hospital_name}}</td>
                    <td>{{item?.registration_no}}</td>
                    <td class="singleLine">
                      <input type="radio" [name]="'hospital'+ind" [checked]="item?.account_activated" [value]="true"
                        (change)="statusChanged(item?.id,true,item?.contact_person_email,item?.contact_person_phone_number,item?.hospital_name,'')">Active
                      <input type="radio" [name]="'hospital'+ind" [checked]="!item?.account_activated" [value]="false"
                        (change)="statusChanged(item?.id,false,item?.contact_person_email,item?.contact_person_phone_number,item?.hospital_name,'')">In Active
                    </td>
                    <td class="singleLine text-center">
                      <button type="button" class="btn btn-primary btn-sm"
                        (click)="openModal(item,'hospital','Hospital Registration Details')"
                        *ngIf="screennames?.screen_name == 'inactivelist' ? screennames.can_update : ''">
                        <i class="fa fa-eye" aria-hidden="true"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-footer text-center">
            <div *ngIf="selectedTest!='doctor'">
              <button class="btn btn-success btn-sm" (click)="submit()" [disabled]="checkedList.length==0"
              *ngIf="screennames?.screen_name == 'inactivelist' ? screennames.can_insert : ''">Submit</button>
            </div>
           <div *ngIf=" selectedTest=='doctor'">
            <button class="btn btn-success btn-sm" (click)="submit()" [disabled]="chkdLst.length==0"
            *ngIf="screennames?.screen_name == 'inactivelist' ? screennames.can_insert : ''">Submit</button>
           </div >
        
          </div>
        </div>
      </div>
    </div>
  </div>
</div>