<input type="button" id="btnMissCallCount" class="d-none" (click)="GetMisCalData()">
<div>
  <nav [hidden]="!isDoctor" class="topnav navbar navbar-expand shadow navbar-light bg-white" id="sidenavAccordion">
    <a class="navbar-brand" [routerLink]="[]">
      <img [src]="logo" class="img-fluid brand_logo" />
    </a>
    <button class="btn btn-icon btn-transparent-dark order-1 order-lg-0 mr-lg-2" id="sidebarToggle">
      <i class="fa fa-bars"></i></button>
    <!-- <button data-toggle="modal" data-placement="top" title="Chat" data-toggle="modal" data-target="#chatmodel" style="margin-left: 34rem;"
      [style.background-color]="style1 ? 'red' : (style2 ? 'blue' : null)" class="btn btn-blue btn-icon float-right"> <i
        class="fas fa-comment"></i>
    </button> -->
    <ul class="navbar-nav align-items-start" *ngIf="roleId==1 && isGlblOrgShow">
      <li class="list-inline-item mr-3">
        <div class="form-group mb-0">
          <!-- [attr.disabled]="true" -->
          <label for="homeOrg mb-0 font-weight-bold"
            style="margin-bottom: 0px !important;font-weight: bold !important;">Organization</label>
          <select class="form-control form-control-sm text-uppercase" id="homeOrg"
            (change)="onOrgChng($event.target.value)">
            <!-- <option class="text-uppercase"  [value]="" selected [attr.disabled]="roleId==1? true : null">AXA</option> -->
            <option class="text-uppercase" *ngFor="let org of orgList" [value]="org.id">{{org.orgnization_name}}
            </option>
          </select>
        </div>
      </li>
    </ul>
    <ul class="navbar-nav align-items-center ml-auto">
      <li class="list-inline-item pointer" title="Missed Call" (click)="onMsdCal()" style="color: #3291d4"
        *ngIf="+roleId==7"><i class="fa fa-phone fa-1x" aria-hidden="true"></i>
        <span class="text-danger" style="font-weight: bold;" *ngIf="msdCalVal!=0"> ({{msdCalVal}})</span>
      </li>
      <li class="list-inline-item d-none d-sm-none d-md-block">
        <p class="text-primary mb-0"><strong>Welcome, {{userName}}<span *ngIf="roleId==7">{{hub_name}}</span>
          </strong></p>
        <p>
          <span class="mb-0 text-right"><strong>({{role_name}}{{lcnsPlanName}})</strong></span>
        </p>
      </li>
      <li class="nav-item dropdown no-caret mr-3 dropdown-user d-none d-sm-none d-md-block"> <a
          class="btn btn-icon btn-transparent-dark dropdown-toggle" id="navbarDropdownUserImage"
          href="javascript:void(0);" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <img class="img-fluid" src="{{image_path}}" />
          <!-- <img class="img-fluid" src="https://via.placeholder.com/60" /> -->
        </a>
        <div class="dropdown-menu dropdown-menu-right border-0 shadow animated--fade-in-up"
          aria-labelledby="navbarDropdownUserImage">
          <!-- <h6 class="dropdown-header d-flex align-items-center"> <img class="dropdown-user-img rounded"
              src="https://source.unsplash.com/QAB-WJcbgJk/60x60" />
            <div class="dropdown-user-details">
              <div class="dropdown-user-details-name">Valerie Luna</div>
              <div class="dropdown-user-details-email">vluna@aol.com</div>
            </div>
          </h6> -->
          <!-- <a class="btn btn-sm " [routerLink]="['/home/changepassword']"><i class="fa fa-key"></i>&nbsp; &nbsp; Change Password</a> -->
          <a class="btn btn-sm " (click)="openModal()"><i class="fa fa-key"></i>&nbsp; &nbsp; Change Password</a>
          <div class="dropdown-divider"></div>
          <a class="btn btn-sm " (click)="onUsrPrfl()"><i class="fa fa-user"></i>&nbsp; &nbsp; User Profile</a>
          <div class="dropdown-divider"></div>
          <!-- <a class="nav-link" >
            <div class="dropdown-item-icon"><i data-feather="settings"></i></div>Change  Password </a><a class="dropdown-item" >
            <div class="dropdown-item-icon" (click)="logOut()"><i data-feather="log-out"></i></div> Logout
          </a> -->
          <a (click)="logOut()" class="btn btn-sm pointer"><i class="fa fa-sign-out-alt"></i>&nbsp; &nbsp; Logout</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item dropitemalign"><i class="fa fa-code-fork" aria-hidden="true"></i>Version
            {{version}}</a>
        </div>
      </li>
    </ul>
  </nav>
  <nav *ngIf="isDoctor" class="navbar navbar-expand  navbar-light bg-white mt-5 d-block d-sm-block d-md-none"
    id="sidenavAccordion">
    <ul class="navbar-nav align-items-right small pt-1 pb-1">
      <!-- <li class="nav-item  no-caret mr-3 text-dark">
        <a href=""  style="color: #000000 !important;">Uma Mahesh (Doctor)</a>     
    </li> -->
      <li class="list-inline-item text-right">
        <p class="text-primary mb-0"><strong>Welcome, {{userName}} <span *ngIf="roleId==7">{{hub_name}}</span>,
            ({{role_name}}) </strong></p>
        <!-- <span class="text-info subcenter">Yashoda Hospital, Hyderbad</span> -->
      </li>
      <li class="nav-item dropdown no-caret mr-3 dropdown-user"> <a class="btn p-0 dropdown-toggle"
          id="navbarDropdownUserImage" href="javascript:void(0);" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          <!-- <img class="img-fluid" src="https://via.placeholder.com/60"/>  -->
          <i class="fa fa-angle-down text-dark"></i>
        </a>
        <div class="dropdown-menu dropdown-menu-right border-0 shadow animated--fade-in-up"
          aria-labelledby="navbarDropdownUserImage">
          <!-- <h6 class="dropdown-header d-flex align-items-center"> <img class="dropdown-user-img rounded"
              src="https://source.unsplash.com/QAB-WJcbgJk/60x60" />
            <div class="dropdown-user-details">
              <div class="dropdown-user-details-name">Valerie Luna</div>
              <div class="dropdown-user-details-email">vluna@aol.com</div>
            </div>
          </h6> -->
          <!-- <a class="btn btn-sm " [routerLink]="['/home/changepassword']"><i class="fa fa-key"></i>&nbsp; &nbsp; Change Password</a> -->
          <a class="btn btn-sm " (click)="openModal()"><i class="fa fa-key"></i>&nbsp; &nbsp; Change Password</a>
          <div class="dropdown-divider"></div>
          <a class="btn btn-sm " (click)="onUsrPrfl()"><i class="fa fa-user"></i>&nbsp; &nbsp; User Profile</a>
          <div class="dropdown-divider"></div>
          <!-- <a class="nav-link" >
            <div class="dropdown-item-icon"><i data-feather="settings"></i></div>Change  Password </a><a class="dropdown-item" >
            <div class="dropdown-item-icon" (click)="logOut()"><i data-feather="log-out"></i></div> Logout
          </a> -->
          <a (click)="logOut()" class="btn btn-sm pointer"><i class="fa fa-sign-out-alt"></i>&nbsp; &nbsp; Logout</a>
        </div>
      </li>
    </ul>
  </nav>
  <!-- Doctor -->
  <div *ngIf="!isDoctor">
    <nav
      class="navbar navbar-expand navbar-dark fixed-top headerbg pt-0 pb-0 pl-0 shadow-sm d-none d-sm-none d-md-block">
      <a class="navbar-brand pt-0 pb-0" href="#"><img [src]="logo1" /></a>
      <!-- <button class="navbar-toggler bg-success" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation"> <span class="navbar-toggler-icon"></span> </button> -->
      <div class="collapse navbar-collapse float-right" id="navbarCollapse">
        <ul class=" "></ul>
        <div class="navbar-collapse m-auto navbar-nav menufont">
          <div class="navbar-collapse m-auto ">
            <ul class="nav navbar ml-auto list-inline">
              <li class="list-inline-item"><span class="text-white">Welcome,</span> <span class="text-success">
                  <strong>{{userName}}<span *ngIf="roleId==7">{{hub_name}}</span>
                    ({{role_name}}{{lcnsPlanName}})</strong></span><br>
                <!-- <span class="text-info subcenter">Yashoda Hospital, Hyderbad</span> -->
              </li>
              <li class="list-inline-item nav-item dropdown"> <a href="#" class="dropdown-toggle" id="navDropDownLink"
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <img
                    src="{{image_path}}" class="user"> </a>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navDropDownLink"> <a
                    class="dropdown-item dropitemalign" (click)="fromPOCUserProfile()"><i class="fas fa-user"> </i>User
                    Profile</a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item dropitemalign pointer" (click)="logOut()">
                    <i class="fas fa-sign-out-alt fontopacity"></i> Logout</a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item dropitemalign"><i class="fas fa-code-fork"></i>Version {{version}}</a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
    <div class="fixed-top headerbg pt-0 pb-0 pl-0 shadow-sm d-block d-sm-block d-md-none">
      <div class="row">
        <div class="col-12"> <a class="navbar-brand pt-0 pb-0" href="#"><img [src]="logo1" class="img-fluid" /></a>
        </div>
        <div class="col-12">
          <div class="navbar-collapse m-auto ">
            <ul class="nav navbar ml-auto list-inline">
              <li class="list-inline-item" style="font-size:12px;"><span class="text-white">Welcome,</span> <span
                  class="text-success">
                  <strong>{{userName}}<span *ngIf="roleId==7">{{hub_name}}</span>
                    ({{role_name}})</strong></span><br>
                <!-- <span class="text-info subcenter">Yashoda Hospital, Hyderbad</span> -->
              </li>
              <li class="list-inline-item nav-item dropdown"> <a href="#" class="dropdown-toggle" id="navDropDownLink"
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <img
                    src="../../../assets/images/ph60.png" class="user"> </a>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navDropDownLink"> <a
                    class="dropdown-item dropitemalign" href="#"><i class="fas fa-user"> </i>
                    Profile</a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item dropitemalign pointer" (click)="logOut()"><i
                      class="fas fa-sign-out-alt fontopacity"></i>
                    Logout</a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item dropitemalign"><i class="fas fa-code-fork"></i>Version {{version}}</a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="layoutSidenav">
    <div *ngIf="isDoctor" id="layoutSidenav_nav">
      <nav class="sidenav shadow-right sidenav-light">
        <div class="sidenav-menu mt-3">
          <div class="nav accordion" id="accordionSidenav">



            <ng-container *ngFor="let menu of menudetails let i=index">
              <a class="nav-link collapsed" href="javascript:void(0);" data-toggle="collapse" aria-expanded="false"
                href="{{'#m' + '-' + menu.moduleId}}" id="{{'#m' + '-' + menu.moduleId}}"
               >
                <div class="nav-link-icon"><i data-feather="admin"></i>
                </div>
                {{menu?.moduleDesc}}
                <div class="sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
              </a>

              <div class="collapse" id="{{'m' + '-' + menu.moduleId}}" data-parent="#accordionSidenav"
                CustomDerDirective>
                <nav class="sidenav-menu-nested nav" *ngFor="let sm of menu?.subMenu;let j =index">

                  <a class="nav-link menu" id="drilldownMenu" routerLink="{{sm?.routing}}" routerLinkActive="active"
                    (click)="shMenu();sidemenu(sm,'home');" *ngIf="sm.screen_name!='help' && sm.screen_name!='homescreen' 
                    && sm.screen_name!='videoconsultation'  && sm.screen_name!='adminusermanual' &&sm.screen_name!='doctorusermanual' ">
                    <i class="{{sm?.fafa_icon}}"></i>&nbsp;&nbsp;<span>{{sm?.screen_label}}</span> </a>

                  <a class="nav-link menu" id="drilldownMenu" [href]="sm?.routing" *ngIf="sm.screen_name=='help'"
                    target="_blank" (click)="shMenu();sidemenu(sm,'home');">
                    <i class="{{sm?.fafa_icon}}"></i>&nbsp;&nbsp;<span>{{sm?.screen_label}}</span> </a>

                  <a class="nav-link menu" id="drilldownMenu" [href]="sm?.routing" *ngIf="sm.screen_name=='homescreen'"
                    target="_blank" (click)="shMenu();sidemenu(sm,'home');">
                    <i class="{{sm?.fafa_icon}}"></i>&nbsp;&nbsp;<span>{{sm?.screen_label}}</span> </a>

                    <a class="nav-link menu" id="drilldownMenu" [href]="sm?.routing" *ngIf="sm.screen_name=='videoconsultation'"
                    target="_blank" (click)="shMenu();sidemenu(sm,'home');">
                    <i class="{{sm?.fafa_icon}}"></i>&nbsp;&nbsp;<span>{{sm?.screen_label}}</span> </a>

                    <a class="nav-link menu" id="drilldownMenu" [href]="sm?.routing" *ngIf="sm.screen_name=='adminusermanual'"
                    target="_blank" (click)="shMenu();sidemenu(sm,'home');">
                    <i class="{{sm?.fafa_icon}}"></i>&nbsp;&nbsp;<span>{{sm?.screen_label}}</span> </a>

                    <a class="nav-link menu" id="drilldownMenu" [href]="sm?.routing" *ngIf="sm.screen_name=='doctorusermanual'"
                    target="_blank" (click)="shMenu();sidemenu(sm,'home');">
                    <i class="{{sm?.fafa_icon}}"></i>&nbsp;&nbsp;<span>{{sm?.screen_label}}</span> </a>
                </nav>

              </div>
            </ng-container>

            <!-- <h6>&nbsp;&nbsp;&nbsp;User Manual</h6> -->
            <label class="mt-2" (click)="menuShow()">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;User Manual</label>
            <div *ngIf="menusShow">
            <ng-container *ngFor="let menu of menudetails let i=index" >
              <a class="nav-link collapsed" href="javascript:void(0);" data-toggle="collapse" aria-expanded="false"
                href="{{'#mm' + '-' + menu.moduleId}}" id="{{'#mm' + '-' + menu.moduleId}}"
               >
                <div class="nav-link-icon"><i data-feather="admin"></i>
                </div>
                {{menu?.moduleDesc}}
                <div class="sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
              </a>

              <div class="collapse" id="{{'mm' + '-' + menu.moduleId}}" data-parent="#accordionSidenav"
                CustomDerDirective>
                <nav class="sidenav-menu-nested nav" *ngFor="let sm of menu?.subMenu;let j =index">

                  <a class="nav-link menu" id="drilldownMenu"   routerLink="/home/usg/{{sm.screen_label}}/{{sm.screen_id}}/{{sm.module_id}}" 
                    *ngIf="sm.screen_name!='help' && sm.screen_name!='homescreen' 
                    && sm.screen_name!='videoconsultation'  && sm.screen_name!='adminusermanual' &&sm.screen_name!='doctorusermanual' ">
                    <i class="{{sm?.fafa_icon}}"></i>&nbsp;&nbsp;<span>{{sm?.screen_label}}</span> </a>

                  <!-- <a class="nav-link menu" id="drilldownMenu" [href]="sm?.routing" *ngIf="sm.screen_name=='help'"
                    target="_blank" (click)="shMenu();sidemenu(sm,'home');">
                    <i class="{{sm?.fafa_icon}}"></i>&nbsp;&nbsp;<span>{{sm?.screen_label}}</span> </a>

                  <a class="nav-link menu" id="drilldownMenu" [href]="sm?.routing" *ngIf="sm.screen_name=='homescreen'"
                    target="_blank" (click)="shMenu();sidemenu(sm,'home');">
                    <i class="{{sm?.fafa_icon}}"></i>&nbsp;&nbsp;<span>{{sm?.screen_label}}</span> </a>

                    <a class="nav-link menu" id="drilldownMenu" [href]="sm?.routing" *ngIf="sm.screen_name=='videoconsultation'"
                    target="_blank" (click)="shMenu();sidemenu(sm,'home');">
                    <i class="{{sm?.fafa_icon}}"></i>&nbsp;&nbsp;<span>{{sm?.screen_label}}</span> </a>

                    <a class="nav-link menu" id="drilldownMenu" [href]="sm?.routing" *ngIf="sm.screen_name=='adminusermanual'"
                    target="_blank" (click)="shMenu();sidemenu(sm,'home');">
                    <i class="{{sm?.fafa_icon}}"></i>&nbsp;&nbsp;<span>{{sm?.screen_label}}</span> </a>

                    <a class="nav-link menu" id="drilldownMenu" [href]="sm?.routing" *ngIf="sm.screen_name=='doctorusermanual'"
                    target="_blank" (click)="shMenu();sidemenu(sm,'home');">
                    <i class="{{sm?.fafa_icon}}"></i>&nbsp;&nbsp;<span>{{sm?.screen_label}}</span> </a> -->
                </nav>

              </div>
            </ng-container>
          </div>



            <div *ngIf="isUkt && nameArray && nameArray?.length !=0 && roleId ==7">
              <h1 class="ml-5"> Chat List</h1>
              <div class="ml-3" id="chatalign" *ngFor="let item of nameArray;let ind=index;">
                <span style="margin-left: 20px;font-size: medium;"
                  (click)="benId(item.beneficiary_id,item.patient_name)"
                  *ngIf="item.usertype !='Doctor' && item.doctor_id == drData.id">{{item.patient_name}} <span
                    data-toggle="modal" data-placement="top" title="Chat" data-toggle="modal"
                    data-target="#chatmodel1"><i class="fa fa-paper-plane" aria-hidden="true"></i></span></span>
              </div>
            </div>
          </div>
          <div class="collapse" id="collapseFlows" data-parent="#accordionSidenav">
            <nav class="sidenav-menu-nested nav"><a class="nav-link" href="multi-tenant-select.html">Multi-Tenant
                Registration</a></nav>
          </div>
        </div>
      </nav>
    </div>
    <!-- <div id="layoutSidenav_content" [ngClass]="{'padding-left':isDoctor?'15rem':'0rem'}"> -->
    <div id="layoutSidenav_content" [ngClass]="!isDoctor?'padding1':'padding2'">
      <main>
        <div *ngIf="isDoctor" class="page-header pb-10 page-header-dark bg-gradient-primary-to-secondary">
          <div class="container-fluid">
            <div class="page-header-content">
              <h1 class="page-header-title d-block">
                <div class="page-header-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" stroke-width="2"
                    stroke-linejoin="round" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-linecap="round"
                    class="feather feather-activity">
                    <polyline points="22 12 18 12 15 21 9 3 6 12 2 12"></polyline>
                  </svg>
                </div>
                <span>Welcome to {{clientName}}</span>
                <span class="float-xl-right float-lg-right float-md-right mx-auto mr-0 mb-2" *ngIf="isShowAPK">
                  <!-- <input type="text" value="https://apps.apple.com/us/app/dhanush-vidmed/id1516571105/eshwar" #apple hidden> -->
                  <span class="d-none d-sm-none d-md-none d-lg-none d-xl-block">
                    <img src="./../../../assets/vmLogos/apk_pics/app_store.png" title="Click to Copy"
                      class="pointer mr-1" (click)="onAPK('a')"> <!-- onPS , apple -->
                    <img src="./../../../assets/vmLogos/apk_pics/play_store.png" title="Click to Copy"
                      class="pointer mr-1" (click)="onAPK('g')">
                  </span>
                </span>
              </h1>
              <div class="d-block d-sm-block d-md-block d-lg-block d-xl-none mb-2">
                <img src="./../../../assets/vmLogos/apk_pics/app_store.png" title="Click to Copy" class="pointer mr-1"
                  (click)="onAPK('a')"> <!-- onPS , apple -->
                <img src="./../../../assets/vmLogos/apk_pics/play_store.png" title="Click to Copy" class="pointer mr-1"
                  (click)="onAPK('g')">
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid " style="margin-top: -7rem ;">
          <router-outlet></router-outlet>
        </div>
      </main>
      <footer class="footer mt-auto footer-light mb-5" id="footerHome">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6 small"> <span *ngIf="!isNIG">Copyright {{current_year}} &copy;Dhanush InfoTech. All
                rights reserved</span> </div> <!-- {{clientName}} -->
            <div class="col-md-6 small">
              <div class="supportHome">
                <h6>For Support, Please contact {{clientName}}</h6>
                <div class="text-right"><a class="btn btn-info" (click)="onTollFree()">
                    <i class="fa fa-phone" aria-hidden="true"></i>&nbsp;&nbsp;{{tollFree}}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </div>
</div>
<button id="logOut" style="display: none;" (click)="logOut(true)"></button>
<div class="modal fade" data-backdrop="static" data-keyboard="false" style="z-index: 99999 !important;" id="chatmodel"
  tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div style="margin-left: 340px;">
          <b>Chat</b>
        </div>

        <button type="button" id="closeid" class="close" (click)="close1()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div id="chatalign" *ngFor="let item of nameArray;let ind=index;">
        <span style="margin-left: 20px;font-size: medium;" (click)="benId(item.beneficiary_id,item.patient_name)"
          *ngIf="item.usertype !='Doctor'">{{item.patient_name}} <span data-toggle="modal" data-placement="top"
            title="Chat" data-toggle="modal" data-target="#chatmodel1"><i class="fa fa-paper-plane"
              aria-hidden="true"></i></span></span>
      </div>


    </div>
  </div>
</div>
<div class="modal fade" data-backdrop="static" data-keyboard="false" style="z-index: 99999 !important;" id="chatmodel1"
  tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div style="margin-left: 340px;">
          <b>Chat</b>
        </div>

        <button type="button" id="closeid" class="close" (click)="close()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <ng-container id="chatalign" *ngFor="let item of chatArray;let ind=index;">
        <!-- <span style="margin-left: 20px;font-size: medium;" *ngIf="item.beneficiary_id == benificiary_id || item.usertype == 'Doctor'">{{item.message}}</span> -->
        <p class="patstyle rounded-pill" *ngIf="item.beneficiary_id == benificiary_id && item.usertype != 'Doctor' "
          id="chatShow"><b>{{item.usertype}}:</b>&nbsp;&nbsp;{{item.message}}</p>
        <!-- <span *ngIf="item.usertype == 'Patient'"><br></span>  -->
        <p class="docstyle rounded-pill"
          *ngIf="item.usertype == 'Doctor' && item.beneficiary_id == benificiary_id && item.doctor_id == drData.id"
          id="chatShoww"><b>{{item.usertype}}:</b>&nbsp;&nbsp;{{item.message}} </p>
        <!-- <span *ngIf="item.usertype == 'Doctor'"><br></span>  -->
        <!-- <span>{{msgDate | date:'dd-MM-yyyy HH:mm a'}}</span> -->
      </ng-container>
      <form (ngSubmit)="sendMessage($event)">
        <div class="modal-footer">
          <div class='col-md-5'>
            <div class="form-group">
              <div class="input_wrapper2">
                <textarea cols="4" rows="4" id="search_string" name="search_string" type="text"
                  [(ngModel)]="messagechat" autocomplete="off" class="form-control" placeholder="Chat Here"></textarea>
                <!-- <input id="search_string" name="search_string" type="text" [(ngModel)]="messagechat" autocomplete="off" 
                  class="form-control" placeholder="Chat Here"> -->
                <div class="unit" *ngIf="messagechat !=''">
                  <button type="submit" class="btn btn-sm btn-primary"><i class="fa fa-paper-plane"
                      aria-hidden="true"></i></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <button type="button" style="display: none;" id="btn_logOut" (click)="forceLogout()"></button>
</div>