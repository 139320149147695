<div class="modal-header">
    <h4 class="modal-title">DSS Details</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click');">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="container-fluid">
        <div>
            <form [formGroup]="dssForm">
                <!-- <div class="form-group">
                    <label class="control-label"> Member Type</label>
                    <div>
                        <input type="radio" formControlName="is_active" [value]="true">Self
                        <input class="ml-3" type="radio" formControlName="is_active" [value]="false">Family Member
                    </div>
                </div> -->
                <div class="form-group">
                    <label class="control-label"> Any Symptoms</label>
                    <div>
                        <input type="radio" formControlName="is_symptoms" [value]="true"  (change)="isShow=false">  Yes
                        <input class="ml-3" type="radio" formControlName="is_symptoms" [value]="false" (change)="isShow=true">  No
                    </div>
                </div>
                <div class="row" *ngIf="isShow">
                    <div class="col-xl-3">
                        <div class="form-group">
                            <label class="control-label">Gestational Age in Week</label>
                            <div>
                                <input type="text" autocomplete="off" formControlName="gestational_age" maxlength="20"
                                    class="form-control form-control-sm" id="gestational_age">
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3">
                        <div class="form-group">
                            <label class="control-label">pulse in one min</label>
                            <div>
                                <input type="text" autocomplete="off" formControlName="pulse_in_one_min" maxlength="20"
                                    class="form-control form-control-sm" id="pulse_in_one_min">
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3">
                        <div class="form-group">
                            <label class="control-label">BP (Diastolic)</label>
                            <div>
                                <input type="text" autocomplete="off" formControlName="diastolic" maxlength="20"
                                    class="form-control form-control-sm" id="diastolic">
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3">
                        <div class="form-group">
                            <label class="control-label">BP (Systolic)</label>
                            <div>
                                <input type="text" autocomplete="off" formControlName="systolic" maxlength="20"
                                    class="form-control form-control-sm" id="systolic">
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3">
                        <div class="form-group">
                            <label class="control-label">Haemoglobin</label>
                            <div>
                                <input type="text" autocomplete="off" formControlName="haemoglobin" maxlength="20"
                                    class="form-control form-control-sm" id="haemoglobin">
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3">
                        <div class="form-group">
                            <label class="control-label">Urine Albumin</label>
                    <div>
                        <input type="radio" formControlName="urine_albunim" [value]="true">  Present
                        <input class="ml-3" type="radio" formControlName="urine_albunim" [value]="false">  Absent
                    </div>
                        </div>
                    </div>
                    <div class="col-xl-3">
                        <div class="form-group">
                            <label class="control-label">HIV</label>
                    <div>
                        <input type="radio" formControlName="hiv" [value]="true">  Positive
                        <input class="ml-3" type="radio" formControlName="hiv" [value]="false">  Negative
                    </div>
                        </div>
                    </div>
                    <div class="col-xl-3">
                        <div class="form-group">
                            <label class="control-label">Syphilis</label>
                    <div>
                        <input type="radio" formControlName="syphilis" [value]="true">  Positive
                        <input class="ml-3" type="radio" formControlName="syphilis" [value]="false">  Negative
                    </div>
                        </div>
                    </div>
                    <div class="col-xl-3">
                        <div class="form-group">
                            <label class="control-label">OGTT Sugar</label>
                    <div>
                        <input type="radio" formControlName="ogt_sugar" [value]="true">  Present
                        <input class="ml-3" type="radio" formControlName="ogt_sugar" [value]="false">  Absent
                    </div>
                        </div>
                    </div>
                    <div class="col-xl-3">
                        <div class="form-group">
                            <label class="control-label">FHS In One Min</label>
                            <div>
                                <input type="text" autocomplete="off" formControlName="fhs_in_one_min" maxlength="20"
                                    class="form-control form-control-sm" id="fhs_in_one_min">
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3">
                        <div class="form-group">
                            <label class="control-label">Spo2</label>
                            <div>
                                <input type="text" autocomplete="off" formControlName="spo2" maxlength="20"
                                    class="form-control form-control-sm" id="spo2">
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3">
                        <div class="form-group">
                            <label class="control-label">Blood Sugar</label>
                            <div>
                                <input type="text" autocomplete="off" formControlName="blood_sugar" maxlength="20"
                                    class="form-control form-control-sm" id="blood_sugar">
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3">
                        <div class="form-group">
                            <label class="control-label">MAlaria RDT</label>
                    <div>
                        <input type="radio" formControlName="malaria" [value]="true">  Positve
                        <input class="ml-3" type="radio" formControlName="malaria" [value]="false">  Negative
                    </div>
                        </div>
                    </div>
                    <div class="col-xl-3">
                        <div class="form-group">
                            <label class="control-label">Weight Just Before Pregnancy</label>
                            <div>
                                <input type="text" autocomplete="off" formControlName="weight_just_before_pregnancy" maxlength="20"
                                    class="form-control form-control-sm" id="weight_just_before_pregnancy">
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3">
                        <div class="form-group">
                            <label class="control-label">Current Weight</label>
                            <div>
                                <input type="text" autocomplete="off" formControlName="current_weight" maxlength="20"
                                    class="form-control form-control-sm" id="current_weight">
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3">
                        <div class="form-group">
                            <label class="control-label"> Height</label>
                            <div>
                                <input type="text" autocomplete="off" formControlName="height" maxlength="20"
                                    class="form-control form-control-sm" id="height">
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3">
                        <div class="form-group">
                            <label class="control-label">Urine Sugar</label>
                            <div>
                                <input type="text" autocomplete="off" formControlName="urine_Sugar" maxlength="20"
                                    class="form-control form-control-sm" id="urine_Sugar">
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3">
                        <div class="form-group">
                            <label class="control-label">Fundal Height</label>
                            <div>
                                <input type="text" autocomplete="off" formControlName="fundal_height" maxlength="20"
                                    class="form-control form-control-sm" id="fundal_height">
                            </div>
                        </div>
                    </div>
                </div>
            </form>


        </div>
    </div>
</div>
<!-- <div class="modal-footer">
    <input type="submit" [disabled]="sbmtDsbld?true:null" class="btn btn-sm btn-primary" (click)="submitForm()"
        value="Submit">&nbsp;&nbsp;
    <button type="button" class="btn btn-sm btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
</div> -->