import { Component, OnInit, Input, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { MasterService } from 'src/app/shared/services/master.service';
import { FileService } from 'src/app/shared/services/file.service';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { ImagePreviewComponent } from 'src/app/modules/modals/image-preview/image-preview.component';
import { environment } from 'src/environments/environment';
import { Common } from 'src/app/common';
import { UserService } from 'src/app/shared/services/user.service';
import { AyushDoctorRegistrationComponent } from '../../ayush/ayush-doctor-registration/ayush-doctor-registration.component';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
declare const updateStatus: any;
declare let $: any;
declare const fnEncryption: any;
@Component({
  selector: 'app-common-doctor-registration',
  templateUrl: './common-doctor-registration.component.html',
  styleUrls: ['./common-doctor-registration.component.css'],
  providers: [DatePipe]
})
export class CommonDoctorRegistrationComponent implements OnInit {

  @Input() title: string;
  @Input() doctor: any;
  @Input() isSubmit_Disabled: boolean = false;
  @Input() isEditable: boolean = false;

  // HPR started
  addOrgTableData: any = [];
  declarationBox: boolean = true;
  VisblePrfPulc: any = '1';
  assistedHPR: any = 'Yes';
  telconsulURL: any = '';
  orgStateCode: any = "";
  orgDistrictCode: any = "";
  nameOfOrganization: any = '';
  organizationType: any = '';
  departmentName: any = '';
  designation: any = '';
  orgAddress: any = '';
  orgPinCode: any = '';
  stateCode: any = "";
  districtCode: any = "";
  subDistrictCode: any = "";
  searchWith: boolean = true;
  hprownershipCode: any = "";
  facilityName: any = ""
  hprForm: FormGroup;
  hprForm2: FormGroup;
  hprForm3: FormGroup;
  hprForm4: FormGroup;
  FacilityId: any = "";
  selectFacility: any;
  hprForm1: FormGroup;
  isHPR: boolean;
  entrOTP: boolean = true;
  istermsChecked: boolean = false;
  otpRes: any;
  enableBtn: boolean = true;
  resend_otp: boolean;
  display: any;
  verifyRes: any;
  rfrshToken: any;
  genOTP: boolean;
  displayTimer: string;
  getOtpBtn: boolean;
  resendMobileOtp: boolean;
  genOTPRes: any;
  mobileLinked: boolean = true;
  verOTP: boolean;
  showFields: boolean;
  storeListdata: any;
  suggestionList = [];
  suggestionId: any;
  verifyMobile: any;
  generateMobileData: any;
  preverify: any;
  ownerTypeList: any;
  facilitySearchList: any = [];
  dtRenderF: boolean;
  SelectFacilityRadio: boolean;
  hprId: any;
  hprNumber: any;
  facilityNme: any;
  publicMobileNumberStatus: any
  publicEmailStatus: any
  publicMobileNumber: any;
  publicEmail: any
  landLineNumber: any
  // HPR ended
  // selectFacility:any;
  isMeridian: boolean = false;
  isGNM = environment.isGNM; isSAGE = environment.isSAGE; isNIG = environment.isNIG;
  doctorForm: FormGroup;
  submitted = false;
  submitted1 = false;
  max_date = new Date(new Date().setFullYear(+`${new Date().getFullYear() - 21}`));
  isEdit = false;
  maxdate: Date;
  newdepartmentList: any
  locationTypeList: Array<any> = [];
  stateList: Array<any> = [];
  districtList: Array<any> = [];
  mandalList: Array<any> = [];
  cityList: Array<any> = [];
  villageList: Array<any> = [];
  weekDaysList: Array<any> = [];
  dropdownSettings_weekDays = {};
  selectedItems = [];

  languagesList: Array<any> = [];
  dropdownSettings_language = {};
  selectedItems_language = [];
  selectedItems_module = [];

  super_SpecialisationList = [];
  is_AssistantDetails: boolean = false;

  uploadFileList: Array<{ id: number, reg_id: number, name: string, File: any, extensions: string, file_name: string, fileType: string, document_path: string }> = []
  labList: Array<any> = [];
  hospitalList: Array<any> = [];
  pharmacyList: Array<any> = [];
  genderList: Array<any> = [];
  maritalList: Array<any> = [];
  titleList: any;
  visitStatusList: any;
  addressTypesList: any;
  qualificationsList: Array<any> = [];
  specializationList: any;
  superSpecialityDegreeList: any;
  bankNamesList: any;
  departmentList: Array<any> = [];
  TENANT_ID: string = '';
  hide = 0;
  isShwDS = false; isShwHQ = false; isShwPic = false; homeAddrs = ''; clncAddrs = ''; hsptlAddrs = '';
  mobPtrn = ''; mobErrMsg = ''; maxLngth = ''; doctorlist: any[] = []; dropdownSettings_module = {};
  lstHrs: Array<any> = [{ name: '00' }, { name: '01' }, { name: '02' }, { name: '03' }, { name: '04' }, { name: '05' }, { name: '06' }, { name: '07' }, { name: '08' }, { name: '09' }, { name: '10' }, { name: '11' }, { name: '12' }, { name: '13' }, { name: '14' }, { name: '15' }, { name: '16' }, { name: '17' }, { name: '18' }, { name: '19' }, { name: '20' }, { name: '21' }, { name: '22' }, { name: '23' }];
  lstMin: Array<any> = [{ name: '00' }, { name: '01' }, { name: '02' }, { name: '03' }, { name: '04' }, { name: '05' }, { name: '06' }, { name: '07' }, { name: '08' }, { name: '09' }, { name: '10' }, { name: '11' }, { name: '12' }, { name: '13' }, { name: '14' }, { name: '15' }, { name: '16' }, { name: '17' }, { name: '18' }, { name: '19' }, { name: '20' },
  { name: '21' }, { name: '22' }, { name: '23' }, { name: '24' }, { name: '25' }, { name: '26' }, { name: '27' }, { name: '28' }, { name: '29' }, { name: '30' }, { name: '31' }, { name: '32' }, { name: '33' }, { name: '34' }, { name: '35' }, { name: '36' }, { name: '37' }, { name: '38' }, { name: '39' }, { name: '40' },
  { name: '41' }, { name: '42' }, { name: '43' }, { name: '44' }, { name: '45' }, { name: '46' }, { name: '47' }, { name: '48' }, { name: '49' }, { name: '50' }, { name: '51' }, { name: '52' }, { name: '53' }, { name: '54' }, { name: '55' }, { name: '56' }, { name: '57' }, { name: '58' }, { name: '59' }];
  isLabPrefferedMandatory: boolean;
  Flagslist: any;
  newSpecializationList: any;
  // JR Doctor 13-03-2023
  showCharge = true;
  hwList: Array<any> = [];
  hprStateList: any;
  hprDistrictList: any;
  hprSubDistrictList: any;
  salutationList: any;
  medicineList: any;
  councilList: any;
  spokenLanguagesList: any;
  dropdownSettings_spokenLanguages: { singleSelection: boolean; idField: string; textField: string; selectAllText: string; unSelectAllText: string; itemsShowLimit: number; enableCheckAll: boolean; closeDropDownOnSelection: boolean; allowSearchFilter: boolean; };
  selectedItemSpokenLng: any = [];
  hprqualificationList: any;
  hprspecializationList: any;
  isDisableMobOtp: boolean = true;
  hprsavepayload: {
    hprToken: any; practitioner: {
      healthProfessionalType: any; officialMobile: any; personalInformation: { salutation: any; firstName: any; nationality: string; languagesSpoken: any; }; addressAsPerKYC: string; communicationAddress: {
        address: string; name: string;
        // "country": "356",
        state: any; district: any; postalCode: any;
      }; registrationAcademic: {
        category: string; registrationData: {
          registeredWithCouncil: any; registrationNumber: any; isNameDifferentInCertificate: string; categoryId: string; qualifications: {
            nameOfDegreeOrDiplomaObtained: string;
            // "country": "356",
            state: any; college: string; university: string; yearOfAwardingDegreeDiploma: string; isNameDifferentInCertificate: string;
          }[];
        }[];
      }; currentWorkDetails: { currentlyWorking: string; purposeOfWork: string; chooseWorkStatus: string; facilityDeclarationData: { facilityId: string; facilityDepartment: string; facilityDesignation: string; }; };
    };
  };
  facilityDesignation: any;
  facilityDepartment: any;
  facilityId: any;
  facilityselection: boolean;
  selectedSpokenLanguages: string = "";
  languagelst: any = [];
  payLoadForHpr: any;
  saveSubmitRes: any;
  preverify1: any;
  universityList: any;
  collegeList: any;
  systemOfMedId: any;
  mobileField: boolean;
  emailField: boolean;
  selectedItemsSysOfMedicine: any = [];
  selectedSystemOfMedicinesList: string;
  selectedFilterValuesMedicine: any = [];
  dropdownSettings_Medicine: {};
  fetchDetailsList: any;
  subCategoryList: any;
  subCatcode: number;
  IsFacilityEnable: boolean = false;
  preVerifiedPayLoad: { idType: string; domainName: string; email: any; firstName: any; middleName: any; lastName: any; password: any; stateCode: any; districtCode: any; pincode: any; txnId: any; hprId: string; hpCategoryCode: number; hpSubCategoryCode: number; notifyUser: boolean; };
  fillAllFields: boolean = false;
  checkAccountRes: any;
  isSubmitButton: boolean;
  dropdownSettings_facility: { singleSelection: boolean; idField: string; textField: string; selectAllText: string; unSelectAllText: string; itemsShowLimit: number; allowSearchFilter: boolean; };
  facilityDatalist: any;
  facilitySelected: boolean;
  addressAsPerKyc: any = "";
  isAddressVerified: boolean = false;
  renewableDateEnable: boolean = false;
  fileSizeError: boolean;
  uploadcer: boolean;
  uploadDeg: boolean;
  workFieldsEnable: boolean = true;
  workStatus: boolean = true;
  isFacilityEnable: boolean = true;
  addorganization: boolean;
  dtRenderOR: boolean;
  workDetails: boolean;
  EnableLinkHPR: boolean=false;
  is1962LiveStock: boolean=false;
  docTypes: any;
  HW: boolean=false;
  isHW: boolean=false;
  AssistantDetails: boolean=false;
  wrkShiftLst: any;
  hideSpecialization: boolean=false;
  isHealthWellness: boolean=false;





  constructor(public activeModal: NgbActiveModal
    , private modalService: NgbModal
    , public changeDetection: ChangeDetectorRef
    , private toastr: ToastrService
    , private datePipe: DatePipe
    , private router: Router
    , private formBuilder: FormBuilder
    , private fileService: FileService
    , private registrationService: RegistrationService
    , private authServ: AuthenticationService
    , private registrationsrv: RegistrationService
    , private notificationService: NotificationService, private userServ: UserService
    , private masterService: MasterService) {
    this.TENANT_ID = sessionStorage.getItem('currentTenant')


  }


  ngOnInit(): void {
    console.log('res', JSON.stringify(this.doctor))
    this.authServ.mobPtrnSubject.subscribe(s => this.mobPtrn = s), this.authServ.mobErrMsgSubject.subscribe(s => this.mobErrMsg = s),
      this.authServ.maxLngthSubject.subscribe(s => this.maxLngth = s), this.defaultLocations(), this.initFormControls(), this.stateApi();
    this.setLocationTypeValidators(), this.setAssistantDetailsValidators(), this.locationChanged('', 'state'), this.formInIt();
    Common.setFocus('doctor_type_id'), this.getAllLabPharmacyHospital(); this.getAllDoctorModules(); this.salutationApi();
    this.medicineApi();this.getDoctorTypes();this.getFeaturePermission();
    this.councilApi(); this.spokenLanguages(); this.degreeDD(); this.qualificationDD(); this.ownershipApi(); this.getFacilities(); this.tenantWiseStates();
    if (this.isNIG)
      this.doctorForm.get('aadhar_number').setValidators(null), this.doctorForm.get('aadhar_number').updateValueAndValidity();
    if(this.is1962LiveStock){
      this.getwrkShftList();
        }
  }

  tenantWiseStates(){
    if(this.is1962LiveStock){
      // this.locationChanged('', 'state')
      this.doctorForm .get('state_id').setValue(121)
      this.locationChanged('121', 'district')
    }
    if(this.isHealthWellness){
      // this.locationChanged('', 'state')
      this.doctorForm .get('state_id').setValue(801)
      this.locationChanged('801', 'district')
      setTimeout(() => {
        this.doctorForm .get('district_id').setValue(4142)
        this.locationChanged('4142', 'mandal')
      }, 500);
    }
    }
  

  defaultLocations() {
    ['DigitalSignature', 'HighestQualifiedCertificate', 'BioData', 'Photo', 'audioRecord', 'AadharCard', 'PANCard'].forEach((element, ind) => this.uploadFileList.push({ id: 0, reg_id: ind + 1, name: element, File: null, extensions: '', file_name: '', fileType: ['8', '9', '4', '5', '10', '12', '13'][ind], document_path: '' }));
    ['png', 'jpg,jpeg,pdf', 'dot,dotx,doc,docx,pdf', 'jpg,jpeg,bmp', 'mp4', 'jpg,jpeg,png,pdf', 'jpg,jpeg,png,pdf'].forEach((item, i) => this.uploadFileList[i].extensions = item);

    this.dropdownSettings_language = {
      singleSelection: false,
      idField: 'id',
      textField: 'language_name',
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.dropdownSettings_spokenLanguages = {
      singleSelection: false,
      idField: 'id',
      textField: 'language_name',
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      itemsShowLimit: 3,
      enableCheckAll: true,
      closeDropDownOnSelection: true,
      allowSearchFilter: true
    };

    this.dropdownSettings_module = {
      singleSelection: true,
      idField: 'id',
      textField: 'module_name',
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      itemsShowLimit: 1,
      allowSearchFilter: true
    };

    this.dropdownSettings_weekDays = {
      singleSelection: false,
      idField: 'id',
      textField: 'weekday',
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.dropdownSettings_Medicine = {
      singleSelection: false,
      idField: 'ndhm_code',
      textField: 'system_of_medicine',
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      itemsShowLimit: 3,
      enableCheckAll: true,
      closeDropDownOnSelection: true,
      allowSearchFilter: true
    };
    this.dropdownSettings_facility = {
      singleSelection: true,
      idField: 'hfr_id',
      textField: 'hfrIdNm',
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      itemsShowLimit: 1,
      allowSearchFilter: true
    };
  }

  getUserPermission() {
    let orgIdValue = JSON.parse(sessionStorage.getItem('currentTenant'));
    this.userServ.getUserPermissionByOrgId(orgIdValue).subscribe((res: any) => {
      if (res.status == 'OK') {
        this.Flagslist = res.data;
        for (let i = 0; i < res.data.length; i++) {
          // if (res.data[i].feature_id.feature_name == "doctorPocfilter") {
          //   this.pocpatientHistory = res.data[i].patient
          // }
          if (res.data[i].feature_id.feature_name == "isLabPrefferedMandatory") {
            this.isLabPrefferedMandatory = res.data[i].patient
          }
          //this.isLabPrefferedMandatory=true
          // if (res.data[i].feature_id.feature_name == "isEnableLogos") {
          //   this.shwLogos = res.data[i].patient;

          // }
          // if (res.data[i].feature_id.feature_name == "isEnableCovid19") {
          //   this.isEnableCovid19 = res.data[i].patient
          // }
          //isEnableCovid19
          // if (res.data[i].feature_id.feature_name == "hideOtherDrugLabelinPOC") {
          //   if (res.data[i].patient) {
          //     this.hideOtherDrugLabel = true;
          //   } else {
          //     this.hideOtherDrugLabel = false;
          //   }


        }
      }
    });
  }

  initFormControls() {
    this.doctorForm = this.formBuilder.group({
      id: ['0'],
      user_id: [''],
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      birth_date: ['', Validators.required],
      gender_id: ['', Validators.required],
      mobile: ['', [Validators.required, Validators.minLength(+this.maxLngth), Validators.pattern(this.mobPtrn)]],
      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9A-Z._%+-]+@[a-z0-9A-Z.-]+\\.[a-zA-Z]{2,4}$')]],
      mci_number: ['', Validators.required],
      mci_number_state_id: ['1', Validators.required],
      department_id: [''],
      marital_status_id: ['1', Validators.required],
      qualification_id: ['', Validators.required],
      specialization_id: ['', Validators.required],
      years_experience: ['', Validators.required],
      from_time: [new Date()],
      to_time: [new Date()],
      // doctor_available_slots: this.formBuilder.group({}),
      doctor_languages: ['', Validators.required],
      doctor_address: [''],
      doctor_hospitals: [''],
      address: [''],
      age: [''],
      exclusive_patients: [true],
      pin_code: ['', [Validators.required, this.isSAGE ? Validators.pattern('^([1-9]){1,6}$') : Validators.pattern('^([1-9]){1}([0-9]){5}?$')]],
      weekday_id: [],
      doctor_type_id: ['', Validators.required],
      audio_consultation_price: [''],
      video_consultation_price: [''],
      hospital_name: [''],
      state_id: ['', Validators.required],
      location_type_id: ['', Validators.required],
      district_id: ['', Validators.required],
      mandal_id: [''],
      village_id: [''],
      city_id: [''],
      home_address: [''],
      clinic_address: [''],
      hospital_address: [''],
      hospital_working_in_presently: [''],
      other_hospital: [''],
      super_qualification_id: [''],
      super_specialization_id: [''],
      max_consultation_time: [''],
      account_activated: [true],
      send_notification_to_mobile: [false],
      bank_account_number: [''],
      branch_name: [''],
      branch_location: [''],
      ifsc_code: ['', Validators.pattern('[A-Z|a-z]{4}[0][a-zA-Z0-9]{6}$')],
      pharmacy_id: [''],
      lab_id: ['',],
      assistant_detail: [false],
      assistant_first_name: [''],
      assistant_contact_number: [''],
      assistant_email: [''],
      notification_to_assistant: [false],
      // aadhar_number: ['', Validators.pattern('[0-9]{12}$')],
      aadhar_number: [''],
      prescription_address: [''],
      reg_expiry_date: ['', Validators.required],
      login_name: [''],
      password: [''],
      longitude: "56.362",
      latitude: "56.365",
      assistant_last_name: "",

      digitalFile: ['', Validators.required],
      qualificationFile: ['', Validators.required],
      photo: [''],
      group_reference_code: [''],
      individual_reference_code: [''],
      exclusive_reference_code: [false],
      aadharFile: [''],// Validators.required
      panFile: [''],//, Validators.required],
      other_qualification: ['', Validators.maxLength(100)],
      employee_id: [''],
      doctor_modules: [[]],
      docTmplt: [''],

      start_hours: [''],
      start_minutes: [''],
      end_hours: [''],
      end_minutes: [''],
      isHW: [false],
      hlthWorker: [''],
      charge_amt: [0],
      facility_id: [''],
      facility_name: [''],
      work_shift_id:['']
    });

  }
  formInIt() {

    this.hprForm = this.formBuilder.group({
      aadhar: [''],
      otp: [''],
      adhrNum: [''],
      suggestionId: [false],
      mobileNumber: [''],
      verotp: [''],
      HprofessionalId: ['']
    })
    this.hprForm1 = this.formBuilder.group({
      aadhar: [''],
      otp: [''],
      adhrNum: [''],
      suggestionId: [""],
      mobileNumber: [''],
      verotp: [''],
      HprofessionalId: [''],
      gender: [''],
      address: [''],
      firstName: ['', Validators.required],
      middleName: [''],
      lastName: [''],
      state: ['', Validators.required],
      district: ['', Validators.required],
      healthProfessionalType: ['', Validators.required],
      healthProfessionalSubType: [''],
      birthDate: ['', Validators.required],
      mobile: ['', [Validators.required, Validators.minLength(+this.maxLngth), Validators.pattern(this.mobPtrn)]],
      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9A-Z._%+-]+@[a-z0-9A-Z.-]+\\.[a-zA-Z]{2,4}$')]],
      password: ['', Validators.required],
      confirmpassword: ['', Validators.required],
      postalCode: ['', [Validators.required, this.isSAGE ? Validators.pattern('^([1-9]){1,6}$') : Validators.pattern('^([1-9]){1}([0-9]){5}?$')]],
    })

    this.hprForm2 = this.formBuilder.group({
      salutation: ['', Validators.required],
      languagesKnown: ['', Validators.required],
      // specialization:[''],
      publicProfile: [false],
      fatherName: [''],
      motherName: [''],
      spouseName: [''],
      nationality: ['1'],
      firstName: ['', Validators.required],
      middleName: [''],
      lastName: [''],
      asPerKYCComAdd: [false],
      NameAsPerKyc: ['', Validators.required],
      country: ['1', Validators.required],
      state: ['', Validators.required],
      district: ['', Validators.required],
      subDistrict: [''],
      village: [''],
      postalCode: ['', Validators.required],
      address: ['', Validators.required],
      publicMobileNumberStatus: ['1'],
      publicEmailStatus: ['1'],
      publicMobileNumber: [''],
      publicEmail: [''],
      landLineNumber: ['']

    })
    this.hprForm3 = this.formBuilder.group({
      medicine: ['', Validators.required],
      registeredWithCouncil: ['', Validators.required],
      registrationNumber: ['', Validators.required],
      registrationDate: [''],
      isPermanentOrRenewable: ['Permanent'],
      renewableDueDate: [''],
      filename: [''],
      isNameDifferentInCertificate: ['0'],
      country: ['1', Validators.required],
      state: ['', Validators.required],
      qualifications: ['', Validators.required],
      university: ['', Validators.required],
      college: ['', Validators.required],
      yearOfAwardingDegreeDiploma: [''],
      monthOfAward: [''],
      isNameDifferentInDegree: ['0']
    })

    this.hprForm4 = this.formBuilder.group({
      currentlyWorking: ['1', Validators.required],
      purposeOfWork: [''],
      purposeOfNotWork: [''],
      chooseWorkStatus: ['1'],
      facility: ['No'],
      searchWith: ['true'],
      organization: ['No'],
      attachment: ['']

    })
  }


  getAllDoctorModules() {
    this.registrationsrv.getAllDctrMdule().subscribe(res => {
      if (res.status === 'OK') {
        if (res.data == null || res.data.length == 0)
          this.doctorlist = [];
        // this.toastr.info(res.message, 'Doctor List');
        else
          this.doctorlist = res.data;
      }
    })
  }

  getFeaturePermission() {
    var featureList: any = JSON.parse(localStorage.getItem('featuresList'));
    for (let i = 0; i < featureList.length; i++) {
      if (featureList[i].feature_id.feature_name == "IsFacilityEnable") {
        this.IsFacilityEnable = featureList[i].patient;
      }
      if (featureList[i].feature_id.feature_name == "EnableLinkHPR") {
        this.EnableLinkHPR = featureList[i].patient;
      }
      if (featureList[i].feature_id.feature_name == "1962LiveStock") {
        this.is1962LiveStock = featureList[i].patient;
      }
      if (featureList[i].feature_id.feature_name == "Health&Wellness") {   
        this.isHealthWellness = featureList[i].patient;
    }
    }
  }

  get f() { return this.doctorForm.controls; }
  get fc() { return this.hprForm1.controls; }
  get gc() { return this.hprForm2.controls; }
  get k() { return this.hprForm3.controls; }
  get m() { return this.hprForm4.controls; }

  onSubmit(event: any) {
    if(!this.is1962LiveStock){
      this.doctorForm.get('audio_consultation_price').setValidators([Validators.required]);
      this.doctorForm.get('audio_consultation_price').updateValueAndValidity();
      this.doctorForm.get('video_consultation_price').setValidators([Validators.required]);
      this.doctorForm.get('video_consultation_price').updateValueAndValidity();
      this.doctorForm.get('photo').setValidators([Validators.required]);
      this.doctorForm.get('photo').updateValueAndValidity();
    }else{
      this.doctorForm.get('photo').setValidators(null);
      this.doctorForm.get('photo').updateValueAndValidity();
      this.doctorForm.get('work_shift_id').setValidators([Validators.required]);
      this.doctorForm.get('work_shift_id').updateValueAndValidity();
    }
    let lgnName: string = this.doctorForm.get('id').value == '0' ? this.doctorForm.value.email : this.doctor?.user_registration?.login_name ?? '',
      isEdit: boolean = false;
    event.preventDefault(), this.submitted = true, Common.getFormValidationErrors(this.doctorForm);
    if (this.doctorForm.invalid) {
      this.toastr.info('Please Enter All Mandatory Fields');
      return;
    }
    else if (this.doctorForm.value.isHW == true && this.doctorForm.value.hlthWorker == '') {
      this.toastr.warning('Please select Health Worker');
      return;
    }
    else if ((this.doctorForm.value.home_address || '') == '' && (this.doctorForm.value.clinic_address || '') == '' && (this.doctorForm.value.hospital_address || '') == '') {
      this.toastr.warning('Please provide one of the address');
      return;
    }
    else {
      if (this.IsFacilityEnable == true) {
        if (this.doctorForm.value.facility_id == "") {
          this.toastr.info("Facility Id is mandatory")
          return
        }
        else if (this.doctorForm.value.facility_name == "") {
          this.toastr.info("Facility Name is mandatory")
          return
        }

      }
      let user = this.authServ.currentUserValue, roleID = +sessionStorage.getItem('logUsrRoleId'), crntTentId = +sessionStorage.getItem('currentTenant');
      this.doctorForm.patchValue({
        login_name: lgnName == '' ? this.doctorForm.get('email').value : lgnName,
        password: +this.doctorForm.value.id == 0 ? '123456' : this.doctor ? this.doctor.user_registration?.password ?? '123456' : ''
      });
      const sendData = JSON.parse(JSON.stringify(this.doctorForm.getRawValue()));

      if (+sendData.audio_consultation_price > +sendData.video_consultation_price) {
        this.toastr.info('Video rate should greater than Audio rate')
        return;
      }

      Object.assign(sendData, {
        "alternate_mobile_no": user?.alternate_mobile_no, "user_types": [{ user_type_id: "7" }],
        "registration_source_id": user?.master_registration_source?.id, "branch_id": user?.orgnization_branch?.id ?? 0,
        "organization_id": roleID == 1 ? crntTentId : user?.orgnization_registration?.id
      });

      const [arr, arr1, adr, hspadr, arr2] = [[], [], [], [], []];
      const [start_Time, end_Time] = [this.datePipe.transform(sendData.from_time, 'yyyy-MM-dd HH:mm:ss'), this.datePipe.transform(sendData.to_time, 'yyyy-MM-dd HH:mm:ss')];
      [sendData.birth_date, sendData.reg_expiry_date] = [this.datePipe.transform(sendData.birth_date, 'yyyy-MM-dd HH:mm:ss'), this.datePipe.transform(sendData.reg_expiry_date, 'yyyy-MM-dd HH:mm:ss')];
      sendData.charge_amt = this.doctorForm.value.charge_amt == true ? 1 : 0;
      /* if (sendData.doctor_modules?.length > 0) {
        sendData.doctor_modules.forEach(item => arr2.push({ is_active: true, module_id: item.id }));
        sendData.doctor_modules = arr2;
      }
      else
        sendData.doctor_modules = []; */

      delete sendData.docTmplt;
      if (+sendData.id == 0)
        delete sendData.id, delete sendData.user_id;
      else
        isEdit = true;
      if (isEdit)
        this.updateDoctor(sendData, start_Time, end_Time);
      else {
        if (sendData.weekday_id?.length > 0) {
          // if (sendData.weekday_id.length > 0) {
          sendData.weekday_id.forEach(item => arr.push({ id: 0, doctor_id: 0, is_active: true, weekday_id: item.id, from_time: start_Time, to_time: end_Time }))
          // sendData.doctor_available_slots = arr;
          sendData.doctor_available_slots = [];
          // }
        }

        if (sendData.doctor_languages?.length > 0) {
          // if (sendData.doctor_languages.length > 0) {
          sendData.doctor_languages.forEach(item => arr1.push({ id: 0, doctor_id: 0, is_active: true, language_id: item.id }));
          sendData.doctor_languages = arr1;
          // }
        }
        ["home_address", "clinic_address", "hospital_address"].forEach((e, i) => {
          const add = this.doctorForm.get(e).value;
          if (add)
            adr.push({
              id: 0, doctor_id: 0, is_active: true, address_type_id: `${i + 1}`, // address: this.doctorForm.get(e).value,
              address: this.doctor ? (i == 0 ? this.homeAddrs : (i == 1 ? this.clncAddrs : this.hsptlAddrs)) : add,
              display_on_prescription: this.doctorForm.get('prescription_address').value == i + 1 ? true : false
            });
        });
        adr.forEach(f => {
          Object.assign(f, {
            pin_code: this.doctorForm.get('pin_code').value, state_id: this.doctorForm.get('state_id').value, district_id: this.doctorForm.get('district_id').value,
            city_id: this.doctorForm.get('city_id').value, mandal_id: this.doctorForm.get('mandal_id').value, village_id: this.doctorForm.get('village_id').value,
            location_type_id: this.doctorForm.get('location_type_id').value
          })
        });
        sendData.doctor_address = adr;

        const typeOfDoc = this.doctorForm.get('doctor_type_id').value;
        hspadr.push({
          id: 0, is_active: true, doctor_registration: 0, working_in_presently: true,
          hospital_name: (+typeOfDoc == 1 || +typeOfDoc == 5 || +typeOfDoc == 6) ? this.doctorForm.get('hospital_name').value : this.doctorForm.get('hospital_working_in_presently').value,
          other_hospital: this.doctorForm.get('other_hospital').value
        });
        sendData.doctor_hospitals = hspadr;
      }
      sendData.age = (new Date()).getFullYear() - (new Date(sendData.birth_date)).getFullYear();
      sendData.address = sendData.home_address || sendData.clinic_address || sendData.hospital_address;
      sendData.department_name = this.departmentList.find(item => item.department_id == sendData.department_id)?.department ?? '';
      this.removedAPIFields(sendData);
      // sendData.aadhar_number = '1234456';
      sendData.first_name = sendData.first_name.toUpperCase();
      // sendData.last_name = sendData?.last_name ?? ''.toUpperCase();
      sendData.last_name = sendData.last_name.toUpperCase();

      if (+sendData.start_hours > 0) {
        if (+sendData.start_hours > +sendData.end_hours) {
          this.toastr.error('Please select End Hours', this.title);
          Common.setFocus('end_hours');
          return;
        }

        if (+sendData.start_minutes > +sendData.end_minutes) {
          this.toastr.error('Please select End Minutes', this.title);
          Common.setFocus('end_minutes');
          return;
        }
        sendData.duty_start_time = `${sendData.start_hours}:${sendData.start_minutes}:00`;
        sendData.duty_end_time = `${sendData.end_hours}:${sendData.end_minutes}:00`;
      }
      else {
        sendData.duty_start_time = '';
        sendData.duty_end_time = '';
      }
      delete sendData.start_hours;
      delete sendData.start_minutes;
      delete sendData.end_hours;
      delete sendData.end_minutes;

      /* if (this.doctorForm.get('prescription_address').value != '')
        sendData.prescription_address = +sendData.prescription_address;
      else
        delete sendData.prescription_address; */
      this.payLoadForHpr = sendData
      this.registrationService.saveDoctor(sendData).subscribe(res => {
        let apiResponse = res;
        if (apiResponse.status == "OK") {
          this.saveSubmitRes = res?.data
          this.saveHprForDoctor();
          let id: number = +(sendData.id ?? 0);
          const notificationData = { email: sendData.email, mobile_no: +sendData.mobile, login_name: sendData.login_name, name: sendData.first_name + ' ' + sendData.last_name }; // `${sendData.first_name} ${sendData.last_name}`

          this.toastr.success(`Record ${(+sendData.id > 0) ? 'Updated' : 'Saved'} Successfully`, 'Doctor Registration');
          if (sessionStorage.getItem('logUsrRoleId') != null) {
            if (+sessionStorage.getItem('logUsrRoleId') == 7) {
              sessionStorage.setItem('DoctorDetails', JSON.stringify(apiResponse.data));
              if (sessionStorage.getItem('userData') != null) {
                let frmId = (<HTMLInputElement>document.getElementById('fromId')).value,
                  payLoad = `doctor/getdoctororhealthworkerrecordbyuserid?roleId=${+sessionStorage.getItem('logUsrRoleId')}&userId=${JSON.parse(sessionStorage.getItem('userData')).id}`;
                this.registrationService.getDHDetails(payLoad).subscribe(res => {
                  sessionStorage.setItem('dtlsDrHw', JSON.stringify(res.data)),
                    updateStatus(frmId, "AVAILABLE", "register");
                });
              }
            }
          }
          if (this.doctorForm.value.isHW) {
            let mapData = [];
            //debugger
            mapData.push({
              doctor_id: apiResponse.data.id, health_worker_id: this.doctorForm.value.hlthWorker,
              id: this.doctor?.doctor_health_worker[0]?.id ?? 0
            });
            this.registrationService.mapDoctorHW(mapData).subscribe(() => {
              // this.initFormControls();
            });
          }
          else {
            if (this.doctor?.doctor_health_worker[0]?.id)
              this.registrationService.unMapDoctorHW(this.doctor?.doctor_health_worker[0]?.id).subscribe(() => { });
            //debugger
          }
          if (id == 0)
            this.send_Notification(notificationData);
          if (this.uploadFileList.some(f => f.File != null))
            this.saveFile(apiResponse.data.id, id);


          else
            this.showMessage(id);
          // let responseData: any = apiResponse.response;
          // this.saveFile(apiResponse.data.id);
          // this.activeModal.close('changes saved');
          // this.initFormControls();
          // this.toastr.success(`Record ${(+sendData.id > 0) ? 'Updated' : 'Saved'} Successfully`, 'Doctor Registration');
        }
      }, error => this.toastr.error(error.message));
    }
  }
  saveHprForDoctor() {
    let payLoad = {
      "id": null,
      "doctorId": this.saveSubmitRes?.id,
      "hubId": null,
      "centerId": null,
      "hprPayload": this.payLoadForHpr,
      "hprId": this.doctor?.hpr_id || this.suggestionId == undefined ? this.checkAccountRes?.hprId : this.suggestionId + '@hpr.abdm',
      "hprNumber": this.doctor?.hpr_number || this.hprNumber || "",
      "isHprLinked": true,
      "isActive": true,
      "userId": sessionStorage.loginId,
      "createdBy": sessionStorage.loginId,
      "modifiedBy": sessionStorage.loginId,
      "createdOn": new Date(),
      "modifiedOn": new Date()
    }
    this.registrationsrv.post(`registration/hpr/save-hpr-record`, payLoad).subscribe({
      next: res => {
        if (res.status == 'OK') {
          this.preverify1 = res.data ?? []
          //  this.registerProfessionalNew();
          if(this.EnableLinkHPR){
            this.toastr.success(res.message)
          }
          

        } else {
          let errMsg: string = res.message;
          this.toastr.error(errMsg);
        }
      },
      error: (err: HttpErrorResponse) => {
        this.toastr.error(err.error.message);

      },
      complete: () => {
        // Any additional logic after patching values
      },
    });
  }

  showMessage(id: number) {
    this.activeModal.close('changes saved');
    this.toastr.success(`Record ${(id > 0) ? 'Updated' : 'Saved'} Successfully`, 'Doctor Registration');
  }

  removedAPIFields(data: any) {
    delete data.pin_code;
    delete data.weekday_id;
    delete data.hospital_name;
    delete data.state_id;
    delete data.location_type_id;
    delete data.district_id;
    delete data.mandal_id;
    delete data.village_id;
    delete data.city_id;
    delete data.home_address;
    delete data.clinic_address;
    delete data.hospital_address;
    delete data.from_time;
    delete data.to_time;
    delete data.address;
    delete data.prescription_address;
    delete data.hospital_working_in_presently;
    delete data.other_hospital;
    // delete data.status;
    delete data.digitalFile;
    delete data.photo;
    delete data.qualificationFile;
    delete data.aadharFile;
    delete data.panFile;
    delete data.isHW;
    delete data.hlthWorker;
  }

  assistantDetails(value){
    if(value){
      this.AssistantDetails=true
    }else{
      this.AssistantDetails=false
    }
    
  }

  onDoctorChange(doctorTypeId: string) {
   
    this.doctorForm.get('facility_id').setValue('')
    this.doctorForm.get('facility_name').setValue('')
    if (doctorTypeId == '4') {

      this.changeDetection.detach();
      const modalRef = this.modalService.open(AyushDoctorRegistrationComponent, { scrollable: true, size: 'xl' });
      this.changeDetection.reattach();
      var closeExistingModal = document.getElementById('doctype1').click();

    }
    this.doctorForm.get('prescription_address').setValue((+doctorTypeId > 0) ? (+doctorTypeId == 1) ? '3' :
      (+doctorTypeId == 3) ? '2' : '' : ''), this.doctorForm.get('hospital_name').setValue(''), this.doctorForm.get('hospital_working_in_presently').setValue('');
    // this.doctorForm.patchValue({ hospital_name: '', hospital_working_in_presently: '', hospital_address: '', clinic_address: '', home_address: '' });

    this.doctorForm.get('bank_account_number').clearValidators();
    this.doctorForm.get('branch_name').clearValidators();
    this.doctorForm.get('branch_location').clearValidators();
    this.doctorForm.get('ifsc_code').clearValidators();
    this.doctorForm.get('hospital_name').clearValidators();
    this.doctorForm.get('department_id').clearValidators();
    this.doctorForm.get('hospital_working_in_presently').clearValidators();

    if (+doctorTypeId == 2) {
      this.getUserPermission()
      this.doctorForm.get('bank_account_number').setValidators([Validators.required]);
      this.doctorForm.get('branch_name').setValidators([Validators.required]);
      if (this.isLabPrefferedMandatory) {
        this.doctorForm.get('lab_id').setValidators([Validators.required])
      }

      this.doctorForm.get('branch_location').setValidators([Validators.required]);
      if (!this.isNIG)
        this.doctorForm.get('ifsc_code').setValidators([Validators.required, Validators.pattern('[A-Z|a-z]{4}[0][a-zA-Z0-9]{6}$')]);
      else
        this.doctorForm.get('ifsc_code').setValidators(null);
      this.doctorForm.get('hospital_working_in_presently').setValidators([Validators.required]);
      this.doctorForm.get('hospital_name').setValidators(null);
      this.doctorForm.get('department_id').setValidators(null);
    }
    else if (+doctorTypeId == 1) {
      this.doctorForm.get('hospital_name').setValidators([Validators.required]);
      this.doctorForm.get('department_id').setValidators([Validators.required]);
      this.doctorForm.get('hospital_working_in_presently').clearValidators();
    }
    else if (+doctorTypeId == 3) {
      this.doctorForm.patchValue({ clinic_address: '1-8-448, Lakshmi Building, 4th, 5th, 6th Floors, Begumpet, Hyderabad, Telangana 500003' })
      this.doctorForm.get('hospital_working_in_presently').setValidators([Validators.required]);
      this.doctorForm.get('hospital_name').clearValidators();
      this.doctorForm.get('department_id').clearValidators();
      // this.doctorForm.get('hospital_name').setValidators(null);
      // this.doctorForm.get('department_id').setValidators(null);
    }
    else if (+doctorTypeId == 4) {

      // this.doctorForm.get('hospital_name').setValidators(null);
      // this.doctorForm.get('department_id').setValidators(null);
    }

    this.doctorForm.get('hospital_working_in_presently').updateValueAndValidity();
    this.doctorForm.get('bank_account_number').updateValueAndValidity();
    this.doctorForm.get('branch_name').updateValueAndValidity();
    this.doctorForm.get('branch_location').updateValueAndValidity();
    this.doctorForm.get('ifsc_code').updateValueAndValidity();
    this.doctorForm.get('hospital_name').updateValueAndValidity();
    this.doctorForm.get('department_id').updateValueAndValidity();
  }

  onHospitalName(val: any) {
    // if (val != '')
    //   this.doctorForm.patchValue({ hospital_address: this.hospitalList.filter(f => f.id == val)[0].address });
    // else
    //   this.doctorForm.patchValue({ hospital_address: '' });
    if (val != '' && val != undefined) {
      let item = this.hospitalList.find(f => f.id == val);
      Promise.all([this.locationChanged(item.state_id, 'district'),
      item.locationtype_id == 1 ? (this.locationChanged(item.district_id, 'mandal'),
        this.locationChanged(item.mandal_id, 'village')) : this.locationChanged(item.district_id, 'city')])
        .then(() => {
          this.doctorForm.patchValue({
            hospital_address: this.hospitalList.filter(f => f.id == val)[0].address, state_id: item.state_id,
            location_type_id: item.locationtype_id, district_id: item.district_id, mandal_id: item.mandal_id, village_id: item.village_id,
            city: item.city_id
          });
        });
      this.getDepartments(+val);
    }
    else {
      this.doctorForm.patchValue({
        state_id: '', location_type_id: '', district_id: '', mandal_id: '', village_id: '', city: '', hospital_address: ''
      });
      this.districtList = this.mandalList = this.villageList = this.cityList = [];
    }

    this.hospitalList.find(item => {
      if (item.id == val) {
        this.doctorForm.get('facility_id').setValue(item.facility_id)
        this.doctorForm.get('facility_name').setValue(item.facility_name)
      }
    }

    )
  }

  getDepartments(id: number) {
    this.departmentList = [];
    this.registrationService.getdepartments(`hospitaldepartments/getbyhospitalid?hospitalId=${id}`).subscribe(res => {
      if (res.status == 'OK') {
        this.departmentList = res.data;
      }
    });
  }

  onHospitalWorking(type: string, val: any) {
    if (val != '' && this.doctorForm.value.doctor_type_id == '3')
      this.doctorForm.patchValue({ clinic_address: '#1-8-448, Lakshmi Building, 4th, 5th & 6th Floors, Begumpet, Hyderabad, Telangana 500003' });
    else
      this.doctorForm.patchValue({ clinic_address: '' });
    if (type == 'other') {
      if (val != '')
        this.doctorForm.get('hospital_working_in_presently').clearValidators();
      else
        this.doctorForm.get('hospital_working_in_presently').setValidators(Validators.required);
      this.doctorForm.get('hospital_working_in_presently').updateValueAndValidity();
    }

    this.hospitalList.find(item => {
      if (item.id == val) {
        this.doctorForm.get('facility_id').setValue(item.facility_id)
        this.doctorForm.get('facility_name').setValue(item.facility_name)
      }
    }

    )

    // if (type == 'dd' && val != '')
    //   this.doctorForm.get('other_hospital').disabled;
    // else
    //   this.doctorForm.get('other_hospital').enabled;
  }

  setAssistantDetailsValidators() {
    const [nameControl, contact_noControl, emailControl] = [this.doctorForm.get('assistant_first_name'), this.doctorForm.get('assistant_contact_number'), this.doctorForm.get('assistant_email')];
    this.doctorForm.get('assistant_detail').valueChanges
      .subscribe(isAssistant => {
        if (isAssistant) {
          nameControl.setValidators([Validators.required]);
          contact_noControl.setValidators([Validators.required, Validators.pattern(this.mobPtrn), Validators.minLength(+this.maxLngth)]);
          emailControl.setValidators([Validators.required]);
        }
        else {
          nameControl.setValidators(null);
          contact_noControl.setValidators(null);
          emailControl.setValidators(null);

          nameControl.setValue('');
          contact_noControl.setValue('');
          emailControl.setValue('');
        }

        nameControl.updateValueAndValidity();
        contact_noControl.updateValueAndValidity();
        emailControl.updateValueAndValidity();
      });
  }

  setLocationTypeValidators() {
    const [city_idControl, mandal_idControl, village_idControl] = [this.doctorForm.get('city_id'), this.doctorForm.get('mandal_id'), this.doctorForm.get('village_id')];
    this.doctorForm.get('location_type_id').valueChanges
      .subscribe(locationType => {
        if (this.isNIG)
          village_idControl.setValue(''), village_idControl.setValidators(null);
        if (+locationType == 2) {
          city_idControl.setValidators(null);
          mandal_idControl.setValue('');
          village_idControl.setValue('');
          mandal_idControl.setValidators(null);
          village_idControl.setValidators(null);
        }
        else if (locationType == 1) {
          city_idControl.setValidators(null);
          city_idControl.setValue('');
          mandal_idControl.setValidators([Validators.required]);
          if (!this.isNIG)
            village_idControl.setValidators([Validators.required]);
        }
        else {
          mandal_idControl.setValue('');
          village_idControl.setValue('');
          city_idControl.setValue('');
          city_idControl.setValidators(null);
          mandal_idControl.setValidators(null);
          village_idControl.setValidators(null);
        }

        city_idControl.updateValueAndValidity();
        mandal_idControl.updateValueAndValidity();
        village_idControl.updateValueAndValidity();
      });
  }

  locationChanged(id: string, locationType: string) {
    let query: string = '';
    let locations = {
      'location': () => {
        // this.districtList = [];
        this.mandalList = [];
        this.villageList = [];
        this.cityList = [];

        // this.labForm.get('state_id').setValue('');
        this.doctorForm.get('district_id').setValue('');
      },
      'state': () => {
        this.districtList = [];
        this.mandalList = [];
        this.villageList = [];
        this.cityList = [];
        // if (+id > 0)
        query = `getOrgStates/${sessionStorage.getItem('currentTenant')}`;
      },
      'district': () => {
        this.districtList = [];
        this.mandalList = [];
        this.villageList = [];
        this.cityList = [];
        if (+id > 0)
          query = `getstatebyid?stateId=${id}`;
      },
      'mandal': () => {
        this.mandalList = [];
        this.villageList = [];
        this.cityList = [];
        if (+id > 0)
          query = `getdistrictbyid?districtId=${id}`;
      },
      'village': () => {
        this.villageList = [];
        if (+id > 0)
          query = `getmandalbyid?mandalId=${id}`;
      },
      'city': () => {
        this.cityList = [];
        if (+id > 0)
          query = `getdistrictbyid?districtId=${id}`;
      }
    };

    locations[locationType]();
    if (query.length > 0)
      this.getMasters(query, locationType);
  }

  getMasters(queryString: string, type: string) {
    this.masterService.getLocations(queryString).subscribe(data => {
      let result = data;
      if (result.status == "OK") {
        let arr: any = result.data;
        let assign_locations = {
          'state': () => this.stateList = arr,//.map(row => this.pick(row, ['id', 'name', 'scheduleCasteType', 'castettype'])),
          'district': () => this.districtList = arr.districts,
          'mandal': () => this.mandalList = arr.mandals,
          'village': () => this.villageList = arr.villages,
          'city': () => this.cityList = arr.cities
        };
        assign_locations[type]();
      }
      this.districtList.sort((a, b) => 0 - (a.district_name > b.district_name ? -1 : 1));
    }, error => this.toastr.error(error));
  }

  keyUp(elementId: string) {
    let el: any = (<HTMLTextAreaElement>(document.getElementById(elementId)));
    el.value = el.value.replace(/^(0*)/, "");
  }

  onKeyNmbr(type: string, val: string) {
    if (val != '') {
      if (+this.maxLngth == +environment.maxLngthSAGE) {
        if (+val[0] == environment.mobValdValSAGE)
          this.doctorForm.get(type).setValue('');
      }
      if (+this.maxLngth == +environment.maxLngthIND) {
        if (+val[0] < environment.mobValdValIND)
          this.doctorForm.get(type).setValue('');
      }
      if (+this.maxLngth == +environment.maxLngthNIG) {
        if (+val[0] > environment.mobValdValNIG)
          this.doctorForm.get(type).setValue('');
      }
    }
  }

  changeProperty(hashmap: any, existProp: string) {
    Object.keys(hashmap).forEach((key) => {
      hashmap['name'] = hashmap[key];
      delete hashmap[key];
    });
  }

  submitForm() {
    document.getElementById('btnsubmit').click();
  }

  superSecialityDegree(_id: number) {
    this.super_SpecialisationList = (+_id == 0) ? [] : this.superSpecialityDegreeList.find(({ id }) => +id == _id).specializations;
  }

  singleFileUpload(event: any, fileNumber: number, fileType: string, frmCntrl: string = '') {
    fileNumber = fileNumber - 1;
    let fileExt: string = this.uploadFileList[fileNumber].extensions;
    // Check if any file is selected.
    if (event.target.files.length > 0) {
      const fileList = event.target, fsize = fileList.files.item(0).size, currentfile = Math.round((fsize / (1024 * 5)));
      // Checking type of file
      if (fileList.files[0]) {
        const fileExtension: string = fileList.files[0].name.split('.').pop().toLowerCase();
        // fileExt = fileExtension;
        if (!this.uploadFileList[fileNumber].extensions.split(',').includes(fileExtension)) {
          this.toastr.warning(`Please Select ${fileExt} files only`);
          if (frmCntrl != '') {
            this.doctorForm.get(frmCntrl).clearValidators(), this.doctorForm.get(frmCntrl).setValue(''), // this.cancelFile(fileNumber+1), 
              this.doctorForm.get(frmCntrl).setValidators([Validators.required]), this.doctorForm.get(frmCntrl).updateValueAndValidity();
          }
          return false;
        }
      }
      // The size of the file.
      if (currentfile >= (1024 * 5)) {
        this.toastr.error(fileList.files[0].name + ' too Big, please select a file less than 5MB', 'Doctor Registration');
        return;
      }
      else {
        this.uploadFileList[fileNumber].File = fileList.files.item(0);
        this.uploadFileList[fileNumber].file_name = fileList.files[0].name;
        this.uploadFileList[fileNumber].fileType = fileType;
        if (this.doctor) {
          let f = this.doctor.doctor_documents.filter(f => f.file_type_id == +this.uploadFileList[fileNumber].fileType);
          this.uploadFileList[fileNumber].id = f.length > 0 ? f[0].id : 0;
        }
      }
    }
  }

  saveFile(id: string, record_id: number) {
    let sendAPIfiles = [];
    this.uploadFileList.forEach(item => {
      if (item.File)
        sendAPIfiles.push(this.fileService.uploadFile(Common.form_Data(item.id.toString(), item.File, id, 'DOCTOR', item.fileType)));
    });

    forkJoin([...sendAPIfiles]).subscribe(res => console.log, err => console.log, () => { this.showMessage(record_id); });
  }

  getAllLabPharmacyHospital() {
    const lab = this.registrationService.getAll('lab/getalllabregistrations');
    const pharmacy = this.registrationService.getAll('pharmacy/getallrecords');
    const languages = this.masterService.getAll('getalllanguages');
    const weekDays = this.masterService.getAll('getallweekdays');
    const gender = this.masterService.getAll('getallgenders');
    const locationType = this.masterService.getAll('getalllocationtypes');
    const marital = this.masterService.getAll('getallmaritalstatus');
    const title = this.masterService.getAll('getalltitle');
    const visitStatus = this.masterService.getAll('getallvisitstatus');
    const addressTypes = this.masterService.getAll('getallAddresstypes');
    const qualifications = this.masterService.getAll('getallqualifications');
    const specialization = this.masterService.getAll('getallspecialization');
    const superSpecialityDegree = this.masterService.getAll('getallsuperspecialityqualifications');
    const bankName = this.masterService.getAll('getallmasterbankdetails');
    const hospital = this.registrationService.getAll('hospital/getallhospitalrecords');
    const hlthWrkrs = this.registrationService.getUnasgndHlthWrkrs();

    forkJoin([lab, pharmacy, languages, weekDays, gender, locationType, marital, title, visitStatus, addressTypes, qualifications,
      specialization, superSpecialityDegree, bankName, hospital, hlthWrkrs]).subscribe({
        next: res => {
          res.forEach((list, ind) => {
            if (list.status === 'OK') {
              if (list.data == null || list.data.length == 0)
                console.log('No records available');
              // this.toastr.info('No records available', 'Pharmacy List');
              else {
                const assignList = {
                  '0': () => this.labList = list.data,
                  '1': () => this.pharmacyList = list.data,
                  '2': () => this.languagesList = list.data,
                  '3': () => this.weekDaysList = list.data,
                  '4': () => this.genderList = list.data,
                  '5': () => this.locationTypeList = list.data,
                  '6': () => this.maritalList = list.data,
                  '7': () => this.titleList = list.data,
                  '8': () => this.visitStatusList = list.data,
                  '9': () => this.addressTypesList = list.data,
                  '10': () => this.qualificationsList = list.data,
                  '11': () => this.specializationList = list.data,
                  '12': () => this.superSpecialityDegreeList = list.data,
                  '13': () => this.bankNamesList = list.data,
                  '14': () => this.hospitalList = list.data,
                  '15': () => this.hwList = list.data,
                }
                assignList[ind]();
              }
            }
          });
        },
        error: err => console.error('something wrong occurred: ', err),
        complete: () => {
          [this.isShwDS, this.isShwHQ, this.isShwPic] = [false, false, false],
            this.qualificationsList.sort((a, b) => a.id - b.id), this.editDoctor();
        }
      });
  }

  cancelFile(ind: number, frmCntrl: string = '', fileTypeId: number = 0) {
    let view_File = this.uploadFileList[ind - 1];
    view_File.File = null, view_File.file_name = '';
    if (this.doctor) {
      if (fileTypeId > 0) {
        this.doctor.doctor_documents.forEach((e, i) => {
          if (e) {
            if (e.file_type_id == fileTypeId)
              this.doctor.doctor_documents[i] = null;
          }
        });
        this.uploadFileList.forEach(e => {
          if (e) {
            if (+e.fileType == fileTypeId)
              e.document_path = '', e.file_name = '', e.id = 0;
          }
        });
        if (fileTypeId == 8)
          this.isShwDS = false;
        if (fileTypeId == 9)
          this.isShwHQ = false;
        if (fileTypeId == 5)
          this.isShwPic = false;
      }
    }
    if (frmCntrl != '') {
      this.doctorForm.get(frmCntrl).clearValidators(), this.doctorForm.get(frmCntrl).setValue(''),
        this.doctorForm.get(frmCntrl).setValidators([Validators.required]), this.doctorForm.get(frmCntrl).updateValueAndValidity();
    }
  }

  viewFile(ind: number) {
    let view_File = this.uploadFileList[ind - 1], imagePath = '';
    console.log('view_File', view_File);
    const doctor_id = +this.doctorForm.get('id').value;
    const fileExtension: string = view_File.file_name.split('.').pop().toLowerCase();
    if (fileExtension == 'pdf')
      (view_File.File) ? Common.showPDF_File(URL.createObjectURL(view_File.File)) : Common.showPDF_File(`${this.registrationService.viewFilePath}DOCTOR/${doctor_id}/${view_File.name}/${view_File.document_path}`);
    else if (['jpg', 'jpeg', 'bmp', 'png'].includes(fileExtension)) {
      const modalRef = this.modalService.open(ImagePreviewComponent, { size: 'lg', scrollable: true });
      if (view_File.File) {
        const reader = new FileReader();
        reader.readAsDataURL(view_File.File); // read file as data url
        // called once readAsDataURL is completed
        reader.onload = (event1: any) => {
          imagePath = event1.target.result;
          modalRef.componentInstance.image = imagePath;
        };
      }
      else {
        imagePath = `${this.registrationService.viewFilePath}DOCTOR/${doctor_id}/${view_File.name}/${view_File.document_path}`;
        modalRef.componentInstance.image = imagePath;
      }
    }
  }

  editDoctor() {

    // console.log('editDoctorsDetails...',JSON.stringify(this.uploadFileList))
    if (this.doctor) {
      this.onQualification(this.doctor?.qualification_id);
      // alert('222')
      this.hide = 1, this.isEdit = true, this.doctor.doctor_documents.forEach(f => {
        const docu = this.uploadFileList.find(e => e.fileType == f.file_type_id);
        if (docu) {
          //alert('333')
          docu.id = f.id;
          docu.document_path = f.document_path;
          docu.file_name = f.file_name;
          if (docu.fileType == '8')
            this.isShwDS = true;
          if (docu.fileType == '9')
            this.isShwHQ = true;
          if (docu.fileType == '5')
            this.isShwPic = true;
          // [this.isShwDS, this.isShwHQ, this.isShwPic] = [docu.fileType == '8', docu.fileType == '9', docu.fileType == '5'];
        }
      });
      console.log('##################.', JSON.stringify(this.uploadFileList))

      //alert('444')
      this.fileValidations(), this.doctorForm.get('account_activated').disable();
      const editDoctor = Object.assign({}, this.doctor);
      let address = (editDoctor.doctor_address.length > 0) ? editDoctor.doctor_address : null;
      let [state_id, location_type_id, district_id, mandal_id] = (address) ? [address[0].state_id, address[0].location_type_id, address[0].district_id, address[0].mandal_id] : [0, 0, 0, 0];
      let docTmplts: any[] = editDoctor.doctor_modules.filter(f => f.is_active == true);
      if (this.doctor?.doctor_health_worker?.length > 0) {
        if (this.doctor?.doctor_health_worker[0]?.is_active)
          //this.hwList.push(this.doctor?.doctor_health_worker[0].health_worker_registration)
          this.hwList.push(this.doctor?.doctor_health_worker[0]);
      }
      Promise.all([this.locationChanged(state_id, 'district'), location_type_id == 1 ? (this.locationChanged(district_id, 'mandal'),
        this.locationChanged(mandal_id, 'village')) : this.locationChanged(district_id, 'city')]).then(() => {
          let [loc, hosp] = [editDoctor.doctor_address, editDoctor.doctor_hospitals];
          this.doctorForm.patchValue({
            state_id: loc[0]?.state_id, district_id: loc[0]?.district_id, mandal_id: loc[0]?.mandal_id, village_id: loc[0]?.village_id,
            city_id: loc[0]?.city_id, pin_code: loc[0]?.pin_code, location_type_id: loc[0]?.location_type_id,
            home_address: loc.filter(f => f.address_type_id == 1 && f.is_active == true)[0]?.address ?? '',
            clinic_address: loc.filter(f => f.address_type_id == 2 && f.is_active == true)[0]?.address ?? '',
            hospital_address: loc.filter(f => f.address_type_id == 3 && f.is_active == true)[0]?.address ?? '',
            prescription_address: loc.filter(f => f.display_on_prescription == true)[0]?.address_type_id,
            isHW: this.doctor?.doctor_health_worker?.length > 0 ? this.doctor?.doctor_health_worker[0]?.is_active : false,
            //hlthWorker: this.doctor?.doctor_health_worker?.length > 0 ? (this.doctor?.doctor_health_worker[0].is_active == true ? this.doctor?.doctor_health_worker[0]?.health_worker_registration.id : '') : '',
            hlthWorker: this.doctor?.doctor_health_worker?.length > 0 ? (this.doctor?.doctor_health_worker[0]?.is_active == true ? this.doctor?.doctor_health_worker[0]?.id : '') : '',
          }, { onlySelf: true, emitEvent: false });
          //(+typeOfDoc == 1) ? this.doctorForm.get('hospital_name').value : this.doctorForm.get('hospital_working_in_presently').value,
          this.doctorForm.patchValue(editDoctor), this.doctorForm.patchValue({
            hospital_name: ((hosp.length > 0) && (editDoctor.doctor_type_id == 1 || editDoctor.doctor_type_id == 5 || editDoctor.doctor_type_id == 6)) ? hosp[0].hospital_name : '',
            hospital_working_in_presently: ((hosp.length > 0) && (editDoctor.doctor_type_id != 1)) ? hosp[0].hospital_name : '',
            other_hospital: (hosp.length > 0) ? hosp[0].other_hospital : '',
          }, { emitEvent: true, onlySelf: false });
          if (new Date(editDoctor.reg_expiry_date) < new Date())
            this.maxdate = new Date(editDoctor.reg_expiry_date);
          else
            this.maxdate = new Date();
          [this.homeAddrs, this.clncAddrs, this.hsptlAddrs] = [loc.filter(f => f.address_type_id == 1)[0]?.address ?? '',
          loc.filter(f => f.address_type_id == 2)[0]?.address ?? '', loc.filter(f => f.address_type_id == 3)[0]?.address ?? ''];
          this.onDoctorChange(editDoctor.doctor_type_id), this.setWeekDays(editDoctor.doctor_available_slots),
            this.setLanguages(editDoctor.doctor_languages), this.superSecialityDegree(editDoctor.super_qualification_id),
            // this.setDrTmplt(editDoctor.doctor_modules),
            this.doctorForm.patchValue({
              from_time: this.gettimeFormat(editDoctor.doctor_available_slots[0]?.from_time),
              to_time: this.gettimeFormat(editDoctor.doctor_available_slots[0]?.to_time),
              weekday_id: this.selectedItems,
              doctor_languages: this.selectedItems_language,
              doctor_modules: this.selectedItems_module,
              birth_date: new Date(editDoctor.birth_date),
              reg_expiry_date: new Date(editDoctor.reg_expiry_date),
              docTmplt: docTmplts[docTmplts.length - 1]?.module_id ?? '',
              facility_id:editDoctor?.facility_id,
              facility_name:editDoctor?.facility_name
              // editDoctor.doctor_modules[editDoctor.doctor_modules.length-1]?.module_id,
            });
            if(this.is1962LiveStock){
              this.doctorForm.patchValue({
              doctor_type_id:editDoctor.doctor_type_id
            });
            }
          // console.log(this.doctor);
          if (editDoctor.preferred_lab_id != null)
            this.doctorForm.get('lab_id').setValue(editDoctor.preferred_lab_id.id);
          if (editDoctor.preferred_pharmacy_id != null)
            this.doctorForm.get('pharmacy_id').setValue(editDoctor.preferred_pharmacy_id.id);
          if (hosp.length > 0)
            this.getDepartments(+hosp[0].hospital_name);
          if (editDoctor.doctor_hospitals[0].hospital_name) {
            this.doctorForm.patchValue({
              hospital_name: editDoctor.doctor_hospitals[0].hospital_name,
              department_id: editDoctor.department_id
            })
            if(editDoctor.qualification_id == 296 || editDoctor.qualification_id == 1){
              this.hideSpecialization=true
              this.doctorForm.get('work_shift_id').setValue(editDoctor?.work_shift_id || '')
            }

          }

          /* let docMods = [];
          if (editDoctor.doctor_modules) {
            let editDM: any = this.doctorlist.filter((f: any) => f.id == editDoctor.doctor_modules[editDoctor.doctor_modules.length-1].module_id)[0];
            docMods.push({ id: editDM.id, module_name: editDM.module_name });
          } */
          // binding empty values while editing record....
          this.doctorForm.patchValue({
            home_address: loc.filter(f => f.address_type_id == 1)[loc.filter(f => f.address_type_id == 1 && f.is_active == true).length - 1]?.address ?? '',
            clinic_address: loc.filter(f => f.address_type_id == 2)[loc.filter(f => f.address_type_id == 2 && f.is_active == true).length - 1]?.address ?? '',
            hospital_address: loc.filter(f => f.address_type_id == 3)[loc.filter(f => f.address_type_id == 3 && f.is_active == true).length - 1]?.address ?? '',
            hospital_name: ((hosp.length > 0) && (editDoctor.doctor_type_id == 1 || editDoctor.doctor_type_id == 5 || editDoctor.doctor_type_id == 6)) ? hosp[0].hospital_name : '',
            hospital_working_in_presently: ((hosp.length > 0) && (editDoctor.doctor_type_id != 1 || editDoctor.doctor_type_id != 5 || editDoctor.doctor_type_id != 6)) ? hosp[hosp.length - 1].hospital_name : '',
            pin_code: loc[loc.length - 1]?.pin_code ?? '', // doctor_modules: [...docMods]
          });

          if (editDoctor.duty_start_time) {
            const startTime = editDoctor.duty_start_time.split(':');
            const endTime = editDoctor.duty_end_time.split(':');

            this.doctorForm.patchValue({
              start_hours: startTime[0],
              start_minutes: startTime[1],
              end_hours: endTime[0],
              end_minutes: endTime[1],
            }, { emitEvent: true, onlySelf: false })
          }
          console.clear();
          // console.log(this.doctorForm)
          // setTimeout(() => { this.doctorForm.get('doctor_modules').setValue(docMods); }, 100);
        });
    }
    else{
      this.maxdate = new Date(), this.isEdit = false;
    }
     
    if(this.doctor){
      this.doctorForm.patchValue({
        hospital_name:this.doctor?.doctor_hospitals?.id
      })
    }
  }

  onAdrsChng(type: string, val: any) {
    if (type == 'ha')
      this.homeAddrs = val; // this.doctorForm.get('home_address').value;
    else if (type == 'ca')
      this.clncAddrs = val; // this.doctorForm.get('clinic_address').value;
    else
      this.hsptlAddrs = val; // this.doctorForm.get('hospital_address').value;
  }

  gettimeFormat(time: string) {
    const date_time = `${this.datePipe.transform(new Date(), 'yyyy-MM-dd')} ${time}`;
    return new Date(date_time);
  }

  setWeekDays(weeks: any[]) {
    weeks.forEach(day => {
      const dayname = this.weekDaysList.find(item => item.id == day.weekday_id && day.is_active);
      if (dayname) {
        const item = { id: dayname.id, weekday: dayname.weekday };
        this.selectedItems.push(item);
      }
    });
  }

  setLanguages(languages: any[]) {
    languages.forEach(day => {
      const dayname = this.languagesList.find(item => item.id == day.language_id && day.is_active);
      if (dayname) {
        const item = { id: dayname.id, language_name: dayname.language_name };
        this.selectedItems_language.push(item);
      }
    });
  }

  onChngDT(dtId: any) {
    if (dtId) {
      const template: any = this.doctorlist.find((item: any) => item.id == dtId);
      if (template) {
        this.doctor.doctor_modules = template;
        let dtVal: any[] = [], data: any = this.doctor, modId = 0;
        if (data)
          modId = data.doctor_modules ? (data.doctor_modules.length > 0 ? data.doctor_modules[0].id : 0) : 0;
        dtVal.push({ id: modId, module_id: template.id, is_active: true });
        this.doctorForm.get('doctor_modules').setValue(dtVal);
      }
    }
    else {
      if (this.doctor) {
        let result: any[] = [];
        this.doctor.doctor_modules.forEach(e => { if (e.is_active) result.push({ id: e.id, module_id: e.module_id, is_active: false }); });
        if (result.length > 0)
          this.doctorForm.get('doctor_modules').setValue(result);
      }
    }
  }

  setDrTmplt(drMods: any[]) {
    drMods.forEach(dt => {
      const template: any = this.doctorlist.find((item: any) => item.id == dt.module_id);
      if (template) {
        const item = { id: template.id, module_name: template.module_name };
        this.selectedItems_module.push(item);
      }
    });
  }
  onQualification(id: any) {
    this.masterService.getAll(`get-specialization-by-qualification/${id}`).subscribe(res => {
      this.newSpecializationList = res.data
    });




  }
  QualificationChanged(id: string) {
    this.doctorForm.patchValue({
      specialization_id: ''
    })

    this.newSpecializationList = (+id > 0) ? this.qualificationsList.find(item => item.id == id).specializations : [];

    //console.log('newSpecializationList',JSON.stringify(this.newSpecializationList))
    this.newSpecializationList = this.newSpecializationList.sort((a, b) => a.specialization.toLowerCase() > b.specialization.toLowerCase() ? 1 : -1);
    // this.specializationList = (+id > 0) ? this.qualificationsList.find(item => item.id == id).specializations : [];
    // this.specializationList=this.specializationList.sort((a,b)=>a.specialization.toLowerCase()>b.specialization.toLowerCase()?1:-1);



    if (id == '1' ||id == '296'){
      this.hideSpecialization=true
      this.doctorForm.get('specialization_id').clearValidators();
      this.doctorForm.get('specialization_id').updateValueAndValidity();
    }
   
    else{
      this.hideSpecialization=false
      this.doctorForm.get('specialization_id').setValidators([Validators.required]);
      this.doctorForm.get('specialization_id').updateValueAndValidity();
    }
      

    
  }

  updateDoctor(sendData: any, start_Time: string, end_Time: string) {
    const old_record = JSON.parse(JSON.stringify(this.doctor));
    let [doctor_id, doctor_available_slots, doctor_languages, doctor_address, doctor_hospitals] = [sendData.id, [], [], [], []];
    this.weekDaysList.forEach(w => {
      let old = (old_record.doctor_available_slots.length > 0) ? old_record.doctor_available_slots.find(e => e.weekday_id == w.id) : null,
        new1 = (sendData.weekday_id?.length > 0) ? sendData.weekday_id.find(e => e.id == w.id) : null;

      if (old && new1)
        doctor_available_slots.push({ id: old.id, doctor_id: doctor_id, is_active: true, weekday_id: w.id, from_time: start_Time, to_time: end_Time })
      else if (old)
        doctor_available_slots.push({ id: old.id, doctor_id: doctor_id, is_active: false, weekday_id: w.id, from_time: start_Time, to_time: end_Time })
      else if (new1)
        doctor_available_slots.push({ id: 0, doctor_id: doctor_id, is_active: true, weekday_id: w.id, from_time: start_Time, to_time: end_Time })
    })
    sendData.doctor_available_slots = doctor_available_slots;
    this.languagesList.forEach(l => {
      let old = (old_record.doctor_languages.length > 0) ? old_record.doctor_languages.find(e => e.language_id == l.id) : null,
        new1 = (sendData.doctor_languages.length > 0) ? sendData.doctor_languages.find(e => e.id == l.id) : null;

      if (old && new1)
        doctor_languages.push({ id: old.id, doctor_id: doctor_id, is_active: true, language_id: l.id });
      else if (old)
        doctor_languages.push({ id: old.id, doctor_id: doctor_id, is_active: false, language_id: l.id });
      else if (new1)
        doctor_languages.push({ id: 0, doctor_id: doctor_id, is_active: true, language_id: l.id });
    });
    sendData.doctor_languages = doctor_languages;

    ["home_address", "clinic_address", "hospital_address"].forEach((e, i) => {
      const add = this.doctorForm.get(e).value, ind = (i + 1);
      let old = old_record.doctor_address.find(e => e.address_type_id == ind);
      const prescription_address = this.doctorForm.get('prescription_address').value == ind ? true : false;

      if (old && add)
        doctor_address.push({ id: old.id, doctor_id: doctor_id, is_active: true, address_type_id: ind, address: add, display_on_prescription: prescription_address });
      else if (old)
        doctor_address.push({ id: old.id, doctor_id: doctor_id, is_active: false, address_type_id: ind, address: (i == 0 ? this.homeAddrs : (i == 1 ? this.clncAddrs : this.hsptlAddrs)), display_on_prescription: prescription_address });
      else if (add)
        doctor_address.push({ id: 0, doctor_id: doctor_id, is_active: true, address_type_id: ind, address: add, display_on_prescription: prescription_address });
    });

    doctor_address.forEach(f => {
      Object.assign(f, {
        pin_code: this.doctorForm.get('pin_code').value, state_id: this.doctorForm.get('state_id').value, district_id: this.doctorForm.get('district_id').value,
        city_id: this.doctorForm.get('city_id').value, mandal_id: this.doctorForm.get('mandal_id').value, village_id: this.doctorForm.get('village_id').value,
        location_type_id: this.doctorForm.get('location_type_id').value
      })
    });
    sendData.doctor_address = doctor_address;

    const typeOfDoc = this.doctorForm.get('doctor_type_id').value;
    doctor_hospitals.push({
      id: (old_record.doctor_hospitals.length > 0) ? old_record.doctor_hospitals[0].id : 0,
      is_active: true, doctor_registration: doctor_id, working_in_presently: true,
      hospital_name: (+typeOfDoc == 1 || +typeOfDoc == 5 || +typeOfDoc == 6) ? this.doctorForm.get('hospital_name').value : this.doctorForm.get('hospital_working_in_presently').value,
      other_hospital: this.doctorForm.get('other_hospital').value
    });
    sendData.doctor_hospitals = doctor_hospitals;

  }

  fileValidations() {
    if (this.doctor.doctor_documents.length > 0) {
      if (this.doctor.doctor_documents.filter(f => f.file_type_id == 8).length > 0)
        this.doctorForm.get('digitalFile').clearValidators();
      else
        this.doctorForm.get('digitalFile').setValidators([Validators.required]);
      if (this.doctor.doctor_documents.filter(f => f.file_type_id == 9).length > 0)
        this.doctorForm.get('qualificationFile').clearValidators();
      else
        this.doctorForm.get('qualificationFile').setValidators([Validators.required]);
      if (this.doctor.doctor_documents.filter(f => f.file_type_id == 5).length > 0)
        this.doctorForm.get('photo').clearValidators();
      else
        this.doctorForm.get('photo').setValidators([Validators.required]);
    }
    this.doctorForm.get('aadharFile').clearValidators(), this.doctorForm.get('panFile').clearValidators(),
      this.doctorForm.get('digitalFile').updateValueAndValidity(), this.doctorForm.get('qualificationFile').updateValueAndValidity(),
      this.doctorForm.get('photo').updateValueAndValidity(), this.doctorForm.get('aadharFile').updateValueAndValidity(),
      this.doctorForm.get('panFile').updateValueAndValidity();
  }

  send_Notification(payload: { email: string, mobile_no: number, name: string, login_name: string }) {
    const postData = {
      "email": payload.email, "login_name": payload.login_name, "mobile_no": payload.mobile_no, "user_id": 7,
      "user_type": "Doctor", "name": payload.name.toUpperCase()
    };
    this.notificationService.sendNotification(postData).subscribe();
  }

  chkDocDocs(): boolean {
    let blnVal = false;
    if (this.doctor?.doctor_documents.length > 0)
      blnVal = this.doctor?.doctor_documents?.filter(f => f.file_type_id == 5)?.file_name != '';
    return blnVal;
  }

  // Link HPR  Binding Start 

  closeterms() {
    $("#termsModal").modal("hide");
  }

  termscheck(event) {
    this.istermsChecked = event.target.checked;
  }

  GetOtp() {
    if (this.istermsChecked == false) {
      this.toastr.error('Please accept the terms & conditions');
      return;
    }
    if (this.hprForm1.value.aadhar.length == 12) {
      let payload = {
        "aadhaar": this.hprForm1.value.aadhar
      }

      this.registrationService.post(`registration/hpr/genAadharOtp`, payload).subscribe(res => {
        if (res.status = "OK") {
          this.otpRes = res.data;
          this.enableBtn = false;
          this.toastr.info(res.message);
          this.entrOTP = false;
          setTimeout(() => {
            this.resend_otp = true
          }, 30000);
          this.aadhaartimer()
          // document.getElementById('Btn2').removeAttribute("disabled");
        } else {
          this.toastr.error(res.message)
        }
      }, error => this.toastr.error(error.message))
    } else {
      this.toastr.error('Please Enter Valid Aadhar Number')
    }

  }

  resendOtp() {
    if (this.istermsChecked == false) {
      this.toastr.error('Please accept the terms & conditions');
      return;
    }
    let data: any = { 'txnId': this.otpRes?.txnId }
    let type: any = this.hprForm1.value.verify_with == 'AADHAAR_OTP' ? 'aadhaar' : 'mobile';
    this.registrationService.resendOtps('registration/jwt/apis/aadhaar/resend-aadhaar-otp', data).subscribe(res => {
      if (res.status = "OK") {
        this.otpRes = res.data;
        this.enableBtn = false;
        this.toastr.info(res.message);
        this.entrOTP = false;
        setTimeout(() => {
          this.resend_otp = true
        }, 30000);

        this.aadhaartimer()

      } else {
        this.toastr.error(res.message)
      }
    })
  }
  aadhaartimer() {
    // let minute = 1;
    let minute: any
    let seconds: number = 90;
    let textSec: any = '0';
    let statSec: number = 90;

    const prefix = minute < 10 ? '0' : '';

    const timer = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = '0' + statSec;
      } else textSec = statSec;

      this.display = `${textSec}`;

      if (seconds == 0) {
        this.enableBtn = true;
        this.resend_otp = true
        console.log('finished');
        clearInterval(timer);
      }
    }, 1000);
  }
  verifyOTP(type: string) {
    let payLoad = {
      "domainName": "@hpr.abdm",
      "idType": "hpr_id",
      "otp": this.hprForm1.value.otp,
      "restrictions": "",
      "txnId": this.otpRes?.txnId
    }

    this.registrationService.post(`registration/hpr/verifyAadharOtp`, payLoad).subscribe(res => {
      if (res.status != 'OK') {
        this.toastr.error(res.message);
      } else {
        this.verifyRes = res.data;
        this.isDisableMobOtp = false;
        this.display = "";
        this.toastr.success(res.message);
        this.toastr.info('Please Enter Mobile Number');
        const names = this.verifyRes?.name.split(' ');
        const firstName = names[0];
        const lastName = names[names.length - 1];
        const middleName = names.slice(1, -1).join(' ');
        this.hprForm1.get('firstName').setValue(firstName)
        this.hprForm1.get('middleName').setValue(middleName)
        this.hprForm1.get('lastName').setValue(lastName)
        this.hprForm1.patchValue({
          birthDate: this.verifyRes?.birthdate,
          postalCode: this.verifyRes?.pincode,
          email: this.verifyRes?.email || "",
          mobile: this.verifyRes?.mobileNumber || "",
          address: this.verifyRes?.address,
          gender: this.verifyRes?.gender
        })
        this.addressAsPerKyc = this.verifyRes?.address
        if (this.addressAsPerKyc != "") {
          this.isAddressVerified == true
        } else {
          this.isAddressVerified == true
        }
        this.checkAccountExistsByAadhar();
        this.genOTP = false;

      }

    }, (err: HttpErrorResponse) => {
      this.toastr.error(err.message)
    });
  }

  checkAccountExistsByAadhar() {
    let payLoad = {
      "txnId": this.otpRes?.txnId,
      "preverifiedCheck": true
    }
    this.registrationService.post(`registration/hpr/checkAccountExistsByAadhar`, payLoad).subscribe(res => {
      if (res.status != 'OK') {
        this.toastr.error(res.message);
      } else {
        this.checkAccountRes = res.data;
        if (this.checkAccountRes?.hprIdNumber == '' || this.checkAccountRes?.hprIdNumber == null || this.checkAccountRes?.hprIdNumber == undefined) {
          this.hprForm1.get('firstName').setValue(res.data?.firstName)
          this.hprForm1.get('middleName').setValue(res.data?.middleName)
          this.hprForm1.get('lastName').setValue(res.data?.lastName)
          this.isSubmitButton = true
        } else {
          this.isSubmitButton = false
          this.isDisableMobOtp = true;
          this.hprId = this.checkAccountRes?.hprId;
          this.suggestionId = this.hprId
          this.hprNumber = this.checkAccountRes?.hprIdNumber;
          this.toastr.info(`Your hprIdNumber is ${this.checkAccountRes?.hprIdNumber}`)
          this.fetchDetails();
          //  this.onPopUp();
          return;
        }

      }

    }, (err: HttpErrorResponse) => {
      this.toastr.error(err.message)
    });
  }

  generateOTP() {

    let payLoad = {
      "txnId": this.otpRes?.txnId,
      "mobileNumber": this.hprForm1.value.mobileNumber
    }
    this.registrationService.post(`registration/hpr/mobileSameAsAadhar`, payLoad).subscribe(res => {
      if (res.status == "OK") {
        if (res.data == null) {
          this.toastr.error(res.message)
        } else {
          this.mobileLinked = res.data

          if (this.mobileLinked) {
            this.suggessionAPI();
            // this.mobileTimer();

          }
        }
      }
      else {
        this.generateMobile();
      }
    }, (err: HttpErrorResponse) => {
      this.toastr.error(err.message)
      // this.generateMobile();
    });

  }
  generateMobile() {
    let payLoad = {
      "mobile": this.hprForm1.value.mobileNumber,
      "txnId": this.otpRes?.txnId
    }
    this.registrationService.post(`registration/hpr/generateMobileOTP`, payLoad).subscribe({
      next: res => {
        if (res.status == 'OK') {
          this.generateMobileData = res.data ?? []
          if (this.generateMobileData) {
            this.mobileLinked = true
          }
          this.toastr.success(res.message)

        } else {
          let errMsg: string = res.message;
          this.toastr.error(errMsg);
        }
      },
      error: (err: HttpErrorResponse) => {
        this.toastr.error(err.error.message);

      },
      complete: () => {
        // Any additional logic after patching values
      },
    });
  }
  verifiedMobile() {
    let payLoad = {
      "otp": "159073",
      "txnId": this.otpRes?.txnId
    }
    this.registrationService.post(`registration/hpr/verifyMobileOTP`, payLoad).subscribe({
      next: res => {
        if (res.status == 'OK') {
          this.verifyMobile = res.data ?? []
          this.toastr.success(res.message)

        } else {
          let errMsg: string = res.message;
          this.toastr.error(errMsg);
        }
      },
      error: (err: HttpErrorResponse) => {
        this.toastr.error(err.error.message);

      },
      complete: () => {
        // Any additional logic after patching values
      },
    });
  }


  suggessionAPI() {

    let payLoad = {
      "txnId": this.otpRes?.txnId

    }
    this.registrationService.post(`registration/hpr/suggestions`, payLoad).subscribe({
      next: res => {
        if (res.status == 'OK') {
          this.suggestionList = res.data ?? []
          this.toastr.success(res.message)

        } else {
          let errMsg: string = res.message;
          this.toastr.error(errMsg);
        }
      },
      error: (err: HttpErrorResponse) => {
        this.toastr.error(err.error.message);

      },
      complete: () => {
        // Any additional logic after patching values
      },
    });

  }
  ownershipApi() {
    this.registrationService.get(`registration/hpr/master?type=OWNER`).subscribe({
      next: res => {
        if (res.status == 'OK') {
          this.ownerTypeList = res.data?.data ?? []
          //  this.toastr.success(res.message)

        } else {
          let errMsg: string = res.message;
          this.toastr.error(errMsg);
        }
      },
      error: (err: HttpErrorResponse) => {
        this.toastr.error(err.message);

      },
      complete: () => {
        // Any additional logic after patching values
      },
    });
  }
  patternCheck(e) {
    //  var pattern = new RegExp('/^[ A-Za-z0-9_@./#&+-]*$/');
    var passw = /^[A-Za-z0-9_@./]\w{8,14}$/;
    if (e.match(passw)) {
      //alert('Correct, try another...')
      // this.patternResult = true;
    }
    else {
      //alert('Wrong...!')
      // this.patternResult = false;
    }
    // this.patternResult=pattern.test(this.newPassword);
  }
  passwordcheck() {
    if (this.hprForm1.value.password == "") {
      this.toastr.info("Please Enter password")
      return
    } else {
      if (this.hprForm1.value.password == this.hprForm1.value.confirmpassword) {
        this.toastr.info("Password Matched")

      } else {
        this.toastr.info("Confirm Password Not Matched With password");
        this.hprForm1.get('confirmpassword').setValue('')

      }
    }

  }
  healthSubCategory(value: any) {
    this.passwordcheck();
    if (value == "administator") {
      this.subCatcode = 11
    } else if (value == "doctor") {
      this.subCatcode = 1
    }
    else if (value == "manufacturer") {
      this.subCatcode = 81
    }
    else if (value == "nurse") {
      this.subCatcode = 2
    }

    this.registrationService.get(`registration/hpr/get-hpid-sub-category?categoryCode=${this.subCatcode}`).subscribe({
      next: res => {
        if (res.status == 'OK') {
          this.subCategoryList = res.data ?? []
          //  this.toastr.success(res.message)

        } else {
          let errMsg: string = res.message;
          this.toastr.error(errMsg);
        }
      },
      error: (err: HttpErrorResponse) => {
        this.toastr.error(err.error.message);

      },
      complete: () => {
        // Any additional logic after patching values
      },
    });
  }
  onChangesuggestion(item: any) {
    this.suggestionId = item

    // const names = item.split(' ');
    // const firstName = names[0];
    // const lastName = names[names.length - 1];
    // const middleName = names.slice(1, -1).join(' ');
    // this.hprForm1.get('firstName').setValue(firstName)
    // this.hprForm1.get('middleName').setValue(middleName)
    // this.hprForm1.get('lastName').setValue(lastName)

  }
  createHprIdWithPreVerified() {
    // if((this.facilityselection==true && this.facilityId=="")||
    // (this.facilityselection==true && this.facilityId==null)||
    // (this.facilityselection==true && this.facilityId==undefined)){
    //   this.toastr.info("Please Select Facility");
    //   return
    // }
    this.submitted1 = true, Common.getFormValidationErrors(this.hprForm1);
    if (this.hprForm1.invalid) {
      this.toastr.info('Please Enter All Mandatory Fields');
      return;
    }

    let payLoad = {

      "idType": "hpr_id",
      "domainName": "@hpr.abdm",
      "email": this.hprForm1.value.email,
      "firstName": this.hprForm1.value.firstName,
      "middleName": this.hprForm1.value.middleName,
      "lastName": this.hprForm1.value.lastName,
      "password": this.hprForm1.value.password,
      "stateCode": this.hprForm1.value.state,
      "districtCode": this.hprForm1.value.district,
      "pincode": this.hprForm1.value.postalCode,
      "txnId": this.verifyRes?.txnId,
      "hprId": this.suggestionId,
      "hpCategoryCode": 1,
      "hpSubCategoryCode": 1,
      "notifyUser": true

    }
    this.preVerifiedPayLoad = payLoad
    this.registrationsrv.post(`registration/hpr/createHprIdWithPreVerified`, payLoad).subscribe({
      next: res => {
        if (res.status == 'OK') {
          this.preverify = res.data ?? []
          this.hprSave1();
          this.toastr.success(res.message)

        } else {
          let errMsg: string = res.message;
          this.toastr.error(errMsg);
        }
      },
      error: (err: HttpErrorResponse) => {
        this.toastr.error(err.error.message);

      },
      complete: () => {
        // Any additional logic after patching values
      },
    });
  }

  hprSave1() {

    let payLoad = {
      "id": null,
      "doctorId": this.doctor?.id || null,
      "hubId": null,
      "centerId": null,
      "hprPayload": this.preVerifiedPayLoad,
      "hprId": this.doctor?.hpr_id || this.suggestionId == undefined ? this.checkAccountRes?.hprId : this.suggestionId,
      "hprNumber": this.doctor?.hpr_number || this.hprNumber || "",
      "isHprLinked": true,
      "isActive": true,
      "userId": sessionStorage.loginId,
      "createdBy": sessionStorage.loginId,
      "modifiedBy": sessionStorage.loginId,
      "createdOn": new Date(),
      "modifiedOn": new Date()
    }
    this.registrationsrv.post(`registration/hpr/save-hpr-record`, payLoad).subscribe({
      next: res => {
        if (res.status == 'OK') {
          this.preverify = res.data ?? []
          //  this.registerProfessionalNew();
          this.toastr.success(res.message)
          setTimeout(() => {
            this.onPopUp();
          }, 3000);


        } else {
          let errMsg: string = res.message;
          this.toastr.error(errMsg);
        }
      },
      error: (err: HttpErrorResponse) => {
        this.toastr.error(err.error.message);

      },
      complete: () => {
        // Any additional logic after patching values
      },
    });
  }

  onPopUp() {
    if (confirm(` Your HPR ID Is ${this.suggestionId} Do You Want To Fill All Remaining Fields Fields`)) {
      this.fillAllFields = true
    }
    else if (this.doctor == "" || this.doctor == null || this.doctor == undefined) {
      this.fillAllFields = false
    }
    else {
      this.activeModal.close('changes saved');
    }

  }




  registerProfessionalNew() {
    this.submitted1 = true,
      Common.getFormValidationErrors(this.hprForm2);
    if (this.hprForm2.invalid) {
      this.toastr.info('Please Enter All Mandatory Fields in Stage 1 ');
      return;
    }

    this.submitted1 = true,
      Common.getFormValidationErrors(this.hprForm3);
    if (this.hprForm3.invalid) {
      this.toastr.info('Please Enter All Mandatory Fields in Stage 2 ');
      return;
    }
    if (this.hprForm4.value.currentlyWorking == '1') {
      if (this.hprForm4.value.purposeOfWork == "" || this.hprForm4.value.purposeOfWork == null || this.hprForm4.value.purposeOfWork == undefined) {
        this.toastr.info("Please Select Nature Of Work")
        return
      } else if (this.hprForm4.value.attachment == "" || this.hprForm4.value.attachment == null || this.hprForm4.value.attachment == undefined) {
        this.toastr.info("Attachment is mandatory")
        return
      }
    } else if (this.hprForm4.value.purposeOfNotWork == "" || this.hprForm4.value.purposeOfNotWork == null || this.hprForm4.value.purposeOfNotWork == undefined) {
      this.toastr.info("Please select reason for presently not working")
      return
    }
    //  let payLoad1={

    //     "hprToken": this.preverify?.token,
    //     "practitioner": {
    //       "healthProfessionalType": this.hprForm1.value.healthProfessionalType,
    //       "officialMobile": this.preverify?.mobile, 
    //       "personalInformation": {
    //         "salutation": this.hprForm1.value.salutation,
    //         "firstName": this.preverify?.firstName,
    //         "nationality": "356",
    //         "languagesSpoken": this.selectedSpokenLanguages
    //       },
    //       "addressAsPerKYC": "",
    //       "communicationAddress": {
    //         "address": "#9/1",
    //         "name": this.preverify?.firstName +''+ this.preverify?.middleName+''+this.preverify?.lastName,
    //         "country": "356",
    //         "state": this.hprForm1.value.state,
    //         "district": this.hprForm1.value.district,
    //         "postalCode": this.hprForm1.value.postalCode
    //       },
    //       "registrationAcademic": {
    //         "category": "1",
    //         "registrationData": [
    //           {
    //             "registeredWithCouncil": this.hprForm1.value.registeredWithCouncil,
    //             "registrationNumber": this.hprForm1.value.registrationNumber,
    //             "isNameDifferentInCertificate": "0",
    //             "categoryId": "1",
    //             "qualifications": [
    //               {
    //                 "nameOfDegreeOrDiplomaObtained": this.hprForm1.value.qualifications,
    //                 "country": "356",
    //                 "state": this.preverify?.StateCode,
    //                 "college": this.hprForm1.value.college,
    //                 "university": this.hprForm1.value.university,
    //                 "yearOfAwardingDegreeDiploma": "2020",
    //                 "isNameDifferentInCertificate": "0"
    //               }
    //             ]
    //           }
    //         ]
    //       },
    //       "currentWorkDetails": {
    //         "currentlyWorking": "1",
    //         "purposeOfWork": "Practice",
    //         "chooseWorkStatus": "0",
    //         "facilityDeclarationData": {
    //           "facilityId": this.facilityId || "",
    //           "facilityDepartment": this.facilityDepartment||"",
    //           "facilityDesignation":this.facilityDesignation||""
    //         }
    //       }
    //     }
    //   }
    let payLoad = {
      "hprToken": this.preverify?.token,
      "practitioner": {
        "healthProfessionalType": this.hprForm1.value.healthProfessionalType,
        "profileVisibleToPublic": this.VisblePrfPulc,
        "officialMobile": this.hprForm1.value.mobile,
        "officialMobileCode": "",
        "officialMobileStatus": "1",
        "officialEmail": this.hprForm1.value.email,
        "officialEmailStatus": "",
        "personalInformation": {
          "salutation": this.hprForm2.value.salutation,
          "firstName": this.hprForm2.value.firstName,
          "nationality": "356",
          "languagesSpoken": this.selectedSpokenLanguages,
        },
        "addressAsPerKYC": "",
        "communicationAddress": {
          "address": this.hprForm2.value.address,
          "name": this.preverify?.firstName + '' + this.preverify?.middleName + '' + this.preverify?.lastName,
          "country": "356",
          "state": this.hprForm2.value.state,
          "district": this.hprForm2.value.district,
          "postalCode": this.hprForm2.value.postalCode
        },
        "contactInformation": {
          "publicMobileNumber": this.hprForm2.value.publicMobileNumber,
          "publicMobileNumberCode": "",
          "publicMobileNumberStatus": this.hprForm2.value.publicMobileNumberStatus,
          "landLineNumber": this.hprForm2.value.landLineNumber,
          "landLineNumberCode": "",
          "publicEmail": this.hprForm2.value.publicEmail,
          "publicEmailStatus": this.hprForm2.value.publicEmailStatus,
        },
        "registrationAcademic": {
          "category": "1",
          "registrationData": [
            {
              "registeredWithCouncil": this.hprForm3.value.registeredWithCouncil,
              "registrationNumber": this.hprForm3.value.registrationNumber,
              "isNameDifferentInCertificate": this.hprForm3.value.isNameDifferentInCertificate,
              "registrationDate": this.hprForm3.value.registrationDate,
              "isPermanentOrRenewable": this.hprForm3.value.isPermanentOrRenewable,
              "renewableDueDate": this.hprForm3.value.renewableDueDate,
              "categoryId": "1",
              "qualifications": [
                {
                  "nameOfDegreeOrDiplomaObtained": this.hprForm3.value.qualifications,
                  "country": "356",
                  "state": this.hprForm3.value.state,
                  "college": this.hprForm3.value.college,
                  "university": this.hprForm3.value.university,
                  "yearOfAwardingDegreeDiploma": this.hprForm3.value.yearOfAwardingDegreeDiploma,
                  "isNameDifferentInCertificate": this.hprForm3.value.isNameDifferentInDegree,
                }
              ]
            }
          ]
        },
        "currentWorkDetails": {
          "currentlyWorking": this.hprForm3.value.currentlyWorking,
          "purposeOfWork": this.hprForm3.value.purposeOfWork || this.hprForm3.value.purposeOfNotWork,
          "chooseWorkStatus": this.hprForm3.value.chooseWorkStatus,
          "facilityDeclarationData": {
            "facilityId": this.facilityId || "",
            "facilityDepartment": this.facilityDepartment || "",
            "facilityDesignation": this.facilityDesignation || ""
          }
        }
      }
    }

    this.hprsavepayload = payLoad
    this.registrationsrv.post(`registration/hpr/registerProfessionalNew`, payLoad).subscribe({
      next: res => {
        if (res.status == 'OK') {
          let hprsave = res.data ?? []
          this.hprSave();
          this.toastr.success(res.message)

        } else {
          let errMsg: string = res.message;
          this.toastr.error(errMsg);
        }
      },
      error: (err: HttpErrorResponse) => {
        this.toastr.error(err.error.message);

      },
      complete: () => {
        // Any additional logic after patching values
      },
    });
  }


  hprSave() {

    let payLoad = {
      "id": null,
      "doctorId": this.doctor?.id || null,
      "hubId": null,
      "centerId": null,
      "hprPayload": this.hprsavepayload,
      "hprId": this.doctor?.hpr_id || this.suggestionId == undefined ? this.checkAccountRes?.hprId : this.suggestionId + '@hpr.abdm',
      "hprNumber": this.doctor?.hpr_number || this.hprNumber || "",
      "isHprLinked": true,
      "isActive": true,
      "userId": sessionStorage.loginId,
      "createdBy": sessionStorage.loginId,
      "modifiedBy": sessionStorage.loginId,
      "createdOn": new Date(),
      "modifiedOn": new Date()
    }
    this.registrationsrv.post(`registration/hpr/save-hpr-record`, payLoad).subscribe({
      next: res => {
        if (res.status == 'OK') {
          this.preverify = res.data ?? []
          //  this.registerProfessionalNew();
          this.toastr.success(res.message)

        } else {
          let errMsg: string = res.message;
          this.toastr.error(errMsg);
        }
      },
      error: (err: HttpErrorResponse) => {
        this.toastr.error(err.error.message);

      },
      complete: () => {
        // Any additional logic after patching values
      },
    });
  }

  reGenerateOtp() {
    if (this.hprForm1.value.adhrNum && this.hprForm1.value.adhrNum != '') {
      let data: any = { 'txnId': this.genOTPRes?.txnId }
      // let type: any = this.patientForm.value.verify_with == 'AADHAAR_OTP' ? 'aadhaar' : 'mobile';

      this.registrationService.resendOtp('registration/jwt/apis/mobile/resend-mobile-otp', data).subscribe(res => {
        this.genOTPRes = res;
        this.genOTP = true
        this.getOtpBtn = true
        this.mobileLinked = res.data?.mobileLinked

        if (res.data?.mobileLinked == true) {
          this.showFields = true
        }
        this.verOTP = false;
        this.mobileTimer();
        this.toastr.info('Please submit the Otp Received On Your Aadhar Linked Number');
      }, (err: HttpErrorResponse) => {
        this.toastr.error(err.message)
      });
    } else {
      this.toastr.warning('Please Enter Valid Mobile Number');
    }
  }

  mobileTimer() {
    // let minute = 1;
    let minute: any
    let seconds: number = 90;
    let textSec: any = '0';
    let statSec: number = 90;

    const prefix = minute < 10 ? '0' : '';

    const timer = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = '0' + statSec;
      } else textSec = statSec;

      this.displayTimer = `${textSec}`;

      if (seconds == 0) {
        this.genOTP = false
        this.resendMobileOtp = true
        this.getOtpBtn = false
        console.log('finished');
        clearInterval(timer);
      }
    }, 1000);
  }

  stateApi() {
    this.registrationService.get(`registration/hpr/getStatesData`).subscribe({
      next: res => {
        if (res.status == 'OK') {
          this.hprStateList = res.data ?? []

          //  this.toastr.success(res.message)

        } else {
          let errMsg: string = res.message;
          this.toastr.error(errMsg);
        }
      },
      error: (err: HttpErrorResponse) => {
        this.toastr.error(err.error.message);

      },
      complete: () => {
        // Any additional logic after patching values
      },
    });
  }

  districtApi(value: any) {
    this.registrationService.get(`registration/hpr/getDistrictDataByStateCode?stateCode=${value}`).subscribe({
      next: res => {
        if (res.status == 'OK') {
          this.hprDistrictList = res.data ?? []
          //  this.toastr.success(res.message)

        } else {
          let errMsg: string = res.message;
          this.toastr.error(errMsg);
        }
      },
      error: (err: HttpErrorResponse) => {
        this.toastr.error(err.error.message);

      },
      complete: () => {
        // Any additional logic after patching values
      },
    });
  }

  subDistrictApi(value: any) {
    this.registrationService.get(`registration/hpr/getSubDistrictDataByDistrictCode?districtCode=${value}`).subscribe({
      next: res => {
        if (res.status == 'OK') {
          this.hprSubDistrictList = res.data ?? []
          //  this.toastr.success(res.message)

        } else {
          let errMsg: string = res.message;
          this.toastr.error(errMsg);
        }
      },
      error: (err: HttpErrorResponse) => {
        this.toastr.error(err.error.message);

      },
      complete: () => {
        // Any additional logic after patching values
      },
    });
  }

  salutationApi() {
    this.masterService.getAll(`get-ndhm-salutations`).subscribe({
      next: res => {
        if (res.status == 'OK') {
          this.salutationList = res.data ?? []
          //  this.toastr.success(res.message)

        } else {
          let errMsg: string = res.message;
          this.toastr.error(errMsg);
        }
      },
      error: (err: HttpErrorResponse) => {
        this.toastr.error(err.error.message);

      },
      complete: () => {
        // Any additional logic after patching values
      },
    });
  }

  medicineApi() {
    this.masterService.getAPI(`vidmed-login/masters/systemofmedicine/getall`).subscribe({
      next: res => {
        if (res.status == 'OK') {
          this.medicineList = res.data ?? []
          //  this.toastr.success(res.message)

        } else {
          let errMsg: string = res.message;
          this.toastr.error(errMsg);
        }
      },
      error: (err: HttpErrorResponse) => {
        this.toastr.error(err.error.message);

      },
      complete: () => {
        // Any additional logic after patching values
      },
    });
  }

  councilApi() {
    this.registrationService.get(`masters/DoctorCouncil/getall`).subscribe({
      next: res => {
        if (res.status == 'OK') {
          this.councilList = res.data ?? []
          //  this.toastr.success(res.message)

        } else {
          let errMsg: string = res.message;
          this.toastr.error(errMsg);
        }
      },
      error: (err: HttpErrorResponse) => {
        this.toastr.error(err.error.message);

      },
      complete: () => {
        // Any additional logic after patching values
      },
    });
  }
  onItemSelectCategory(item: any) {
    var addTotal
    if (this.spokenLanguagesList.filter(f => f.id == item.id))
      // this.selectedItemSpokenLng.push(item);
      this.languagelst.push(item.id);
    console.log('languagelst', this.languagelst);

    this.selectedSpokenLanguages = this.languagelst.join(',');
    // this.selectedItemSpokenLng = [...this.selectedItemSpokenLng];
    // console.log("this.selectedItemSpokenLng", this.selectedItemSpokenLng);
    console.log("this.selectedItemSpokenLngonlyIds", this.selectedSpokenLanguages);
  }

  onItemDeSelectCategory(item: any) {
    var addTotal = 0
    // var data = this.selectedItemSpokenLng;
    var data = this.languagelst;
    data.forEach((x, i) => {
      // if (x.id == item.id) data.splice(i, 1);
      if (x == item.id) data.splice(i, 1);
    });

    this.selectedItemSpokenLng = [...data];
    // this.selectedItemSpokenLng.push(item.id);
    this.selectedSpokenLanguages = this.selectedItemSpokenLng.join(',')
    // console.log("removeselectedItemSpokenLngids", this.selectedItemSpokenLng);
    console.log("removeselectedSpokenLanguagesonlyids", this.selectedSpokenLanguages);

    // var filterDeletedItem = this.dropdownList1.filter(f => f.id == item.id);
    // addTotal = filterDeletedItem[0].normal_cost
    // setTimeout(() => {
    //   this.packageCost = this.packageCost - addTotal
    // }, 2);

  }
  spokenLanguages() {
    this.masterService.getAll(`get-abdm-spoken-languages`).subscribe({
      next: res => {
        if (res.status == 'OK') {
          this.spokenLanguagesList = res.data ?? []
          //  this.toastr.success(res.message)

        } else {
          let errMsg: string = res.message;
          this.toastr.error(errMsg);
        }
      },
      error: (err: HttpErrorResponse) => {
        this.toastr.error(err.error.message);

      },
      complete: () => {
        // Any additional logic after patching values
      },
    });
  }

  degreeDD() {
    this.masterService.getAll(`get-doctor-degree`).subscribe({
      next: res => {
        if (res.status == 'OK') {
          this.hprqualificationList = res.data ?? []
          //  this.toastr.success(res.message)

        } else {
          let errMsg: string = res.message;
          this.toastr.error(errMsg);
        }
      },
      error: (err: HttpErrorResponse) => {
        this.toastr.error(err.error.message);

      },
      complete: () => {
        // Any additional logic after patching values
      },
    });
  }

  qualificationDD() {
    this.masterService.getAll(`get-doctor-specializations`).subscribe({
      next: res => {
        if (res.status == 'OK') {
          this.hprspecializationList = res.data ?? []
          //  this.toastr.success(res.message)

        } else {
          let errMsg: string = res.message;
          this.toastr.error(errMsg);
        }
      },
      error: (err: HttpErrorResponse) => {
        this.toastr.error(err.error.message);

      },
      complete: () => {
        // Any additional logic after patching values
      },
    });
  }

  universityDD(value: any) {
    this.universityList = [];
    this.masterService.getAPI(`vidmed-login/masters/get-university/${value}`).subscribe({
      next: res => {
        if (res.status == 'OK') {
          this.universityList = res.data ?? []
          this.hprForm1.get('university').setValue('')
          //  this.toastr.success(res.message)

        } else {
          let errMsg: string = res.message;
          this.toastr.error(errMsg);
        }
      },
      error: (err: HttpErrorResponse) => {
        this.toastr.error(err.error.message);

      },
      complete: () => {
        // Any additional logic after patching values
      },
    });
  }
  collegeDD() {
    this.collegeList = [];
    if (this.hprForm3.value.medicine == "" || this.hprForm3.value.medicine == null || this.hprForm3.value.medicine == undefined) {
      this.toastr.info("Please Select System Of Medicine")
      return
    }
    if (this.hprForm3.value.state == "" || this.hprForm3.value.state == null || this.hprForm3.value.state == undefined) {
      this.toastr.info("Please Select State")
      return
    }
    // this.systemOfMedId = this.medicineList.find( (e: any) => e.ndhm_code == this.hprForm1.value.medicine).id;
    this.masterService.getAPI(`vidmed-login/masters/get-colleges-by-stateid/${this.hprForm3.value.state}/${this.selectedSystemOfMedicinesList}`).subscribe({
      next: res => {
        if (res.status == 'OK') {
          this.collegeList = res.data ?? []
          //  this.toastr.success(res.message)

        } else {
          let errMsg: string = res.message;
          this.toastr.error(errMsg);
        }
      },
      error: (err: HttpErrorResponse) => {
        this.toastr.error(err.error.message);

      },
      complete: () => {
        // Any additional logic after patching values
      },
    });
  }
  facilitySearch() {
    this.dtRenderF = false
    if (this.isFacilityEnable == true) {
      if (this.facilityName == "" || this.facilityName == null || this.facilityName == undefined) {
        this.toastr.info("Please provide Ownership, State,District and Facility Name")
        return
      }
    } else if (this.isFacilityEnable == false) {
      if (this.FacilityId == "" || this.FacilityId == null || this.FacilityId == undefined) {
        this.toastr.info("Please provide Facility Id")
        return
      }
    }

    let payload = {
      "ownershipCode": this.hprownershipCode || "",
      "stateLGDCode": this.stateCode || "",
      "districtLGDCode": this.districtCode || "",
      "subDistrictLGDCode": this.subDistrictCode || "",
      "pincode": this.hprForm1.value.postalCode || "",
      "facilityName": this.facilityName || "",
      "facilityId": this.FacilityId || "",
      "page": 1,
      "resultsPerPage": 2
    }
    this.registrationService.post(`registration/hfr/search`, payload).subscribe({
      next: res => {
        if (res.status == 'OK') {
          this.facilitySearchList = res.data?.facilities ?? []
          this.dtRenderF = true
          this.facilitySearchList.forEach(e => { e.SelectFacilityRadio = false })
          console.log(this.facilitySearchList);


          //  this.toastr.success(res.message)

        } else {
          let errMsg: string = res.message;
          this.dtRenderF = true
          this.toastr.error(errMsg);
        }
      },
      error: (err: HttpErrorResponse) => {
        this.toastr.error(err.error.message);

      },
      complete: () => {
        // Any additional logic after patching values
      },
    });
  }

  selectFacilityType(item: any) {
    this.facilityDesignation = item?.facilityType
    this.facilityDepartment = item?.systemOfMedicine
    this.facilityId = item?.facilityId
  }

  addFacility(value: any) {
    if (value == 'Yes') {
      this.facilityselection = true;
    } else {
      this.facilityselection = false;
    }
  }
  changePublicMb(value: any) {
    if (this.hprForm2.value.publicMobileNumberStatus == "1") {
      this.hprForm2.get('publicMobileNumber').setValue(this.hprForm1.value.mobile)
      this.mobileField = true
    } else {
      this.hprForm2.get('publicMobileNumber').setValue('')
      this.mobileField = false
    }
  }
  changePublicEmail(value: any) {
    if (this.hprForm2.value.publicEmailStatus == "1") {
      this.hprForm2.get('publicEmail').setValue(this.hprForm1.value.email)
      this.emailField = true
    } else {
      this.hprForm2.get('publicEmail').setValue('')
      this.emailField = false
    }
  }
  // System Of Medicine multiselect Start
  onItemSelectAllSysMedicine(item: any) {
    let list = []
    list.push(this.medicineList.map(item => item.ndhm_code));
    this.selectedItemsSysOfMedicine = list[0];
    this.selectedSystemOfMedicinesList = this.selectedItemsSysOfMedicine.join(',')
    // console.log("Select All System Of Medicine", this.selectedItemsSysOfMedicine);
    console.log("Select All System Of Medicine", this.selectedSystemOfMedicinesList);
  }
  onItemDESelectAllSysMedicine(item: any) {
    this.selectedItemsSysOfMedicine = [];
    console.log("DE System Of MedicineListALL", this.selectedItemsSysOfMedicine);
  }

  onItemSelectSysMedicine(item: any) {
    var addTotal
    this.selectedFilterValuesMedicine = []
    if (this.medicineList.filter(f => f.ndhm_code == item.ndhm_code))
      // this.selectedItemsMedicine.push(item)
      this.selectedItemsSysOfMedicine.push(item.ndhm_code);
    this.selectedSystemOfMedicinesList = this.selectedItemsSysOfMedicine.join(',')
    this.selectedItemsSysOfMedicine = [...this.selectedItemsSysOfMedicine];
    // console.log("System Of Medicine select", this.selectedItemsSysOfMedicine);
    console.log("System Of Medicine select", this.selectedSystemOfMedicinesList);
  }

  onItemDeSelectSysMedicine(item: any) {
    var addTotal = 0
    var data = this.selectedItemsSysOfMedicine;
    data.forEach((x, i) => {
      if (x == item.ndhm_code) data.splice(i, 1);
    });

    this.selectedItemsSysOfMedicine = [...data];
    this.selectedSystemOfMedicinesList = this.selectedItemsSysOfMedicine.join(',')
    // console.log("removeselectedItemsSysOfMedicine", this.selectedItemsSysOfMedicine);
    console.log("removeselectedItemsSysOfMedicine", this.selectedSystemOfMedicinesList);
    // var filterDeletedItem = this.dropdownList1.filter(f => f.id == item.id);
    // addTotal = filterDeletedItem[0].normal_cost
    // setTimeout(() => {
    //   this.packageCost = this.packageCost - addTotal
    // }, 2);

  }

  patchFetchDetails() {
    const names = this.fetchDetailsList?.practitioners[0]?.name.split(' ');
    const firstName = names[0];
    const lastName = names[names.length - 1];
    const middleName = names.slice(1, -1).join(' ');

    this.hprForm2.patchValue({
      salutation: this.fetchDetailsList?.practitioners[0].salutation,
      nationality: 1,
      firstName: firstName,
      middleName: middleName,
      lastName: lastName,
      country: 1
    })
    this.hprForm3.patchValue({
      medicine: '',
      registeredWithCouncil: '',
      registrationNumber: '',
      registrationDate: '',
      isPermanentOrRenewable: '',
      renewableDueDate: '',
      filename: '',
      isNameDifferentInCertificate: '',
      country: 1,
      state: "",
      qualifications: '',
      university: '',
      college: '',
      yearOfAwardingDegreeDiploma: '',
      monthOfAward: '',
      isNameDifferentInDegree: ''
    })
    this.hprForm4.patchValue({
      currentlyWorking: '',
      purposeOfWork: '',
      purposeOfNotWork: '',
      chooseWorkStatus: '',
      facility: '',
      searchWith: '',
      organization: '',
      attachment: ''
    })
  }
  //  System Of Medicine multiselect End

  fetchDetails() {
    let payload =
    {
      "practitioner": {
        "id": this.hprNumber,
        "name": "",
        "contactNumber": "",
        "state": "",
        "registrationNumber": "",
        "stateCouncilName": ""
      }
    }
    this.registrationService.post(`registration/hpr/fetch-professional-info`, payload).subscribe({
      next: res => {
        if (res.status == 'OK') {
          this.fetchDetailsList = res.data
          if (res.data == null || res.data.length == 0) {
            this.fillAllFields = false
          } else {
            this.fetchDetailsList = res.data
            this.fillAllFields = false
          }

        } else {
          let errMsg: string = res.message;
          this.toastr.error(errMsg);
        }
      },
      error: (err: HttpErrorResponse) => {
        this.toastr.error(err.error.message);

      },
      complete: () => {
        // Any additional logic after patching values
      },
    });
  }
  selectedHprId(d: any) {
    this.hprForm1.get('HprofessionalId').setValue(d);
    this.suggestionId = this.hprForm1.value.HprofessionalId + '@hpr.abdm'
    // this.hprForm1.controls['HprofessionalId'].setValue(d);
  }
  selectedHprIdInput(value: any) {
    this.hprForm1.get('HprofessionalId').setValue(value);
    this.suggestionId = value + '@hpr.abdm'
  }

  getFacilities() {
    this.registrationService.get(`registration/hfr/getallhfrfacilities`).subscribe({
      next: res => {
        if (res.status == 'OK') {
          let fcLst: any[] = res.data ?? [];
          fcLst.forEach(r => { Object.assign(r, { hfrIdNm: `${r.hfr_id}-${r.hfr_name}` }) });
          this.facilityDatalist = fcLst;
          //  this.toastr.success(res.message)

        } else {
          let errMsg: string = res.message;
          this.toastr.error(errMsg);
        }
      },
      error: (err: HttpErrorResponse) => {
        this.toastr.error(err.error.message);

      },
      complete: () => {
        // Any additional logic after patching values
      },
    });
  }

  isFacilitySelect(e: any) {
    let hfrId = e.hfr_id
    this.facilitySelected = true;
    let hfrName1 = this.facilityDatalist.find((el: any) => el.hfr_id == e.hfr_id).hfr_name;
    this.doctorForm.get('facility_name').setValue(hfrName1)
    this.doctorForm.get('facility_id').setValue(hfrId)
    console.log("facility_name", this.doctorForm.value.facility_name);
    console.log("facility_id", this.doctorForm.value.facility_id);
  }
  isFacilityDeSelect(e: any) {
    this.facilitySelected = false;
    this.doctorForm.get('facility_name').setValue('')
    this.doctorForm.get('facility_id').setValue('')
  }
  asPerKYCComAdd() {
    this.hprForm2.patchValue({
      NameAsPerKyc: this.verifyRes?.name,
      address: this.verifyRes?.address,
      postalCode: this.verifyRes?.pincode,

    })
  }
  prsnldetailSave() {
    this.submitted1 = true,
      Common.getFormValidationErrors(this.hprForm2);
    if (this.hprForm2.invalid) {
      this.toastr.info('Please Enter All Mandatory Fields');
      return;
    }
  }
  backOne() {

  }
  changePorR(value: any) {
    if (value == "Permanent") {
      this.renewableDateEnable = false
    }
    else {
      this.renewableDateEnable = true
    }

  }
  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    const fileSizeInMB: number = file.size / (1024 * 1024);

    if (fileSizeInMB > 5) {
      this.fileSizeError = true;
      // Optionally, you can reset the file input to clear the selected file
      // (event.target as HTMLInputElement).value = '';
    } else {
      this.fileSizeError = false;
      // Here you can proceed with the file upload process
    }
  }

  uploadCertificate(value: any) {
    if (value == '1') {
      this.uploadcer = true
    } else {
      this.uploadcer = false
    }


  }
  uploadDegree(value: any) {
    if (value == '1') {
      this.uploadDeg = true
    } else {
      this.uploadDeg = false
    }
  }

  regDetailSave() {
    this.submitted1 = true,
      Common.getFormValidationErrors(this.hprForm3);
    if (this.hprForm3.invalid) {
      this.toastr.info('Please Enter All Mandatory Fields in Stage 2 ');
      return;
    }
  }
  workDetailSave() {
    if (this.hprForm4.value.currentlyWorking == '1') {
      if (this.hprForm4.value.purposeOfWork == "" || this.hprForm4.value.purposeOfWork == null || this.hprForm4.value.purposeOfWork == undefined) {
        this.toastr.info("Please Select Nature Of Work")
        return
      } else if (this.hprForm4.value.attachment == "" || this.hprForm4.value.attachment == null || this.hprForm4.value.attachment == undefined) {
        this.toastr.info("Attachment is mandatory")
        return
      }
    } else if (this.hprForm4.value.purposeOfNotWork == "" || this.hprForm4.value.purposeOfNotWork == null || this.hprForm4.value.purposeOfNotWork == undefined) {
      this.toastr.info("Please select reason for presently not working")
      return
    }
    else {
      this.workDetails = true;
    }
    //   this.submitted1 = true,
    //   Common.getFormValidationErrors(this.hprForm4);
    // if (this.hprForm4.invalid) {
    //   this.toastr.info('Please Enter All Mandatory Fields');
    //   return;
    // }
  }
  previewDetailSave() {
    if (this.declarationBox == false) {
      this.toastr.info("Please Check Declaration CheckBox")
      return
    }
    else {
      this.registerProfessionalNew();
    }
  }

  isstatusWorking(value: any) {
    if (value == '1') {
      this.workFieldsEnable = true
      // this.hprForm4.get('purposeOfWork').setValidators([Validators.required]);
      // this.hprForm4.get('attachment').setValidators([Validators.required]);
      // this.hprForm4.controls['purposeOfNotWork'].clearValidators();
    } else {
      this.workFieldsEnable = false
      // this.hprForm4.controls['purposeOfNotWork'].setValidators([Validators.required]);
      // this.hprForm4.controls['purposeOfWork'].clearValidators();
      // this.hprForm4.controls['attachment'].clearValidators();
    }
  }
  isWorkingStatus(value: any) {
    if (value == '0') {
      this.workStatus = false
    } else {
      this.workStatus = true
    }
  }

  isSearchWith(value: any) {
    if (value == 'true') {
      this.isFacilityEnable = true
    }
    else {
      this.isFacilityEnable = false
    }
  }

  addOrganization(value: any) {
    if (value == 'Yes') {
      this.addorganization = true;
    } else {
      this.addorganization = false;
    }
  }

  addOrganizationTable() {
    if (this.orgStateCode == "") {
      this.toastr.info("Please Select State")
      return
    } else if (this.orgDistrictCode == "") {
      this.toastr.info("Please Select District")
      return
    }
    else if (this.nameOfOrganization == "") {
      this.toastr.info("Please Enter Name Of Organization Where You Work")
      return
    } else if (this.designation == "") {
      this.toastr.info("Please Enter Designation")
      return
    } else if (this.orgAddress == "") {
      this.toastr.info("Please Enter Address")
      return
    } else if (this.orgPinCode == "") {
      this.toastr.info("Please Enter PIN Code")
      return
    }

    this.dtRenderOR = false
    let data = {
      'FacilityID': "",
      'FacilityStatus': "",
      'Name': this.nameOfOrganization,
      'Address': this.orgAddress,
      'State': this.orgStateCode,
      'District': this.orgDistrictCode,
      'Type': this.nameOfOrganization,
      'Department': this.departmentName,
      'Designation': this.designation,
      'eLoc': "",
      'Status': 'Declared',
      'Action': ""
    }
    this.changeDetection.detectChanges();
    this.addOrgTableData.push(data);
    this.dtRenderOR = true;
    this.orgEmpty();
    this.changeDetection.detectChanges();
  }

  orgEmpty() {
    this.orgStateCode = "";
    this.orgDistrictCode = "";
    this.nameOfOrganization = '';
    this.organizationType = '';
    this.departmentName = '';
    this.designation = '';
    this.orgAddress = '';
    this.orgPinCode = '';
  }

  allMndatoryChecking() {
    this.regDetailSave();
    this.workDetailSave();
    // this.previewDetailSave();
  }

  // Link HPR Binding End

  getDoctorTypes(){

    this.registrationService.get(`mvu/doctortypemapping/getbyorgid/${sessionStorage.TENANT_ID}`).subscribe({
      next: res => {
        if (res.status == 'OK') {
           this.docTypes = res.data??[]
        //  this.toastr.success(res.message)

        } else {
          let errMsg: string = res.message;
          this.toastr.error(errMsg);
        }
      },
      error: (err: HttpErrorResponse) => {
        this.toastr.error(err.message);

      },
      complete: () => {
        // Any additional logic after patching values
      },
    });

  }
  isHealth(value){
if(value){
  this.isHW=true
}else{
  this.isHW=false
}
  }

  getwrkShftList(){

    this.registrationService.get(`mvu/work-shift/getall`).subscribe({
      next: res => {
        if (res.status == 'OK') {
           this.wrkShiftLst = res.data??[]  
        } else {
          let errMsg: string = res.message;
          this.toastr.error(errMsg);
        }
      },
      error: (err: HttpErrorResponse) => {
        this.toastr.error(err.message);

      },
      complete: () => {
        // Any additional logic after patching values
      },
    });

  }

}