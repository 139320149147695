import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { ToastrConfig, ToastrService } from 'ngx-toastr';
import { Covid19Masters, IMaster } from 'src/app/shared/models/covid19Masters';


@Component({
  selector: 'app-covid19',
  templateUrl: './covid19.component.html',
  styleUrls: ['./covid19.component.css']
})
export class Covid19Component implements OnInit {

  form: FormGroup;
  @Input() formGroupName: any;
  @Input() stages: any;
  investigationsList: Array<any> = [];
  reasonList: Array<any> = [];
  remarksList: Array<any> = [];
  VaccinesList: Array<any> = [];
  VaccinesStatusList: Array<any> = [];
  MedicinesList: Array<any> = [];
  stageList: Array<any> = [];

  MedicinesSettings: any = {};
  maxDate: Date = new Date();
  constructor(private rootFormGroup: FormGroupDirective
    , private toastr: ToastrService
    , private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
    this.loadLists();
    this.validationControls();
  }

  get Master(): IMaster[] {
    return Covid19Masters.getMasters('master');
  }

  onChange(event: any, type: string) {
    this.form.get(type).setValue(event.checked ? event.value : '');
  }

  loadLists() {
    this.MedicinesSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.investigationsList = [
      { id: 1, name: 'Positive RT-PCR' },
      { id: 2, name: 'Positive Rapid antigen' },
      { id: 3, name: 'Tested negative but Diagnosed on the basis of COVID-19 symptoms' },
      { id: 4, name: 'Tested, result awaited and Diagnosed on the basis of COVID-19 symptoms' },
      { id: 5, name: 'Not tested but Diagnosed on the basis of COVID-19 symptoms' },
      { id: 6, name: 'Other method' },
    ];

    this.reasonList = [
      { id: 1, name: 'Chance of exposure' },
      { id: 2, name: 'Onset of symptoms' },
      { id: 3, name: 'Other reasons' },
    ];

    this.remarksList = [
      { id: 1, name: 'MILD' },
      { id: 2, name: 'MODERATE' },
      { id: 3, name: 'SEVERE' },
    ];

    this.VaccinesList = [
      { id: 1, name: 'COVISHIELD' },
      { id: 2, name: 'COVAXIN' },
      { id: 3, name: 'SPUTNIK V' },
      { id: 4, name: 'Others' },
    ];

    this.VaccinesStatusList = [
      { id: 1, name: 'Single Dose' },
      { id: 2, name: '2nd Dose' },
      { id: 3, name: "Haven't received" },
    ];

    this.stageList = [
      { id: 1, name: 'PREVENTIVE' },
      { id: 2, name: 'MILD' },
      { id: 3, name: 'MODERATE' },
      { id: 4, name: 'SEVERE' },
      { id: 5, name: 'POST COVID-19 REHABILITATION' },
    ];

    this.MedicinesList = [
      { id: 1, name: 'Methylprednisolone' },
      { id: 2, name: 'Tab Ivermectin' },
      { id: 3, name: 'Tab Zinc' },
      { id: 4, name: 'Dexamethasone' },
      { id: 5, name: 'Tab Paracetamol' },
      { id: 6, name: 'Tab Vitamin C' },
      { id: 7, name: 'Inhalational Budesonide' },
      { id: 8, name: 'Tab Azithromycin' },
      { id: 9, name: 'Antiviral (Remdesivir)' },
    ];

  }

  validationControls() {
    const [investigations, nameFormControl, ctFormControl, specifyFormControl,
      reasonFormControl, vaccineFormControl, otherFormControl,
      vaccinationStatusFormControl, vaccinationDateFormControl, symptomsFormControl] =
      [
        this.form.get("patient_identified.investigations"),
        this.form.get("patient_identified.name"),
        this.form.get("patient_identified.ct_value"),
        this.form.get("patient_identified.specify"),
        this.form.get("patient_identified.reason_for_testing"),
        this.form.get("clinical_evalution_of_the_patient.vaccine_name"),
        this.form.get("clinical_evalution_of_the_patient.other_name"),
        this.form.get("clinical_evalution_of_the_patient.vaccination_status"),
        this.form.get("clinical_evalution_of_the_patient.vaccination_date"),
        this.form.get("symptoms"),
      ];

    investigations.valueChanges.subscribe((name: string) => {
      (name == 'Other method') ? nameFormControl.enable() : nameFormControl.disable();
      (name == 'Positive RT-PCR') ? ctFormControl.enable() : ctFormControl.disable();

      ctFormControl.setValue('');
      nameFormControl.setValue('');
    });

    reasonFormControl.valueChanges.subscribe((name: string) => {
      (name == 'Other reasons') ? specifyFormControl.enable() : specifyFormControl.disable();
      specifyFormControl.setValue('');
    });

    vaccineFormControl.valueChanges.subscribe((name: string) => {
      (name == 'Others') ? otherFormControl.enable() : otherFormControl.disable();
      otherFormControl.setValue('');
    });
    symptomsFormControl.valueChanges.subscribe((status: boolean) => {
      status ? this.addPresenting_complaints() : this.removePresenting_complaints();
    });
    vaccinationStatusFormControl.valueChanges.subscribe((name: string) => {
      if (name == "Haven't received") {
        vaccineFormControl.disable();
        vaccinationDateFormControl.disable();
        otherFormControl.disable();
      }
      else {
        vaccineFormControl.enable();
        vaccinationDateFormControl.enable();
        otherFormControl.enable();
      }
      vaccineFormControl.setValue('');
      vaccinationDateFormControl.setValue('');
      otherFormControl.setValue('');
    });
  }

  presenting_complaints(): FormArray {
    return this.form.get("presenting_complaints") as FormArray;
  }

  newPresenting_complaints(item: any): FormGroup {
    return this.formBuilder.group({
      symptoms: [item],
      check_box: [false],
      day_of_consultation: [{ value: '', disabled: true }],
      remarks_if_any: [{ value: '', disabled: true }],
      stage: [{ value: '', disabled: true }]
    });
  }

  addPresenting_complaints() {
    const symptomsArr = [
      'Fever (Jwara)',
      'Cough (Kasa)',
      'Dyspnea (Shwas)',
      'Fatigue (Shram)',
      'Body ache (Angamard)',
      'Sore throat (Shookapurnagalasyata)',
      'Headache (Shirahshoola)',
      'Diarrhoea (Atisaar)',
      'Vomiting (Chhardi)',
      'Ageusia (Arasasayta)',
      'Anosmia (Gandhashunayta)',
      'OTHERS'
    ];

    symptomsArr.forEach(e => this.presenting_complaints().push(this.newPresenting_complaints(e)));
    this.enableDisable_OtherName(false);
    console.log(this.form.getRawValue())
  }

  removePresenting_complaints() {
    this.presenting_complaints().clear();
    this.enableDisable_OtherName(false);
  }

  onSymptomsChanged(event: any, ind: number) {
    const symptomsFormArray = this.presenting_complaints().controls[ind];
    ['day_of_consultation', 'remarks_if_any', 'stage'].forEach(e => {
      const control = symptomsFormArray.get(e);
      control.setValue('');
      event.target.checked ? control.enable() : control.disable();
    });

    if (ind == 11) //'Other'
      this.enableDisable_OtherName(event.target.checked);
  }

  enableDisable_OtherName(status: boolean) {
    const otherNameFormControl = this.form.get("otherName");
    otherNameFormControl.setValue('');
    status ? otherNameFormControl.enable() : otherNameFormControl.disable();
  }

  keyUp(elementId: string) {
    let el: any = (<HTMLTextAreaElement>(document.getElementById(elementId)));
    el.value = el.value.replace(/^(0*)/, "");
  }

  onSysDia() {
    const [diastolicFormControl,systolicFormControl] = [this.form.get('general_examination.Diastolic'),this.form.get('general_examination.Systolic')]
    let systolic: string  = systolicFormControl.value; //this.form.value.general_examination.Systolic;
    let diastolic: string = diastolicFormControl.value;//this.form.value.general_examination.Diastolic;

    if (systolic != '' && diastolic == '') {
      diastolicFormControl.setValidators([Validators.required, Validators.min(30), Validators.max(200)]);
      diastolicFormControl.updateValueAndValidity();
    }
    else if (systolic == '' && diastolic != '') {
      systolicFormControl.setValidators([Validators.required, Validators.min(50), Validators.max(400)]);
      systolicFormControl.updateValueAndValidity();
    }
    else {
      if (+diastolic > 0 && +systolic > 0) {
        if (+diastolic >= +systolic) {
          diastolicFormControl.setErrors({ 'dia': true });
          this.toastr.error('Systolic should be greater than Diastolic');
        }
      }
      else if ((diastolic == '' && systolic == '') || (+systolic >= +diastolic)) {
        diastolicFormControl.setErrors(null);
        systolicFormControl.setValidators([Validators.min(50), Validators.max(400)]);
        diastolicFormControl.setValidators([Validators.min(30), Validators.max(200)]);
        systolicFormControl.updateValueAndValidity();
        diastolicFormControl.updateValueAndValidity();
      }
    }
  }
}