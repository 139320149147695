<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container-fluid">
      <form [formGroup]="pharmacyForm" (ngSubmit)="onSubmit($event)">
        <div class="row">
          <div class="col-xl-3">
            <div class="form-group">
              <label class="control-label">Is Hospital Pharmacy</label>
              <div>
                <input type="checkbox" name="isHospitalPharmacy" formControlName="isHospitalPharmacy" #isHospital
                  (change)="isHospitapharmacy($event.target.checked)">
              </div>
            </div>
          </div>
          <div class="col-xl-3" *ngIf="isHospital.checked">
            <div class="form-group">
              <label class="control-label">Hospital Name <span class="text-danger">*</span> </label>
              <div>
                <select formControlName="hospital_id" id="hospital_id" (change)="isHospitalChanged($event.target.value)"
                  [ngClass]="{ 'is-invalid': submitted && f.hospital_id.errors }" class="form-control form-control-sm">
                  <option value="">Select</option>
                  <option *ngFor="let item of hospitalList" [value]="item.id">
                    {{item.hospital_name}}</option>
                </select>
                <div *ngIf="submitted && f.hospital_id.errors" class="invalid-feedback">
                  <div *ngIf="f.hospital_id.errors.required">Hospital is required</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3" *ngIf="isHospital.checked">
            <div class="form-group">
              <label class="control-label">Hosipital Address </label>
              <div>
                <input type="text" autocomplete="off" formControlName="hospitalAddress" id="hospitalAddress" onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                    (event.charCode >= 97 && event.charCode <= 122) || (event.charCode == 32) || (event.charCode == 44)|| 
                    (event.charCode == 45)|| (event.charCode == 47)) ||  (event.charCode >= 48 && event.charCode <= 57)'
                  class="form-control form-control-sm text-uppercase"
                  [ngClass]="{ 'is-invalid': submitted && f.hospitalAddress.errors }">
              </div>
            </div>
          </div>
          <div class="col-xl-3" *ngIf="isHospital.checked">
            <div class="form-group">
              <label class="control-label">Registered Pharmacy </label>
              <div>
                <select formControlName="registeredPharmacy" id="registeredPharmacy"
                  (change)="onRegPharmacyChange($event.target.value)"
                  [ngClass]="{ 'is-invalid': submitted && f.registeredPharmacy.errors }"
                  class="form-control form-control-sm">
                  <option value="">Select</option>
                  <option *ngFor="let item of pharmacyList" [value]="item.id">{{item.pharmacy_name}}</option>
                </select>
              </div>
            </div>
          </div>
          <!-- </div>
        <div class="row"> -->
          <div class="col-xl-3">
            <div class="form-group">
              <label class="control-label">{{isNIG ? 'States' : 'State'}} <span class="text-danger">*</span> </label>
              <div>
                <select formControlName="state_id" id="state_id" #State (change)="locationChanged(State.value,'district')"
                  [ngClass]="{ 'is-invalid': submitted && f.state_id.errors }" class="form-control form-control-sm">
                  <option value="">Select</option>
                  <option *ngFor="let state of stateList" [value]="state.id">
                    {{state.state_name}}</option>
                </select>
                <div *ngIf="submitted && f.state_id.errors" class="invalid-feedback">
                  <div *ngIf="f.state_id.errors.required">
                    {{isNIG ? 'States' : 'State'}} is required
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3">
            <div class="form-group">
              <label class="control-label">Location Type <span class="text-danger">*</span> </label>
              <div>
                <select formControlName="location_type_id" id="location_type_id" #locationId
                  (change)="locationChanged('','location')"
                  [ngClass]="{ 'is-invalid': submitted && f.location_type_id.errors }"
                  class="form-control form-control-sm">
                  <option value="">Select</option>
                  <option *ngFor="let location of locationTypeList" [value]="location.id">
                    {{location.location_type}}</option>
                </select>
                <div *ngIf="submitted && f.location_type_id.errors" class="invalid-feedback">
                  <div *ngIf="f.location_type_id.errors.required">Location Type is required</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3">
            <div class="form-group">
              <label class="control-label">{{isNIG ? 'State Capitals' : 'District'}} <span class="text-danger">*</span>
              </label>
              <div>
                <select formControlName="district_id" id="district_id" #District
                  (change)="locationChanged(District.value,(+locationId.value==2)?'city':'mandal')"
                  [ngClass]="{ 'is-invalid': submitted && f.district_id.errors }" class="form-control form-control-sm">
                  <option value="">Select</option>
                  <option *ngFor="let district of districtList" [value]="district.id">
                    {{district.district_name}}</option>
                </select>
                <div *ngIf="submitted && f.district_id.errors" class="invalid-feedback">
                  <div *ngIf="f.district_id.errors.required">
                    {{isNIG ? 'State Capitals' : 'District'}} is required
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3" *ngIf="+locationId.value!=2">
            <!-- Mandal -->
            <div class="form-group">
              <label class="control-label">{{isNIG ? 'Local Government Areas' : 'Mandal'}} </label>
              <div>
                <select formControlName="mandal_id" id="mandal_id" #Mandal
                  (change)="locationChanged(Mandal.value,'village')"
                  [ngClass]="{ 'is-invalid': submitted && f.mandal_id.errors }" class="form-control form-control-sm">
                  <option value="">Select</option>
                  <option *ngFor="let mandal of mandalList" [value]="mandal.id">
                    {{mandal.mandal_name}}</option>
                </select>
                <div *ngIf="submitted && f.mandal_id.errors" class="invalid-feedback">
                  <div *ngIf="f.mandal_id.errors.required">
                    {{isNIG ? 'Local Government Areas ' : 'Mandal '}} is required
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- </div>
        <div class="row"> -->
          <div class="col-xl-3" *ngIf="+locationId.value!=2 && !isNIG">
            <div class="form-group">
              <label class="control-label">Village</label>
              <div>
                <select formControlName="village_id" id="village_id"
                  [ngClass]="{ 'is-invalid': submitted && f.village_id.errors }" class="form-control form-control-sm">
                  <option value="">Select</option>
                  <option *ngFor="let village of villageList" [value]="village.id">
                    {{village.village_name}}</option>
                </select>
                <div *ngIf="submitted && f.village_id.errors" class="invalid-feedback">
                  <div *ngIf="f.village_id.errors.required">Village is required</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3" *ngIf="(+locationId.value ==2)">
            <div class="form-group">
              <label class="control-label">City </label>
              <div>
                <select formControlName="city_id" id="city_id" [ngClass]="{ 'is-invalid': submitted && f.city_id.errors }"
                  class="form-control form-control-sm">
                  <option value="">Select</option>
                  <option *ngFor="let city of cityList" [value]="city.id">
                    {{city.city_name}}</option>
                </select>
                <div *ngIf="submitted && f.city_id.errors" class="invalid-feedback">
                  <div *ngIf="f.city_id.errors.required">City is required</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3">
            <label class="control-label">Store Type <span class="text-danger">*</span> </label>
            <select formControlName="store_type" id="serviceSubGrpId"
            class="form-control form-control-sm" (change)="storeTypeChange($event.target.value)"
            [ngClass]="{ 'is-invalid': submitted && f.store_type.errors }">
            <option value="">Select</option>
            <option value=1>Main Store</option>
            <option value=2>Pharmacy Store</option>
            <option value=3>Sub Store</option>
            <option value=4>Optical Store</option>
            <option value=5>Surgical Store</option>
            <option value=6>Other Store</option>
            <!-- <option *ngFor="let item of storeTypeList" [value]="item.store_type">{{item?.name}}</option> -->
        
          </select>
          <div *ngIf="submitted && f.store_type.errors" class="invalid-feedback">
            <div *ngIf="f.store_type.errors.required">Store Type is required</div>
          </div>
        </div>
         
          <div class="col-xl-3" *ngIf="mainstoreEnable">
            <label class="control-label">Main Store<span class="text-danger">*</span> </label>
            <select formControlName="main_store_id" id="Store" [ngClass]="{ 'is-invalid': submitted && f.main_store_id.errors }"
            class="form-control form-control-sm">
            <option value="">Select</option>
            <option *ngFor="let store of storeList" [value]="store.store_id">
              {{store.name}}</option>
          </select>
          <div *ngIf="submitted && f.main_store_id.errors" class="invalid-feedback">
            <div *ngIf="f.main_store_id.errors.required">Main Store is required</div>
          </div>
        </div>
         
          <div class="col-xl-3">
            <div class="form-group">
              <label class="control-label">Pharmacy Name <span class="text-danger">*</span> </label>
              <div>
                <input type="text" autocomplete="off" formControlName="pharmacy_name" id="pharmacy_name" maxlength="50"
                  [ngClass]="{ 'is-invalid': submitted && f.pharmacy_name.errors }"
                  class="form-control form-control-sm text-uppercase"
                  onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122)|| event.charCode == 32)'>
                <div *ngIf="submitted && f.pharmacy_name.errors" class="invalid-feedback">
                  <div *ngIf="f.pharmacy_name.errors.required">Pharmacy Name is required</div>
                </div>
              </div>
            </div>
          </div>

          <!-- facility Added start -->

          <div class="col-xl-3" *ngIf="IsFacilityEnable">
            <label class="control-label"> Select Facility</label>
            <div>
                 <ng-multiselect-dropdown [settings]="dropdownSettings_facility" [placeholder]="'Select'" 
                 [data]="facilityDatalist"
                 [(ngModel)]="selectFacility"  (onSelect)="isFacilitySelect($event)"
                 (onDeSelect)="isFacilityDeSelect($event)"></ng-multiselect-dropdown>
            </div>
        </div>
        <div class="col-xl-3" *ngIf="IsFacilityEnable">
          <div class="form-group">
            <label class="control-label"> Facility ID <span class="text-danger">*</span> </label>
            <div>
              <input type="text" autocomplete="off" formControlName="facility_id" id="facility_id" [attr.disabled]="facilitySelected?true:null"
                 maxlength="50"
                class="form-control form-control-sm">
            </div>
          </div>
        </div>
        <div class="col-xl-3" *ngIf="IsFacilityEnable">
          <div class="form-group">
            <label class="control-label"> Facility Name <span class="text-danger">*</span> </label>
            <div>
              <input type="text" autocomplete="off" formControlName="facility_name" id="facility_name" [attr.disabled]="facilitySelected?true:null"
                 maxlength="50"
                class="form-control form-control-sm">
            </div>
          </div>
        </div>
          <!-- facility added End -->
          <div class="col-xl-3">
            <div class="form-group">
              <label class="control-label">Address <span class="text-danger">*</span>
              </label>
              <div>
                <textarea type="text" autocomplete="off" formControlName="address" id="address" (keyup)="keyUp('address')"
                  maxlength="100" [ngClass]="{ 'is-invalid': submitted && f.address.errors }"
                  onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                  (event.charCode >= 97 && event.charCode <= 122) || (event.charCode == 32) || (event.charCode == 44)|| 
                                  (event.charCode == 45)|| (event.charCode == 47)) ||  (event.charCode >= 48 && event.charCode <= 57)' class="form-control form-control-sm"></textarea>
                <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                  <div *ngIf="f.address.errors.required">Address is required
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- </div>
                <div class="row"> -->
          <div class="col-xl-3">
            <div class="form-group">
              <label class="control-label">Pharmacy Contact No. <span class="text-danger">*</span>
              </label>
              <div>
                <input type="text" autocomplete="off" formControlName="pharmacy_conatct_no" id="pharmacy_conatct_no"
                  [maxlength]="maxLngth" [ngClass]="{ 'is-invalid': submitted && f.pharmacy_conatct_no.errors }"
                  class="form-control form-control-sm" (keyup)="onKeyNmbr('pharmacy_conatct_no', $event.target.value)"
                  onkeypress='return event.charCode >= 48 && event.charCode <= 57' [pattern]="mobPtrn">
                <!-- (keyup)="keyUp('pharmacy_conatct_no')" -->
                <div *ngIf="submitted && f.pharmacy_conatct_no.errors" class="invalid-feedback">
                  <div *ngIf="f.pharmacy_conatct_no.errors.required">Please Enter 10 digit Mobile Number</div>
                  <div *ngIf="f.pharmacy_conatct_no.errors.pattern">Pharmacy Contact No. {{mobErrMsg}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3">
            <div class="form-group">
              <label class="control-label">Pin Code <span class="text-danger">*</span>
              </label>
              <div>
                <input type="text" autocomplete="off" formControlName="pin_code" maxlength="6" minlength="6" id="pin_code"
                  (keyup)="keyUp('pin_code')" [ngClass]="{ 'is-invalid': submitted && f.pin_code.errors }"
                  class="form-control form-control-sm"
                  onkeypress='return ((event.charCode >= 48 && event.charCode <= 57)||(event.charCode == 32))'>
                <div *ngIf="submitted && f.pin_code.errors" class="invalid-feedback">
                  <div *ngIf="f.pin_code.errors.required">Pincode is required</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3">
            <div class="form-group">
              <label class="control-label">Registration No <span class="text-danger">*</span> </label>
              <div>
                <input type="text" autocomplete="off" formControlName="registration_no" (focus)="dp.hide()"
                  id="registration_no" maxlength="15" [ngClass]="{ 'is-invalid': submitted && f.registration_no.errors }"
                  class="form-control form-control-sm">
                <div *ngIf="submitted && f.registration_no.errors" class="invalid-feedback">
                  <div *ngIf="f.registration_no.errors.required">Registration No is required
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3">
            <div class="form-group">
              <label class="control-label">Registration Expiry Date <span class="text-danger">*</span> </label>
              <div>
                <input type="text" class="form-control form-control-sm pb-2" id="registration_exp_date"
                  formControlName="registration_exp_date" autocomplete="off" placement="up" [minDate]="maxdate"
                  bsDatepicker #dp="bsDatepicker" (focus)="dp.show()"
                  [ngClass]="{ 'is-invalid': submitted && f.registration_exp_date.errors }"
                  [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY',containerClass: 'theme-dark-blue', showWeekNumbers: false }">
                <div *ngIf="submitted && f.registration_exp_date.errors" class="invalid-feedback">
                  <div *ngIf="f.registration_exp_date.errors.required">Registration Expiry Date is required</div>
                </div>
              </div>
            </div>
          </div>
          <!-- </div>
                <div class="row"> -->
          <div class="col-xl-3">
            <div class="form-group">
              <label class="control-label">Shop Number
              </label>
              <div>
                <input type="text" autocomplete="off" formControlName="shop_no" id="shop_no" maxlength="10"
                  onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.charCode >= 97 && event.charCode <= 122'
                  [ngClass]="{'is-invalid': submitted && f.shop_no.errors }" (focus)="dp.hide()"
                  class="form-control form-control-sm">
                <!-- ' -->
                <div *ngIf="submitted && f.shop_no.errors" class="invalid-feedback">
                  <div *ngIf="f.shop_no.errors.required">Shop No is required
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3">
            <div class="form-group">
              <label class="control-label">Contact Person Name <span class="text-danger">*</span>
              </label>
              <div>
                <input type="text" autocomplete="off" formControlName="contact_person_name" id="contact_person_name"
                  maxlength="30" [ngClass]="{ 'is-invalid': submitted && f.contact_person_name.errors }"
                  class="form-control form-control-sm text-uppercase" onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                  (event.charCode >= 97 && event.charCode <= 122) || event.charCode == 32)'>
                <div *ngIf="submitted && f.contact_person_name.errors" class="invalid-feedback">
                  <div *ngIf="f.contact_person_name.errors.required">Contact Person Name is required</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3">
            <div class="form-group">
              <label class="control-label">Contact Person Mobile No <span class="text-danger">*</span>
              </label>
              <div>
                <input type="text" autocomplete="off" formControlName="contact_person_mobile" id="contact_person_mobile"
                  onkeypress='return event.charCode >= 48 && event.charCode <= 57' [maxlength]="maxLngth"
                  [ngClass]="{ 'is-invalid': submitted && f.contact_person_mobile.errors }" [pattern]="mobPtrn"
                  class="form-control form-control-sm" (keyup)="onKeyNmbr('contact_person_mobile', $event.target.value)">
                <!-- (keyup)="keyUp('contact_person_mobile')" -->
                <div *ngIf="submitted && f.contact_person_mobile.errors" class="invalid-feedback">
                  <div *ngIf="f.contact_person_mobile.errors.required">Contact Person Mobile No. is required</div>
                  <div *ngIf="f.contact_person_mobile.errors.pattern">Contact Person Mobile No. {{mobErrMsg}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3">
            <div class="form-group">
              <label class="control-label">Pharmacy Whatsapp Number</label>
              <div>
                <!-- [ngClass]="{ 'is-invalid': isWhatsAppInvalid }" -->
                <input autocomplete="off" formControlName="whatsapp_number" id="whatsapp_number" [pattern]="mobPtrn"
                  onkeypress='return event.charCode >= 48 && event.charCode <= 57' [maxlength]="maxLngth" type="text"
                  [ngClass]="{ 'is-invalid': submitted && f.whatsapp_number.errors }" class="form-control form-control-sm"
                  (keyup)="onKeyNmbr('whatsapp_number', $event.target.value)">
                <!-- (keyup)="keyUp('whatsapp_number')" [ngClass]="{ 'is-invalid': submitted && f.whatsapp_number.errors }" -->
                <!-- <div *ngIf="submitted && f.whatsapp_number.errors" class="invalid-feedback">
                  <div *ngIf="f.whatsapp_number.errors.required">
                    Mobile Number is requird
                  </div>
                  <div *ngIf="f.whatsapp_number.errors.pattern">
                    Please enter 10 digits
                  </div>
                </div> -->
                <div *ngIf="submitted && f.whatsapp_number.errors" class="invalid-feedback">
                  <div *ngIf="f.whatsapp_number.errors.pattern">Pharmacy Whatsapp Number {{mobErrMsg}}</div>
                </div>
                <!-- <div *ngIf="isWhatsAppInvalid" class="invalid-feedback">
                  <div>Pharmacy Whatsapp Number {{mobErrMsg}}</div>
                </div> -->
              </div>
            </div>
          </div>
          <!-- </div>
                <div class="row"> -->
          <div class="col-xl-3">
            <div class="form-group">
              <label class="control-label">Email <span class="text-danger">*</span></label>
              <div>
                <input type="text" autocomplete="off" formControlName="email" id="email" maxlength="50"
                  [ngClass]="{ 'is-invalid': submitted && f.email.errors }" class="form-control form-control-sm"
                  pattern="^[a-zA-Z0-9._]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$" onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode == 64) ||(event.charCode == 46) ||
                  (event.charCode >= 97 && event.charCode <= 122)) || (event.charCode >= 48 && event.charCode <= 57)'>
                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                  <div *ngIf="f.email.errors.required">
                    Email is required
                  </div>
                  <div *ngIf="f.email.errors.pattern">
                    sample email address is x@yahoo.com
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3" *ngIf="!isHospital.checked">
            <div class="form-group">
              <label class="control-label">Bank Account Number
              </label>
              <div>
                <input type="text" autocomplete="off" formControlName="bank_account_number" id="bank_account_number"
                  (keyup)="keyUp('bank_account_number')" onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                  maxlength="20" [ngClass]="{ 'is-invalid': submitted && f.bank_account_number.errors }"
                  class="form-control form-control-sm">
                <div *ngIf="submitted && f.bank_account_number.errors" class="invalid-feedback">
                  <div *ngIf="f.bank_account_number.errors.required">
                    Lab Bank Account Number is required
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3" *ngIf="!isHospital.checked">
            <div class="form-group">
              <label class="control-label">Bank Name
              </label>
              <div>
                <select formControlName="branch_name" id="branch_name" class="form-control form-control-sm"
                  [ngClass]="{ 'is-invalid': submitted && f.branch_name.errors }">
                  <option value="">Select</option>
                  <option *ngFor="let item of bankNamesList" [value]="item.id">
                    {{item.bank_name}}</option>
                </select>
                <!-- <input type="text" autocomplete="off" formControlName="branch_name" id="branch_name"
                  (keyup)="keyUp('branch_name')" maxlength="10"
                  [ngClass]="{ 'is-invalid': submitted && f.branch_name.errors }" class="form-control form-control-sm"> -->
                <div *ngIf="submitted && f.branch_name.errors" class="invalid-feedback">
                  <div *ngIf="f.branch_name.errors.required">
                    Bank Name is
                    required
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3" *ngIf="!isHospital.checked">
            <div class="form-group">
              <label class="control-label">Bank Location
              </label>
              <div>
                <input type="text" autocomplete="off" formControlName="branch_location" id="branch_location"
                  (keyup)="keyUp('branch_location')"
                  onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122)|| event.charCode == 32)'
                  maxlength="50" [ngClass]="{ 'is-invalid': submitted && f.branch_location.errors }"
                  class="form-control form-control-sm text-uppercase">
                <div *ngIf="submitted && f.branch_location.errors" class="invalid-feedback">
                  <div *ngIf="f.branch_location.errors.required">
                    Bank Location is
                    required
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- </div>
                <div class="row"> -->
          <div class="col-xl-3" *ngIf="!isHospital.checked && !isNIG">
            <div class="form-group">
              <label class="control-label">IFSC Code</label>
              <div>
                <input type="text" autocomplete="off" formControlName="ifsc_code" id="ifsc_code" maxlength="11"
                  [ngClass]="{ 'is-invalid': submitted && f.ifsc_code.errors }" class="form-control form-control-sm">
                <div *ngIf="submitted && f.ifsc_code.errors" class="invalid-feedback">
                  <div *ngIf="f.ifsc_code.errors.pattern">
                    Enter Valid IFSC, ex: SBIN0001050
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3" *ngIf="isGAM">
            <div class="form-group">
                <label class="control-label">Center Mapping With Agent </label>
                <div>
                  <select class="form-control form-control-sm" id="payment_type"
                  formControlName="center_id">
                      <option value=0>Select</option>
                      <option *ngFor="let center of getAllcenters" [value]="center.id">
                        {{center.center_name}}</option>
                  </select>
                </div>
            </div>
        </div>
          <div class="col-xl-3">
            <div class="form-group">
              <label class="control-label">is HeadOffice
              </label>
              <div>
                <input type="checkbox" name="isheadOffice" value="one" formControlName="is_headoffice">
              </div>
            </div>
          </div>
          <div class="col-xl-3">
            <div class="form-group">
              <label class="control-label"> Status <span class="text-danger">*</span></label>
              <div>
                <input type="radio" formControlName="account_activated" [value]="true">Active
                <input type="radio" formControlName="account_activated" [value]="false">In Active
              </div>
            </div>
          </div>
          <!-- <div class="col-xl-3">
            <div class="form-group">
              <label class="control-label"></label>
              <div>
                <button type="button" class="btn btn-primary btn-sm">Registration Certificate</button>
                <input type="file" accept="image/png, application/pdf" name="input-file-preview" formControlName="regFile"
                  (change)="singleFileUpload($event,0,'1')" [ngClass]="{'is-invalid': submitted && f.regFile.invalid }" />
                <div *ngIf="submitted && f.regFile.errors" class="invalid-feedback">
                  <div *ngIf="f.regFile.errors.required">
                    Registration Certificate is required
                  </div>
                </div>
              </div>
            </div>
          </div> -->
        </div>
        <div class="row">
          <div class="col-xl-3">
            <div class="form-group">
              <label class="control-label">Is Logistic Info Required</label>
              <div>
                <input type="checkbox" name="isLogistic" formControlName="isLogistic" (change)="isChngLog()">
              </div>
            </div>
          </div>
          <div class="col-xl-3">
            <div class="form-group">
              <label class="control-label">Logistic Person Name
                <span *ngIf="pharmacyForm.get('isLogistic').value" class="text-danger">*</span> </label>
              <div>
                <input type="text" autocomplete="off" formControlName="person_name" id="person_name"
                  [ngClass]="{ 'is-invalid': submitted && f.person_name.errors }"
                  class="form-control form-control-sm text-uppercase" maxlength="50"
                  onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                    (event.charCode >= 97 && event.charCode <= 122) || event.charCode == 32)'
                  [attr.disabled]="pharmacyForm.get('isLogistic').value ? null : true">
                <div *ngIf="submitted && f.person_name.errors" class="invalid-feedback">
                  <div *ngIf="f.person_name.errors.required">Logistic Person Name is required</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3">
            <div class="form-group">
              <label class="control-label">Logistic Person Email
                <span *ngIf="pharmacyForm.get('isLogistic').value" class="text-danger">*</span>
              </label>
              <div>
                <input type="text" autocomplete="off" formControlName="delivery_person_email"
                  [ngClass]="{ 'is-invalid': submitted && f.delivery_person_email.errors }" maxlength="60"
                  class="form-control form-control-sm" id="delivery_person_email"
                  onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode == 64) ||
                    (event.charCode == 46) || (event.charCode >= 97 && event.charCode <= 122)) ||
                    (event.charCode >= 48 && event.charCode <= 57)' [attr.disabled]="pharmacyForm.get('isLogistic').value ? null : true">
                <div *ngIf="submitted && f.delivery_person_email.errors" class="invalid-feedback">
                  <div *ngIf="f.delivery_person_email.errors.required">Logistic Person Email is required</div>
                  <div *ngIf="f.delivery_person_email.errors.pattern">Sample Email address is x@yahoo.com</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3">
            <div class="form-group">
              <label class="control-label">Logistic Person Mobile No
                <span *ngIf="pharmacyForm.get('isLogistic').value" class="text-danger">*</span>
              </label>
              <div>
                <input id="delivery_person_mobile_no" type="text" autocomplete="off"
                  formControlName="delivery_person_mobile_no" [pattern]="mobPtrn"
                  (keyup)="onKeyNmbr('delivery_person_mobile_no', $event.target.value)" [maxlength]="maxLngth"
                  onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                  [ngClass]="{ 'is-invalid': submitted && f.delivery_person_mobile_no.errors }"
                  class="form-control form-control-sm" [attr.disabled]="pharmacyForm.get('isLogistic').value ? null : true">
                <div *ngIf="submitted && f.delivery_person_mobile_no.errors" class="invalid-feedback">
                  <div *ngIf="f.delivery_person_mobile_no.errors.required">Logistic Person Mobile No is required</div>
                  <div *ngIf="f.delivery_person_mobile_no.errors.pattern">Logistic Person Mobile No {{mobErrMsg}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="table-responsive">
            <table class="table table-bordered table-sm">
              <tbody>
                <tr>
                  <td>
                    <input type="file" class="d-none" formControlName="regFile" #regFile name="input-file-preview"
                      (change)="singleFileUpload($event,0,'1')"
                      [ngClass]="{'is-invalid': submitted && f.regFile.invalid }" />
                    <p class="btn badge badge-success badge-pill text-white" (click)="regFile.click()">Registration
                      Certificate</p> <span class="text-danger">*</span>
                    <p class="small mb-1">Png,Jpg or pdf format only</p>
                    <div *ngIf="submitted && f.regFile.errors" class="invalid-feedback">
                      <div *ngIf="f.regFile.errors.required">Registration Certificate is required</div>
                    </div>
                  </td>
                  <td>
                    <input type="file" class="d-none" formControlName="digitalsignature" #digitalsignature
                      name="input-file-preview" (change)="singleFileUpload($event,1,'8')"
                      [ngClass]="{'is-invalid': submitted && f.digitalsignature.invalid }" />
                    <p class="btn badge badge-success badge-pill text-white" (click)="digitalsignature.click()">
                      Digital Signature </p> <span class="text-danger">*</span>
                    <p class="small mb-1">Png,Jpg or pdf format only</p>
                    <div *ngIf="submitted && f.digitalsignature.errors" class="invalid-feedback">
                      <div *ngIf="f.digitalsignature.errors.required">Digital Signature is required</div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span> <a class="badge badge-danger badge-pill" title="Cancel File" *ngIf="uploadFileList[0].File"
                        (click)="cancelFile(1)">
                        <i class="fa fa-trash text-white"></i></a>&nbsp;
                    </span>
                    <a href="javascript:void(0)" (click)="viewFile(1)">{{uploadFileList[0].file_name}}</a>
                  </td>
                  <td>
                    <span> <a class="badge badge-danger badge-pill" title="Cancel File" *ngIf="uploadFileList[1].File"
                        (click)="cancelFile(2)">
                        <i class="fa fa-trash text-white"></i></a>&nbsp;
                    </span>
                    <a href="javascript:void(0)" (click)="viewFile(2)">{{uploadFileList[1].file_name}}</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <input type="submit" id="btnsubmit" style="display: none;">
      </form>
    </div>
  </div>
  <div class="modal-footer">
    <input type="submit" class="btn btn-sm btn-primary" [disabled]="isSubmit_Disabled?true:null" (click)="submitForm()"
      value="Submit">&nbsp;&nbsp;
    <button type="button" class="btn btn-sm btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
  </div>