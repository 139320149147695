<div class="modal fade" data-backdrop="static" data-keyboard="false" id="confirmMessage" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true" style="z-index: 99999;">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Confirmation Message</h5>
                <button type="button" id="btnConfirmMessageClose" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                Patient/Agent has disconnected the video consultation,
                but still do you want to provide plan of care?
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary btn-sm" data-dismiss="modal">Yes</button>
                <button type="button"  class="btn btn-danger btn-sm" data-dismiss="modal"
                    (click)="goto_Consultation()">No</button>
            </div>
        </div>
    </div>
</div>