import { Common } from 'src/app/common';
import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { Router } from '@angular/router';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import * as CryptoJS from 'crypto-js';  
import { environment } from 'src/environments/environment';

declare let $: any;
declare let fnEncryption: any;
declare let fnDecrypt: any;
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  @Input() title: string;
  public barLabel: string = "Password strength:";  
  oldPassword: string = '';
  newPassword: string = '';
  confirmed_password: string = '';
  userId:any;
  num: number = 0;
  patternResult: boolean=true;
  constructor(public activeModal: NgbActiveModal
    , private toastr: ToastrService
    , private router: Router
    , private authServ: AuthenticationService
    , private authenticationService: AuthenticationService
    ,private registrationService:RegistrationService) { }

  ngOnInit(): void {
    this.userId = sessionStorage.getItem('session_id')
    Common.setFocus('oldPassword');
  }
  patternCheck(e){
    //  var pattern = new RegExp('/^[ A-Za-z0-9_@./#&+-]*$/');
      var passw= /^[A-Za-z0-9_@./]\w{8,14}$/;
  if(this.newPassword.match(passw)) 
  { 
  //alert('Correct, try another...')
  this.patternResult= true;
  }
  else
  { 
  //alert('Wrong...!')
  this.patternResult= false;
  }
      // this.patternResult=pattern.test(this.newPassword); 
    }
  submit() {
    let user = this.authServ.currentUserValue;
    // let decyptPswd = fnDecrypt(user.password, environment.securityKey);
    if (this.oldPassword === '') {
      this.toastr.error('Please enter Old Password', this.title);
      Common.setFocus('oldPassword');
      return;
    }
    else if (this.newPassword === '') {
      this.toastr.error('Please enter New Password', this.title);
      Common.setFocus('newPassword');
      return;
    }
    else if (this.confirmed_password === '') {
      this.toastr.error('Please enter Confirm Password', this.title);
      Common.setFocus('confirmed_password');
      return;
    }

    if (this.oldPassword == this.newPassword) {
      [this.oldPassword, this.newPassword] = ['', ''];
      Common.setFocus('oldPassword');
      this.toastr.error('Old Password and New Password should not be same, please re-enter'); //, this.title
      return;
    }
    else if (this.confirmed_password != this.newPassword) {
      [this.confirmed_password, this.newPassword] = ['', ''];
      Common.setFocus('newPassword');
      this.toastr.error('Cofirm Password and New Password should be same', this.title);
      return;
    }
    else if (this.confirmed_password == '123456' && this.newPassword == '123456') {
      [this.confirmed_password, this.newPassword] = ['', ''];
      Common.setFocus('newPassword');
      this.toastr.error('Default password 123456 is not allowed');
      return;
    }
    else if (this.oldPassword != sessionStorage.getItem('password')) {
      // console.log("sessionStorage.getItem('password')", sessionStorage.getItem('password'))
      Common.setFocus('oldPassword');
      this.toastr.error('Old Password not matched.');
      return;
    }
    
    this.oldPassword = fnEncryption(this.oldPassword, environment.securityKey)
    this.newPassword = fnEncryption(this.newPassword, environment.securityKey)
    let changeData = {
      "userId": this.authenticationService.currentUserValue.id,
      "oldPassword": this.oldPassword,
      "newPassword": this.newPassword
    }

    this.authenticationService.changepassword(changeData).subscribe(res => {
      sessionStorage.setItem('password','');
      if (res.status === "OK") {
        this.toastr.success('Your password changed successfully', this.title);
        setTimeout(() => {
          this.activeModal.close('changes saved');
          this.sessionManagement()
          this.authenticationService.logout();
          this.router.navigate(['']);
        })
      }
    });

  }
  sessionManagement(){
    if(this.userId){
    this.registrationService.session(this.userId).subscribe(res => {
      if (res.status === "success" ) {
    }
    });
  }
  }
}