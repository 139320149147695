export interface IMaster {
    id: number;
    name: string;
}
export class Covid19Masters {
    static getMasters(masterType: string): IMaster[] {
        let masterData: Array<IMaster> = [];
        const assignData = {
            'investigations': [
                { id: 1, name: 'Positive RT-PCR' },
                { id: 2, name: 'Positive Rapid antigen' },
                { id: 3, name: 'Tested negative but Diagnosed on the basis of COVID-19 symptoms' },
                { id: 4, name: 'Tested, result awaited and Diagnosed on the basis of COVID-19 symptoms' },
                { id: 5, name: 'Not tested but Diagnosed on the basis of COVID-19 symptoms' },
                { id: 6, name: 'Other method' },
            ],
            'reason': [
                { id: 1, name: 'Chance of exposure' },
                { id: 2, name: 'Onset of symptoms' },
                { id: 3, name: 'Other reasons' },
            ],
            'remarks': [
                { id: 1, name: 'MILD' },
                { id: 2, name: 'MODERATE' },
                { id: 3, name: 'SEVERE' },
            ],
            'Vaccines': [
                { id: 1, name: 'COVISHIELD' },
                { id: 2, name: 'COVAXIN' },
                { id: 3, name: 'SPUTNIK V' },
                { id: 4, name: 'Others' },
            ],
            'VaccinesStatus': [
                { id: 1, name: 'Single Dose' },
                { id: 2, name: '2nd Dose' },
                { id: 3, name: "Haven't received" },
            ],
            'stage': [
                { id: 1, name: 'PREVENTIVE' },
                { id: 2, name: 'MILD' },
                { id: 3, name: 'MODERATE' },
                { id: 4, name: 'SEVERE' },
                { id: 5, name: 'POST COVID-19 REHABILITATION' },
            ],
            'Medicines': [
                { id: 1, name: 'Methylprednisolone' },
                { id: 2, name: 'Tab Ivermectin' },
                { id: 3, name: 'Tab Zinc' },
                { id: 4, name: 'Dexamethasone' },
                { id: 5, name: 'Tab Paracetamol' },
                { id: 6, name: 'Tab Vitamin C' },
                { id: 7, name: 'Inhalational Budesonide' },
                { id: 8, name: 'Tab Azithromycin' },
                { id: 9, name: 'Antiviral (Remdesivir)' },
            ],
        };
        masterData = assignData[masterType];
        return masterData;
    }
}