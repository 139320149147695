import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { forkJoin, Observable, of } from 'rxjs';
// import { map, catchError } from 'rxjs/operators';
import { MasterService } from '../services/master.service';

@Injectable()
export class MatersResolver implements Resolve<any> {
    mastersData: any;
    constructor(private masterService: MasterService) { }

    resolve() {
        const states = this.masterService.getAll('getallstates');
        const vaccinations = this.masterService.getAll('getvaccinations');
        this.mastersData = forkJoin([states, vaccinations])
        return this.mastersData;
    }
}


// If error occurs, this resolve error method won't redirect user to component

// resolve() {
//    return this.usersListService.getUsers().pipe(
//       catchError((error) => {
//          return empty();
//       })
//    )
// }