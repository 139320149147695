<input type="hidden" id="convId">
<input type="hidden" id="fromId">
<input type="hidden" id="toId">
<input type="hidden" id="log">
<input type="hidden" id="sessionName" value="">
<input type="hidden" id="user" value="">
<input type="hidden" id="forceRecordingId">
<input type="hidden" id="patient_interaction_id">
<input type="hidden" id="order_no">
<input type="hidden" id="hospital_id">
<input type="hidden" id="symptoms">
<input type="hidden" id="is_self">
<input type="hidden" id="family_member">
<input type="hidden" id="patient_info">
<input type="hidden" id="ovUrl">
<input type="hidden" id="ovKey">
<input type="hidden" id="apUrl">
<input type="hidden" id="newUserId">
<input type="hidden" id="doctor_id">
<input type="hidden" id="lgnUsrName">
<input type="hidden" id="lgnUsrPswd">
<!--AYUSH POC-->
<input type="hidden" id="calPocId">
<input type="hidden" id="dependentId">
<input type="hidden" id="isConsultationForDependent">
<input type="hidden" id="isPaid">
<input type="hidden" id="appOS">
<div id="blackout" class="noselect" style.display = "{{isConnectionAvailable ? 'none' : 'block'}}">
  <br /><br /><br /><br /><br /><p>No Internet connection!</p><br />
</div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">  
  <p style="color: white">Please Wait. </p>  
</ngx-spinner>
<!-- <div class="spinner-border" role="status"> <span class="sr-only">Loading...</span> </div> -->
<button type="button" style="display: none;" id="docnameshow" (click)="sourcetype()"></button>
<button type="button" style="display: none;" id="docnameshownew" (click)="sourcetypeclick()"></button>
<!-- <button type="button" style="display: none;" id="docnameshow2" (click)="sourcetypenew()"></button>
<button type="button" style="display: none;" id="docnameshownew2" (click)="sourcetypeclicknew()"></button> -->
<button type="button" style="display: none;" id="videoCallModal" data-toggle="modal" data-target="#exampleModalCenter"></button>
<div class="modal fade" data-backdrop="static" data-keyboard="false" id="exampleModalCenter" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg " role="document">
    <div class="modal-content panelbg">
      <div class="modal-body">
        <h5>Video Conference Confirmation</h5>
        <div class="row">
          <div class="col-md-5 col-6 pr-0">
            <div class="message">
              <h6>{{sourcetitle}} <span id="patient_name"></span> is calling for <span id="patient_complaints"></span></h6> 
            </div>
          </div>
          <div class="col-md-1 col-6 pr-0"></div>
          <div class="col-md-6 col-6 pt-2 pl-0">
            <h6>Hello <span *ngIf="roleId=='7'">Dr.</span> <span class="text-primary" id="doctorName"></span></h6>
            <p class="small">You have an incoming call, Please accept</p>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-success rounded-circle btn-lg ml-1 mr-1 call-animation"
          data-dismiss="modal" (click)="acceptCall()"> <i class="fa fa-phone text-white"></i></button>
        <button type="button" onclick="cancelCall()" class="btn btn-danger rounded-circle btn-lg ml-1 mr-1" data-dismiss="modal">
          <i class="fa fa-phone fa-rotate-180 text-white "></i><br /></button>
        <button type="button" style="display: none;" id="btnVideoCallModalClose" data-dismiss="modal"></button>
      </div>
    </div>
  </div>
</div>
<audio id="audio"> <source src="/assets/js/ringtone.mp3" type="audio/mpeg"> </audio>
<div id="main-content">
  <router-outlet></router-outlet>
  
  
</div>
<button type="button" style="display: none;" (click)="declineCall()" id="btnDeclineCall"></button>
<button type="button" style="display: none;" (click)="declineDocCall()" id="btnDeclineDocCall"></button>
<button type="button" style="display: none;" (click)="endAgntCall()" id="btnEndAgntCall"></button>
<button type="button" style="display: none;" (click)="viduException()" id="btnException"></button>
<div class="bottomMenu" *ngIf="lstDrgs.length != 0 && drgTxt != '' && isGnm == true">
    <div class="container-fluid ml-0 mr-0 p-0">
      <div class="row mb-0">
        <div class="col-12 pt-1 pb-1 bgnews shadow border-bottom border-light">
          <div class="container-fluid">
            <div class="row"> 
              <div class="col-md-3 col-lg-2 pr-md-0 pr-5">
                <div class="p-1 bg-success text-white text-center breaking-caret"><span class="font-weight-bold">Latest Drugs</span></div>
              </div>
              <div class="col-md-9 col-lg-10">
                <div class="breaking-box mt-1 ml-2">
                  <p class="marquee"><span class="font-weight-bold" style="animation: marquee 60s linear infinite; color: #144bb1"
                    [innerHtml]="drgTxt"></span></p>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<button type="button" style="display: none;" data-toggle="modal" data-target="#drRegExpiryMdl" id="drRegExpiry"></button>
<div class="modal fade" data-backdrop="static" data-keyboard="false" tabindex="-1" style="z-index: 99999 !important;"
  id="drRegExpiryMdl" role="dialog" aria-labelledby="edit" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title"> Hello Doctor, you cannot do any consultations as your Registration got expired. Kindly contact Admin...! </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-success" data-dismiss="modal">Okay</button>
      </div>
    </div>
  </div>
</div>
<button type="button" style="display: none;" id="recStart" (click)="onRec(true)"></button>
<button type="button" style="display: none;" id="btnPatient_connection" (click)="onPatient_connection"></button>