import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-poc-confirm-messsage-model',
  templateUrl: './poc-confirm-messsage-model.component.html',
  styleUrls: ['./poc-confirm-messsage-model.component.css']
})
export class POCConfirmMesssageModelComponent implements OnInit {
  @Output() previewPrescription = new EventEmitter<void>();
  @Input() isShowPC: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

  onPrescription(): void { this.previewPrescription.emit(); }

}
