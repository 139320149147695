import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators, ValidationErrors } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';

import { Common } from 'src/app/common';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { MasterService } from 'src/app/shared/services/master.service';
import { FileService } from 'src/app/shared/services/file.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { ImagePreviewComponent } from 'src/app/modules/modals/image-preview/image-preview.component';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-common-hospital-reg',
  templateUrl: './common-hospital-reg.component.html',
  styleUrls: ['./common-hospital-reg.component.css'],
  providers: [DatePipe]
})
export class CommonHospitalRegComponent implements OnInit {

  @Input() title: string;
  @Input() hospitalData: any;
  @Input() isSubmit_Disabled: boolean = false;

  isMeridian: boolean = false;
  hospitalForm: FormGroup;
  submitted = false;
  locationTypeList: Array<any> = [];
  stateList: Array<any> = [];
  districtList: Array<any> = [];
  mandalList: Array<any> = [];
  cityList: Array<any> = [];
  villageList: Array<any> = [];
  uploadFileList: Array<{ id: number, reg_id: number, name: string, File: any, extensions: string, file_name: string, fileType: string, document_path: string }> = [];
  labList: Array<any> = [];
  pharmacyList: Array<any> = [];
  bankNamesList: Array<any> = [];
  departmentList: Array<any>[];
  dropdownSettings_departments = {};
  selectedItems_language = [];
  editDepartmentList = [];
  selectFacility:any;
  mobPtrn = ''; mobErrMsg = ''; maxLngth = ''; isNIG = environment.isNIG;
  IsFacilityEnable:boolean=false;
  facilityDatalist: any;
  dropdownSettings_facility: { singleSelection: boolean; idField: string; textField: string; selectAllText: string; unSelectAllText: string; itemsShowLimit: number; allowSearchFilter: boolean; };
  facilitySelected: boolean;
  is1962LiveStock: boolean=false;
  exl: boolean=false;
  isHealthWellness: boolean=false;

  constructor(public activeModal: NgbActiveModal
    , private modalService: NgbModal
    , private toastr: ToastrService
    , private formBuilder: FormBuilder
    , private registrationService: RegistrationService
    , private masterService: MasterService
    , private authServ: AuthenticationService
    , private notificationService: NotificationService
    , private fileService: FileService) { }

  ngOnInit(): void {
    this.authServ.mobPtrnSubject.subscribe(s => this.mobPtrn = s),
      this.authServ.mobErrMsgSubject.subscribe(s => this.mobErrMsg = s),
      this.authServ.maxLngthSubject.subscribe(s => this.maxLngth = s);
      // this.setLocationTypeValidators();
      // this.defaultLocations();
    this.initFormControls();
    const vlgCntrl = this.hospitalForm.get('village_id'), ifscCntrl = this.hospitalForm.get('ifsc_code');
    vlgCntrl.setValue(''), ifscCntrl.setValue('');
    if (this.isNIG)
      vlgCntrl.setValidators(null), ifscCntrl.setValidators(null);
    else
      vlgCntrl.setValidators([Validators.required]), ifscCntrl.setValidators([Validators.pattern('^[A-Z]{4}0[A-Z0-9]{6}$')]);
      vlgCntrl.updateValueAndValidity(), ifscCntrl.updateValueAndValidity(), this.setFocus('locationtype_id'), this.setLocationTypeValidators(),
      this.locationChanged('', 'state'), this.defaultLocations(), this.getAllMasters(), this.multiselectDropdowns();this.getFacilities(); this.getFeaturePermission();
      this.tenantWiseStates();
  }

  tenantWiseStates(){
  if(this.is1962LiveStock){
    // this.locationChanged('', 'state')
    this.hospitalForm.get('state_id').setValue(121)
    this.locationChanged('121', 'district')
  }
  if(this.isHealthWellness){
    // this.locationChanged('', 'state')
    this.hospitalForm.get('state_id').setValue(801)
    this.locationChanged('801', 'district')
    setTimeout(() => {
      this.hospitalForm.get('district_id').setValue(4142)
      this.locationChanged('4142', 'mandal')
    }, 500);
  }
  }

  multiselectDropdowns() {
    this.dropdownSettings_departments = {
      singleSelection: false,
      idField: 'id',
      textField: 'department',
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.dropdownSettings_facility = {
      singleSelection: true,
      idField: 'hfr_id',
      textField: 'hfrIdNm',
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      itemsShowLimit: 1,
      allowSearchFilter: true
    };
  }

  defaultLocations() {
    ['RegistrationCertificate', 'Logo','FacilityLogo','Facility Watwemark'].forEach((element, ind) => this.uploadFileList.push({ id: 0, reg_id: ind, name: element, File: null, extensions: '', file_name: '', fileType: ['1', '2','85','86'][ind], document_path: '' }));
    ['png,jpg,jpeg,pdf', 'png,jpg,jpeg,pdf','png,jpg,jpeg','png,jpg,jpeg'].forEach((item, i) => this.uploadFileList[i].extensions = item);

    // this.locationTypeList = [
    //   { id: 1, name: 'Urban' },
    //   { id: 2, name: 'Rural' }
    // ];
  }

  onKeyNmbr(type: string, val: string) {
    if (val != '') {
      if (+this.maxLngth == 9) {
        if (+val[0] == environment.mobValdValSAGE)
          this.hospitalForm.get(type).setValue('');
      }
      if (+this.maxLngth == 10) {
        if (+val[0] < environment.mobValdValIND)
          this.hospitalForm.get(type).setValue('');
      }
    }
  }

  getFeaturePermission() {
    var featureList: any = JSON.parse(localStorage.getItem('featuresList'));
    for (let i = 0; i < featureList.length; i++) {
      if (featureList[i].feature_id.feature_name == "IsFacilityEnable") {
        this.IsFacilityEnable = featureList[i].patient;
      }
      if (featureList[i].feature_id.feature_name == "1962LiveStock") {
        this.is1962LiveStock = featureList[i].patient;
      }
      if (featureList[i].feature_id.feature_name == "Health&Wellness") {   
        this.isHealthWellness = featureList[i].patient;
    }
      
      //isEnableEHR

    }

  }

  checkFacility(){
    if(this.IsFacilityEnable==true){
      // this.hospitalForm.get('facility_id').setValidators([Validators.required])
      // this.hospitalForm.get('facility_name').setValidators([Validators.required])
      if(this.hospitalForm.value.facility_id==""){
        this.toastr.info("Facility Id is mandatory")
        return
      }
      else if(this.hospitalForm.value.facility_name=="")
      this.toastr.info("facility_name is mandatory")
    return
    }
  }

  initFormControls() {
    this.hospitalForm = this.formBuilder.group({
      id: ['0'],
      locationtype_id: ['', Validators.required],
      state_id: ['', Validators.required],
      district_id: ['', Validators.required],
      mandal_id: ['', Validators.required],
      city_id: [''],
      village_id: [''],
      pin_code: ['', [Validators.required, Validators.pattern('^([1-9]){1}([0-9]){5}?$')]],
      hospital_name: ['', [Validators.required, Validators.maxLength(50)]],
      address: ['', [Validators.required, Validators.maxLength(100)]],
      contact_person_email: ['', [Validators.email, Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      contact_person_first_name: ['', Validators.required],
      contact_person_phone_number: ['',Validators.required],
      registration_no: ['', Validators.required],
      // whatsapp_number: ['', Validators.pattern(/^\d+(\.\d{1,2})?$/)],
      whatsapp_number:['',Validators.pattern],
      bank_account_number: [''],
      branch_name: [''],
      branch_location: [''],
      // ifsc_code: ['', Validators.pattern('[A-Z|a-z]{4}[0][a-zA-Z0-9]{6}$')],
      ifsc_code:['',Validators.pattern('^[A-Z]{4}0[A-Z0-9]{6}$')],
      pan_no:[''],
      gstin_no:[''],
      is_headoffice: [false],
      account_activated: [true],
      is_active: [true],
      regFile: ['', Validators.required], //
      // nablFile: [''], //
      exclude_external_patients: [''],
      exclogo: [''], //
      facilitylogo:[''],
      facilitywatermark:[''],
      longitude: "25.3659",
      latitude: "36.32513",
      contact_person_last_name: ['', Validators.required],
      hospital_department_list: [[], Validators.required],
      facility_id:[''],
      facility_name:[''],
      user_types: [
        [{
          "user_type_id": 49
        }]
      ],
      login_name: "",
      password: "123456",
      registration_source_id: 1,
      branch_id: 0,
    });
  }

  get f() { return this.hospitalForm.controls; }

  onSubmit(event: any) {
   
 
      event.preventDefault(), this.submitted = true, this.getFormValidationErrors();
      setTimeout(() => {
        if (this.hospitalForm.invalid) {
          this.toastr.info('Enter all Mandatory Fields');
          return;
        }
        else {
          if(this.IsFacilityEnable==true){
            if(this.hospitalForm.value.facility_id==""){
              this.toastr.info("Facility Id is mandatory")
              return
            }
            else if(this.hospitalForm.value.facility_name==""){
              this.toastr.info("Facility Name is mandatory")
              return
            }
  
          }
          this.hospitalForm.patchValue({
            location_type_id: +this.hospitalForm.value.location_type_id, state_id: +this.hospitalForm.value.state_id, district_id: +this.hospitalForm.value.district_id,
            city_id: +this.hospitalForm.value.city_id, mandal_id: +this.hospitalForm.value.mandal_id, village_id: +this.hospitalForm.value.village_id
          })
          let arr = [];
          const sendData = JSON.parse(JSON.stringify(this.hospitalForm.getRawValue()));
          if (+sendData.id == 0)
            delete sendData.id;
          if (sendData.hospital_department_list?.length > 0) {
            sendData.hospital_department_list.forEach(item => arr.push({ id: 0, hospital_id: 0, department_id: item.id, department: item.department, is_active: true }));
            sendData.hospital_department_list = arr;
          }
          if (this.hospitalData) {
            let data = sendData.hospital_department_list;
            if (this.editDepartmentList.length > 0) {
              this.editDepartmentList.forEach(e => {
                let item = sendData.hospital_department_list.filter(f => f.department_id == e.department_id);
                if (item.length == 0) {
                  e.is_active = false;
                  let uid = e.oldid;
                  e.id = uid;
                  ['created_on', 'modified_on', 'oldid'].forEach(el => { delete e[el]; }), data.push(e);
                }
                else
                  item[0].id = e.oldid, item[0].hospital_id = sendData.id ? sendData.id : 0;
              });
            }
          }
          ["regFile", "exclogo","facilitylogo","facilitywatermark"].forEach(el => { delete sendData[el]; })
          let data={
           
          }
         
          sendData.login_name= this.hospitalForm.value.contact_person_email
          sendData.user_id=this.hospitalForm.value.id==0?null:this.hospitalData?.user_id;
          // sendData.nabl_expiry_date = this.datePipe.transform(sendData.nabl_expiry_date, 'yyyy-MM-dd HH:mm:ss')
          this.registrationService.saveHospital(`hospital/registration?TENANT_ID=${sessionStorage.getItem('currentTenant')}`, sendData).subscribe(res => { // save
            let apiResponse = res;
            if (apiResponse.status == "OK" && apiResponse.message != "Record Already Exists") {
              let id: number = +(sendData.id ?? 0);
              if (id == 0) {
                const notificationData = { email: sendData.contact_person_email, mobile_no: +sendData.contact_person_phone_number, name: sendData.hospital_name };
                this.send_Notification(notificationData);
              }
              if (this.uploadFileList.some(f => f.File != null))
                this.saveFile(apiResponse.data.id,id);
              else
                this.showMessage(id);
            }
            else
              this.toastr.error(apiResponse.message);
          }, error => this.toastr.error(error.message));
        }
      }, 1500);
    
  }

  showMessage(id: number) {
    this.activeModal.close('changes saved');
    this.toastr.success(`Record ${(id > 0) ? 'Updated' : 'Saved'} Successfully`, 'Hospital Registration');
  }

  setLocationTypeValidators() {
    const [cityIdControl, mandalIdControl, villageIdControl] = [this.hospitalForm.get('city_id'), this.hospitalForm.get('mandal_id'),
      this.hospitalForm.get('village_id')];
    this.hospitalForm.get('locationtype_id').valueChanges
      .subscribe(locationType => {
        if (this.isNIG)
          villageIdControl.setValue(''), villageIdControl.setValidators(null);
        if (+locationType == 2) {
          cityIdControl.setValidators(null);
          mandalIdControl.setValue('');
          villageIdControl.setValue('');
          mandalIdControl.setValidators(null);
          villageIdControl.setValidators(null);
        }
        else if (locationType == 1) {
          cityIdControl.setValidators(null);
          cityIdControl.setValue('');
          mandalIdControl.setValidators([Validators.required]);
          if (!this.isNIG)
            villageIdControl.setValidators([Validators.required]);
        }

        cityIdControl.updateValueAndValidity();
        mandalIdControl.updateValueAndValidity();
        villageIdControl.updateValueAndValidity();
      });
  }

  locationChanged(id: string, locationType: string) {
    let query: string = '';
    let locations = {
      'location': () => {
        // this.districtList = [];
        this.mandalList = [];
        this.villageList = [];
        this.cityList = [];
        // this.labForm.get('state_id').setValue('');
        this.hospitalForm.get('district_id').setValue('');
      },
      'state': () => {
        this.districtList = [];
        this.mandalList = [];
        this.villageList = [];
        this.cityList = [];
        // if (+id > 0)
        query = `getOrgStates/${sessionStorage.getItem('currentTenant')}`;
      },
      'district': () => {
        this.districtList = [];
        this.mandalList = [];
        this.villageList = [];
        this.cityList = [];
        if (+id > 0)
          query = `getstatebyid?stateId=${id}`;
      },
      'mandal': () => {
        this.mandalList = [];
        this.villageList = [];
        this.cityList = [];
        if (+id > 0)
          query = `getdistrictbyid?districtId=${id}`;
      },
      'village': () => {
        this.villageList = [];
        if (+id > 0)
          query = `getmandalbyid?mandalId=${id}`;
      },
      'city': () => {
        this.cityList = [];
        if (+id > 0)
          query = `getdistrictbyid?districtId=${id}`;
      }
    };
    locations[locationType]();
    if (query.length > 0)
      this.getMasters(query, locationType);
  }

  getMasters(queryString: string, type: string) {
    this.masterService.getLocations(queryString).subscribe(data => {
      let result = data;
      if (result.status == "OK") {
        let arr: any = result.data;
        let assign_locations = {
          'state': () => this.stateList = arr,//.map(row => this.pick(row, ['id', 'name', 'scheduleCasteType', 'castettype'])),
          'district': () => this.districtList = arr.districts,
          'mandal': () => this.mandalList = arr.mandals,
          'village': () => this.villageList = arr.villages,
          'city': () => this.cityList = arr.cities
        };
        assign_locations[type]();
      }
      this.districtList.sort((a, b) => 0 - (a.district_name > b.district_name ? -1 : 1));
    }, error => this.toastr.error(error));
  }

  setFocus(elementName: string) {
    let el: any = (<HTMLTextAreaElement>(document.getElementById(elementName)));
    el.value = '';
    el.focus();
  }

  keyUp(elementId: string) {
    let el: any = (<HTMLTextAreaElement>(document.getElementById(elementId)));
    el.value = el.value.replace(/^(0*)/, "");
  }

  changeProperty(hashmap: any, existProp: string) {
    Object.keys(hashmap).forEach((key) => {
      // const newkey = key + "xxx";
      hashmap['name'] = hashmap[key];
      delete hashmap[key];
    });
  }

  getFormValidationErrors() {
    Object.keys(this.hospitalForm.controls).forEach(key => {
      const controlErrors: ValidationErrors = this.hospitalForm.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
        });
      }
    });
  }

  restFormValues() {
    this.initFormControls(), this.setLocationTypeValidators();
  }

  submitForm() {
    document.getElementById('btnsubmit').click();
  }

  singleFileUpload(event: any, fileNumber: number, fileType: string) {
    let fileExt: string = this.uploadFileList[fileNumber].extensions;
    // Check if any file is selected.
    if (event.target.files.length > 0) {
      const fileList = event.target, fsize = fileList.files.item(0).size, currentfile = Math.round((fsize / (1024 * 5)));
      let validate: any= this.fileService.isValidFileName(fileList.files[0].name);
      if(!validate){
       this.toastr.warning(`Please give Valid File Format`);
       return;
      }
      // Checking type of file
      if (fileList.files[0]) {
        const fileExtension: string = fileList.files[0].name.split('.').pop().toLowerCase();
        // fileExt = fileExtension;
        if (!this.uploadFileList[fileNumber].extensions.split(',').includes(fileExtension)) {
          this.toastr.warning(`Please Select ${fileExt} files only`);
          return false;
        }
      }
      // The size of the file.
      if (currentfile >= (1024 * 5)) {
        this.toastr.error(fileList.files[0].name + ' too Big, please select a file less than 5MB', 'Doctor Registration');
        return;
      }
      else {
        this.uploadFileList[fileNumber].File = fileList.files.item(0);
        this.uploadFileList[fileNumber].file_name = fileList.files[0].name;
        this.uploadFileList[fileNumber].fileType = fileType;
        if (this.hospitalData) {
          let f = this.hospitalData.hospital_documents.filter(f => f.file_type_id == +this.uploadFileList[fileNumber].fileType);
          this.uploadFileList[fileNumber].id = f.length > 0 ? f[0].id : 0;
        }
      }
    }
  }

  saveFile(id: string,record_id: number) {
    let sendAPIfiles = [];
    this.uploadFileList.forEach(item => {
      if (item.File)
        sendAPIfiles.push(this.fileService.uploadFile(Common.form_Data(item.id.toString(), item.File, id, 'HOSPITAL', item.fileType)));
    });

    forkJoin([...sendAPIfiles]).subscribe(res => console.log, err => console.log, () => { this.showMessage(record_id); });
  }

  getAllMasters() {
    const lab = this.registrationService.getAll('lab/getalllabregistrations');
    const pharmacy = this.registrationService.getAll('pharmacy/getallrecords');
    const locationType = this.masterService.getAll('getalllocationtypes');
    const bankName = this.masterService.getAll('getallmasterbankdetails');
    const deptName = this.masterService.getAll('department/getall');

    forkJoin([lab, pharmacy, locationType, bankName, deptName]).subscribe(
      {
        next: res => {
          res.forEach((list, ind) => {
            if (list.status === 'OK') {
              if (list.data == null || list.data.length == 0)
                console.log('No records available');
              // this.toastr.info('No records available', 'Pharmacy List');
              else {
                const assignList = {
                  '0': () => this.labList = list.data,
                  '1': () => this.pharmacyList = list.data,
                  '2': () => this.locationTypeList = list.data,
                  '3': () => this.bankNamesList = list.data,
                  '4': () => this.departmentList = list.data,
                }
                assignList[ind]();
              }
            }
          });
        },
        error: err => console.error('something wrong occurred: ', err),
        complete: () => {
          this.editHospital();
        }
      });
  }

  send_Notification(payload: { email: string, mobile_no: number, name: string }) {
    const postData = {
      "email": payload.email,//payload.email,
      "mobile_no": payload.mobile_no,
      "user_id": 0,
      "user_type": "hospital",
      "name": payload.name.toUpperCase()
    }
    this.notificationService.sendNotification(postData).subscribe();
  }

  cancelFile(ind: number) {
    let view_File = this.uploadFileList[ind - 1];
    view_File.File = null;
    view_File.file_name = '';
  }

  viewFile(ind: number) {
    let view_File = this.uploadFileList[ind - 1], imagePath = '';
    const doctor_id = +this.hospitalForm.get('id').value;
    const fileExtension: string = view_File.file_name.split('.').pop().toLowerCase();
    if (fileExtension == 'pdf')
      (view_File.File) ? Common.showPDF_File(URL.createObjectURL(view_File.File)) : Common.showPDF_File(`${this.registrationService.viewFilePath}HOSPITAL/${doctor_id}/${view_File.name}/${view_File.document_path}`);
    else if (['jpg', 'jpeg', 'bmp', 'png'].includes(fileExtension)) {
      const modalRef = this.modalService.open(ImagePreviewComponent, { size: 'lg', scrollable: true });
      if (view_File.File) {
        const reader = new FileReader();
        reader.readAsDataURL(view_File.File); // read file as data url
        // called once readAsDataURL is completed
        reader.onload = (event1: any) => {
          imagePath = event1.target.result;
          modalRef.componentInstance.image = imagePath;
        };
      }
      else {
        imagePath = `${this.registrationService.viewFilePath}HOSPITAL/${doctor_id}/${view_File.name}/${view_File.document_path}`;
        modalRef.componentInstance.image = imagePath;
      }
    }
  }

  editHospital() {
    if (this.hospitalData) {
      this.hospitalData.hospital_documents.forEach(f => {
        const docu = this.uploadFileList.find(e => e.fileType == f.file_type_id);
        if (docu) {
          docu.id = f.id;
          docu.document_path = f.document_path;
          docu.file_name = f.file_name;
        }
      });
      this.hospitalData.hospital_department_list = this.hospitalData.hospital_department_list.filter(f => f.is_active == true);
      this.editDepartmentList = [...this.hospitalData.hospital_department_list];
      this.hospitalForm.controls.regFile.clearValidators();
      this.hospitalForm.controls.regFile.updateValueAndValidity();
      this.hospitalForm.controls.account_activated.disable();
      Promise.all([this.locationChanged(this.hospitalData.state_id, 'district'),
      this.hospitalData.locationtype_id == 1 ? (this.locationChanged(this.hospitalData.district_id, 'mandal'),
        this.locationChanged(this.hospitalData.mandal_id, 'village')) : this.locationChanged(this.hospitalData.district_id, 'city')])
        .then(() => {
          ;
          if (this.editDepartmentList)
            this.hospitalData.hospital_department_list.forEach(e => {
              Object.assign(e, { oldid: e.id });
              e.id = e.department_id, e.hospital_id = this.hospitalData.id
            });
          this.hospitalForm.patchValue(this.hospitalData);
        });
    }
  }

  onExcLogo(value) {
    if (value){
      this.hospitalForm.controls.exclogo.setValidators(Validators.required);
      this.exl=true
    } 
    else{
      this.exl=false
      this.hospitalForm.controls.exclogo.clearValidators();
    }
     

    this.hospitalForm.controls.exclogo.updateValueAndValidity();
  }
  getFacilities(){
    this.registrationService.get(`registration/hfr/getallhfrfacilities`).subscribe({
      next: res => {
        if (res.status == 'OK') {
            let fcLst: any[] =  res.data ?? []; 
           fcLst.forEach( r => { Object.assign( r, {hfrIdNm: `${r.hfr_id}-${r.hfr_name}`})});
           this.facilityDatalist = fcLst;
        //  this.toastr.success(res.message)

        } else {
          let errMsg: string = res.message;
          this.toastr.error(errMsg);
        }
      },
      error: (err: HttpErrorResponse) => {
        this.toastr.error(err.error.message);

      },
      complete: () => {
        // Any additional logic after patching values
      },
    });
  }

  isFacilitySelect(e:any){
    let hfrId= e.hfr_id
    this.facilitySelected=true;
    let hfrName1 = this.facilityDatalist.find( (el: any) => el.hfr_id == e.hfr_id).hfr_name;
    this.hospitalForm.get('facility_name').setValue(hfrName1)
    this.hospitalForm.get('facility_id').setValue(hfrId)
    console.log("facility_name",this.hospitalForm.value.facility_name);
    console.log("facility_id",this.hospitalForm.value.facility_id);
  }
  isFacilityDeSelect(e:any){
    this.facilitySelected=false;
    this.hospitalForm.get('facility_name').setValue('')
    this.hospitalForm.get('facility_id').setValue('')
  }

}