import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { MasterService } from 'src/app/shared/services/master.service';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { Common } from 'src/app/common';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';

@Component({
  selector: 'app-refund-list',
  templateUrl: './refund-list.component.html',
  styleUrls: ['./refund-list.component.css'],
  providers: [DatePipe]
})

export class RefundListComponent implements OnDestroy, OnInit {
  private getRefund$ = new Subject<void>();
  private saveRefund$ = new Subject<void>();
  isSubmitDsbld = false; r
  @Input() title: string;
  @Input() DataEdit: any;
  submitted = false;
  lstGrid: Array<any> = [];
  refundForm: FormGroup;
  transaction_amount: string = '';
  refundStatusList: Array<any> = [];
  max_date = new Date();
  constructor(public activeModal: NgbActiveModal
    , private toastr: ToastrService
    , private datePipe: DatePipe
    , private formBuilder: FormBuilder
    , private masterService: MasterService
    , private registrationService: RegistrationService,
    private authSvc: AuthenticationService) { }

  ngOnDestroy(): void {
    this.getRefund$.next();
    this.getRefund$.complete();
    this.saveRefund$.next();
    this.saveRefund$.complete();
  }

  ngOnInit(): void {
    this.initFormControls();
    this.setApprovedStatusValidators();
    this.refundForm.get('refund_request_id').setValue('');
    Common.setFocus('refund_request_id');
    this.editRefund();
  }

  initFormControls() {
    this.refundForm = this.formBuilder.group({
      id: [0],
      approved_on: ['', Validators.required],
      refund_request_id: ['', Validators.required],
      refund_amount: [''],
      refunded_on: [''],
      admin_comments: ['', Validators.required],
      admin_approved: ['', Validators.required],
    });
  }

  get f() { return this.refundForm.controls; }

  onSubmit(event: any) {
    this.submitted = true;
    Common.getFormValidationErrors(this.refundForm);
    if (this.refundForm.invalid) {
      this.toastr.info('Please Enter All Mandatory Fields');
      return;
    }
    else if (this.refundForm.value.admin_comments == "" || this.refundForm.value.admin_comments == undefined || this.refundForm.value.admin_comments == null) {
      this.toastr.info('Please Enter Remarks');
      return;
    }
    else {
      const sendData = this.refundForm.getRawValue();
      let [statusId, refundAmt] = [sendData.refund_request_id, sendData.refund_amount];
      if ((statusId == 3) && (refundAmt > +this.transaction_amount)) {
        this.toastr.error('Refund Amount should be less than or equal to Transaction Amount', 'Refund Request Detail')
        Common.setFocus('refund_amount');
        return;
      }

      if (+sendData.refund_request_id > 0)
        sendData.refund_request = this.refundStatusList.find(e => e.id == +sendData.refund_request_id).name;
      else
        sendData.refund_request = '';
      [sendData.approved_on, sendData.refunded_on] = [sendData.approved_on ? this.datePipe.transform(sendData.approved_on, 'yyyy-MM-dd') : null, sendData.refunded_on ? this.datePipe.transform(sendData.refunded_on, 'yyyy-MM-dd') : null];
      this.registrationService.save('refund/updaterequest', sendData).pipe(takeUntil(this.saveRefund$)).subscribe(res => {
        if (res.status === 'OK') {
          this.toastr.success(res.message, 'Refund Details');
          this.activeModal.close('changes saved');         
        }       
      }, error => { this.toastr.error(error.message) });
    }
  }

  submitForm() { document.getElementById('btnsubmit').click(); }

  setApprovedStatusValidators() {//admin_comments
    const [refund_amountControl, refunded_onControl, approved_onControl, admin_commentsControl] = [this.refundForm.get('refund_amount'), this.refundForm.get('refunded_on'), this.refundForm.get('approved_on'), this.refundForm.get('admin_comments')];
    this.refundForm.get('refund_request_id').valueChanges
      .subscribe(approvedStatus => {
        if (+approvedStatus == 3) {
          refund_amountControl.setValidators([Validators.required]);
          refunded_onControl.setValidators([Validators.required]);
          approved_onControl.setValidators(null);
          admin_commentsControl.setValidators(null);
          refund_amountControl.enable();
          refunded_onControl.enable();
          approved_onControl.disable();
        }
        else {
          refund_amountControl.setValidators(null);
          refunded_onControl.setValidators(null);
          approved_onControl.setValidators([Validators.required]);
          admin_commentsControl.setValidators([Validators.required]);
          refund_amountControl.disable();
          refunded_onControl.disable();
          approved_onControl.enable();
        }
        refund_amountControl.setValue('');
        refunded_onControl.setValue('');

        refund_amountControl.updateValueAndValidity();
        refunded_onControl.updateValueAndValidity();
      });
  }

  editRefund() {
    if (this.DataEdit) {
      this.transaction_amount = this.DataEdit.transaction_amount;
      this.registrationService.getRefund(`refund/getbyid?id=${this.DataEdit.id}`).pipe(takeUntil(this.getRefund$)).subscribe(res => {
        if (res.status === "OK")
          if (res.data !== null || res.data !== "No refund found") {
            let result = res.data;
            this.refundForm.patchValue({
              id: result.id,
              admin_approved: result.admin_approved ?? true,
              refund_amount: result.refund_amount ?? '',
              approved_on: result.approved_on ? new Date(result.approved_on) : '',
              refunded_on: result.refunded_on ? new Date(result.refunded_on) : '',
              admin_comments: result.admin_comments ?? '',
              refund_request_id: result.refund_request_id ?? '',
            });
            // this.refundStatusList = [(+this.DataEdit.status == 2) ? { id: 1, name: 'In Progress' } : { id: 3, name: 'Refunded' }];
            // setTimeout(() => { this.refundForm.value.refund_amount = result.refund_amount; }, 500);
            this.isSubmitDsbld = result.admin_approved ?? true;
            this.refundStatusList = [(+this.DataEdit.status == 2) ? { id: 1, name: 'In Progress' } : { id: 3, name: 'Refunded' }];
            this.refundForm.get('refund_request_id').setValue(this.refundStatusList.filter(f => f.id == this.refundForm.value.refund_request_id).length > 0 ? this.refundForm.value.refund_request_id : '');
          }
      });
    }
  }

  approvalStatusChanged(status: boolean) {
    const refund_request_idControl = this.refundForm.get('refund_request_id');
    if (status) {
      refund_request_idControl.setValidators([Validators.required]);
      refund_request_idControl.enable();
    }
    else {
      refund_request_idControl.setValidators(null);
      refund_request_idControl.disable();
    }
    refund_request_idControl.setValue('');
    refund_request_idControl.updateValueAndValidity();
  }

  keyUp(elementId: string) {
    let el: any = (<HTMLTextAreaElement>(document.getElementById(elementId)));
    el.value = el.value.replace(/^(0*)/, "");
  }

}