<div id="sessionExpired" [ngClass]="{'d-block':showSessionExpired,'d-none':!showSessionExpired}" class="alert alert-danger" role="alert">
    <strong> Your session has expired. Please login again.</strong>
</div>
<div class="limiter">
    <div class="container-login100" [ngStyle]="{ 'background': 'url(' + lgnUrl + ')', 'background-repeat': 'no-repeat', 'background-size': 'cover' }">
        <div class="wrapper">
            <div class="text-center" style="top:0px;  position:absolute;">
                </div>
            <div class="login-page">
                <div class="form m-auto pt-3 pr-5 pl-5">
                    <div class="m-auto pl-5 pr-5 border  border-info shadow-sm rounded-pill"
                        style="top: -31px;position: relative;  background: white;">
                        <h5 style="font-weight: bold" class="text-info pl-3 pr-3 pt-2 pb-2">
                            User Login<!-- <input type="checkbox" checked [(ngModel)]="isDoctor">Doctor({{isDoctor}}) -->
                        </h5><!-- <h5 style="font-weight: bold" class="text-info mt-1">Doctor Login</h5> -->
                    </div>
                    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="login-form mt-2">
                        <div class="col-12 padingleft input-effect">
                            <input class="effect-16" autocomplete="off" formControlName="username" id="username"
                                maxlength="50" name="username" type="text" (change)="onChngUN()" />
                            <label class="fontsize col-12 padingleft text-left"><i class="fas fa-user"
                                    style="color:#007c78;"></i> Username</label>
                            <span class="focus-border"></span> </div>
                        <div class="col-12 padingleft input-effect mt-4">
                            <input class="effect-16" type="password" formControlName="password" id="password"
                                maxlength="15" name="password" type="password" />
                            <label class="fontsize col-12 padingleft text-left"><i class="fas fa-key"
                                    style="color:#007c78;"></i> Password</label>
                            <span class="focus-border"></span> </div>
                        <div class="row mt-4 mb-2">
                            <div class="col-12 col-lg-12 col-12">
                                <button type="submit" class="btn btn-lg btn-primary active  p-1 pt-2"
                                    style="background-color:#a0530b;"> <span
                                        style="text-transform: capitalize; font-size: 18px">Login</span></button>
                            </div>
                            <!-- <div class="col-12 col-lg-8 col-8 mt-3"><a class="float-left forgo" > Forgot Password? </a></div> -->
                        </div>
                    </form>
                    <!-- <div class="row">
                        <div class="col-6 col-lg-6 small mb-1 text-left">
                            <a class="text-info pointer" style="font-size: 12px;"
                                (click)="forgotpaasword()"><i class="fa fa-key"></i> <strong> Forgot Password</strong></a>
                        </div>
                        <div class="col-6 col-lg-6 small mb-1">
                            <div class="form-check-inline">
                                <label class="form-check-label pointer">
                                    <input type="checkbox" class="form-check-input chk" [checked]="isRmbrChk"
                                        (change)="isRmbrChk=!isRmbrChk"><strong>Remember me</strong>
                                </label>
                            </div>
                        </div>
                    </div> -->                    
                    <div class="row">
                        <div class="col-12 col-lg-7 small mb-1 text-xl-left pl-0 pr-0">
                            <a class="text-info pointer" style="font-size: 12px;"
                                (click)="forgotpaasword()"><i class="fa fa-key"></i> <strong> Forgot Password</strong></a>
                        </div>
                        <div class="col-12 col-lg-5 small text-xl-right pl-0 pr-0">
                            <div>
                                <label class="pointer chk2"><input type="checkbox" style="width: auto !important;" [checked]="isRmbrChk"
                                    (change)="isRmbrChk=!isRmbrChk" /> <strong> Remember me</strong>
                                </label>
                            </div>
                        </div>
                    </div>
                    <p class="text-center text-white small" style="position: relative; top: 45px;">Powered by {{pwrdBy}}
                        <br /><span *ngIf="isNIG">&copy;All rights reserved to Dhanush InfoTech</span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="support">
    <h6>For Support, Please contact {{clientName}}</h6>
    <div class="text-right"><a class="btn btn-info" (click)="onTollFree()">
        <i class="fa fa-phone" aria-hidden="true"></i>&nbsp;&nbsp;{{tollFree}}</a>
    </div>
</div>
<!-- <div class="col-md-6 offset-md-3 mt-5">
    <div class="card">
        <h4 class="card-header">
            Angular 8 JWT Login Example<br />
            apiCalled: {{apiCalled}} <br />
            submit clicked: {{loginCalled}}
        </h4>
        <div class="card-body">
            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                <div class="form-group">
                    <label for="username">Username</label>
                    <input type="text" formControlName="username" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.username.errors }" autocomplete="off" />
                    <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                        <div *ngIf="f.username.errors.required">Username is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="password">Password</label>
                    <input type="password" formControlName="password" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                        <div *ngIf="f.password.errors.required">Password is required</div>
                    </div>
                </div>
                <button [disabled]="loading" class="btn btn-primary">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    Login
                </button>
                <button class="btn btn-primary" #loginButton>Login</button>
                <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
            </form>
        </div>
    </div>
</div> -->