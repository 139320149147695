<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="container-fluid">
        <h6 class="text-center">Enter One Time Password</h6>
        <p class="mb-4 text-center">One Time Password(OTP)has been sent to your mobile/eMail</p>
            <!-- ****992922/m******@gmail.com.</p> -->
        <input type="text" id="OTP" onkeypress='return (event.charCode >= 48 && event.charCode <= 57)' [(ngModel)]="OTP" class="form-control form-control-sm" />
        <!-- <p class="small float-right">Get OTP on SMS (00.26)</p> -->
        <a class="small float-right" href="javascript:void(0)" (click)="resendOTP()">Resend OTP</a>

        <a class="btn btn-block btn-sm btn-success mt-3 mb-3 text-white" (click)="verifyOTP()">Verify OTP </a>
    </div>
</div>
<!-- <div class="modal-footer">
    <input type="submit" class="btn btn-sm btn-primary" value="Submit">&nbsp;&nbsp;
    <button type="button" class="btn btn-sm btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
</div> -->