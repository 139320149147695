<div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">Demo Video</h5>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="card-body">
    <div class="row small">
        <!-- <div class="col-xl-12 videoWrapper" style="padding-bottom: 30% !important;" *ngIf="type=='d1'">
            <iframe [src]="vdoPath1"></iframe>
        </div>
        <div class="col-xl-12 videoWrapper" style="padding-bottom: 30% !important;" *ngIf="type=='d2'">
            <iframe [src]="vdoPath2"></iframe>
        </div> -->
        <div class="col-xl-12 videoWrapper" style="padding-bottom: 30% !important;">
            <iframe [src]="vdoPath"></iframe>
        </div>
        <!-- <div class="col-xl-12 videoWrapper" style="padding-bottom: 30% !important;" [hidden]="type!='d1'">
            <iframe src="https://vidmed2.dhanushinfotech.com/media/Doctor_Login_Demo_Video_Vidmed2.0.mp4"
                width='1366px' height='623px'></iframe>
        </div>
        <div class="col-xl-12 videoWrapper" style="padding-bottom: 30% !important;" [hidden]="type!='d2'">
            <iframe src="https://vidmed2.dhanushinfotech.com/media/Doctor_Patient_Consultation_Video_Vidmed2.0.mp4"
                width='1366px' height='623px'></iframe>
        </div> -->
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-sm btn-secondary" (click)="activeModal.close('Close click')">Close</button>
</div>
            <!-- <iframe src='https://view.officeapps.live.com/op/embed.aspx?src=http://remote.url.tld/path/to/document.doc' width='1366px'
                height='623px' frameborder='0'>This is an embedded <a target='_blank' href='http://office.com'>Microsoft Office</a>
                document, powered by <a target='_blank' href='http://office.com/webapps'>Office Online</a>.</iframe> -->
            <!-- <iframe src="https://drive.google.com/file/d/15om8RXb8o4xNI3Ge6LTTKwL4tu45YFxV/view?usp=sharing"></iframe> -->
            <!-- <iframe [src]="docPath"></iframe> -->
            <!-- <iframe src="https://docs.google.com/gview?url=http://remote.url.tld/path/to/document.doc&embedded=true"></iframe> -->