import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-history-of-present-illness',
  templateUrl: './history-of-present-illness.component.html',
  styleUrls: ['./history-of-present-illness.component.css']
})
export class HistoryOfPresentIllnessComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
