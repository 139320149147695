<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="container-fluid">
        <form [formGroup]="doctorForm" (ngSubmit)="onSubmit($event)">
            <div class="row">
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Type of Doctor <span class="text-danger">*</span> </label>
                        <div>
                            <select formControlName="doctor_type_id" id="doctor_type_id" #TypeOfDoctor
                                [ngClass]="{ 'is-invalid': submitted && f.doctor_type_id.errors }"
                                class="form-control form-control-sm" (change)="onDoctorChange($event.target.value,true)"
                                [attr.disabled]="isEditable ? true : null">
                                <option value="">Select</option>
                                <!-- <option value="1">Hospital</option>
                                <option value="2">Consultant</option> -->
                                <option value="1">Medical Specialist Doctor</option>
                                <option value="2">Medical Officer</option>
                                <!-- <option value="4">Ayush</option> -->
                                <option *ngIf="isGNM" value="3">Genome</option>
                            </select>
                            <div *ngIf="submitted && f.doctor_type_id.errors" class="invalid-feedback">
                                <div *ngIf="f.doctor_type_id.errors.required">Type of Doctor is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" *ngIf="hide==1">
                    <div class="form-group">
                        <label class="control-label">  Hospital Unique Code </label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="group_reference_code" id="group_reference_code"
                                [ngClass]="{ 'is-invalid': submitted && f.group_reference_code.errors }" maxlength="20" 
                                class="form-control form-control-sm" readonly>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" *ngIf="hide==1"> 
                    <div class="form-group">
                        <label class="control-label"> Individual Unique Code </label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="individual_reference_code"
                                [ngClass]="{ 'is-invalid': submitted && f.individual_reference_code.errors }" maxlength="20"
                                class="form-control form-control-sm" id="individual_reference_code" readonly>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-xl-3" > -->
                    <!-- <div class="form-check-inline">
                        <label class="control-label">Exclusivity for Reference Code &nbsp;&nbsp;&nbsp; 
                        <input type="checkbox" formControlName="exclusive_reference_code" id="exclusive_reference_code"
                            [attr.disabled]="isEditable ? true : null"></label>
                    </div> -->
                    <!-- below line commented as per ling murthi inputs 28-07-2022 -->
                    <!-- <div class="form-check form-check-inline" style="padding-left: 15px !important;">
                        <input type="checkbox" formControlName="exclusive_reference_code" id="exclusive_reference_code"
                            [attr.disabled]="isEditable ? true : null">  &nbsp;&nbsp;
                        <label class="form-check-label" for="inlineCheckbox22"> Exclusivity for Reference Code</label>
                      </div> -->
                      <!-- lines ended 28-07-2022 -->
                <!-- </div> -->
            </div>
            <div class="row">
                <!-- <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Hospital Name<span class="text-danger" *ngIf="+TypeOfDoctor.value==1">*</span> </label>
                        <div>
                            <select formControlName="hospital_name" id="hospital_name"
                                [ngClass]="{ 'is-invalid': submitted && f.hospital_name.errors }"
                                [attr.disabled]="(+TypeOfDoctor.value==1 && !isEditable)?null:true"
                                class="form-control form-control-sm" (change)="onHospitalName($event.target.value)">
                                <option value="">Select</option>
                                <option *ngFor="let hospital of hospitalList" [value]="hospital.id">
                                    {{hospital.hospital_name}}</option>
                            </select>
                            <div *ngIf="submitted && f.hospital_name.errors" class="invalid-feedback">
                                <div *ngIf="f.hospital_name.errors.required">Hospital Name is required</div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <!-- New lines added below for huds drop don when we select type of doctore is Hospital 05-04-2022 -->
                <div class="col-xl-3" *ngIf="hubsList && hubsList.length>0">
                    <div class="form-group">
                        <label class="control-label">Hub Hospitals<span class="text-danger" *ngIf="+TypeOfDoctor.value==1">*</span> </label>
                        <div>
                            <select formControlName="hospital_name" id="hub_hospital"
                                [ngClass]="{ 'is-invalid': submitted && f.hospital_name.errors }"
                                (change)="onChangedForDepartments($event.target.value)"
                                class="form-control form-control-sm">
                                <option value="null" *ngIf="!isEdit">Select</option>
                                <option *ngFor="let hubObj of hubsList" [value]="hubObj.id">
                                    {{hubObj.hospital_name}}</option>
                            </select>
                            <div *ngIf="submitted && f.hospital_name.errors" class="invalid-feedback">
                                <div *ngIf="f.hospital_name.errors.required">Hub is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end of hud drop down 05-04-2022 -->
                <!-- New lines added for referral type of hospital when we select the Consultant 05-04-2022 -->
                <div class="col-xl-3" *ngIf="getallhospitalTypeList && getallhospitalTypeList.length>0 && hubsList.length==0">
                    <div class="form-group">
                        <label class="control-label">Category Type <span class="text-danger" *ngIf="+TypeOfDoctor.value==2">*</span> </label>
                        <div>
                          <select class="form-control form-control-sm" id="centerType" [(ngModel)]="refType" (change)='changeHospitalType($event.target.value)'
                          formControlName="center_type" >
                              <!-- <option value="Center">Center</option> -->
                              <option value="" *ngIf="!isEdit">Select</option>
                              <option *ngFor="let type of getallhospitalTypeList" [value]="type">
                                {{type}}</option>
                          </select>
                         
                        </div>
                    </div>
                </div>
                <!-- end of referral types of hospital drop down -->
                <div class="col-xl-3" *ngIf="(refType!='Referral' && refType!='Hub') && (centerHospitals && centerHospitals.length>0)">
                    <div class="form-group">
                        <label class="control-label">Spoke Hospitals <span class="text-danger" *ngIf="refType!='Referral'">*</span></label>
                        <div>                            
                            <div >
                                <!-- <ng-multiselect-dropdown [settings]="dropdownSettings_module" [placeholder]="'Select'"
                                    [data]="doctorlist" [disabled]="isEditable" formControlName="doctor_modules"></ng-multiselect-dropdown> -->
                                <select formControlName="center_id" id="center_id"  class="form-control form-control-sm" [ngClass]="{ 'is-invalid': submitted && f.center_id.errors }"  (change)="onChangedForDepartments($event.target.value)">
                                    <option value="" *ngIf="!isEdit">Select</option>
                                    <option *ngFor="let center of centerHospitals" [value]="center.id">{{center.hospital_name}}</option>
                                </select>

                                <div *ngIf="submitted && f.center_id.errors" class="invalid-feedback">
                                    <div *ngIf="f.center_id.errors.required">Center Hospital is required</div>
                                </div>
                                <!-- [ngClass]="{ 'is-invalid': submitted && f.doctor_modules.errors }" -->
                                <!-- <div *ngIf="submitted && f.doctor_modules.errors" class="invalid-feedback" >
                                    <div *ngIf="f.doctor_modules.errors.required">Languages is required</div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" *ngIf="refType=='Referral' && getallRefhospitalTypesList && getallRefhospitalTypesList.length>0">
                    <div class="form-group">
                        <label class="control-label">Type of Referral Hospital<span class="text-danger">*</span></label>
                        <div>
                          <select class="form-control form-control-sm" id="hospital_type_id" 
                          formControlName="hospital_type_id" [ngClass]="{ 'is-invalid': submitted && f.hospital_type_id.errors }" (change)='changeReferralType($event.target.value)'>
                              <option value="null" *ngIf="!isEdit">Select</option>
                              <option *ngFor="let type of getallRefhospitalTypesList" [value]="type.id">
                                {{type.hospital_type}}</option>
                          </select>
                          <div *ngIf="submitted && f.hospital_type_id.errors" class="invalid-feedback">
                            <div *ngIf="f.hospital_type_id.errors.required">Referral Type Hospital is required</div>
                        </div>
                          <!-- <div *ngIf="submitted && f.referral_type.errors" class="invalid-feedback">
                            <div *ngIf="f.hospital_type_id.errors.required">Referral is required</div>
                          </div>-->
                        </div> 
                    </div>
                </div>
              
                <!-- new lines added for the referral hospital drop down 06-04-2022 -->
                <div class="col-xl-3" *ngIf="refType=='Referral' && getallRefhospitalTypesList && getallRefhospitalTypesList.length>0">
                    <div class="form-group">
                        <label class="control-label">Referral Hospitals <span class="text-danger" *ngIf="refType=='Referral'">*</span></label>
                        <div>                            
                            <div >
                                <!-- <ng-multiselect-dropdown [settings]="dropdownSettings_module" [placeholder]="'Select'"
                                    [data]="doctorlist" [disabled]="isEditable" formControlName="doctor_modules"></ng-multiselect-dropdown> -->
                                <select formControlName="referral_hospital" id="referral_hospital" [ngClass]="{ 'is-invalid': submitted && f.referral_hospital.errors }"  class="form-control form-control-sm"  (change)="onChangedForDepartments($event.target.value)">
                                    <option value="null" *ngIf="!isEdit">Select</option>
                                    <option *ngFor="let ref of referralHospitals" [value]="ref.id">{{ref.hospital_name}}</option>
                                </select>
                                <div *ngIf="submitted && f.referral_hospital.errors" class="invalid-feedback">
                                    <div *ngIf="f.referral_hospital.errors.required">Referral  Hospital is required</div>
                                </div>
                                <!-- [ngClass]="{ 'is-invalid': submitted && f.doctor_modules.errors }" -->
                                <!-- <div *ngIf="submitted && f.doctor_modules.errors" class="invalid-feedback" >
                                    <div *ngIf="f.doctor_modules.errors.required">Languages is required</div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end of line referral hospital drop down -->
                
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> Name <span class="text-danger">*</span> </label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="first_name" id="first_name"
                                maxlength="20" [ngClass]="{ 'is-invalid': submitted && f.first_name.errors }" 
                                onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                    (event.charCode >= 97 && event.charCode <= 122)|| event.charCode == 32)'
                                class="form-control form-control-sm text-uppercase"
                                [attr.disabled]="isEditable ? true : null">
                            <div *ngIf="submitted && f.first_name.errors" class="invalid-feedback">
                                <div *ngIf="f.first_name.errors.required">Name is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> Surname <span class="text-danger">*</span> </label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="last_name" id="last_name"
                                [ngClass]="{ 'is-invalid': submitted && f.last_name.errors }" maxlength="20" (focus)="dp.hide()"
                                onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                    (event.charCode >= 97 && event.charCode <= 122)|| event.charCode == 32)'
                                class="form-control form-control-sm text-uppercase"
                                [attr.disabled]="isEditable ? true : null">
                            <div *ngIf="submitted && f.last_name.errors" class="invalid-feedback">
                                <div *ngIf="f.last_name.errors.required">Name is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Employee ID </label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="employee_id"
                                class="form-control form-control-sm text-uppercase" id="employee_id"
                                onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                (event.charCode >= 97 && event.charCode <= 122)) || (event.charCode >= 48 &&
                                event.charCode <= 57)' [attr.disabled]="isEditable ? true : null" maxlength="20">
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Date of Birth <span class="text-danger">*</span> </label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="birth_date" id="birth_date"
                                bsDatepicker  #dp="bsDatepicker" class="form-control form-control-sm" [maxDate]="max_date"
                                [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false,adaptivePosition: true,isAnimated: true }"
                                [ngClass]="{ 'is-invalid': submitted && f.birth_date.errors }" (focus)="dp.show()"
                                [attr.disabled]="isEditable ? true : null">
                            <div *ngIf="submitted && f.birth_date.errors" class="invalid-feedback">
                                <div *ngIf="f.birth_date.errors.required">
                                    Date of Birth is required
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <!-- </div>
            <div class="row"> -->
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Gender <span class="text-danger">*</span> </label>
                        <div>
                            <select formControlName="gender_id" id="gender_id" (focus)="dp.hide()"
                                [ngClass]="{ 'is-invalid': submitted && f.gender_id.errors }"
                                class="form-control form-control-sm" [attr.disabled]="isEditable ? true : null">
                                <option value="">Select</option>
                                <option *ngFor="let gender of genderList" [value]="gender.id">{{gender.gender_name}}</option>
                            </select>
                            <div *ngIf="submitted && f.gender_id.errors" class="invalid-feedback">
                                <div *ngIf="f.gender_id.errors.required">Gender is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> Mobile Number <span class="text-danger">*</span> </label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="mobile" id="mobile"
                                [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }" [pattern]="mobPtrn"
                                onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'
                                class="form-control form-control-sm" [maxlength]="maxLngth"
                                (keyup)="onKeyNmbr('mobile', $event.target.value)" [attr.disabled]="isEdit==true?true:null">
                            <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
                                <div *ngIf="f.mobile.errors?.required">Mobile Number is required</div>
                                <div *ngIf="f.mobile.errors.pattern">Mobile Number {{mobErrMsg}}</div>
                                <!-- <div *ngIf="f.mobile.errors.required">Mobile Number {{mobErrMsg}}</div>
                                <div *ngIf="f.mobile.errors.minLength">Mobile is 10 digits</div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> Email <span class="text-danger">*</span> </label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="email" id="email"
                                [ngClass]="{ 'is-invalid': submitted && f.email.errors }" maxlength="60"
                                class="form-control form-control-sm" [attr.disabled]="isEdit==true?true:null"  
                                onkeypress='return ((event.charCode == 64) ||(event.charCode == 46) ||
                                (event.charCode >= 97 && event.charCode <= 122)) || (event.charCode >= 48 && event.charCode <= 57)'>
                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                <div *ngIf="f.email.errors.required">Email is required</div>
                                <div *ngIf="f.email.errors.pattern">Enter Valid Email</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">{{isSAGE ? 'KMPDU' : 'MCI'}} Number <span class="text-danger">*</span></label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="mci_number"
                                [ngClass]="{ 'is-invalid': submitted && f.mci_number.errors }"
                                class="form-control form-control-sm" maxlength="20"
                                onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                (event.charCode >= 97 && event.charCode <= 122) || (event.charCode == 32)) ||
                                (event.charCode >= 48 && event.charCode <= 57)' id="mci_number"
                                [attr.disabled]="isEditable ? true : null">
                            <div *ngIf="submitted && f.mci_number.errors" class="invalid-feedback">
                                <div *ngIf="f.mci_number.errors.required">{{isSAGE ? 'KMPDU' : 'MCI'}} Number is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Registration Expiry Date<span class="text-danger">*</span></label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="reg_expiry_date" id="reg_expiry_date" 
                                bsDatepicker #dp1="bsDatepicker" [minDate]="maxdate" class="form-control form-control-sm"
                                [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false,adaptivePosition: true,isAnimated: true }"
                                [ngClass]="{ 'is-invalid': submitted && f.reg_expiry_date.errors }" (focus)="dp1.show()"
                                [attr.disabled]="isEditable ? true : null">
                            <div *ngIf="submitted && f.reg_expiry_date.errors" class="invalid-feedback">
                                <div *ngIf="f.reg_expiry_date.errors.required">Registration Expiry Date is required</div>
                            </div>
                        </div>
                    </div>
                </div>
            <!-- </div>
            <div class="row"> -->
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Location Type <span class="text-danger">*</span> </label>
                        <div>
                            <select formControlName="location_type_id" id="location_type_id" #locationId
                                [ngClass]="{ 'is-invalid': submitted && f.location_type_id.errors }"
                                (change)="locationChanged('','location')" class="form-control form-control-sm"
                                [attr.disabled]="isEditable ? true : null">
                                <option value="">Select</option>
                                <option *ngFor="let location of locationTypeList" [value]="location.id">
                                    {{location.location_type}}</option>
                            </select>
                            <div *ngIf="submitted && f.location_type_id.errors" class="invalid-feedback">
                                <div *ngIf="f.location_type_id.errors.required">Location Type is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">{{isNIG ? 'States' : 'State'}} <span class="text-danger">*</span> </label>
                        <div>
                            <select formControlName="state_id" id="state_id" #State
                                (change)="locationChanged(State.value,'district')"
                                [ngClass]="{ 'is-invalid': submitted && f.state_id.errors }"
                                class="form-control form-control-sm"[attr.disabled]="isEditable ? true : null">
                                <option value="">Select</option>
                                <option *ngFor="let state of stateList" [value]="state.id">
                                    {{state.state_name}}</option>
                            </select>
                            <div *ngIf="submitted && f.state_id.errors" class="invalid-feedback">
                                <div *ngIf="f.state_id.errors.required">
                                    {{isNIG ? 'States' : 'State'}} is required
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">{{isNIG ? 'State Capitals' : 'District'}} <span class="text-danger">*</span> </label>
                        <div>
                            <select formControlName="district_id" id="district_id" #District
                                (change)="locationChanged(District.value,(+locationId.value==2)?'city':'mandal')"
                                [ngClass]="{ 'is-invalid': submitted && f.district_id.errors }"
                                class="form-control form-control-sm" [attr.disabled]="isEditable ? true : null">
                                <option value="">Select</option>
                                <option *ngFor="let district of districtList" [value]="district.id">
                                    {{district.district_name}}</option>
                            </select>
                            <div *ngIf="submitted && f.district_id.errors" class="invalid-feedback">
                                <div *ngIf="f.district_id.errors.required">
                                    {{isNIG ? 'State Capitals' : 'District'}} is required
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">
                            {{isSAGE ? 'Location' : (isNIG ? 'Local Government Areas' : 'Block')}}
                            <span *ngIf="+locationId?.value==1" class="text-danger">*</span> </label>
                        <div>
                            <select formControlName="mandal_id" id="mandal_id" #Mandal
                                [attr.disabled]="(+locationId.value==2 || isEditable)?true:null"
                                (change)="locationChanged(Mandal.value,'village')"
                                [ngClass]="{ 'is-invalid': submitted && f.mandal_id.errors }"
                                class="form-control form-control-sm">
                                <option value="">Select</option>
                                <option *ngFor="let mandal of mandalList" [value]="mandal.id">
                                    {{mandal.mandal_name}}</option>
                            </select>
                            <div *ngIf="submitted && f.mandal_id.errors" class="invalid-feedback">
                                <div *ngIf="f.mandal_id.errors.required">
                                    {{isSAGE ? 'Location' : (isNIG ? 'Local Government Areas' : 'Block')}} is required
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <!-- </div>
            <div class="row"> -->
                <div class="col-xl-3" *ngIf="+locationId.value==2">
                    <div class="form-group">
                        <label class="control-label">City </label>
                        <div>
                            <select formControlName="city_id" id="city_id"
                                [ngClass]="{ 'is-invalid': submitted && f.city_id.errors }"
                                class="form-control form-control-sm"
                                [attr.disabled]="isEditable ? true : null">
                                <option value="">Select</option>
                                <option *ngFor="let city of cityList" [value]="city.id">
                                    {{city.city_name}}</option>
                            </select>
                            <div *ngIf="submitted && f.city_id.errors" class="invalid-feedback">
                                <div *ngIf="f.city_id.errors.required">City is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" *ngIf="+locationId.value != 2 && !isNIG">
                    <div class="form-group">
                        <label class="control-label">Village <span *ngIf="+locationId.value==1" class="text-danger">*</span> </label>
                        <div>
                            <select formControlName="village_id" id="village_id"
                                [attr.disabled]="(+locationId.value==2 || isEditable)?true:null"
                                [ngClass]="{ 'is-invalid': submitted && f.village_id.errors }"
                                class="form-control form-control-sm">
                                <option value="">Select</option>
                                <option *ngFor="let village of villageList" [value]="village.id">
                                    {{village.village_name}}</option>
                            </select>
                            <div *ngIf="submitted && f.village_id.errors" class="invalid-feedback">
                                <div *ngIf="f.village_id.errors.required">Village is required
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
             
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Pin Code <span class="text-danger">*</span>
                        </label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="pin_code" id="pin_code"
                                (keyup)="keyUp('pin_code')" maxlength="6" [attr.disabled]="isEditable ? true : null"
                                onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                                [ngClass]="{ 'is-invalid': submitted && f.pin_code.errors }"
                                class="form-control form-control-sm"> <!-- [minlength]="isSAGE?1:6" -->
                            <div *ngIf="submitted && f.pin_code.errors" class="invalid-feedback">
                                <div *ngIf="f.pin_code.errors?.required">Pin Code is required</div>
                                <div *ngIf="f.pin_code.errors.pattern">Enter Valid Pin Code</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Marital Status<span class="text-danger">*</span></label>
                        <div>
                            <select formControlName="marital_status_id" id="marital_status_id"
                                [ngClass]="{ 'is-invalid': submitted && f.marital_status_id.errors }"
                                class="form-control form-control-sm">
                                <option value="">Select</option>
                                <option *ngFor="let marital of maritalList" [value]="marital.id">{{marital.marital_status}}</option>
                                
                            </select>
                            <div *ngIf="submitted && f.marital_status_id.errors" class="invalid-feedback">
                                <div *ngIf="f.marital_status_id.errors.required">Marital Status is required</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row"> -->
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Qualification <span class="text-danger">*</span> </label>
                        <div>
                            <select formControlName="qualification_id" id="qualification_id"
                                (change)="QualificationChanged($event.target.value)"
                                [ngClass]="{ 'is-invalid': submitted && f.qualification_id.errors }"
                                class="form-control form-control-sm" #QualificationId
                                [attr.disabled]="isEditable ? true : null"
                                (change)="onQualification($event.target.value)">
                                <option value="">Select</option>
                                <option *ngFor="let qualification of qualificationsList" [value]="qualification.id">
                                    {{qualification.qualification}}</option>
                            </select>
                            <div *ngIf="submitted && f.qualification_id.errors" class="invalid-feedback">
                                <div *ngIf="f.qualification_id.errors.required">Qualification is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" *ngIf="+f.qualification_id.value != 1">
                    <div class="form-group">
                        <label class="control-label"> Specialization <span class="text-danger">*</span> </label>
                        <div>
                            <select formControlName="specialization_id" id="specialization_id"
                                [ngClass]="{ 'is-invalid': submitted && f.specialization_id.errors }"
                                class="form-control form-control-sm" [attr.disabled]="isEditable ? true : null">
                                <option value="">Select</option>
                                <option *ngFor="let specialization of newSpecializationList" [value]="specialization.id">
                                    {{specialization.specialization}}</option>
                            </select>
                            <div *ngIf="submitted && f.specialization_id.errors" class="invalid-feedback">
                                <div *ngIf="f.specialization_id.errors.required">Specialization is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" *ngIf="+f.qualification_id.value != 1">
                    <div class="form-group">
                        <label class="control-label"> Super Speciality Degree</label>
                        <div>
                            <select formControlName="super_qualification_id" id="super_qualification_id"
                                (change)="superSecialityDegree($event.target.value)"
                                class="form-control form-control-sm" [attr.disabled]="isEditable ? true : null">
                                <option value="">Select</option>
                                <option *ngFor="let superSpecialityDegree of superSpecialityDegreeList"
                                    [value]="superSpecialityDegree.id">{{superSpecialityDegree.qualification}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" *ngIf="+f.qualification_id.value != 1">
                    <div class="form-group">
                        <label class="control-label">Super Specialisation</label>
                        <div>
                            <select formControlName="super_specialization_id" id="super_specialization_id"
                                class="form-control form-control-sm" [attr.disabled]="isEditable ? true : null">
                                <option value="">Select</option>
                                <option *ngFor="let super of super_SpecialisationList" [value]="super.id">
                                    {{super.specialization}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Other Qualifications, if any</label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="other_qualification" id="other_qualification"
                                [ngClass]="{ 'is-invalid': submitted && f.other_qualification.errors }" maxlength="100"
                                placeholder="Other Qualifications, if any" class="form-control form-control-sm"
                                [attr.disabled]="isEditable ? true : null">
                            <div *ngIf="submitted && f.other_qualification.errors" class="invalid-feedback">
                                <div *ngIf="f.other_qualification.errors.maxlength">Should allow up to 100 characters only</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Department<span class="text-danger" *ngIf="+TypeOfDoctor.value==1">*</span> </label>
                        <div>
                            <!-- <select formControlName="department_id" id="department_id"
                                [attr.disabled]="(+TypeOfDoctor.value==1 && !isEditable)?null:true"
                                [ngClass]="{ 'is-invalid': submitted && f.department_id.errors }"
                                class="form-control form-control-sm">
                                <option value="">Select</option>
                                <option *ngFor="let dept of departmentList" [value]="dept.department_id">
                                    {{dept.department}}</option>
                            </select> -->
                            <select formControlName="department_id" id="department_id"
                            class="form-control form-control-sm">
                            <option value="">Select</option>
                            <option *ngFor="let dept of departmentList" [value]="dept.department_id">
                                {{dept.department}}</option>
                        </select>
                            <div *ngIf="submitted && f.department_id.errors" class="invalid-feedback">
                                <div *ngIf="f.department_id.errors.required">Department is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Hospital Working in Presently
                            <span *ngIf="+TypeOfDoctor.value==2 || +TypeOfDoctor.value==3" class="text-danger">*</span></label>
                        <div>
                            <select formControlName="hospital_working_in_presently" id="hospital_working_in_presently"
                                class="form-control form-control-sm" [attr.disabled]="(+TypeOfDoctor.value==1 || isEditable)?true:null"
                                [ngClass]="{ 'is-invalid': submitted && f.hospital_working_in_presently.errors }"
                                (change)="OtherHsptl.value='';onHospitalWorking('dd', $event.target.value)" #HospitalPresent>
                                <option value="">Select</option>
                                <option *ngFor="let hospital of hospitalList" [value]="hospital.id">
                                    {{hospital.hospital_name}}</option>
                            </select>
                            <div *ngIf="submitted && f.hospital_working_in_presently.errors" class="invalid-feedback">
                                <div *ngIf="f.hospital_working_in_presently.errors.required">Hospital Working in Presently is required
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <!-- <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> Other Hospital</label>
                        <div>
                              <input type="text" autocomplete="off" formControlName="other_hospital" id="other_hospital"
                                class="form-control form-control-sm" maxlength="100" #OtherHsptl [readonly]="isEditable"
                                [attr.disabled]="(+TypeOfDoctor.value==1 || HospitalPresent.value!='')?true:null"
                                (change)="onHospitalWorking('other',$event.target.value)"
                                onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                    (event.charCode >= 97 && event.charCode <= 122)|| event.charCode == 32)'>
                        </div>
                    </div>
                </div> -->
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> Experience (Years) <span class="text-danger">*</span>
                        </label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="years_experience"
                                id="years_experience" maxlength="30" [attr.disabled]="isEditable ? true : null"
                                onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                                [ngClass]="{ 'is-invalid': submitted && f.years_experience.errors }"
                                class="form-control form-control-sm">
                            <div *ngIf="submitted && f.years_experience.errors" class="invalid-feedback">
                                <div *ngIf="f.years_experience.errors.required">Experience is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Audio Consultation Rate<span class="text-danger">*</span></label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="audio_consultation_price"
                                id="audio_consultation_price" maxlength="5"
                                [ngClass]="{ 'is-invalid': submitted && f.audio_consultation_price.errors }"
                                onkeypress='return  (event.charCode >= 48 && event.charCode <= 57)'
                                class="form-control form-control-sm">
                            <div *ngIf="submitted && f.audio_consultation_price.errors" class="invalid-feedback">
                                <div *ngIf="f.audio_consultation_price.errors.required">Audio Consultation Rate is
                                    required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Video Consultation Rate<span class="text-danger">*</span></label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="video_consultation_price"
                                id="video_consultation_price" maxlength="5" class="form-control form-control-sm"
                                [ngClass]="{ 'is-invalid': submitted && f.video_consultation_price.errors }"
                                onkeypress='return  (event.charCode >= 48 && event.charCode <= 57)' (focus)="dp1.hide()">
                            <div *ngIf="submitted && f.video_consultation_price.errors" class="invalid-feedback">
                                <div *ngIf="f.video_consultation_price.errors.required">
                                    Video Consultation Rate is required</div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <!-- </div><div class="row"> -->
                <!-- <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> Available on Days <span class="text-danger">*</span>
                        </label>
                        <div>
                            <ng-multiselect-dropdown [settings]="dropdownSettings_weekDays" [placeholder]="'All'"
                                [data]="weekDaysList" formControlName="weekday_id"></ng-multiselect-dropdown>
                        </div>
                    </div>
                </div>
                <div class="col-xl-2">
                    <div class="form-group">
                        <label class="control-label">Available From Time<span class="text-danger">*</span></label>
                        <div>
                            <timepicker formControlName="from_time" [showMeridian]="isMeridian"></timepicker>
                        </div>
                    </div>
                </div>
                <div class="col-xl-2">
                    <div class="form-group">
                        <label class="control-label">Available To Time<span class="text-danger">*</span></label>
                        <div>
                            <timepicker formControlName="to_time" [showMeridian]="isMeridian"></timepicker>
                        </div>
                    </div>
                </div> -->
                
                <!-- </div><div class="row"> -->

                    <div class="col-xl-3" [ngClass]="showCharge?'':'d-none'">
                        <div class="form-group">
                            <label class="control-label"> is Health Worker</label>
                            <div>
                                <input type="checkbox" #isHW formControlName="isHW" (change)="HW.value=''">
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3" [ngClass]="showCharge?'':'d-none'">
                        <div class="form-group">
                            <label class="control-label"> Health Worker</label>
                            <div>
                                <!-- [attr.disabled]="isHW.checked?null:true" -->
                                <select formControlName="hlthWorker" id="hlthWorker"
                                [attr.disabled]="isHW.checked?null:true" class="form-control form-control-sm" #HW>
                                    <option value="">Select</option>
                                    <option *ngFor="let hw of hwList" [value]="hw.id">{{hw.first_name+' '+hw.last_name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                
                    <div class="col-xl-3">
                        <div class="form-group" *ngIf="showCharge">
                            <label class="control-label"> Is Doctor Chargeable</label>
                            <div>
                                <input type="checkbox" formControlName="charge_amt">
                            </div>
                        </div>
                    </div>
                
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> Languages Preferred <span class="text-danger">*</span> </label>
                        <div>                            
                            <div tabindex="0" class="" >
                                <ng-multiselect-dropdown [settings]="dropdownSettings_language" [placeholder]="'All'"
                                    [data]="languagesList" [ngClass]="{ 'is-invalid': submitted && f.doctor_languages.errors }"
                                    [disabled]="isEditable" formControlName="doctor_languages"></ng-multiselect-dropdown>
                                <div *ngIf="submitted && f.doctor_languages.errors" class="invalid-feedback" >
                                    <div *ngIf="f.doctor_languages.errors.required">Languages is required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> Status <span class="text-danger">*</span>
                        </label>
                        <div>
                            <input type="radio" formControlName="account_activated" [attr.disabled]="isEditable?true:null" [value]="true">Active
                            <input type="radio" formControlName="account_activated" [attr.disabled]="isEditable?true:null" [value]="false">In Active
                        </div>
                    </div>
                </div>
                <div class="">
                    <!-- <div class="form-group">
                        <label class="control-label">Send Notifications to Mobile</label>
                        <div>
                            <input type="checkbox" formControlName="send_notification_to_mobile" [attr.disabled]="isEditable?true:null">
                        </div>
                    </div> -->
                    <div class="form-check form-check-inline">
                        <input type="checkbox" formControlName="send_notification_to_mobile" [attr.disabled]="isEditable?true:null">
                        &nbsp;&nbsp;<label class="form-check-label" for="inlineCheckbox22">Send Notifications to Mobiles</label>
                      </div>
                </div>
                <div class="col-xl-3" style="display: none;" >
                    <div class="form-group">
                        <label class="control-label">Bank Account Number <span class="text-danger">*</span> </label>
                        <div>

                            <input type="text" autocomplete="off" formControlName="bank_account_number"
                                id="bank_account_number" class="form-control form-control-sm" maxlength="20"
                                onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'
                                (keyup)="keyUp('bank_account_number')" [attr.disabled]="isEditable ? true : null"
                                [ngClass]="{ 'is-invalid': submitted && doctorForm.get('bank_account_number').invalid }">
                            <div *ngIf="submitted && doctorForm.get('bank_account_number').invalid"
                                class="invalid-feedback">
                                <div *ngIf="doctorForm.get('bank_account_number').errors.required">
                                    Bank Account Number is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" style="display: none;" >
                    <div class="form-group">
                        <label class="control-label"> Bank Name <span class="text-danger">*</span> </label>
                        <div>
                            <select formControlName="branch_name" id="branch_name" class="form-control form-control-sm"
                                [ngClass]="{ 'is-invalid': submitted && doctorForm.get('branch_name').errors }"
                                [attr.disabled]="isEditable ? true : null">
                                <option value="">Select</option>
                                <option *ngFor="let item of bankNamesList" [value]="item.id">
                                    {{item.bank_name}}</option>
                            </select>
                            <div *ngIf="submitted && doctorForm.get('branch_name').invalid" class="invalid-feedback">
                                <div *ngIf="doctorForm.get('branch_name').errors.required">
                                    Bank Name is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" style="display: none;" >
                    <div class="form-group">
                        <label class="control-label"> Branch Location <span class="text-danger">*</span> </label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="branch_location" id="branch_location"
                                class="form-control form-control-sm" [attr.disabled]="isEditable ? true : null"
                                [ngClass]="{ 'is-invalid': submitted && doctorForm.get('branch_location').invalid }"
                                (keyup)="keyUp('branch_location')" onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                    (event.charCode >= 97 && event.charCode <= 122) || (event.charCode == 32))' maxlength="50">
                            <div *ngIf="submitted && doctorForm.get('branch_location').invalid"
                                class="invalid-feedback">
                                <div *ngIf="doctorForm.get('branch_location').errors.required">
                                    Branch Location is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" style="display: none;" >
                    <div class="form-group">
                        <label class="control-label">{{isSAGE ? 'SWIFT' : 'IFSC'}} Code<span class="text-danger">*</span></label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="ifsc_code" id="ifsc_code"
                                class="form-control form-control-sm" (keyup)="keyUp('ifsc_code')"
                                [ngClass]="{ 'is-invalid': submitted && doctorForm.get('ifsc_code').invalid }"
                                onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                    (event.charCode >= 97 && event.charCode <= 122) || (event.charCode == 32)) ||
                                    (event.charCode >= 48 && event.charCode <= 57)'
                                [attr.disabled]="isEditable ? true : null" maxlength="11">
                            <div *ngIf="submitted && doctorForm.get('ifsc_code').invalid" class="invalid-feedback">
                                <div *ngIf="doctorForm.get('ifsc_code').errors.required">
                                    {{isSAGE ? 'SWIFT' : 'IFSC'}} Code is required</div>
                                <div *ngIf="doctorForm.get('ifsc_code').errors.pattern">
                                    Enter Valid {{isSAGE ? 'SWIFT' : 'IFSC'}} Code, ex: SBIN0001050</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- </div><div class="row"> -->
                <div class="col-xl-3" *ngIf="!isNIG">
                    <div class="form-group">
                        <label class="control-label">{{isSAGE ? 'National ID' : 'Aadhaar Number'}}</label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="aadhar_number" id="aadhar_number"
                                (keyup)="keyUp('aadhar_number')" class="form-control form-control-sm" maxlength="12"
                                onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'
                                [attr.disabled]="isEditable ? true : null"
                                [ngClass]="{ 'is-invalid': submitted && doctorForm.get('aadhar_number').invalid }">
                            <div *ngIf="submitted && doctorForm.get('aadhar_number').invalid" class="invalid-feedback">
                                <div *ngIf="doctorForm.get('aadhar_number').errors.pattern">
                                    Enter Valid {{isSAGE ? 'National ID' : 'Aadhaar Number'}} </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Home Address </label>
                        <div>
                            <!-- [attr.disabled]="(+TypeOfDoctor.value==2)?null:true" (change)="onAdrsChng('ha', $event.target.value)" -->
                            <textarea rows="2" class="form-control form-control-sm" [readonly]="isEditable"
                                id="home_address" maxlength="100" (keyup)="keyUp('home_address')" formControlName="home_address"
                                [attr.disabled]="(+TypeOfDoctor.value==2)?null:true"></textarea>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Clinic Address </label>
                        <div>
                             [attr.disabled]="(+TypeOfDoctor.value>=2)?null:true" (change)="onAdrsChng('ca', $event.target.value)" 
                            <textarea class="form-control form-control-sm" formControlName="clinic_address" rows="2"
                                maxlength="100" (keyup)="keyUp('clinic_address')" [readonly]="isEditable" id="clinic_address"
                                [attr.disabled]="(+TypeOfDoctor.value==1)?true:null"></textarea>
                        </div>
                    </div>
                </div> -->
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Hospital Address </label>
                        <div>
                            <!-- (change)="onAdrsChng('la', $event.target.value)" -->
                            <textarea rows="2" class="form-control form-control-sm" formControlName="hospital_address"
                                id="hospital_address" [readonly]="isEditable" [attr.disabled]="(+TypeOfDoctor.value==3)?true:null"
                                maxlength="100" ></textarea>
                        </div>
                    </div>
                </div>
                <!-- </div><div class="row"> -->
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Address for Prescription</label>
                        <div>
                            <select formControlName="prescription_address" id="prescription_address"
                                [attr.disabled]="(+TypeOfDoctor.value==2 && !isEditable)?null:true"
                                class="form-control form-control-sm">
                                <option value="">Select</option>
                                <option value="1">Home</option>
                                <option value="2">Clinic</option>
                                <option value="3">Hospital</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Pharmacy Preferred</label>
                        <div>
                            <select formControlName="pharmacy_id" id="pharmacy_id" class="form-control form-control-sm"
                                [attr.disabled]="(+TypeOfDoctor.value==2 && !isEditable)?null:true">
                                <option value="">Select</option>
                                <option *ngFor="let pharmacy of pharmacyList" [value]="pharmacy.id">
                                    {{pharmacy.pharmacy_name}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> Lab Preferred</label>
                        <div>
                            <select formControlName="lab_id" id="lab_id" class="form-control form-control-sm"
                                [attr.disabled]="(+TypeOfDoctor.value==2 && !isEditable)?null:true">
                                <option value="">Select</option>
                                <option *ngFor="let lab of labList" [value]="lab.id">{{lab.lab_name}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 pt-4">
                    <div class="form-check form-check-inline" style=" margin:12px 0px !important">
                        <input class="form-check-input" type="checkbox" id="inlineCheckbox22" #AssistantDetails formControlName="assistant_detail"
                        [attr.disabled]="isEditable ? true : null">
                        <label class="form-check-label" for="inlineCheckbox22"> Assistant Details</label>
                      </div>

                    <!-- <div class="form-group">
                        <label class="control-label"> Assistant Details</label>
                        <div>
                            <input type="checkbox" #AssistantDetails formControlName="assistant_detail"
                                [attr.disabled]="isEditable ? true : null">
                        </div>
                    </div> -->
                </div>
                <!-- <div class="col-xl-3"></div> -->
                <!-- </div><div class="row"> -->
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Assistant Name <span *ngIf="AssistantDetails.checked" class="text-danger">*</span></label>
                        <div>
                            <input type="text" [attr.disabled]="(AssistantDetails.checked)?null:true" autocomplete="off"
                                formControlName="assistant_first_name" id="assistant_first_name" [readonly]="isEditable"
                                [ngClass]="{'is-invalid': submitted && doctorForm.get('assistant_first_name').invalid }"
                                class="form-control form-control-sm text-uppercase" onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                (event.charCode >= 97 && event.charCode <= 122) || (event.charCode == 32))'
                                maxlength="50" (keyup)="keyUp('assistant_first_name')">
                            <div *ngIf="submitted && doctorForm.get('assistant_first_name').errors"
                                class="invalid-feedback">
                                <div *ngIf="doctorForm.get('assistant_first_name').errors.required">Assistant Name is
                                    required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Contact Number <span *ngIf="AssistantDetails.checked" class="text-danger">*</span></label>
                        <div>
                            <input type="text" [attr.disabled]="(AssistantDetails.checked)?null:true" autocomplete="off"
                                maxlength="12" formControlName="assistant_contact_number" id="assistant_contact_number"
                                [ngClass]="{'is-invalid': submitted && doctorForm.get('assistant_contact_number').invalid }"
                                class="form-control form-control-sm" [pattern]="mobPtrn" [maxlength]="maxLngth"
                                onkeypress='return (event.charCode >= 48 && event.charCode <= 57)' [readonly]="isEditable"
                                (keyup)="onKeyNmbr('assistant_contact_number', $event.target.value)"> <!-- (keyup)="keyUp('assistant_contact_number')"> -->
                            <div *ngIf="submitted && doctorForm.get('assistant_contact_number').errors" 
                                class="invalid-feedback">
                                <div *ngIf="doctorForm.get('assistant_contact_number').errors.required">Contact
                                    Number is required</div>                                
                                <div *ngIf="f.assistant_contact_number.errors.pattern">Contact Number {{mobErrMsg}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Email <span *ngIf="AssistantDetails.checked" class="text-danger">*</span></label>
                        <div>
                            <input type="text" [attr.disabled]="(AssistantDetails.checked)?null:true" autocomplete="off"
                                formControlName="assistant_email" id="assistant_email" maxlength="60"
                                [ngClass]="{'is-invalid': submitted && doctorForm.get('assistant_email').invalid }"
                                class="form-control form-control-sm" [readonly]="isEditable">
                            <div *ngIf="submitted && doctorForm.get('assistant_email').errors" class="invalid-feedback">
                                <div *ngIf="doctorForm.get('assistant_email').errors.required">Email is required
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <!-- <div class="form-group">
                        <label class="control-label"> Send Notification</label>
                        <div>
                            <input type="checkbox" [attr.disabled]="(AssistantDetails.checked)?null:true"
                                formControlName="notification_to_assistant" [readonly]="isEditable">
                        </div>
                    </div> -->
                    <div class="form-check form-check-inline">
                        <input type="checkbox" [attr.disabled]="(AssistantDetails.checked)?null:true"
                        formControlName="notification_to_assistant" [readonly]="isEditable"> 
                        &nbsp;&nbsp; <label class="form-check-label" for="inlineCheckbox22">Send Notification</label>
                      </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Doctor Module</label>
                        <div>                            
                            <div tabindex="0" class="">
                                <!-- <ng-multiselect-dropdown [settings]="dropdownSettings_module" [placeholder]="'Select'"
                                    [data]="doctorlist" [disabled]="isEditable" formControlName="doctor_modules"></ng-multiselect-dropdown> -->
                                <select formControlName="docTmplt" id="docTmplt" class="form-control form-control-sm"
                                    (change)="onChngDT($event.target.value)">
                                    <option value="">Select</option>
                                    <option *ngFor="let dt of doctorlist" [value]="dt.id">{{dt.module_name}}</option>
                                </select>
                                <!-- [ngClass]="{ 'is-invalid': submitted && f.doctor_modules.errors }" -->
                                <!-- <div *ngIf="submitted && f.doctor_modules.errors" class="invalid-feedback" >
                                    <div *ngIf="f.doctor_modules.errors.required">Languages is required</div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Maximum Consultation Time</label>
                        <div>
                            <!-- <input type="text" autocomplete="off" formControlName="max_consultation_time"
                                id="max_consultation_time" placeholder="Maximum Consultation Time"
                                class="form-control form-control-sm"> -->
                            <select formControlName="max_consultation_time" id="max_consultation_time" (focus)="dp1.hide()"
                                class="form-control form-control-sm" [attr.disabled]="isEditable ? true : null"
                                [ngClass]="{ 'is-invalid': submitted && f.max_consultation_time.errors }">
                                <option value="">Select</option>
                                <option value="10">10 Minutes</option>
                                <option value="15">15 Minutes</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-xl-2">
                    <div class="form-group">
                        <label class="control-label">Doctor Start Hours</label>
                        <div>                            
                            <div tabindex="0" class="">
                                <!-- <ng-multiselect-dropdown [settings]="dropdownSettings_module" [placeholder]="'Select'"
                                    [data]="doctorlist" [disabled]="isEditable" formControlName="doctor_modules"></ng-multiselect-dropdown> -->
                                <select formControlName="start_hours" id="start_hours" class="form-control form-control-sm">
                                    <option value="">Select</option>
                                    <option *ngFor="let hr of lstHrs" [value]="hr.name">{{hr.name}}</option>
                                </select>
                                <!-- [ngClass]="{ 'is-invalid': submitted && f.doctor_modules.errors }" -->
                                <!-- <div *ngIf="submitted && f.doctor_modules.errors" class="invalid-feedback" >
                                    <div *ngIf="f.doctor_modules.errors.required">Languages is required</div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-2">
                    <div class="form-group">
                        <label class="control-label">Doctor Start Minutes</label>
                        <div>                            
                            <div tabindex="0" class="">
                                <!-- <ng-multiselect-dropdown [settings]="dropdownSettings_module" [placeholder]="'Select'"
                                    [data]="doctorlist" [disabled]="isEditable" formControlName="doctor_modules"></ng-multiselect-dropdown> -->
                                <select formControlName="start_minutes" id="start_minutes" class="form-control form-control-sm">
                                    <option value="">Select</option>
                                    <option *ngFor="let mt of lstMin" [value]="mt.name">{{mt.name}}</option>
                                </select>
                                <!-- [ngClass]="{ 'is-invalid': submitted && f.doctor_modules.errors }" -->
                                <!-- <div *ngIf="submitted && f.doctor_modules.errors" class="invalid-feedback" >
                                    <div *ngIf="f.doctor_modules.errors.required">Languages is required</div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-2">
                    <div class="form-group">
                        <label class="control-label">Doctor End Hours</label>
                        <div>                            
                            <div tabindex="0" class="">
                                <!-- <ng-multiselect-dropdown [settings]="dropdownSettings_module" [placeholder]="'Select'"
                                    [data]="doctorlist" [disabled]="isEditable" formControlName="doctor_modules"></ng-multiselect-dropdown> -->
                                <select formControlName="end_hours" id="end_hours" class="form-control form-control-sm">
                                    <option value="">Select</option>
                                    <option *ngFor="let hr of lstHrs" [value]="hr.name">{{hr.name}}</option>
                                </select>
                                <!-- [ngClass]="{ 'is-invalid': submitted && f.doctor_modules.errors }" -->
                                <!-- <div *ngIf="submitted && f.doctor_modules.errors" class="invalid-feedback" >
                                    <div *ngIf="f.doctor_modules.errors.required">Languages is required</div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-2">
                    <div class="form-group">
                        <label class="control-label">Doctor End Minutes</label>
                        <div>                            
                            <div tabindex="0" class="">
                                <!-- <ng-multiselect-dropdown [settings]="dropdownSettings_module" [placeholder]="'Select'"
                                    [data]="doctorlist" [disabled]="isEditable" formControlName="doctor_modules"></ng-multiselect-dropdown> -->
                                <select formControlName="end_minutes" id="end_minutes" class="form-control form-control-sm">
                                    <option value="">Select</option>
                                    <option *ngFor="let mt of lstMin" [value]="mt.name">{{mt.name}}</option>
                                </select>
                                <!-- [ngClass]="{ 'is-invalid': submitted && f.doctor_modules.errors }" -->
                                <!-- <div *ngIf="submitted && f.doctor_modules.errors" class="invalid-feedback" >
                                    <div *ngIf="f.doctor_modules.errors.required">Languages is required</div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="col-xl-12">
                    <div class="table-responsive">
                        <table class="table table-bordered table-sm">
                            <tbody>
                                <tr [hidden]="isEditable">
                                    <td>
                                        <div>
                                            <input type="file" class="d-none" id="digitalCertificate" #digitalCertificate
                                                formControlName="digitalFile" (change)="singleFileUpload($event,1,'8','digitalFile')"
                                                [ngClass]="{'is-invalid': submitted && f.digitalFile.invalid }">
                                                <p class="btn badge badge-success badge-pill text-white"
                                                    (click)="digitalCertificate.click()">Digital Signature</p>
                                                <span class="text-danger">*</span> <p class="small mb-1">Png format only</p>
                                            <div *ngIf="submitted && f.digitalFile.errors" class="invalid-feedback">
                                                <div *ngIf="f.digitalFile.errors.required">Digital Signature is required</div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <input type="file" class="d-none" id="mciCertificate" #mciCertificate
                                            formControlName="qualificationFile" (change)="singleFileUpload($event,2,'9','qualificationFile')"
                                            [ngClass]="{'is-invalid': submitted && f.qualificationFile.invalid }">
                                        <!-- <a class="btn btn-sm btn-primary text-white"
                                            (click)="mciCertificate.click()">Highest Qualification Certificate <span
                                                class="text-danger">*</span></a> -->
                                        <p class="btn badge badge-success badge-pill text-white"
                                            (click)="mciCertificate.click()">Highest Qualification Certificate</p>
                                            <span class="text-danger">*</span>
                                        <p class="small mb-1">jpg or pdf format only</p>
                                        <div *ngIf="submitted && f.qualificationFile.errors" class="invalid-feedback">
                                            <div *ngIf="f.qualificationFile.errors.required">Qualification Certificate is
                                                required
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <input type="file" class="d-none" id="bioData" #bioData
                                            (change)="singleFileUpload($event,3,'4')">
                                        <!-- <a class="btn btn-sm btn-primary text-white" (click)="bioData.click()">Bio Data</a> -->
                                        <p class="btn badge badge-success badge-pill text-white" (click)="bioData.click()">
                                            Bio Data</p>
                                        <p class="small mb-1">word or pdf format only</p>
                                    </td>
                                    <td>
                                        <input type="file" class="d-none" id="photo" #photo
                                            formControlName="photo" (change)="singleFileUpload($event,4,'5','photo')"
                                            [ngClass]="{'is-invalid': submitted && f.photo.invalid }">
                                        <!-- <a class="btn btn-sm btn-primary text-white" (click)="photo.click()">Photo </a> -->
                                        <p class="btn badge badge-success badge-pill text-white"
                                            (click)="photo.click()">Photo</p>   <span class="text-danger">*</span>
                                        <p class="small mb-1">jpg,bmp format only</p>
                                        <div *ngIf="submitted && f.photo.errors" class="invalid-feedback">
                                            <div *ngIf="f.photo.errors.required">Photo is required
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <input type="file" class="d-none" id="audioRecord" #audioRecord
                                            (change)="singleFileUpload($event,5,'10')">
                                        <!-- <a class="btn btn-sm btn-primary text-white" (click)="audioRecord.click()">Video
                                            Record </a> -->
                                        <p class="btn badge badge-success badge-pill text-white"
                                            (click)="audioRecord.click()">Video Record </p>
                                        <p class="small mb-1">mp4 format only</p>
                                    </td>
                                    <td *ngIf="!isNIG">
                                        <input type="file" class="d-none" id="aadharFile" #aAdhar
                                            formControlName="aadharFile" (change)="singleFileUpload($event,6,'12')"
                                            [ngClass]="{'is-invalid': submitted && f.aadharFile.invalid }">
                                        <!-- <a class="btn btn-sm btn-primary text-white" (click)="aAdhar.click()">Aadhar<span
                                                class="text-danger">*</span></a> -->
                                        <p class="btn badge badge-success badge-pill text-white" (click)="aAdhar.click()">
                                            {{isSAGE ? 'National ID' : 'Aadhaar'}} </p>
                                        <p class="small mb-1">jpg or pdf format only</p>
                                        <div *ngIf="submitted && f.aadharFile.errors" class="invalid-feedback">
                                            <div *ngIf="f.aadharFile.errors.required">
                                                {{isSAGE ? 'National ID' : 'Aadhaar'}} is required</div>
                                        </div>
                                    </td>
                                    <td>
                                        <input type="file" class="d-none" id="panFile" #PanCard
                                            formControlName="panFile" (change)="singleFileUpload($event,7,'13')"
                                            [ngClass]="{'is-invalid': submitted && f.panFile.invalid }">
                                        <!-- <a class="btn btn-sm btn-primary text-white" (click)="PanCard.click()">Pan Card<span
                                                class="text-danger">*</span></a> -->
                                        <p class="btn badge badge-success badge-pill text-white" (click)="PanCard.click()">
                                            PAN Number </p>
                                        <p class="small mb-1">jpg or pdf format only</p>
                                        <div *ngIf="submitted && f.panFile.errors" class="invalid-feedback">
                                            <div *ngIf="f.panFile.errors.required">PAN Number image is required
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr [hidden]="!isEditable">
                                    <td>
                                        <p class="btn badge badge-success badge-pill text-white">Digital Signature</p> <span class="text-danger">*</span>
                                    </td>
                                    <td>
                                        <p class="btn badge badge-success badge-pill text-white">Highest Qualification Certificate</p> <span class="text-danger">*</span>
                                    </td>
                                    <td>
                                        <p class="btn badge badge-success badge-pill text-white">Bio Data</p>
                                    </td>
                                    <td>
                                        <p class="btn badge badge-success badge-pill text-white">Photo</p> <span class="text-danger">*</span>
                                    </td>
                                    <td>
                                        <p class="btn badge badge-success badge-pill text-white">Video Record </p>
                                    </td>
                                    <td *ngIf="!isNIG">
                                        <p class="btn badge badge-success badge-pill text-white">{{isSAGE ? 'National ID' : 'Aadhaar'}} </p>
                                    </td>
                                    <td>
                                        <p class="btn badge badge-success badge-pill text-white">PAN Number </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>
                                            <a class="badge badge-danger badge-pill pointer" title="Cancel File"
                                                *ngIf="uploadFileList[0].File || isShwDS" [hidden]="isEditable"
                                                (click)="cancelFile(1,'digitalFile',8)">
                                                <i class="fa fa-trash text-white"></i>
                                            </a>&nbsp;
                                        </span>
                                        <a href="javascript:void(0)"
                                            (click)="viewFile(1)">{{uploadFileList[0].file_name}}</a></td>
                                    <td>
                                        <span>
                                            <a class="badge badge-danger badge-pill pointer" title="Cancel File"
                                                *ngIf="uploadFileList[1].File || isShwHQ" [hidden]="isEditable"
                                                (click)="cancelFile(2,'qualificationFile',9)">
                                                <i class="fa fa-trash text-white"></i></a>&nbsp;
                                        </span>
                                        <a href="javascript:void(0)"
                                            (click)="viewFile(2)">{{uploadFileList[1].file_name}}</a></td>
                                    <td>
                                        <span>
                                            <a class="badge badge-danger badge-pill pointer" title="Cancel File"
                                                [attr.disabled]="isEditable ? true : null" [hidden]="isEditable"
                                                *ngIf="uploadFileList[2].File" (click)="cancelFile(3)">
                                                <i class="fa fa-trash text-white"></i></a>&nbsp;
                                        </span>
                                        <a href="javascript:void(0)"
                                            (click)="viewFile(3)">{{uploadFileList[2].file_name}}</a></td>
                                    <td>
                                        <span>
                                            <a class="badge badge-danger badge-pill pointer" (click)="cancelFile(4,'photo', 5)"
                                                *ngIf="uploadFileList[3].File || isShwPic" title="Cancel File" [hidden]="isEditable">
                                                <i class="fa fa-trash text-white"></i></a>&nbsp;
                                        </span>
                                        <a href="javascript:void(0)"
                                            (click)="viewFile(4)">{{uploadFileList[3].file_name}}</a></td>
                                    <td>
                                        <span>
                                            <a class="badge badge-danger badge-pill pointer" title="Cancel File"
                                                *ngIf="uploadFileList[4].File" (click)="cancelFile(5)" [hidden]="isEditable">
                                                <i class="fa fa-trash text-white"></i></a>&nbsp;
                                        </span>
                                        <a href="javascript:void(0)"
                                            (click)="viewFile(5)">{{uploadFileList[4].file_name}}</a></td>
                                    <td>
                                        <span>
                                            <a class="badge badge-danger badge-pill pointer" title="Cancel File"
                                                *ngIf="uploadFileList[5].File" (click)="cancelFile(6)" [hidden]="isEditable">
                                                <i class="fa fa-trash text-white"></i></a>&nbsp;
                                        </span>
                                        <a href="javascript:void(0)"
                                            (click)="viewFile(6)">{{uploadFileList[5].file_name}}</a></td>
                                    <td>
                                        <span>
                                            <a class="badge badge-danger badge-pill pointer" title="Cancel File"
                                                *ngIf="uploadFileList[6].File" (click)="cancelFile(7)" [hidden]="isEditable">
                                                <i class="fa fa-trash text-white"></i></a>&nbsp;
                                        </span>
                                        <a href="javascript:void(0)" (click)="viewFile(7)">{{uploadFileList[6].file_name}}</a></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <input type="submit" id="btnsubmit" style="display: none;">
        </form>
    </div>
</div>
<div class="modal-footer">
    <input type="submit" *ngIf="!isSubmit_Disabled" class="btn btn-sm btn-primary" (click)="submitForm()"
        value="Submit">&nbsp;&nbsp; <!-- [disabled]="isSubmit_Disabled?true:null" -->
    <button type="button" class="btn btn-sm btn-outline-dark"  id="doctype1" (click)="activeModal.close('Close click')">Close</button>
</div>