export class User {
    id: number;
    username: string;
    password?: string;
    firstName: string;
    lastName: string;
    photo?: string;
    status?: boolean;
    authToken?: string;
}
export class ChatMessageDto {
    
    org_id:string;  
    doctor_id:string;
    beneficiary_id:string;
    consulation_id:string;
    message:string;
    usertype:string;
    patient_name:string;
    property:string;

constructor(org_id: string, doctor_id: string,beneficiary_id:string,consulation_id:string,message:string,patient_name:string,property:string,){
    this.org_id = org_id;
    this.property=property;
    this.doctor_id = doctor_id;
    this.beneficiary_id=beneficiary_id;
    this.consulation_id=consulation_id;
    this.message=message;
    this.usertype = 'Doctor';
    this.patient_name=patient_name;
}
}