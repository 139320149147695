import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Common} from 'src/app/common';
import { LabService } from 'src/app/shared/axaservices/lab.service';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { ExcelDownloadService } from 'src/app/shared/services/excel-download.service';
import { ExcelService } from 'src/app/shared/services/excel.service';
import { MasterService } from 'src/app/shared/services/master.service';

import { RegistrationService } from 'src/app/shared/services/registration.service';
import { commonValidation } from '../../axa/commonvalidation';

@Component({
  selector: 'app-book-appointments',
  templateUrl: './book-appointments.component.html',
  styleUrls: ['./book-appointments.component.css']
})
export class BookAppointmentsComponent extends commonValidation implements OnInit {

  // bookappointment:FormGroup;
  // isSubmt  = false;
  // genderList: Array<any> = [];
  // hsplList:any=[]
  // vstList:any=[]
  // splzList:any=[]
  // userId: any;
  // crntuser: any;
  // features: any;
  // isEdit: boolean;
  // eId: any;
  // pstkDetails: any;
  // qualificationList: any=[];
  // qualificationId: any;
  // // hospitalId: any;
  // qualificationIds: any;
  // splzId: any;
  // doctorList: any;
  // paymentList: any=[];
  // hospitalId: any;
  
  constructor(
    config: NgbModalConfig
    , private modalService: NgbModal
    , private toastr: ToastrService
    , public activeModal: NgbActiveModal
    , private router: Router
    , private formBuilder: FormBuilder
    , private authSvc: AuthenticationService
    , private regService: RegistrationService
    , private datePipe: DatePipe,
    private excelDownloadService: ExcelDownloadService,
    private excelService: ExcelService
    , private lab:LabService
    , private masterService: MasterService
    , public changeDetection: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(){

    // this.crntuser = JSON.parse(sessionStorage.getItem('currentUser'));
    // this.userId = this.crntuser.user_role_mapping[0].id
    // this.LoadData();
  //     this.formInIt();
  //   this.features= JSON.parse(localStorage.getItem('featuresList'));
  //    this.gender();
  //    this.visitTypes();
  //    this.hospitalData();
  //    this.qualification();
  //    this.paymentTypes();
   }

  
//   get f() { return this.bookappointment.controls };
//   formInIt(){
//     this.bookappointment = this.formBuilder.group({
     
//       id: [0], 
//       age:[],
//       mobileNumber:[],
//       caseNumber:[],
//       area:[],
//       beneficiary_id:[315],
//       dependent_id:[],
//       hospital_id: [0,Validators.required],
//       visit_type_id: [],
//       visit_id: [0,Validators.required],
//       gender_id:[0,Validators.required],
//       service_type_id: [],
//       qualification_id: [0,Validators.required],
//       specialization_id: [0],
//       doctor_id: [0,Validators.required],
//       agent_id: [],
//       complaint: [],
//       transaction_type_id: [],
//       transaction_id: [],
//       name: [],
//       consultation_fee: [],
//       discount_amount: [],
//       total_amount: [],
//       cash_received: [],
//       status_id: [],
//       is_active: true,
//       created_by: sessionStorage.getItem("loginId"),
//       created_on: [new Date()],
//       modified_by: [],
//       modified_on: []
//     })
//    }

//   gender(){
//   this.masterService.getAll(`getallgenders`).subscribe(res => {
//         if (res?.status === "OK") {
//           if (res?.data == null || res?.data.length == 0) {
//             this.toastr.info('No records available');
//             this.genderList = [];
//           }
//           else
//             this.genderList = res.data;
//         }
//       },
//         error => { this.toastr.error(error.message); }
//       );
//   }
//   hospitalData(){
//     this.regService.getAll(`hospital/getallhospitals`).subscribe(res => {
//           if (res?.status === "OK") {
//             if (res?.data == null || res?.data.length == 0) {
//               this.toastr.info('No records available');
//               this.genderList = [];
//             }
//             else
//               this.hsplList = res.data;
//           }
//         },
//           error => { this.toastr.error(error.message); }
//         );
//     }

//     visitTypes(){
//         this.masterService.getAll(`master-visit-types/getall`).subscribe(res => {
//               if (res?.status === "OK") {
//                 if (res?.data == null || res?.data.length == 0) {
//                   this.toastr.info('No records available');
//                   this.vstList = [];
//                 }
//                 else
//                   this.vstList = res.data;
//               }
//             },
//               error => { this.toastr.error(error.message); }
//             );
//     }

//     qualification(){
//       this.masterService.getAll(`getallqualifications`).subscribe(res => {
//             if (res?.status === "OK") {
//               if (res?.data == null || res?.data.length == 0) {
//                 this.toastr.info('No records available');
//                 this.qualificationList = [];
//               }
//               else
//                 this.qualificationList = res.data;
//             }
//           },
//             error => { this.toastr.error(error.message); }
//           );
//   }

//   specialization(event:any){
//     this.qualificationId=event.target.value
//     this.masterService.getAll(`get-specialization-by-qualification/${this.qualificationId}`).subscribe(res => {
//           if (res?.status === "OK") {
//             if (res?.data == null || res?.data.length == 0) {
//               this.toastr.info('No records available');
//               this.splzList = [];
//             }
//             else
//               this.splzList = res.data;
//           }
//         },
//           error => { this.toastr.error(error.message); }
//         );
// }
// hospitalIds(event:any){
//   this.hospitalId=event.target.value
// }

// doctorData(event:any){
//   // this.hospitalId = this.bookappointment.getRawValue().hopsital_id
//   this.qualificationIds=this.bookappointment.getRawValue().qualification_id
//   this.splzId=this.bookappointment.getRawValue().specialization_id
//   this.regService.getTransaction(`doctor/getdoctorsbyhospitalidandqualificationorspecialization?hospitalId=${this.hospitalId}&qualificationId=${this.qualificationIds}&specializationId=${this.splzId}`).subscribe(res => {
//         if (res?.status === "OK") {
//           if (res?.data == null || res?.data.length == 0) {
//             this.toastr.info('No records available');
//             this.doctorList = [];
//           }
//           else
//             this.doctorList = res.data;
//         }
//       },
//         error => { this.toastr.error(error.message); }
//       );
//   }


//   onSave() {

//     if (this.bookappointment.invalid) {
//       this.isSubmt  = true;
//       this.toastr.info('Please Enter All Mandatory Fields');
//       return;
//     }
   
//     const payload = this.bookappointment.getRawValue();
//     if(this.isEdit==true){
//       payload.id =this.eId
//       }else{
//        payload.id=0
//       }
//       payload.hospital_id=this.hospitalId
//     this.regService.saveNoJwt(`clinic-billing/save-or-update`,payload).subscribe(res => {
//       if (res?.status == 'OK') {
//         this.pstkDetails = res.data;
//         this.toastr.success(res.message);
//         this.activeModal.close('Close click')
//         this.onBack();
      
//       }
//     },
//       err => { this.toastr.error(err.message); },
//       () => { })
//   }
//   paymentTypes(){
//     this.masterService.getAll(`master-transaction-types/getall`).subscribe(res => {
//           if (res?.status === "OK") {
//             if (res?.data == null || res?.data.length == 0) {
//               this.toastr.info('No records available');
//               this.paymentList = [];
//             }
//             else
//               this.paymentList = res.data;
//           }
//         },
//           error => { this.toastr.error(error.message); }
//         );
// }
 
//  onBack(){

//   }
}
