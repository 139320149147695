import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Validators, FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { ToastrService } from 'ngx-toastr';
declare let $: any;
@Component({
  selector: 'app-covid',
  templateUrl: './covid.component.html',
  styleUrls: ['./covid.component.css'],
  encapsulation: ViewEncapsulation.None,

})
export class CovidComponent implements OnInit {
  @Input() title: string;
  @Input() consultData: any;
  @Input() isPOC: boolean = false;
  nodalList: Array<any> = [];
  showSubmit: boolean = true;
  covidForm: FormGroup;
  constructor(public activeModal: NgbActiveModal,
    private regService: RegistrationService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService) {
    this.initFormControls();
  }

  ngOnInit(): void {
    this.loadData();
    this.changColor();
    let e: any = document.getElementsByTagName('ngb-modal-backdrop')[0];
    e.style.removeProperty('z-index');

    //this.covidForm.disable({emitEvent:false})
  }

  onChange(event: any, type: string) {
    this.covidForm.get(type).setValue(event.checked ? event.value : '');
  }

  initFormControls() {
    this.covidForm = this.formBuilder.group({
      nodal_id: [''],
      temperature: ['normal'],
      symptoms: this.formBuilder.group({
        dryCough: ['',],
        sneezing: [''],
        soreThroat: [''],
        severeCough: [''],
        weakness: [''],
        drowsiness: [''],
        feelingBreathless: [''],
        severeWeakness: [''],
        breathDifficulty: [''],
        pressureInChest: [''],
        lossofTaste: [''],
        lossofSmellLoss: [''],
      }),
      travelHistory: ['No Travel History'],
      co_morbidities: this.formBuilder.group({
        diabetes: [''],
        highbp: [''],
        heartDisease: [''],
        kidneyDisease: [''],
        lungDisease: [''],
        stroke: [''],
        reducedImmunity: ['']
      }),
      symptomsProgressed: ['No Change'],
      precautions: this.formBuilder.group({
        airborne: [''],
        contact: [''],
        droplet: [''],
        standard: ['']
      }),
      pointOfCare: this.formBuilder.group({
        fingerstickGlucose: [''],
      }),
      chemistry: this.formBuilder.group({
        basicMetabolicProfile: [''],
        comprehensiveMetabolic: [''],
        c_reactiveProtein: [''],
        d_dimer: [''],
        ferritin: [''],
        fibrinogen: [''],
        lacticAcid: [''],
        hepaticFunctionPanel: [''],
        lipidPanel: [''],
        procalcitonin: [''],
      }),
      hematology: this.formBuilder.group({
        cbc: [''],
        esr: [''],
        pt: [''],
        partial: [''],
      }),
      inﬂuenza_pcr: this.formBuilder.group({
        nose: [''],
        sputum: [''],
      }),
      sars_cov2: this.formBuilder.group({
        realTimePolymerase: [''],
        nasopharyngeal: [''],
        oropharyngeal: [''],
        sputum: [''],
        rsv: [''],
      }),
      urine: this.formBuilder.group({
        urinalysis: [''],
      }),
      plainFilms: this.formBuilder.group({
        xRay: [''],
        pa: [''],
        lateral: [''],
      }),
      ctScan: this.formBuilder.group({
        withoutIvContrast: [''],
        withIvContrast: [''],
      }),
      instructions: this.formBuilder.group({
        smokingCessation: [''],
        routineScreening: [''],
        contactLocal: [''],
      }),
      patienteducation: this.formBuilder.group({
        infection: [''],
        bodyTemperature: [''],
        responsibilities: [''],
      }),
    });
  }

  changColor() {
    $('[data-toggle="buttons"] .btn').on('click', function () {
      // toggle style
      $(this).toggleClass('btn-teal btn-red active');

      // toggle checkbox
      var $chk = $(this).find('[type=checkbox]');
      $chk.prop('checked', !$chk.prop('checked'));

      return false;
    });

    $('form').on('submit', function (e) {
      // watch form values
      $('#formValues').html(($('form').serialize()));
      e.preventDefault();
    });
  }

  onSubmit(event) {
    let val = this.covidForm.value;

    // let [chem, hema, micro, real, urine, radiology, ctscan] = [false, false, false, false, false, false, false,];
    // chem = Object.keys(val.chemistry).some(k => val.chemistry[k]);
    // hema = Object.keys(val.hematology).some(k => val.hematology[k]);
    // micro = Object.keys(val.inﬂuenza_pcr).some(k => val.inﬂuenza_pcr[k]);
    // real = Object.keys(val.sars_cov2).some(k => val.sars_cov2[k]);
    // urine = Object.keys(val.urine).some(k => val.urine[k]);
    // radiology = Object.keys(val.plainFilms).some(k => val.plainFilms[k]);
    // ctscan = Object.keys(val.ctScan).some(k => val.ctScan[k]);

    // if (chem || hema || micro || real || urine || radiology || ctscan) {
    //   if (val.nodal_id == '') {
    //     this.toastr.warning('Please Select Nodal Officer');
    //     return;
    //   }
    //   else
    //     document.getElementById('btnCovidModal').click();
    // }
    // else
    if(val) {
      this.toastr.success('Covid 19 Submitted Successfully');
    }
    this.activeModal.close(this.covidForm.getRawValue());
  }

  async loadData() {
    this.regService.getAll('nodalofficer/getall').subscribe(res => {
      this.nodalList = res.data;
      if (this.isPOC == false && this.consultData && Object.keys(this.consultData).length > 0)
        this.showSubmit = false, this.covidForm.patchValue(this.consultData);
      if (this.isPOC && Object.keys(this.consultData).length > 0 && this.consultData[0] != null)
        this.showSubmit = true, this.covidForm.patchValue(this.consultData[0]);
    });
  }

  closeModal() {
    this.activeModal.close(this.covidForm.getRawValue());
  }

  sendNotification() {
    this.activeModal.close(this.covidForm.getRawValue());
  }

}
