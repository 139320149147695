<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body p-1">

    <div class="tab_cls">
        <nav>
            <div class="nav nav-tabs" id="nav-tab" role="tablist">
                <button class="nav-link active" id="Personal-Information-tab" data-toggle="tab"
                    data-target="#Personal-Information" type="button" role="tab" aria-controls="Personal-Information"
                    aria-selected="true">Personal Information</button>
                <button class="nav-link" id="Physical-Health-tab" data-toggle="tab" data-target="#Physical-Health"
                    type="button" role="tab" aria-controls="Physical-Health" aria-selected="false">Physical Health &
                    Activity</button>
                <button class="nav-link" id="Mental-Health-tab" data-toggle="tab" data-target="#Mental-Health"
                    type="button" role="tab" aria-controls="Mental-Health" aria-selected="false">Mental
                    Health</button>
                <!-- <button class="nav-link" id="symptoms-tab" data-toggle="tab" data-target="#symptoms"
                            type="button" role="tab" aria-controls="symptoms" aria-selected="false">Symptoms</button> -->
                <button class="nav-link" id="sypt-tab" data-toggle="tab" data-target="#sypt" type="button" role="tab"
                    aria-controls="sypt" aria-selected="false">symptoms</button>
                <button class="nav-link" id="diagnosis-tab" data-toggle="tab" data-target="#diagnosis" type="button"
                    role="tab" aria-controls="diagnosis" aria-selected="false">Diagnosis</button>
                <button class="nav-link" id="family-history-tab" data-toggle="tab" data-target="#family-history"
                    type="button" role="tab" aria-controls="family-history" aria-selected="false">Family
                    History</button>
                <button class="nav-link" id="ayush-tab" data-toggle="tab" data-target="#ayush" type="button" role="tab"
                    aria-controls="ayush" aria-selected="false">Ayush</button>
                <button class="nav-link" id="ayush-tab" data-toggle="tab" data-target="#summary" type="button"
                    role="tab" aria-controls="summary" aria-selected="false">Summary</button>

                <!-- <button class="nav-link" id="nav-contact-tab" data-toggle="tab" data-target="#Mental-Health" type="button" role="tab" aria-controls="Mental-Health" aria-selected="false">Mental Health</button>
          <button class="nav-link" id="nav-contact-tab" data-toggle="tab" data-target="#Mental-Health" type="button" role="tab" aria-controls="Mental-Health" aria-selected="false">Mental Health</button>
          <button class="nav-link" id="nav-contact-tab" data-toggle="tab" data-target="#Mental-Health" type="button" role="tab" aria-controls="Mental-Health" aria-selected="false">Mental Health</button>
          <button class="nav-link" id="nav-contact-tab" data-toggle="tab" data-target="#Mental-Health" type="button" role="tab" aria-controls="Mental-Health" aria-selected="false">Mental Health</button> -->
            </div>
        </nav>
        <div class="tab-content" id="nav-tabContent">
            <div class="tab-pane fade show active" id="Personal-Information" role="tabpanel"
                aria-labelledby="Personal-Information-tab">
                <div class="container-fluid">

                    <h6 class="heading mb-4">1.Personal Information</h6>
                    <!-- static value start -->
                    <div>
                        <h2>BMI Calculator</h2>
                        <div class="mb-2 mt-2">
                            <label for="height">Height (cm): </label>
                            <input type="text" id="height" [(ngModel)]="height" (input)="calculateBMI()">
                        </div>
                        <div class="mb-2 mt-2">
                            <label for="weight">Weight (kg): </label>
                            <input type="text" id="weight" [(ngModel)]="weight" (input)="calculateBMI()">
                        </div>

                        
                        <div *ngIf="bmi">
                            <p>Your BMI is: {{ bmi | number: '1.1-1' }}</p>
                            <p>your BMI value is: {{ BmiResult }}</p>
                        </div>
                    </div>

                    <!-- static value end -->


                    <div *ngFor="let item of questionList">
                        <div class="row">
                            <div class="col-xl-3 mb-2 mt-2"><label>{{item.questionLang[0]?.langStr}}</label></div>


                            <div class="col-xl-4 mb-2 mt-2"
                                *ngIf="item.questionLang[0]?.id==1 || item.questionLang[0]?.id==2">
                                <input type="text">
                            </div>
                            <div class="col-xl-4 mb-2 mt-2" *ngIf="item.questionLang[0]?.id==3">
                                <input type="button" value="calculate" (click)="calculation()">
                            </div>
                            <div class="col-xl-4 mb-2 mt-2" *ngIf="item.questionLang[0]?.id==4 || item.questionLang[0]?.id==5 ||item.questionLang[0]?.id==6||item.questionLang[0]?.id==7
                            ||item.questionLang[0]?.id==8||item.questionLang[0]?.id==9">
                                <select>
                                    <option selected>Select</option>
                                    <option *ngFor="let a of item?.answers">{{a.answerLang[0]?.langStr}}</option>
                                </select>
                            </div>
                            <div class="col-xl-4 mb-2 mt-2"
                                *ngIf="item.questionLang[0]?.id==10||item.questionLang[0]?.id==11">
                                <input type="radio" value="yes" selected> Yes
                                <input type="radio" value="no"> no
                            </div>
                        </div>
                        <!-- {{item?.answerLang[0]?.langStr}}   -->
                    </div>
                </div>
            </div>





            <div class="tab-pane fade" id="Physical-Health" role="tabpanel" aria-labelledby="Physical-Health-tab">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-xl-12 pb-3 pt-3">
                            <div class="container-fluid tab1">
                                <div class="row">
                                    <div class="col-xl-12">
                                        <h6 class="heading">2.Physical Health & Activity</h6>
                                    </div>
                                    <div class="col-xl-3">
                                        <label class="form-label">weight</label>
                                        <div class="input-group mb-3">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="basic-addon1"><i
                                                        class="fas fa-weight-scale"></i></span>
                                            </div>
                                            <input type="text" class="form-control" placeholder="78 KGs"
                                                aria-label="Username" aria-describedby="basic-addon1" disabled>
                                        </div>
                                    </div>
                                    <div class="col-xl-3">
                                        <label class="form-label">Height</label>
                                        <div class="input-group mb-3">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="basic-addon1"><i
                                                        class="fas fa-text-height"></i></span>
                                            </div>
                                            <input type="text" class="form-control" placeholder="78 KGs"
                                                aria-label="Username" aria-describedby="basic-addon1" disabled>
                                        </div>
                                    </div>
                                    <div class="col-xl-3" style="margin-top:2rem !important;">
                                        <button type="button" class="btn btn-md btn-primary">Calculate
                                            BMI</button>
                                    </div>
                                    <div class="col-xl-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1"> How do you describe your energy
                                                level through out the day? </label>
                                            <input type="text" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp">
                                        </div>
                                    </div>
                                    <div class="col-xl-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail12"> Describe your diet (e.g.,
                                                vegetarian, non-vegetarian, and vegan). Are there any specific
                                                dietary restrictions or preferences you follow?
                                            </label>
                                            <input type="text" class="form-control" id="exampleInputEmail12"
                                                aria-describedby="emailHelp1">
                                        </div>



                                    </div>

                                    <div class="col-xl-12">


                                        <div class="form-group">
                                            <label for="exampleInputEmail13"> How Physically active are you?
                                            </label>
                                            <div>

                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="radio"
                                                        name="inlineRadioOptions" id="inlineRadio1" value="option1">
                                                    <label class="form-check-label" for="inlineRadio1">Sedentary</label>
                                                </div>
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="radio"
                                                        name="inlineRadioOptions" id="inlineRadio2" value="option2">
                                                    <label class="form-check-label" for="inlineRadio2">Moderately
                                                        Active</label>
                                                </div>
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="radio"
                                                        name="inlineRadioOptions" id="inlineRadio3" value="option3">
                                                    <label class="form-check-label" for="inlineRadio2">Active</label>
                                                </div>
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="radio"
                                                        name="inlineRadioOptions" id="inlineRadio4" value="option4">
                                                    <label class="form-check-label" for="inlineRadio2">Intense</label>
                                                </div>
                                            </div>



                                        </div>


                                    </div>

                                    <div class="col-xl-12">


                                        <div class="form-group">
                                            <label for="exampleInputEmail13"> How Physically active are you?
                                            </label>
                                            <div>


                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <div class="card card1 h-100" id="card1">
                                                            <div class="card-body">
                                                                <h6 class="card-title">Sedentary</h6>
                                                                <p class="card-text">Desk job/limited movement
                                                                    through out day</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="card card1 h-100" id="card2">
                                                            <div class="card-body">
                                                                <h6 class="card-title">Moderately Active</h6>
                                                                <p class="card-text">
                                                                    (Walking/stretching/home-exercise)</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="card card1 h-100" id="card3">
                                                            <div class="card-body">
                                                                <h6 class="card-title">Active</h6>
                                                                <p class="card-text">
                                                                    (Gym/swimming/yoga/sports/jogging)</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="card card1 h-100" id="card3">
                                                            <div class="card-body">
                                                                <h6 class="card-title">Intense</h6>
                                                                <p class="card-text">
                                                                    (Running/marathon/professional sports)</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>



                                        </div>


                                    </div>




                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
            <div class="tab-pane fade" id="Mental-Health" role="tabpanel" aria-labelledby="Mental-Health-tab">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-xl-12 pb-3 pt-3">








                            <div class="col-xl-12">
                                <h6 class="heading">3.Mental health</h6>

                                <div class="rating">
                                    <div class="circle filled-circle"></div>


                                    <div class="line num"></div>

                                    <div class="circle"></div>
                                    <div class="line"></div>
                                    <div class="circle"></div>
                                    <div class="line"></div>
                                    <div class="circle"></div>
                                    <div class="line"></div>
                                    <div class="circle"></div>
                                    <div class="line"></div>
                                    <div class="circle"></div>
                                    <div class="line"></div>
                                    <div class="circle"></div>
                                    <div class="line"></div>
                                    <div class="circle"></div>
                                    <div class="line"></div>
                                    <div class="circle"></div>
                                </div>



                                <div class="form-group mt-2">
                                    <label for="exampleInputEmail1"> Describe your diet (e.g., vegetarian,
                                        non-vegetarian, and vegan). Are there any specific dietary restrictions
                                        or preferences you follow?
                                        Enter text here... </label>
                                    <input type="text" class="form-control" id="exampleInputEmail1"
                                        aria-describedby="emailHelp">

                                </div>



                            </div>

                        </div>
                    </div>
                </div>

            </div>

            <!-- <div class="tab-pane fade" id="symptoms" role="tabpanel" aria-labelledby="symptoms-tab">

                        <div class="container-fluid">
                            <div class="row">

                                <div class=" col-xl-12 pb-3 pt-3">
                                    <h6 class="heading">4 Which of these symptoms you usually face?</h6>
                                </div>

                                <div class="col-xl-12 mt-2 mb-2">


                                    <div class="accordion" id="accordionExample">
                                        <div class="card">
                                            <div class="card-header p-0" id="headingOne">
                                                <h2 class="mb-0">
                                                    <button class="btn btn-link btn-block text-left" type="button"
                                                        data-toggle="collapse" data-target="#collapseOne"
                                                        aria-expanded="true" aria-controls="collapseOne">
                                                        <i class="fas fa-user-doctor"></i> Common Issues (02)
                                                        <i class="fas fa-angle-up float-right rotate-icon"></i>

                                                    </button>
                                                </h2>
                                            </div>

                                            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                                                data-parent="#accordionExample">
                                                <div class="card-body px-0 py-2 card_check"
                                                    style="background-color: #EBF5F7;">


                                                    <div class="container-fluid  ">
                                                        <div class="row">
                                                            <div class="col-xl-12 align-middle py-2">
                                                                <div class="float-left">
                                                                    <p class="mb-0">weight loss</p>

                                                                </div>
                                                                <div class="float-right">
                                                                    <input class="form-check-input" type="radio"
                                                                        name="flexRadioDefault" id="flexRadioDefault1">
                                                                </div>

                                                            </div>
                                                            <div class="col-xl-12 align-middle bg-white py-2"
                                                                style="border: 2px solid #1CADC7; ">
                                                                <div class="float-left">
                                                                    <p class="mb-0">weight Gain</p>

                                                                </div>
                                                                <div class="float-right">
                                                                    <input class="form-check-input" type="radio"
                                                                        name="flexRadioDefault" id="flexRadioDefault1"
                                                                        checked>
                                                                </div>

                                                            </div>
                                                            <div class="col-xl-12 align-middle py-2">
                                                                <div class="float-left">
                                                                    <p class="mb-0">Swelling in legs</p>

                                                                </div>
                                                                <div class="float-right">
                                                                    <input class="form-check-input" type="radio"
                                                                        name="flexRadioDefault" id="flexRadioDefault1">
                                                                </div>

                                                            </div>
                                                            <div class="col-xl-12 align-middle py-2">
                                                                <div class="float-left">
                                                                    <p class="mb-0">Swelling in foot</p>

                                                                </div>
                                                                <div class="float-right">
                                                                    <input class="form-check-input" type="radio"
                                                                        name="flexRadioDefault" id="flexRadioDefault1">
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>




                                                </div>
                                            </div>
                                        </div>
                                        <div class="card">
                                            <div class="card-header p-0" id="headingTwo">
                                                <h2 class="mb-0">
                                                    <button class="btn btn-link btn-block text-left collapsed"
                                                        type="button" data-toggle="collapse" data-target="#collapseTwo"
                                                        aria-expanded="false" aria-controls="collapseTwo">
                                                        <i class="fas fa-brain"></i> Head, Brain & Nerves (02) <i
                                                            class="fas fa-angle-up float-right rotate-icon"></i>
                                                    </button>

                                                </h2>
                                            </div>
                                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo"
                                                data-parent="#accordionExample">
                                                <div class="card-body">
                                                    Some placeholder content for the second accordion panel. This panel
                                                    is hidden by default.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card">
                                            <div class="card-header p-0" id="headingThree">
                                                <h2 class="mb-0">
                                                    <button class="btn btn-link btn-block text-left collapsed"
                                                        type="button" data-toggle="collapse"
                                                        data-target="#collapseThree" aria-expanded="false"
                                                        aria-controls="collapseThree">
                                                        <i class="fas fa-brain"></i> Skin (03) <i
                                                            class="fas fa-angle-up float-right rotate-icon"></i>
                                                    </button>

                                                </h2>
                                            </div>
                                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree"
                                                data-parent="#accordionExample">
                                                <div class="card-body">
                                                    And lastly, the placeholder content for the third and final
                                                    accordion panel. This panel is hidden by default.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card">
                                            <div class="card-header p-0" id="heading4">
                                                <h2 class="mb-0">
                                                    <button class="btn btn-link btn-block text-left collapsed"
                                                        type="button" data-toggle="collapse" data-target="#collapse4"
                                                        aria-expanded="false" aria-controls="collapse4">
                                                        <i class="fas  fa-eye"></i> Eyes <i
                                                            class="fas fa-angle-up float-right rotate-icon"></i>
                                                    </button>

                                                </h2>
                                            </div>
                                            <div id="collapse4" class="collapse" aria-labelledby="heading4"
                                                data-parent="#accordionExample">
                                                <div class="card-body">
                                                    And lastly, the placeholder content for the third and final
                                                    accordion panel. This panel is hidden by default.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card">
                                            <div class="card-header p-0" id="heading5">
                                                <h2 class="mb-0">
                                                    <button class="btn btn-link btn-block text-left collapsed"
                                                        type="button" data-toggle="collapse" data-target="#collapse5"
                                                        aria-expanded="false" aria-controls="collapse5">
                                                        <i class="fas  fa-ear-listen"></i> ears <i
                                                            class="fas fa-angle-up float-right rotate-icon"></i>
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id="collapse5" class="collapse" aria-labelledby="heading5"
                                                data-parent="#accordionExample">
                                                <div class="card-body">
                                                    And lastly, the placeholder content for the third and final
                                                    accordion panel. This panel is hidden by default.
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>


                    </div> -->
            <div class="tab-pane fade" id="sypt" role="tabpanel" aria-labelledby="sypt-tab">
                <div class="container-fluid">
                    <div class="row">

                        <div class=" col-xl-12 pb-3 pt-3">
                            <h6 class="heading">4 Which of these symptoms you usually face?</h6>
                        </div>

                        <div class="col-xl-12 mt-2 mb-2">


                            <div class="accordion" id="accordionExample">
                                <div class="card">
                                    <div class="card-header p-0" id="headingOne">
                                        <h2 class="mb-0">
                                            <button class="btn btn-link btn-block text-left" type="button"
                                                data-toggle="collapse" data-target="#collapseOne" aria-expanded="true"
                                                aria-controls="collapseOne">
                                                <i class="fas fa-user-doctor"></i> Common Issues (02)
                                                <i class="fas fa-angle-up float-right rotate-icon"></i>

                                            </button>
                                        </h2>
                                    </div>

                                    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                                        data-parent="#accordionExample">
                                        <div class="card-body px-0 py-2 card_check" style="background-color: #EBF5F7;">


                                            <div class="container-fluid  ">
                                                <div class="row">
                                                    <div class="col-xl-12 align-middle py-2">
                                                        <div class="float-left">
                                                            <p class="mb-0">weight loss</p>

                                                        </div>
                                                        <div class="float-right">
                                                            <input class="form-check-input" type="radio"
                                                                name="flexRadioDefault" id="flexRadioDefault1">
                                                        </div>

                                                    </div>
                                                    <div class="col-xl-12 align-middle bg-white py-2"
                                                        style="border: 2px solid #1CADC7; ">
                                                        <div class="float-left">
                                                            <p class="mb-0">weight Gain</p>

                                                        </div>
                                                        <div class="float-right">
                                                            <input class="form-check-input" type="radio"
                                                                name="flexRadioDefault" id="flexRadioDefault1" checked>
                                                        </div>

                                                    </div>
                                                    <div class="col-xl-12 align-middle py-2">
                                                        <div class="float-left">
                                                            <p class="mb-0">Swelling in legs</p>

                                                        </div>
                                                        <div class="float-right">
                                                            <input class="form-check-input" type="radio"
                                                                name="flexRadioDefault" id="flexRadioDefault1">
                                                        </div>

                                                    </div>
                                                    <div class="col-xl-12 align-middle py-2">
                                                        <div class="float-left">
                                                            <p class="mb-0">Swelling in foot</p>

                                                        </div>
                                                        <div class="float-right">
                                                            <input class="form-check-input" type="radio"
                                                                name="flexRadioDefault" id="flexRadioDefault1">
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>




                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header p-0" id="headingTwo">
                                        <h2 class="mb-0">
                                            <button class="btn btn-link btn-block text-left collapsed" type="button"
                                                data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false"
                                                aria-controls="collapseTwo">
                                                <i class="fas fa-brain"></i> Head, Brain & Nerves (02) <i
                                                    class="fas fa-angle-up float-right rotate-icon"></i>
                                            </button>

                                        </h2>
                                    </div>
                                    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo"
                                        data-parent="#accordionExample">
                                        <div class="card-body">
                                            Some placeholder content for the second accordion panel. This panel
                                            is hidden by default.
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header p-0" id="headingThree">
                                        <h2 class="mb-0">
                                            <button class="btn btn-link btn-block text-left collapsed" type="button"
                                                data-toggle="collapse" data-target="#collapseThree"
                                                aria-expanded="false" aria-controls="collapseThree">
                                                <i class="fas fa-brain"></i> Skin (03) <i
                                                    class="fas fa-angle-up float-right rotate-icon"></i>
                                            </button>

                                        </h2>
                                    </div>
                                    <div id="collapseThree" class="collapse" aria-labelledby="headingThree"
                                        data-parent="#accordionExample">
                                        <div class="card-body">
                                            And lastly, the placeholder content for the third and final
                                            accordion panel. This panel is hidden by default.
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header p-0" id="heading4">
                                        <h2 class="mb-0">
                                            <button class="btn btn-link btn-block text-left collapsed" type="button"
                                                data-toggle="collapse" data-target="#collapse4" aria-expanded="false"
                                                aria-controls="collapse4">
                                                <i class="fas  fa-eye"></i> Eyes <i
                                                    class="fas fa-angle-up float-right rotate-icon"></i>
                                            </button>

                                        </h2>
                                    </div>
                                    <div id="collapse4" class="collapse" aria-labelledby="heading4"
                                        data-parent="#accordionExample">
                                        <div class="card-body">
                                            And lastly, the placeholder content for the third and final
                                            accordion panel. This panel is hidden by default.
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header p-0" id="heading5">
                                        <h2 class="mb-0">
                                            <button class="btn btn-link btn-block text-left collapsed" type="button"
                                                data-toggle="collapse" data-target="#collapse5" aria-expanded="false"
                                                aria-controls="collapse5">
                                                <i class="fas  fa-ear-listen"></i> ears <i
                                                    class="fas fa-angle-up float-right rotate-icon"></i>
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapse5" class="collapse" aria-labelledby="heading5"
                                        data-parent="#accordionExample">
                                        <div class="card-body">
                                            And lastly, the placeholder content for the third and final
                                            accordion panel. This panel is hidden by default.
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="diagnosis" role="tabpanel" aria-labelledby="diagnosis-tab">..5.</div>
            <div class="tab-pane fade" id="family-history" role="tabpanel" aria-labelledby="family-history-tab">
                ..6.</div>
            <div class="tab-pane fade" id="ayush" role="tabpanel" aria-labelledby="ayush-tab">..7.</div>
            <div class="tab-pane fade" id="summary" role="tabpanel" aria-labelledby="summary-tab">..8.</div>



        </div>
        <!-- Next and Previous buttons -->
        <div class="p-2">
            <button class="btn btn-sm btn-primary me-2" id="prevTab">Previous</button> &nbsp;
            <button class="btn  btn-sm btn-primary" id="nextTab">Next</button>
        </div>
    </div>

</div>
<div class="modal-footer">
    <button type="button" class="btn btn-danger btn-sm" (click)="activeModal.close('Close click')">Close</button>
</div>