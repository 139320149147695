import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Common } from 'src/app/common';
import { forkJoin, Subject } from 'rxjs';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { RegistrationService } from 'src/app/shared/services/registration.service'
import { environment } from 'src/environments/environment';
import { MasterService } from 'src/app/shared/services/master.service';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { Router } from '@angular/router';
import { UserService } from 'src/app/shared/services/user.service';
import { shareReplay } from 'rxjs/operators';
import { ActiveDoctorsListComponent } from '../active-doctors-list/active-doctors-list.component';
import { HttpErrorResponse } from '@angular/common/http';
import { LabService } from 'src/app/shared/axaservices/lab.service';
declare let $: any;
declare let JqueryReady: any;
declare const answerCall: any;
declare let joinSession: any;
@Component({
  selector: 'app-family-member-registration',
  templateUrl: './family-member-registration.component.html',
  styleUrls: ['./family-member-registration.component.css']
})
export class FamilyMemberRegistrationComponent implements OnInit {
  opBillingForm: any = null;
  familyForm:FormGroup;
  familyLists:Array<any> = [];
  dtOptions: any = {};
  dtRender:boolean;
  dtTrigger: any = new Subject();
  defaultValue: any;
  submitted:boolean = false;
  genderList:Array<any> = [];
  relationList:Array<any> = [];
  doctorDetails: any;
  mobPtrn = ''; mobErrMsg = ''; maxLngth = '';idCard='';menuItems = { isVle: false};
  orgname = '';showCard = false;roleId:any;
  itemData: any;
  caseNo: any;
  isNDHMEnable:  boolean=false;
  isEnableEHR:  boolean=false;
  familyMemberButtonHide: boolean=false;
  hideLinkWithAbha: boolean=false;
  isenableDoctorToPatientCall: boolean=false;
  IsFacilityEnable:  boolean=false;
  isHealthWellness:  boolean=false;
  constructor(public activeModal: NgbActiveModal
    , private modalService: NgbModal
    , private toastr: ToastrService
    , private masterService: MasterService
    , private authServ: AuthenticationService
    , private registrationService: RegistrationService
    , private formBuilder: FormBuilder
    , private router: Router
    , public changeDetection: ChangeDetectorRef
    , private userServices:UserService
    , private lab: LabService
    , private datePipe: DatePipe,
    ) {
     }

  ngOnInit(): void {
    this.roleId = this.authServ.roleId;
    if (this.roleId == null) {
      this.roleId = JSON.parse(sessionStorage.getItem('currentUser')).user_role_mapping[0].user_type_id.id;
    }
    this.getFeaturePermission();
    this.authServ.mobPtrnSubject.subscribe(s => this.mobPtrn = s), this.authServ.mobErrMsgSubject.subscribe(s => this.mobErrMsg = s),
    this.authServ.maxLngthSubject.subscribe(s => this.maxLngth = s);
    this.authServ.isShowGlblOrg.next(false), this.authServ.tenantId = sessionStorage.getItem('defCrntTnnt') || sessionStorage.getItem('TENANT_ID'),
    sessionStorage.setItem('currentTenant', sessionStorage.getItem('defCrntTnnt') || sessionStorage.getItem('TENANT_ID'));  this.doctorDetails = JSON.parse(sessionStorage.getItem('DoctorDetails'));
    this.authServ.crntOrgIDSubject.subscribe(org => this.getUserPermission(org));
    this.LoadFamily(),this.initFormControls();
    this.orgname=sessionStorage.getItem('orgName');
    if( this.orgname == 'GAM VMED' ||this.orgname == 'Jan Sevak Kendra' || this.orgname == 'TANMAN SEWA SANSTHAN' || this.orgname == 'GY Foundation AM'){
      this.showCard = true;
          } else{
            this.showCard = false
          }
          
  }
  getFeaturePermission() {
    var featureList: any = JSON.parse(localStorage.getItem('featuresList'));
    for (let i = 0; i < featureList.length; i++) {
      if (featureList[i].feature_id.feature_name == "isNDHMEnable") {
        this.isNDHMEnable = featureList[i].patient;
      }
      if (featureList[i].feature_id.feature_name == "isEnableEHR") {
        this.isEnableEHR = featureList[i].patient;

      }
      if (featureList[i].feature_id.feature_name == "familyMemberButtonHide") {
        if (featureList[i].patient && (this.roleId == 7 || this.roleId == 2)) {
          this.familyMemberButtonHide = true;
        } else {
          this.familyMemberButtonHide = false;
        }
      }
      if (featureList[i].feature_id.feature_name == "hideLinkWithAbha") {
        if (featureList[i].patient && (this.roleId == 7 || this.roleId == 2)) {
          this.hideLinkWithAbha = true;
        } else {
          this.hideLinkWithAbha = false;
        }
      }
      if (featureList[i].feature_id.feature_name == "isenableDoctorToPatientCall") {

        this.isenableDoctorToPatientCall = featureList[i].patient;

      }
      if (featureList[i].feature_id.feature_name == "IsFacilityEnable") {
          
        this.IsFacilityEnable = featureList[i].patient;
     
    }
    if (featureList[i].feature_id.feature_name == "Health&Wellness") {
          
      this.isHealthWellness = featureList[i].patient;
   
  }
      //isEnableEHR

    }

  }
  initFormControls() {
    this.familyForm = this.formBuilder.group({
      first_name: ['', Validators.required],
      last_name: [''],
      age: ['', Validators.required],
      mobile: ['',[Validators.pattern(this.mobPtrn)]],
      email: ['', [Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      gender_id: ['', Validators.required],
      relation_id: ['',Validators.required],
      agent_id:[sessionStorage.agentId]
    });
  }
  LoadFamily(){
    this.dtRender=false
    let benId = this.registrationService.familyDetails.id
    let benName = this.registrationService.familyDetails.first_name+''+this.registrationService.familyDetails.last_name
    this.registrationService.getBeneficiaryFamilyList(benId).subscribe((res:any) => {
     
        if (res.status == 'OK') {
          this.familyLists = [];
          this.changeDetection.detectChanges();
           this.familyLists = res.data
           this.changeDetection.detectChanges();
           this.dtRender=true;
           this.genderList = this.registrationService.genderList;
           this.relationList = this.registrationService.relationsList;
          // Common.loadDataTable(this.familyLists,'#FamilyTable'); 
          // this.dtTrigger.next();
          // setTimeout(() => $(`#FamilyTable`).DataTable({ destroy: true }));
        }
        else{
          this.toastr.error(res.error);
          this.familyLists = [];     
        }  
        this.relationList=this.registrationService.relationsList.sort((a,b)=>a.relation.toLowerCase()>b.relation.toLowerCase()?1:-1);
      })
      
  }
  // 
  OfflinePocSubmit(patient_data: any) {
    sessionStorage.setItem('pocEditFmember',JSON.stringify(patient_data))
    sessionStorage.setItem('familymemberscreenT','familymemberscreen');
    sessionStorage.setItem('patientidchat',patient_data.id);
    //new changes
    sessionStorage.setItem('callingpatient_id_fm',patient_data.id)
    sessionStorage.setItem('callingpatient_id',patient_data.beneficiary_id)
    sessionStorage.setItem('patientidchat_fm',patient_data.id);
    sessionStorage.setItem('agentcallpname',patient_data.member_name);
    sessionStorage.setItem('isfmlymembr','familymember');
    sessionStorage.setItem('fm_age',patient_data.age)
    sessionStorage.setItem('fm_email',patient_data.email)
    sessionStorage.setItem('fm_gender_id',patient_data.gender_id)
    sessionStorage.setItem('fm_mobile',patient_data.mobile)
    //console.log(patient_data);
    // let payload = {
    //   "patient_id": patient_data.id,
    //   "doctor_id": this.doctorDetails.id,
    //   "patient_name": patient_data.first_name + patient_data.last_name,
    //   "patient_email": patient_data.email
    // }
    let benId = this.registrationService.familyDetails.id;
    let benName = this.registrationService.familyDetails.first_name+''+this.registrationService.familyDetails.last_name;
    let benAge = this.registrationService.familyDetails.age;
    let benEmail = this.registrationService.familyDetails.email
    let payload2 = {
      "patient_id": patient_data.id,
      "doctor_id": this.doctorDetails.id,
      "patient_name": benName,
      "patient_email": benEmail,
      "chart_history": {
        "patientName":benName,
        "age": benAge,
        "symptoms": "",
        "temperature": "",
        "shivering": "",
        "anyOtherProblem": "",
        "anyHealthIssues": "",
        "allergies": "no",
        "patientInformation": {
          "id": patient_data.id,
          "name": patient_data.member_name,
          "genderId": patient_data.gender_id,
          "gender": "",
          "age":patient_data.age,
          "mobile": patient_data.mobile,
          "familyMemberId":patient_data.id,
          "isSelf": false,
          "Symptoms": "",
          "symptonsEng": ""
        }
      }
    }

    // let payload = {
    //   "patient_id": patient_data?.id,
    //   "doctor_id":  this.doctorDetails?.id,
    //   "patient_name": patient_data?.first_name +' '+ patient_data.middle_name +' '+ patient_data.last_name,
    //   "patient_email": patient_data.email
    // }
    // this.regService.generateConsulation('POST', payload).subscribe(res => {




    let payload = {
      "patient_id": patient_data.beneficiary_id,
      "doctor_id": this.doctorDetails.id,
      "patient_name": patient_data.member_name,
      "patient_email": ''
    }
    this.registrationService.generateConsulation('POST', payload).subscribe(res => {
      if (res.status == 'OK') {
        let fromId = (this.doctorDetails.id).toString();
        let toId = (patient_data.id).toString();
        $("#fromId").val("doc_" + fromId);
        $("#toId").val("pat_" + toId);
        $("#order_no").val(res.data.order_no);
        $("#is_self").val(false);
        $("#patient_name").val(patient_data.first_name + patient_data.last_name);
        $("#patient_interaction_id").val(res.data.consultation_id);
        $("#patient_info").val(JSON.stringify({ "patient_interaction_id": res.data.consultation_id, "order_no": res.data.order_no, "hospital_id": "", "symptoms": "", "is_self": false }));
        sessionStorage.isPatient = true;
        this.activeModal.dismiss('Cross click')
        this.router.navigate([`home/${sessionStorage.getItem('pocType')}`])
          .then((e) => {
            let ws = JSON.parse(sessionStorage.getItem('webSkt')); // JqueryReady(); // answerCall(fromId, toId);
            Promise.all([JqueryReady()]).then(res => { answerCall(fromId, toId, ws.expn, ws.qlfnName, ws.grCode, ws.irCode, ws.erCode); });
            if (e)
              console.log("Navigation is successful!");
            else
              console.log("Navigation has failed!");
          });

      }
      else {
        this.toastr.warning(res.message, 'Generate Consulation');
      }
    });


  }

  getpatientid(item:any){
    sessionStorage.setItem('callingpatient_id',item.id);
    sessionStorage.setItem('is_self_ehr',0+"");
    this.activeModal.dismiss('Cross click');
    let val=false;
    localStorage.setItem('ehrback',JSON.stringify(val));
    this.router.navigate(['home/consultation/ehrReport']);
  }
  onSubmit(event:any){
    let benId = this.registrationService.familyDetails.id
    let payLoad = {
      "beneficiary_id": benId ,
      "member_name": this.familyForm.value.first_name +' '+ this.familyForm.value.last_name,
      "age": this.familyForm.value.age,
      "mobile":this.familyForm.value.mobile,
      "email": this.familyForm.value.email,
      "gender_id":parseInt(this.familyForm.value.gender_id) ,
      "relation_id":this.familyForm.value.relation_id,
      "agent_id":sessionStorage.agentId,
      "relation":"",
      "is_guardian": false
    }
    // this.relationList.find(e => e.id == this.familyForm.value.relation_id).relation 
      this.submitted = true, Common.getFormValidationErrors(this.familyForm);
      if (this.familyForm.invalid) {
        this.toastr.info('Please Enter All Mandatory Fields');
        return;
      }
      else {
        // let payLoad = this.familyForm.getRawValue();
        if(this.familyForm.value.relation_id > 0){
          payLoad.relation = this.relationList.find(e => e.id == this.familyForm.value.relation_id).relation ;
          
        }
        if(payLoad.agent_id==undefined){
          payLoad.agent_id=null;
        }
        this.registrationService.saveFamilyMem(payLoad).subscribe(res => {
          if (res) {
            if (res.status == "OK")
               this.submitted=false,
              this.initFormControls(),
              this.LoadFamily();
            else
              this.toastr.error(res.message);
          }
          else
            this.toastr.error(res.message);
        })
      }

  }
  get f() { return this.familyForm.controls; }

  memberHCDwnld(i:any){
    this.registrationService.getMemID(i.id).subscribe(res => {
      this.idCard = res.data
     if (this.idCard == '') {
       this.toastr.info(res.message);
       return;
     }else{
       window.open(this.idCard)
     }

   })
  }
  showOrHideMenuItems = async (arrPermision: any[], screenName: string) => {
    this.menuItems[screenName] = arrPermision.some(e => e.patient && e.feature_id.feature_name === screenName);
  }
  getUserPermission(orgId: number) {
    let orgIdValue=JSON.parse(sessionStorage.getItem('orgid'));
  this.userServices.getUserPermissionByOrgId(orgIdValue).pipe(shareReplay())
      .subscribe((res:any) => {
        if (res.status == 'OK') {
          this.showOrHideMenuItems(res.data, 'isVle');
         
        }
      }, error => { });
  }
  opendoclist(item:any) {
    sessionStorage.setItem('patlistA','patlistagent');
    //  register('', 'pat_'+item.id,item.first_name, '', '', '', '');
    //  //statusRegister('', '', '', '', '', '');
    //  //updateStatus(item.id,"7",item.status,"call",'',item.numberOfYrsExperience,item.qualification,item.groupReferenceCode,item.individualReferenceCode,item.exclusiveReferenceCode)
    //  statusRegister(item.id, 'patient', "AVAILABLE", "register", '', '', '', '', '', '')
     sessionStorage.setItem('callingpatient_id_fm',item.id)
     sessionStorage.setItem('callingpatient_id',item.beneficiary_id)
     sessionStorage.setItem('patientidchat_fm',item.id);
     sessionStorage.setItem('agentcallpname',item.member_name);
     sessionStorage.setItem('isfmlymembr','familymember');
     sessionStorage.setItem('fm_age',item.age)
     sessionStorage.setItem('fm_email',item.email)
     sessionStorage.setItem('fm_gender_id',item.gender_id)
     sessionStorage.setItem('fm_mobile',item.mobile)
     this.activeModal.dismiss('Cross click');
      const modalRef = this.modalService.open(ActiveDoctorsListComponent, { scrollable: true, size: 'xl' });
      //modalRef.componentInstance.pagetitle = 'familymemberscreen';
      sessionStorage.setItem('familymemberscreenT','familymemberscreen');
    }

    onBack() {
      this.router.navigate(['home/patientList']);
    }

    onClose(){
      //sushma 08-07-2022 hide back button
      let val= JSON.parse(localStorage.getItem('ehrback'));
      if(val == false){
        this.router.navigate(['home/patientList']);
        this.activeModal.dismiss('Cross click');
      }
      else{
        this.activeModal.dismiss('Cross click');
      }
    }

    openBookAppointments(item:any){
      // const modalRef = this.modalService.open(BookAppointmentsComponent, { scrollable: true, size: 'xl' });
      // modalRef.componentInstance.title = this.modalTitle;
      // modalRef.componentInstance.roleId = item.id;
      this.itemData=item
      sessionStorage.setItem('selectedmemberData', JSON.stringify(this.itemData))
      this.router.navigate(['/home/crpt/bookappointment'])
      this.activeModal.dismiss('Cross click');
     
    }

    onBillDetails(patient) {
      if(this.isHealthWellness){
        this.directOpBilling(patient)
     }else{
      console.log(patient);
      this.opBillingForm='Open'
      this.caseNo = patient.id;
      sessionStorage.setItem('fcaseNo',this.caseNo)
      sessionStorage.setItem('patientaddser',undefined)
      sessionStorage.setItem('loaddata','addservice')
      sessionStorage.setItem('addserPatData',JSON.stringify(patient))
      // this.modalService.open(labBilling, { size: 'xl' });
      this.router.navigate([`/home/lab/opbilling`])
      this.activeModal.dismiss('Cross click');
    }
  }

    directOpBilling(item:any){
      let crntuser = JSON.parse(sessionStorage.getItem('currentUser'));
      let collectionTime = this.datePipe.transform(new Date(), 'HH:mm:ss')
      let payload = {
        labOpBilling: {
          age: item.age,
          billAmount: 0,
          billDate: this.datePipe.transform(new Date(), "yyyy-MM-dd") + 'T' + collectionTime,
          // billDate: this.consultation_date +' '+ this.collectionTime,
          billNo: 0,
          caseNo: item.id,
          // cashRequestId: +this.cashReceived,
          // dueAmount: this.dueAmount,
          discountAmount: 0,
          // id: 0,
          is_self: item?.relation == 'self'?true:false,
          doctorName: null,
          isHomeSample: true,
          patientName: item?.member_name,
          patientpay_amount: 0,
          patientpaid_amount: 0,
          status: true,
          visitId: '',
          centerId: crntuser?.id,
          createdBy: crntuser?.id,
        },
        labOpBillingDetails: []
      }
        this.lab.save(`opbilling/save`, payload).subscribe({
          next: res => {
            if (res.status == 'OK') {
             let opDetail = res.data;
            //  this.activeModal.close('Close click');
             this.toastr.success(res.message)
    
            } else {
              let errMsg: string = res.message;
              this.toastr.error(errMsg);
            }
          },
          error: (err: HttpErrorResponse) => {
            this.toastr.error(err.message);
    
          },
          complete: () => {
            // Any additional logic after patching values
          },
        });
    }
}
