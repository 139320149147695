import { Component, EventEmitter, OnInit, Output } from '@angular/core';


@Component({
  selector: 'app-confirm-message-model',
  templateUrl: './confirm-message-model.component.html',
  styleUrls: ['./confirm-message-model.component.css']
})
export class ConfirmMessageModelComponent implements OnInit {

  @Output() gotoConsultation = new EventEmitter<void>();
  constructor() { }

  ngOnInit(): void {
  }

  goto_Consultation(): void {
    this.gotoConsultation.emit();
  }

}

